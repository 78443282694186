.custom-carousel {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .custom-carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .custom-carousel-item {
    min-width: 100%;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .custom-carousel-item.active {
    opacity: 1;
  }
  
  .custom-carousel-item img {
    height: 300px; /* Uniform height for all images */
    object-fit: cover;
    width:420px;
  }
  
  .custom-carousel-item img.special-width {
    width: 300px; /* Specific width for the second image */
    object-fit: contain; /* Adjusts image within the set width, maintaining aspect ratio */
  }
  
  .custom-carousel-pointers {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .custom-pointer {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: gray;
    margin: 0 5px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
  }
  
  .custom-pointer.active {
    background-color: black;
  }
  