.banner-container {
  padding-right: 10px !important;
  padding-left: 15px !important;
  padding-top: 120px;
  padding-bottom: 2rem;
  display: flex;
  justify-content: space-between;
}
.diwali-image{
  height: 32rem;
  position: relative;
  left: 2%;

}
.main-bnr-one .point-list li.show i{
  display: none !important;
}
.left-banner,
.right-banner {
  width: 48%;
}
.select_logos {
  padding-bottom: 30px;
  height: 6rem;
  margin: auto;
}

.watch-container {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.watch-container:hover {
  transform: scale(1.05);
}

.watch-container:hover div {
  cursor: pointer;
  font-weight: 500;
}

.watch-button {
  display: flex;
  gap: 30px;
  align-items: center;
  margin-bottom: 3rem;
}

.slider-2,
.slider-1,
.slider-3,
.slider-4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.watch-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  flex-grow: 1;
}

.button-container .btn {
  width: 100%;
}

.custom-button {
  height: 45px;
  padding: 9px !important;
  border-radius: 1.4rem;
  border: 1px solid #ff4848;
  color: white !important;
  background: #ff4848;
  font-weight: 500;
}
.custom-button a {
  color: white !important;
}

.free-watch-title {
  text-align: center;
  cursor: pointer;
  transition: font-weight 0.15s ease;
  font-size: 16px !important;
}

.free-watch-title:hover {
  font-weight: 600;
}

.right-watch-box {
  display: none;
}

.point-list li:nth-child(1) {
  right: 0 !important;
  bottom: 0 !important;
}
.point-list li:nth-child(2) {
  left: 2% !important;
  bottom: 46% !important;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 10px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "•";
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.right-banner {
  height: 30rem !important;
  width: 30rem !important;
}
.first-slide-image.imgs {
  width: fit-content !important;
  height: 30rem;
  width: 30rem !important;
  object-fit: cover;
  /* margin-bottom: 16rem; */
  border-radius: 10px;
  /* margin-top: 40px !important; */
}
/* Mobile devices in portrait mode */
@media only screen and (max-width: 767px) and (orientation: portrait) {

  .phone-myclnq-img {
    width: 200px !important;
    height: auto !important;
  }
  .nav-buttons.fixed-bottom-navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    justify-content: center;
    background-color: #fff;
    z-index: 1000;
  }

  .fixed-bottom-navbar {
    display: flex !important;
    position: fixed !important;
    bottom: 0;
    /* width: 100%; */
    /* box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1); */
    background: transparent;
    transition: all 0.5s;
    z-index: 999;
    animation: 0.95s ease 0s normal forwards 1 running headerSlideup;
  }
  .qr-image-header {
    height: 3.3rem;
  }
  .select_logos {
    margin-top: 2rem;
  }
  .banner-container {
    flex-direction: column-reverse;
    padding-top: 80px;
  }
  .left-banner {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
  }

  .right-banner {
    width: 100%;
    justify-content: center;
  }
  .diwali-image{
    height: 23rem;
    position: relative;
    left: 28%;
    width: 12rem;
    top: 7%;
  }

  .main-container-box {
    width: 100dvw;
  }

  .watch-button {
    flex-direction: column;
    margin-bottom: 0;
    padding-top: 2rem;
  }

  .watch-container {
    flex-direction: column;
    width: 100%;
    padding-bottom: 6rem;
    text-align: center;
  }

  .position-point {
    display: block !important;
  }

  .point-list li:nth-child(1) {
    right: 1% !important;
    bottom: 0 !important;
  }
  .point-list li:nth-child(2) {
    left: 2% !important;
    bottom: 44% !important;
  }

  .custom-neg-marg {
    margin-top: -6rem !important;
  }

  .mobile-fixed-topbar {
    display: block !important;
    position: fixed !important;
    bottom: 0;
    /* width: 100%; */
    /* box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1); */
    background: transparent;
    transition: all 0.5s;
    z-index: 999;
    animation: none !important;
  }
  .fixed-topbar-right ul {
    display: flex;
    gap: 10px !important;
  }
  .fixed-topbar-right ul li {
    padding-left: 0 !important;
  }
  .fixed-topbar-left {
    display: none !important;
  }

  .custom-buttons {
    background-color: #ff4848 !important;
    border: 1px solid #ff4848 !important;
    border-radius: 1.4rem !important;
    padding: 8px 15px !important;
  }

  .dz-topbar-left {
    display: none !important;
  }

  .right-mobile-view ul {
    display: flex !important;
    gap: 10px;
    padding-left: 0 !important ;
  }
  .right-mobile-view ul li {
    display: flex !important;
    padding-left: 0 !important ;
  }
  .right-banner {
    height: 100dvw !important;
    width: 100dvw !important;
  }
  .first-slide-image.imgs {
    height: 98dvw !important;
    width: 98dvw !important;
  }

  .select_logos {
    height: 6rem !important;
  }

  .banner-container {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .scroltop {
    bottom: 70px !important;
  }

  .article-main-image{
    height: auto !important;
    width: auto !important;
  }
}

/* Mobile devices in landscape mode */
@media only screen and (max-width: 999px) and (orientation: landscape) {
  .banner-container {
    padding-bottom: 5rem;
  }
  .mobile-fixed-topbar {
    display: block !important;
    position: fixed !important;
    bottom: 0;
    /* width: 100%; */
    /* box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1); */
    background: transparent;
    transition: all 0.5s;
    z-index: 999;
    animation: none !important;
  }
  .fixed-topbar-right ul {
    display: flex;
    gap: 10px !important;
  }
  .fixed-topbar-right ul li {
    padding-left: 0 !important;
  }
  .fixed-topbar-left {
    display: none !important;
  }

  .point-list li:nth-child(1).show span {
    transform: translate(-91%, -31%) scale(1);
  }

  .custom-buttons {
    background-color: #ffffff !important;
    border: 1px solid red !important;
    border-radius: 1.4rem !important;
    padding: 8px 15px !important;
  }

  .dz-topbar-left {
    display: none !important;
  }

  .right-mobile-view ul {
    display: flex !important;
    gap: 10px;
    padding-left: 0 !important ;
  }
  .right-mobile-view ul li {
    display: flex !important;
    padding-left: 0 !important ;
  }
  .free-watch-title {
    text-align: center;
  }

  .left-banner {
    padding-top: 0;
  }

  .watch-button {
    flex-direction: column;
  }

  .left-watch-box {
    display: none;
    cursor: pointer !important;
  }

  .right-watch-box {
    display: flex;
    width: 100%;
    margin-bottom: 4rem;
    cursor: pointer !important;
  }
  .right-watch-box img {
    height: 170px;
    width: auto;
    padding-top: 2rem;
  }

  .point-list li span {
    font-size: 8px !important;
  }

  .point-list li:nth-child(1) {
    right: 0 !important;
    bottom: 0 !important;
  }
  .point-list li:nth-child(2) {
    left: 8% !important;
    bottom: 44% !important;
  }

  .position-point {
    display: block !important;
  }

  .first-slide-image.imgs,
  .right-banner {
    width: 33dvw !important;
    height: 33dvw !important;
  }
  .select_logos {
    height: 6rem !important;
  }

  .article-main-image {
    height: 13rem !important;
    width: auto !important;
  }


}
