// Scroltop
.scroltop {
    box-shadow: -4px 4px 24px -10px var(--primary) !important;
    background: var(--primary);
    color: var(--theme-text-color);
    border-color: var(--primary);
    border-style: solid;
    border-width: 0;
    bottom: 15px;
    cursor: pointer;
    display: none;
    height: 50px;
    line-height: 50px;
    margin: 0;
    position: fixed;
    right: 15px;
    text-align: center;
    width: 50px;
    z-index: 999;
    box-shadow: -4px 4px 24px -10px;
	@include transitionSlow;
	
	@include respond('phone'){
		height: 35px;
		width: 35px;
		line-height: 35px;
	}
}