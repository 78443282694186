// Banner One
.main-bnr-two{
	position:relative;
  	align-items: center;
	z-index:1;
	overflow: hidden;
	
	&::after{
		content: ' ';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--primary);
		opacity: 0.2;
		z-index: -1;
	}
	
	.banner-media{
		text-align: center;
		z-index: 2;
		position: relative;
		max-width: 100%;
		width: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		
		@include respond('laptop'){
			img{
				max-width: 90%;
			}
		}
	}
	
	.banner-inner{
		display: flex;
		min-height: 960px;
		padding-top: 180px;
		position: relative;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		
		@include respond('laptop'){
			min-height: 700px;	
			padding-top: 50px;
		}
		@include respond('tab-land'){
			min-height: 700px;	
			padding-top: 50px;
		}	
		@include respond('tab-port'){
			padding-top: 0;	
			min-height: 500px;
		}
		@include respond('phone-land'){
			min-height: 500px;	
			min-height: 400px;
		}
		@include respond('phone'){
			min-height: 280px;
		}
		
		.data-text{
			position: absolute;
			color: #fff;
			font-weight: 700;
			font-size: 330px;
			-webkit-text-shadow: 0px -15px 50px rgba(152, 54, 0, 0.1);
			background: linear-gradient(180deg, #FFFFFF 25%, rgba(255, 255, 255, 0) 80%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			text-fill-color: transparent;
			top: 16%;
			left: 0;
			bottom: auto;
			width: 100%;
			display: flex;
			justify-content: center;
			opacity: 1;
			
			span{
				animation: none;
			}
			@include respond('laptop'){
				font-size: 250px;
				top: 10%;
			}
			@media (max-width:1401px){
				font-size: 220px;
				top: 28%;
			}
			@include respond('tab-port'){
				font-size: 130px;
				top: 30%;
			}
			@include respond('phone-land'){
				font-size: 110px;	
			}
			@include respond('phone'){
				font-size: 60px;	
				top: 40%;
			}
		}
	}
}

