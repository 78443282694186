.diwali-section-main{
    height: 80rem;
    background-color: white;
    background-image: url("../assets//images//about//about-bg.png");
}
.main-diwali-box{
    position: relative;
    top: 90px;
    width: 95%;
    margin-left: 2.5rem;
    border-radius: 2rem;
    border-right: 2px dashed rgb(220, 192, 35); /* Dashed blue right border */
    border-bottom: 2px dashed rgb(236, 206, 37); /* Dashed blue right border */
    border-left: 2px dashed rgb(236, 206, 37); /* Dashed blue right border */
    border-top: 2px dashed rgb(236, 206, 37); /* Dashed blue right border */

}
.content-section-diwali h4{
    /* color: #9e7ebc; Violet color */
    font-size: 1.2rem;
  }
 
  .content-section-diwali {
    position: relative; /* Required for positioning the pseudo-element */
    color: white; /* Text color */
    overflow: hidden; /* Prevent content overflow */
    width: 85%;
    padding: 1.5rem;
  margin: 4rem 1rem 1rem 6.8rem;
  box-shadow: 0px -1px 10px 4px rgba(231, 228, 228, 0.5);
  border-radius: 10px; /* Rounded corners */
  margin-bottom: 1rem;
  background-image: url("https://c8.alamy.com/comp/ENK9N5/diwali-diya-with-sparkling-crackers-ENK9N5.jpg");
}

.content-section-diwali::before {
    content: ""; /* Necessary for pseudo-element */
    position: absolute; /* Position it relative to the parent */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("https://c8.alamy.com/comp/ENK9N5/diwali-diya-with-sparkling-crackers-ENK9N5.jpg");
    background-size:250%; /* Make sure the image covers the div */
    background-repeat: no-repeat;
    filter: blur(8px); /* Adjust blur intensity */
    z-index: 1; /* Behind the text */
}

.content-section-diwali h4, /* Adjust as needed for your text elements */
.content-section-diwali p, ol,ul {
    position: relative; /* Required to bring text above the pseudo-element */
    z-index: 2; /* Ensure text appears above the blurred image */
    color: black;
}

.diwlai-offer-img{
    height: 14rem;
    border-radius: 7px;
    position: relative;
    left: 36%;
    top: 32px;
}
.list-style{
    margin-left: 2rem;
}
.list-style li{
    list-style-type: square;
}
.qr-image-phone{
    position: absolute;
    left: 73%;
    top: -1%;
    height: 29rem;
    z-index: 2;
}

@media (max-width: 768px) { /* Tablet view */
    .main-diwali-box {
        top: 60px; /* Adjust top positioning */
        width: 90%; /* Slightly reduce width */
        margin-left: 5%; /* Center the box */
    }
    
    .content-section-diwali {
        margin: 2rem 0; /* Reduce margins */
        padding: 1rem; /* Keep padding consistent */
    }
    
    .diwlai-offer-img {
        height: 12rem; /* Reduce image size */
        top: 24px; /* Adjust top position */
    }

    .qr-image-phone {
        left: 50%; /* Center the QR image */
        top: 10%; /* Adjust top position */
        transform: translateX(-50%); /* Center the QR image */
        height: 15rem; /* Reduce QR image size */
    }
}

@media (max-width: 480px) { /* Mobile view */
    .main-diwali-box {
        top: 70px; /* Further adjust top positioning */
        width: 95%; /* Full width */
        margin-left: auto; /* Centering */
        margin-right: auto; /* Centering */
    }
    .diwali-section-main{

        height: 110rem !important;
    }
    .content-section-diwali {
        margin: 1rem 0; /* Further reduce margins */
        width: 100%;
        top: 10px;
        height: 90rem;
    }

    .diwlai-offer-img {
        height: 10rem; /* Further reduce image size */
        top: 16px; /* Adjust top position */
        left: 16%;
    }

    .qr-image-phone {
        left: 50%; /* Center the QR image */
        top: 5%; /* Adjust top position */
        transform: translateX(-50%); /* Center the QR image */
        height: 12rem; /* Further reduce QR image size */
    }
    .content-section-diwali::before {
        content: ""; /* Necessary for pseudo-element */
        position: absolute; /* Position it relative to the parent */
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url("https://c8.alamy.com/comp/ENK9N5/diwali-diya-with-sparkling-crackers-ENK9N5.jpg");
        background-size:690%; /* Make sure the image covers the div */
        background-repeat: no-repeat;
        filter: blur(8px); /* Adjust blur intensity */
        z-index: 1; /* Behind the text */
    }

    .content-section-diwali h4 {
        font-size: 1.1rem; /* Adjust font size */
    }
    
    .content-section-diwali p {
        font-size: 0.9rem; /* Adjust font size */
    }
    .qr-image-phone{
        top: 10%;
    }
}

@media (max-width: 414px){
    .diwali-section-main{

        height: 109rem !important;
    }
    .content-section-diwali {
        margin: 1rem 0; /* Further reduce margins */
        width: 100%;
        top: 32px;
        height: auto;
    }
}
@media (max-width: 390px){
    .diwali-section-main{

        height: 119rem !important;
    }
    .content-section-diwali {
        margin: 1rem 0; /* Further reduce margins */
        width: 100%;
        top: 32px;
        height: auto;
    }
}