.audio-player {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #fff;
    border-radius: 5px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center ;
}

.play-pause-button {
    background: none;
    border: none;
    font-size: 14px;
    cursor: pointer;
    outline: none;
}

.play-pause-button i {
    color: #ff4848;
}
.text{
    text-wrap: nowrap;
    color: black;

}
