.box-header.clearfix {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  width: 100%;
}

.custom-navbar {
  max-width: 1401px;
  margin: auto;
  padding: 0 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-fixed {
  position: relative;
  z-index: 1;
}

.nav-fixed::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  transform: skew(-10deg);
  z-index: -1;
}
.nav-buttons {
  display: flex;
  gap: 20px;
}

.logo-header {
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
}

.qr-image-header {
  height: 4rem;
  border-radius: 5px;
}
.qr-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.qr-box p {
  margin-bottom: 0;
  color: #ff4848;
  font-weight: 500;
  text-align: center;
}

.new-button-border2 {
  border: 1px solid #ff4848;
  padding: 9px 20px;
  background-color: white;
  border-radius: 1.4rem;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}
.new-button-border2:hover {
  border: 1px solid #ff4848;
  color: white !important;
  background: #ff4848;
}

.new-button-border2:hover span,
.new-button-border2:hover i {
  color: white !important;
  transition: color 0.3s ease;
}

.new-button-border {
  background-color: white;
  color: #ff4848;
  border: 1px solid #ff4848;
  padding: 9px 20px;
  height: 45px;
  border-radius: 1.4rem;
  font-weight: 500;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.new-button-border:hover {
  border: 1px solid #ff4848;
  color: white !important;
  background: #ff4848;
}

.new-button-border:hover a {
  color: white !important;
  transition: color 0.3s ease;
}

.nav-buttons {
  align-items: center;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .custom-navbar {
    flex-direction: column;
    /* padding: 0 0 10px 0; */
  }

  .logo-header {
    width: 100dvw !important;
    flex-grow: 1;
    padding-left: 15px !important;
  }
  .nav-fixed::before {
    transform: skew(0deg);
  }

  .nav-buttons {
    /* margin-left: 10px; */
    padding: 5px 0;
    align-items: center;
  }
  .footer-bottom {
    margin-bottom: 4rem;
  }
}
