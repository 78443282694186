/*
    =========================
	Template Name 	 : BodyShape
	Author			 : DexignZone
	Version			 : 2.0
	Author Portfolio : https://themeforest.net/user/dexignzone
	=========================
	
	[Table of Contents] 

	=== SCSS Start ===
	
    1. Abstract
		1.1 abstract.scss
		1.2 mixins.scss
		1.3 variable.scss
		
    2. Base
		2.1 base.scss
		2.2 custom-grid.scss
		2.3 fonts.scss
		2.4 preloader.scss
		2.5 reset.scss
		2.6 shortcode.scss
		
    3. Components
		3.1 About
		3.2 Blog Post
		3.3 Iconbox
		3.4 Portfolio
		3.5 Section Head
		3.6 Team
		3.7 Testimonials
		3.8 Other scss File
		
    4. Layout
		4.1 Banner
		4.2 Footer
		4.3 Header
		4.4 Theme
		4.5 Other scss File
		
    5. Pages
		5.1 index.scss
		5.2 blog-details.scss
		5.3 coming-soon.scss
		5.4 contact-us.scss
		5.5 course-details.scss
		5.6 error-404.scss
		5.7 event-detail.scss
		5.8 pages.scss
		5.9 under-construction.scss
		
	=== SCSS Start ===
*/
/*=== Abstract ===*/
@import url("../icons/font-awesome/css/all.min.css");
@import url("../icons/flaticon/flaticon_bodyshape.css");
:root {
  --primary: #ff4848;
  --secondary: #232323;
  --gray: #f3f4f8;
  --primary-hover: #ff4848;
  --primary-dark: #9f3a00;
  --rgba-secondary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-2: rgba(255, 72, 72, 0.2);
  --rgba-primary-3: rgba(255, 72, 72, 0.3);
  --rgba-primary-4: rgba(255, 72, 72, 0.4);
  --rgba-primary-5: rgba(255, 72, 72, 0.5);
  --rgba-primary-6: rgba(255, 72, 72, 0.6);
  --rgba-primary-7: rgba(255, 72, 72, 0.7);
  --rgba-primary-8: rgba(255, 72, 72, 0.8);
  --rgba-primary-9: rgba(255, 72, 72, 0.9);
  --border-radius-base: 6px;
  --font-family-base: Poppins, sans-serif;
  --font-family-title: Poppins, sans-serif;
  --title: #222222;
}

/*=== Bootstrap ===*/
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #ff4848;
  --bs-secondary: #232323;
  --bs-success: #13c24d;
  --bs-info: #00aeff;
  --bs-warning: #ffbd13;
  --bs-danger: #ff0003;
  --bs-light: #faf8f2;
  --bs-dark: #222222;
  --bs-primary-rgb: 255, 129, 57;
  --bs-secondary-rgb: 35, 35, 35;
  --bs-success-rgb: 19, 194, 77;
  --bs-info-rgb: 0, 174, 255;
  --bs-warning-rgb: 255, 189, 19;
  --bs-danger-rgb: 255, 0, 3;
  --bs-light-rgb: 250, 248, 242;
  --bs-dark-rgb: 34, 34, 34;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 102, 102, 102;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Poppins, sans-serif;
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #666666;
  --bs-body-bg: #ffffff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
.comments-title,
.blog-title,
.comment-reply-title,
.wp-block-search .wp-block-search__label,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 800;
  line-height: 1.2;
  color: #222222;
}
.color-red-head {
  color: var(--bs-primary);
}
.bazaar-img {
  border-radius: 1rem;
}
h1,
.h1 {
  font-size: calc(1.3875rem + 1.65vw);
}
@media (min-width: 1401px) {
  h1,
  .h1 {
    font-size: 2.625rem;
  }
}

h2,
.h2 {
  font-size: calc(1.35rem + 1.2vw);
}
@media (min-width: 1401px) {
  h2,
  .h2 {
    font-size: 2.25rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1401px) {
  h3,
  .h3 {
    font-size: 1.875rem;
  }
}

h4,
.h4,
.comments-title,
.blog-title,
.comment-reply-title,
.wp-block-search .wp-block-search__label {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1401px) {
  h4,
  .h4,
  .comments-title,
  .blog-title,
  .comment-reply-title,
  .wp-block-search .wp-block-search__label {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #ff4848;
  text-decoration: underline;
}
a:hover {
  color: #cc672e;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #ff5e78;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.75rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #aeaed5;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1401px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.17188rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1401px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1401px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1401px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1401px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1401px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1401px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.17188rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1170px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1401px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 49%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1170px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 49%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 57.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: max-content;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #666666;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #666666;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #666666;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #666666;
  vertical-align: top;
  border-color: #e1e1f0;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #ffe6d7;
  --bs-table-striped-bg: #f2dbcc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6cfc2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd5c7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6cfc2;
}

.table-secondary {
  --bs-table-bg: lightgray;
  --bs-table-striped-bg: #c8c8c8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bebebe;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c3c3c3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bebebe;
}

.table-success {
  --bs-table-bg: #d0f3db;
  --bs-table-striped-bg: #c6e7d0;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbdbc5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0e1cb;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bbdbc5;
}

.table-info {
  --bs-table-bg: #ccefff;
  --bs-table-striped-bg: #c2e3f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d7e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bdddec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b8d7e6;
}

.table-warning {
  --bs-table-bg: #fff2d0;
  --bs-table-striped-bg: #f2e6c6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dabb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece0c0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dabb;
}

.table-danger {
  --bs-table-bg: #ffcccd;
  --bs-table-striped-bg: #f2c2c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6b8b9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecbdbe;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6b8b9;
}

.table-light {
  --bs-table-bg: #faf8f2;
  --bs-table-striped-bg: #eeece6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1dfda;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7e5e0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1dfda;
}

.table-dark {
  --bs-table-bg: #222222;
  --bs-table-striped-bg: #2d2d2d;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #383838;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #333333;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #383838;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1169.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.17188rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.82031rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #aeaed5;
}

.form-control,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #666666;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control,
  .wp-block-categories-dropdown select,
  .wp-block-archives-dropdown select {
    transition: none;
  }
}
.form-control[type="file"],
.wp-block-categories-dropdown select[type="file"],
.wp-block-archives-dropdown select[type="file"] {
  overflow: hidden;
}
.form-control[type="file"]:not(:disabled):not([readonly]),
.wp-block-categories-dropdown select[type="file"]:not(:disabled):not([readonly]),
.wp-block-archives-dropdown select[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus {
  color: #666666;
  background-color: #ffffff;
  border-color: #ffc09c;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(255, 129, 57, 0.25);
}
.form-control::-webkit-date-and-time-value,
.wp-block-categories-dropdown select::-webkit-date-and-time-value,
.wp-block-archives-dropdown select::-webkit-date-and-time-value {
  height: 1.6em;
}
.form-control::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.wp-block-categories-dropdown select:disabled,
.wp-block-archives-dropdown select:disabled,
.form-control[readonly],
.wp-block-categories-dropdown select[readonly],
.wp-block-archives-dropdown select[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button,
.wp-block-categories-dropdown select::file-selector-button,
.wp-block-archives-dropdown select::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #666666;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button,
  .wp-block-categories-dropdown select::file-selector-button,
  .wp-block-archives-dropdown select::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.wp-block-categories-dropdown select:hover:not(:disabled):not([readonly])::file-selector-button,
.wp-block-archives-dropdown select:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button,
.wp-block-categories-dropdown select::-webkit-file-upload-button,
.wp-block-archives-dropdown select::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #666666;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button,
  .wp-block-categories-dropdown select::-webkit-file-upload-button,
  .wp-block-archives-dropdown select::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.wp-block-categories-dropdown select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.wp-block-archives-dropdown select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.6;
  color: #666666;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.6em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.6em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.6em + 1rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.6em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.6em;
  border-radius: 0.25rem;
}

.form-select,
select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #666666;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select,
  select {
    transition: none;
  }
}
.form-select:focus,
select:focus {
  border-color: #ffc09c;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(255, 129, 57, 0.25);
}
.form-select[multiple],
select[multiple],
.form-select[size]:not([size="1"]),
select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled,
select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring,
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #666666;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.3em;
  vertical-align: top;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #ffc09c;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(255, 129, 57, 0.25);
}
.form-check-input:checked {
  background-color: #ff4848;
  border-color: #ff4848;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #ff4848;
  border-color: #ff4848;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffc09c'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check[disabled] + .wp-block-button__link,
.btn-check:disabled + .btn,
.btn-check:disabled + .wp-block-button__link {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(255, 129, 57, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(255, 129, 57, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #ff4848;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #ffd9c4;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #ff4848;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #ffd9c4;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.wp-block-categories-dropdown .form-floating > select,
.wp-block-archives-dropdown .form-floating > select,
.form-floating > .form-select,
.form-floating > select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.wp-block-categories-dropdown .form-floating > select,
.wp-block-archives-dropdown .form-floating > select {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.wp-block-categories-dropdown .form-floating > select::placeholder,
.wp-block-archives-dropdown .form-floating > select::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus,
.wp-block-categories-dropdown .form-floating > select:focus,
.wp-block-archives-dropdown .form-floating > select:focus,
.form-floating > .form-control:not(:placeholder-shown),
.wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown),
.wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.wp-block-categories-dropdown .form-floating > select:-webkit-autofill,
.wp-block-archives-dropdown .form-floating > select:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select,
.form-floating > select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.wp-block-categories-dropdown .form-floating > select:focus ~ label,
.wp-block-archives-dropdown .form-floating > select:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown) ~ label,
.wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label,
.form-floating > select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label,
.wp-block-categories-dropdown .form-floating > select:-webkit-autofill ~ label,
.wp-block-archives-dropdown .form-floating > select:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.h-80{
  height: 80px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.wp-block-categories-dropdown .input-group > select,
.wp-block-archives-dropdown .input-group > select,
.input-group > .form-select,
.input-group > select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.wp-block-categories-dropdown .input-group > select:focus,
.wp-block-archives-dropdown .input-group > select:focus,
.input-group > .form-select:focus,
.input-group > select:focus {
  z-index: 3;
}
.input-group .btn,
.input-group .wp-block-button__link {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus,
.input-group .wp-block-button__link:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #666666;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.wp-block-categories-dropdown .input-group-lg > select,
.wp-block-archives-dropdown .input-group-lg > select,
.input-group-lg > .form-select,
.input-group-lg > select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn,
.input-group-lg > .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}

.input-group-sm > .form-control,
.wp-block-categories-dropdown .input-group-sm > select,
.wp-block-archives-dropdown .input-group-sm > select,
.input-group-sm > .form-select,
.input-group-sm > select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn,
.input-group-sm > .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}

.input-group-lg > .form-select,
.input-group-lg > select,
.input-group-sm > .form-select,
.input-group-sm > select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #13c24d;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.82031rem;
  color: #000;
  background-color: rgba(19, 194, 77, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.was-validated .wp-block-categories-dropdown select:valid,
.wp-block-categories-dropdown .was-validated select:valid,
.was-validated .wp-block-archives-dropdown select:valid,
.wp-block-archives-dropdown .was-validated select:valid,
.form-control.is-valid,
.wp-block-categories-dropdown select.is-valid,
.wp-block-archives-dropdown select.is-valid {
  border-color: #13c24d;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2313c24d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.was-validated .wp-block-categories-dropdown select:valid:focus,
.wp-block-categories-dropdown .was-validated select:valid:focus,
.was-validated .wp-block-archives-dropdown select:valid:focus,
.wp-block-archives-dropdown .was-validated select:valid:focus,
.form-control.is-valid:focus,
.wp-block-categories-dropdown select.is-valid:focus,
.wp-block-archives-dropdown select.is-valid:focus {
  border-color: #13c24d;
  box-shadow: 0 0 0 0rem rgba(19, 194, 77, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:valid,
.was-validated select:valid,
.form-select.is-valid,
select.is-valid {
  border-color: #13c24d;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.was-validated select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"],
select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2313c24d' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.was-validated select:valid:focus,
.form-select.is-valid:focus,
select.is-valid:focus {
  border-color: #13c24d;
  box-shadow: 0 0 0 0rem rgba(19, 194, 77, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #13c24d;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #13c24d;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0rem rgba(19, 194, 77, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #13c24d;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.was-validated .input-group .wp-block-categories-dropdown select:valid,
.wp-block-categories-dropdown .was-validated .input-group select:valid,
.was-validated .input-group .wp-block-archives-dropdown select:valid,
.wp-block-archives-dropdown .was-validated .input-group select:valid,
.input-group .form-control.is-valid,
.input-group .wp-block-categories-dropdown select.is-valid,
.wp-block-categories-dropdown .input-group select.is-valid,
.input-group .wp-block-archives-dropdown select.is-valid,
.wp-block-archives-dropdown .input-group select.is-valid,
.was-validated .input-group .form-select:valid,
.was-validated .input-group select:valid,
.input-group .form-select.is-valid,
.input-group select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .wp-block-categories-dropdown select:valid:focus,
.wp-block-categories-dropdown .was-validated .input-group select:valid:focus,
.was-validated .input-group .wp-block-archives-dropdown select:valid:focus,
.wp-block-archives-dropdown .was-validated .input-group select:valid:focus,
.input-group .form-control.is-valid:focus,
.input-group .wp-block-categories-dropdown select.is-valid:focus,
.wp-block-categories-dropdown .input-group select.is-valid:focus,
.input-group .wp-block-archives-dropdown select.is-valid:focus,
.wp-block-archives-dropdown .input-group select.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.was-validated .input-group select:valid:focus,
.input-group .form-select.is-valid:focus,
.input-group select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ff0003;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.82031rem;
  color: #000;
  background-color: rgba(255, 0, 3, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.was-validated .wp-block-categories-dropdown select:invalid,
.wp-block-categories-dropdown .was-validated select:invalid,
.was-validated .wp-block-archives-dropdown select:invalid,
.wp-block-archives-dropdown .was-validated select:invalid,
.form-control.is-invalid,
.wp-block-categories-dropdown select.is-invalid,
.wp-block-archives-dropdown select.is-invalid {
  border-color: #ff0003;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff0003'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff0003' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.was-validated .wp-block-categories-dropdown select:invalid:focus,
.wp-block-categories-dropdown .was-validated select:invalid:focus,
.was-validated .wp-block-archives-dropdown select:invalid:focus,
.wp-block-archives-dropdown .was-validated select:invalid:focus,
.form-control.is-invalid:focus,
.wp-block-categories-dropdown select.is-invalid:focus,
.wp-block-archives-dropdown select.is-invalid:focus {
  border-color: #ff0003;
  box-shadow: 0 0 0 0rem rgba(255, 0, 3, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:invalid,
.was-validated select:invalid,
.form-select.is-invalid,
select.is-invalid {
  border-color: #ff0003;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.was-validated select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"],
select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff0003'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff0003' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.was-validated select:invalid:focus,
.form-select.is-invalid:focus,
select.is-invalid:focus {
  border-color: #ff0003;
  box-shadow: 0 0 0 0rem rgba(255, 0, 3, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #ff0003;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #ff0003;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0rem rgba(255, 0, 3, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #ff0003;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.was-validated .input-group .wp-block-categories-dropdown select:invalid,
.wp-block-categories-dropdown .was-validated .input-group select:invalid,
.was-validated .input-group .wp-block-archives-dropdown select:invalid,
.wp-block-archives-dropdown .was-validated .input-group select:invalid,
.input-group .form-control.is-invalid,
.input-group .wp-block-categories-dropdown select.is-invalid,
.wp-block-categories-dropdown .input-group select.is-invalid,
.input-group .wp-block-archives-dropdown select.is-invalid,
.wp-block-archives-dropdown .input-group select.is-invalid,
.was-validated .input-group .form-select:invalid,
.was-validated .input-group select:invalid,
.input-group .form-select.is-invalid,
.input-group select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .wp-block-categories-dropdown select:invalid:focus,
.wp-block-categories-dropdown .was-validated .input-group select:invalid:focus,
.was-validated .input-group .wp-block-archives-dropdown select:invalid:focus,
.wp-block-archives-dropdown .was-validated .input-group select:invalid:focus,
.input-group .form-control.is-invalid:focus,
.input-group .wp-block-categories-dropdown select.is-invalid:focus,
.wp-block-categories-dropdown .input-group select.is-invalid:focus,
.input-group .wp-block-archives-dropdown select.is-invalid:focus,
.wp-block-archives-dropdown .input-group select.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.was-validated .input-group select:invalid:focus,
.input-group .form-select.is-invalid:focus,
.input-group select.is-invalid:focus {
  z-index: 3;
}

.btn,
.wp-block-button__link {
  display: inline-block;
  font-weight: 400;
  line-height: 1.6;
  color: #666666;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn,
  .wp-block-button__link {
    transition: none;
  }
}
.btn:hover,
.wp-block-button__link:hover {
  color: #666666;
}
.btn-check:focus + .btn,
.btn-check:focus + .wp-block-button__link,
.btn:focus,
.wp-block-button__link:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(255, 129, 57, 0.25);
}
.btn:disabled,
.wp-block-button__link:disabled,
.btn.disabled,
.disabled.wp-block-button__link,
fieldset:disabled .btn,
fieldset:disabled .wp-block-button__link {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary,
.wp-block-button__link {
  color: #000;
  background-color: #ff4848;
  border-color: #ff4848;
}
.btn-primary:hover,
.wp-block-button__link:hover {
  color: #000;
  background-color: #ff9457;
  border-color: #ff8e4d;
}
.btn-check:focus + .btn-primary,
.btn-check:focus + .wp-block-button__link,
.btn-primary:focus,
.wp-block-button__link:focus {
  color: #000;
  background-color: #ff9457;
  border-color: #ff8e4d;
  box-shadow: 0 0 0 0rem rgba(217, 110, 48, 0.5);
}
.btn-check:checked + .btn-primary,
.btn-check:checked + .wp-block-button__link,
.btn-check:active + .btn-primary,
.btn-check:active + .wp-block-button__link,
.btn-primary:active,
.wp-block-button__link:active,
.btn-primary.active,
.active.wp-block-button__link,
.show > .btn-primary.dropdown-toggle,
.show > .dropdown-toggle.wp-block-button__link {
  color: #000;
  background-color: #ff9a61;
  border-color: #ff8e4d;
}
.btn-check:checked + .btn-primary:focus,
.btn-check:checked + .wp-block-button__link:focus,
.btn-check:active + .btn-primary:focus,
.btn-check:active + .wp-block-button__link:focus,
.btn-primary:active:focus,
.wp-block-button__link:active:focus,
.btn-primary.active:focus,
.active.wp-block-button__link:focus,
.show > .btn-primary.dropdown-toggle:focus,
.show > .dropdown-toggle.wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(217, 110, 48, 0.5);
}
.btn-primary:disabled,
.wp-block-button__link:disabled,
.btn-primary.disabled,
.disabled.wp-block-button__link {
  color: #000;
  background-color: #ff4848;
  border-color: #ff4848;
}

.btn-secondary {
  color: #fff;
  background-color: #232323;
  border-color: #232323;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #1e1e1e;
  border-color: #1c1c1c;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: #fff;
  background-color: #1e1e1e;
  border-color: #1c1c1c;
  box-shadow: 0 0 0 0rem rgba(68, 68, 68, 0.5);
}
.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #1c1c1c;
  border-color: #1a1a1a;
}
.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(68, 68, 68, 0.5);
}
.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #fff;
  background-color: #232323;
  border-color: #232323;
}

.btn-success {
  color: #000;
  background-color: #13c24d;
  border-color: #13c24d;
}
.btn-success:hover {
  color: #000;
  background-color: #36cb68;
  border-color: #2bc85f;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  color: #000;
  background-color: #36cb68;
  border-color: #2bc85f;
  box-shadow: 0 0 0 0rem rgba(16, 165, 65, 0.5);
}
.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #42ce71;
  border-color: #2bc85f;
}
.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(16, 165, 65, 0.5);
}
.btn-success:disabled,
.btn-success.disabled {
  color: #000;
  background-color: #13c24d;
  border-color: #13c24d;
}

.btn-info {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}
.btn-info:hover {
  color: #000;
  background-color: #26baff;
  border-color: #1ab6ff;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #26baff;
  border-color: #1ab6ff;
  box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5);
}
.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #33beff;
  border-color: #1ab6ff;
}
.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5);
}
.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}

.btn-warning {
  color: #000;
  background-color: #ffbd13;
  border-color: #ffbd13;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffc736;
  border-color: #ffc42b;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #ffc736;
  border-color: #ffc42b;
  box-shadow: 0 0 0 0rem rgba(217, 161, 16, 0.5);
}
.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffca42;
  border-color: #ffc42b;
}
.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(217, 161, 16, 0.5);
}
.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #ffbd13;
  border-color: #ffbd13;
}

.btn-danger {
  color: #000;
  background-color: #ff0003;
  border-color: #ff0003;
}
.btn-danger:hover {
  color: #000;
  background-color: #ff2629;
  border-color: #ff1a1c;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: #000;
  background-color: #ff2629;
  border-color: #ff1a1c;
  box-shadow: 0 0 0 0rem rgba(217, 0, 3, 0.5);
}
.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #000;
  background-color: #ff3335;
  border-color: #ff1a1c;
}
.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(217, 0, 3, 0.5);
}
.btn-danger:disabled,
.btn-danger.disabled {
  color: #000;
  background-color: #ff0003;
  border-color: #ff0003;
}

.btn-light {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}
.btn-light:hover {
  color: #000;
  background-color: #fbf9f4;
  border-color: #fbf9f3;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #000;
  background-color: #fbf9f4;
  border-color: #fbf9f3;
  box-shadow: 0 0 0 0rem rgba(213, 211, 206, 0.5);
}
.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #fbf9f5;
  border-color: #fbf9f3;
}
.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(213, 211, 206, 0.5);
}
.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}

.btn-dark {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1d1d1d;
  border-color: #1b1b1b;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #1d1d1d;
  border-color: #1b1b1b;
  box-shadow: 0 0 0 0rem rgba(67, 67, 67, 0.5);
}
.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1b1b1b;
  border-color: #1a1a1a;
}
.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0rem rgba(67, 67, 67, 0.5);
}
.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: #ff4848;
  border-color: #ff4848;
}
.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  color: #000;
  background-color: #ff4848;
  border-color: #ff4848;
}
.btn-check:focus + .btn-outline-primary,
.is-style-outline .btn-check:focus + .wp-block-button__link,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(255, 129, 57, 0.5);
}
.btn-check:checked + .btn-outline-primary,
.is-style-outline .btn-check:checked + .wp-block-button__link,
.btn-check:active + .btn-outline-primary,
.is-style-outline .btn-check:active + .wp-block-button__link,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary.active,
.is-style-outline .active.wp-block-button__link,
.btn-outline-primary.dropdown-toggle.show,
.is-style-outline .dropdown-toggle.show.wp-block-button__link {
  color: #000;
  background-color: #ff4848;
  border-color: #ff4848;
}
.btn-check:checked + .btn-outline-primary:focus,
.is-style-outline .btn-check:checked + .wp-block-button__link:focus,
.btn-check:active + .btn-outline-primary:focus,
.is-style-outline .btn-check:active + .wp-block-button__link:focus,
.btn-outline-primary:active:focus,
.is-style-outline .wp-block-button__link:active:focus,
.btn-outline-primary.active:focus,
.is-style-outline .active.wp-block-button__link:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.is-style-outline .dropdown-toggle.show.wp-block-button__link:focus {
  box-shadow: 0 0 0 0rem rgba(255, 129, 57, 0.5);
}
.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link {
  color: #ff4848;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #232323;
  border-color: #232323;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #232323;
  border-color: #232323;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0rem rgba(35, 35, 35, 0.5);
}
.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #232323;
  border-color: #232323;
}
.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(35, 35, 35, 0.5);
}
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #232323;
  background-color: transparent;
}

.btn-outline-success {
  color: #13c24d;
  border-color: #13c24d;
}
.btn-outline-success:hover {
  color: #000;
  background-color: #13c24d;
  border-color: #13c24d;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0rem rgba(19, 194, 77, 0.5);
}
.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #13c24d;
  border-color: #13c24d;
}
.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(19, 194, 77, 0.5);
}
.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #13c24d;
  background-color: transparent;
}

.btn-outline-info {
  color: #00aeff;
  border-color: #00aeff;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5);
}
.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #00aeff;
  border-color: #00aeff;
}
.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5);
}
.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #00aeff;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffbd13;
  border-color: #ffbd13;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffbd13;
  border-color: #ffbd13;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0rem rgba(255, 189, 19, 0.5);
}
.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffbd13;
  border-color: #ffbd13;
}
.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(255, 189, 19, 0.5);
}
.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #ffbd13;
  background-color: transparent;
}

.btn-outline-danger {
  color: #ff0003;
  border-color: #ff0003;
}
.btn-outline-danger:hover {
  color: #000;
  background-color: #ff0003;
  border-color: #ff0003;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0rem rgba(255, 0, 3, 0.5);
}
.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #ff0003;
  border-color: #ff0003;
}
.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(255, 0, 3, 0.5);
}
.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #ff0003;
  background-color: transparent;
}

.btn-outline-light {
  color: #faf8f2;
  border-color: #faf8f2;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5);
}
.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #faf8f2;
  border-color: #faf8f2;
}
.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(250, 248, 242, 0.5);
}
.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #faf8f2;
  background-color: transparent;
}

.btn-outline-dark {
  color: #222222;
  border-color: #222222;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0rem rgba(34, 34, 34, 0.5);
}
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #222222;
  border-color: #222222;
}
.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0rem rgba(34, 34, 34, 0.5);
}
.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #222222;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #ff4848;
  text-decoration: underline;
}
.btn-link:hover {
  color: #cc672e;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .wp-block-button__link {
  padding: 0.5rem 1rem;
  font-size: 1.17188rem;
  border-radius: 1rem;
}

.btn-sm,
.btn-group-sm > .btn,
.btn-group-sm > .wp-block-button__link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  border-radius: 0.75rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.9375rem;
  color: #666666;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu {
  overflow-y: scroll; /* Ensure the scrollbar is shown */
}

/* Style the scrollbar track (the part the scrollbar moves along) */
.dropdown-menu::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

/* Style the scrollbar handle (the draggable part) */
.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #888; /* Set the color of the scrollbar handle */
  border-radius: 10px; /* Round the corners of the scrollbar handle */
}

/* Style the scrollbar handle on hover */
.dropdown-menu::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change the color when hovering over the handle */
}

/* Style the scrollbar track (for Firefox) */
.dropdown-menu {
  scrollbar-width: thin; /* Make the scrollbar thin */
  scrollbar-color: #888 #f1f1f1; /* Set the color of the scrollbar handle and track */
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1170px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #ff4848;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.82031rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #ff4848;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group > .wp-block-button__link,
.btn-group-vertical > .btn,
.btn-group-vertical > .wp-block-button__link {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:checked + .wp-block-button__link,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn-check:focus + .wp-block-button__link,
.btn-group > .btn:hover,
.btn-group > .wp-block-button__link:hover,
.btn-group > .btn:focus,
.btn-group > .wp-block-button__link:focus,
.btn-group > .btn:active,
.btn-group > .wp-block-button__link:active,
.btn-group > .btn.active,
.btn-group > .active.wp-block-button__link,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:checked + .wp-block-button__link,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn-check:focus + .wp-block-button__link,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .wp-block-button__link:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .wp-block-button__link:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .wp-block-button__link:active,
.btn-group-vertical > .btn.active,
.btn-group-vertical > .active.wp-block-button__link {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .wp-block-button__link:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn-group:not(:last-child) > .wp-block-button__link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > .wp-block-button__link:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > :not(.btn-check) + .wp-block-button__link,
.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn-group:not(:first-child) > .wp-block-button__link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split,
.btn-group-sm > .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split,
.btn-group-lg > .wp-block-button__link + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .wp-block-button__link,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .wp-block-button__link:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .wp-block-button__link:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn,
.btn-group-vertical > .btn-group:not(:last-child) > .wp-block-button__link {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .wp-block-button__link ~ .btn,
.btn-group-vertical > .btn ~ .wp-block-button__link,
.btn-group-vertical > .wp-block-button__link ~ .wp-block-button__link,
.btn-group-vertical > .btn-group:not(:first-child) > .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .wp-block-button__link {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #ff4848;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover,
.nav-link:focus {
  color: #cc672e;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #ffffff;
  border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #ff4848;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.17188rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.17188rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
  .navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
  .navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
  .navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1170px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
  .navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
  .navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.9375rem;
  color: #666666;
  text-align: left;
  background-color: #ffffff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #e67433;
  background-color: #fff2eb;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e67433'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23666666'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #ffc09c;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(255, 129, 57, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #ff4848;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #cc672e;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #cc672e;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(255, 129, 57, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #ff4848;
  border-color: #ff4848;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.17188rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge,
.wp-block-button__link .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #994d22;
  background-color: #ffe6d7;
  border-color: #ffd9c4;
}
.alert-primary .alert-link {
  color: #7a3e1b;
}

.alert-secondary {
  color: #151515;
  background-color: lightgray;
  border-color: #bdbdbd;
}
.alert-secondary .alert-link {
  color: #111111;
}

.alert-success {
  color: #0b742e;
  background-color: #d0f3db;
  border-color: #b8edca;
}
.alert-success .alert-link {
  color: #095d25;
}

.alert-info {
  color: #006899;
  background-color: #ccefff;
  border-color: #b3e7ff;
}
.alert-info .alert-link {
  color: #00537a;
}

.alert-warning {
  color: #664c08;
  background-color: #fff2d0;
  border-color: #ffebb8;
}
.alert-warning .alert-link {
  color: #523d06;
}

.alert-danger {
  color: #990002;
  background-color: #ffcccd;
  border-color: #ffb3b3;
}
.alert-danger .alert-link {
  color: #7a0002;
}

.alert-light {
  color: #646361;
  background-color: #fefefc;
  border-color: #fefdfb;
}
.alert-light .alert-link {
  color: #504f4e;
}

.alert-dark {
  color: #141414;
  background-color: lightgray;
  border-color: #bdbdbd;
}
.alert-dark .alert-link {
  color: #101010;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.70313rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
@media (min-width: 1401px) {
  .progress {
    font-size: 0.70312rem;
  }
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #ff4848;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #666666;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #ff4848;
  border-color: #ff4848;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1170px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #994d22;
  background-color: #ffe6d7;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #994d22;
  background-color: #e6cfc2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #994d22;
  border-color: #994d22;
}

.list-group-item-secondary {
  color: #151515;
  background-color: lightgray;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #151515;
  background-color: #bebebe;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #151515;
  border-color: #151515;
}

.list-group-item-success {
  color: #0b742e;
  background-color: #d0f3db;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #0b742e;
  background-color: #bbdbc5;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0b742e;
  border-color: #0b742e;
}

.list-group-item-info {
  color: #006899;
  background-color: #ccefff;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #006899;
  background-color: #b8d7e6;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #006899;
  border-color: #006899;
}

.list-group-item-warning {
  color: #664c08;
  background-color: #fff2d0;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #664c08;
  background-color: #e6dabb;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664c08;
  border-color: #664c08;
}

.list-group-item-danger {
  color: #990002;
  background-color: #ffcccd;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #990002;
  background-color: #e6b8b9;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #990002;
  border-color: #990002;
}

.list-group-item-light {
  color: #646361;
  background-color: #fefefc;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #646361;
  background-color: #e5e5e3;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #646361;
  border-color: #646361;
}

.list-group-item-dark {
  color: #141414;
  background-color: lightgray;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #141414;
  background-color: #bebebe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141414;
  border-color: #141414;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0rem rgba(255, 129, 57, 0.25);
  opacity: 1;
}
.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 15px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e1e1f0;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #e1e1f0;
  border-bottom-right-radius: calc(1rem - 1px);
  border-bottom-left-radius: calc(1rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1170px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1169.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.82031rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.82031rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #222222;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #666666;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin-top: -0.5rem;
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.6;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before,
.placeholder.wp-block-button__link::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #ff4848;
}
.link-primary:hover,
.link-primary:focus {
  color: #ff9a61;
}

.link-secondary {
  color: #232323;
}
.link-secondary:hover,
.link-secondary:focus {
  color: #1c1c1c;
}

.link-success {
  color: #13c24d;
}
.link-success:hover,
.link-success:focus {
  color: #42ce71;
}

.link-info {
  color: #00aeff;
}
.link-info:hover,
.link-info:focus {
  color: #33beff;
}

.link-warning {
  color: #ffbd13;
}
.link-warning:hover,
.link-warning:focus {
  color: #ffca42;
}

.link-danger {
  color: #ff0003;
}
.link-danger:hover,
.link-danger:focus {
  color: #ff3335;
}

.link-light {
  color: #faf8f2;
}
.link-light:hover,
.link-light:focus {
  color: #fbf9f5;
}

.link-dark {
  color: #222222;
}
.link-dark:hover,
.link-dark:focus {
  color: #1b1b1b;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1170px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #e1e1f0 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #e1e1f0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #e1e1f0 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #e1e1f0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #e1e1f0 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #ff4848 !important;
}

.border-secondary {
  border-color: #232323 !important;
}

.border-success {
  border-color: #13c24d !important;
}

.border-info {
  border-color: #00aeff !important;
}

.border-warning {
  border-color: #ffbd13 !important;
}

.border-danger {
  border-color: #ff0003 !important;
}

.border-light {
  border-color: #faf8f2 !important;
}

.border-dark {
  border-color: #222222 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
  gap: .5rem;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.3875rem + 1.65vw) !important;
}

.fs-2 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-3 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.6 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
  font-size: .8rem;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #aeaed5 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.75rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 1rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.25rem !important;
  }
  .gap-sm-2 {
    gap: 0.5rem !important;
  }
  .gap-sm-3 {
    gap: 1rem !important;
  }
  .gap-sm-4 {
    gap: 1.5rem !important;
  }
  .gap-sm-5 {
    gap: 3rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-3 {
    margin-top: 1rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-5 {
    margin-top: 3rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.25rem !important;
  }
  .me-sm-2 {
    margin-right: 0.5rem !important;
  }
  .me-sm-3 {
    margin-right: 1rem !important;
  }
  .me-sm-4 {
    margin-right: 1.5rem !important;
  }
  .me-sm-5 {
    margin-right: 3rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }
  .ms-sm-3 {
    margin-left: 1rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }
  .ms-sm-5 {
    margin-left: 3rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-3 {
    padding-top: 1rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-5 {
    padding-top: 3rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pe-sm-3 {
    padding-right: 1rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pe-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }
  .ps-sm-3 {
    padding-left: 1rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }
  .ps-sm-5 {
    padding-left: 3rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.25rem !important;
  }
  .gap-md-2 {
    gap: 0.5rem !important;
  }
  .gap-md-3 {
    gap: 1rem !important;
  }
  .gap-md-4 {
    gap: 1.5rem !important;
  }
  .gap-md-5 {
    gap: 3rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.25rem !important;
  }
  .mt-md-2 {
    margin-top: 0.5rem !important;
  }
  .mt-md-3 {
    margin-top: 1rem !important;
  }
  .mt-md-4 {
    margin-top: 1.5rem !important;
  }
  .mt-md-5 {
    margin-top: 3rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.25rem !important;
  }
  .me-md-2 {
    margin-right: 0.5rem !important;
  }
  .me-md-3 {
    margin-right: 1rem !important;
  }
  .me-md-4 {
    margin-right: 1.5rem !important;
  }
  .me-md-5 {
    margin-right: 3rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-5 {
    margin-bottom: 3rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.25rem !important;
  }
  .ms-md-2 {
    margin-left: 0.5rem !important;
  }
  .ms-md-3 {
    margin-left: 1rem !important;
  }
  .ms-md-4 {
    margin-left: 1.5rem !important;
  }
  .ms-md-5 {
    margin-left: 3rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.25rem !important;
  }
  .pt-md-2 {
    padding-top: 0.5rem !important;
  }
  .pt-md-3 {
    padding-top: 1rem !important;
  }
  .pt-md-4 {
    padding-top: 1.5rem !important;
  }
  .pt-md-5 {
    padding-top: 3rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.25rem !important;
  }
  .pe-md-2 {
    padding-right: 0.5rem !important;
  }
  .pe-md-3 {
    padding-right: 1rem !important;
  }
  .pe-md-4 {
    padding-right: 1.5rem !important;
  }
  .pe-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-5 {
    padding-bottom: 3rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.25rem !important;
  }
  .ps-md-2 {
    padding-left: 0.5rem !important;
  }
  .ps-md-3 {
    padding-left: 1rem !important;
  }
  .ps-md-4 {
    padding-left: 1.5rem !important;
  }
  .ps-md-5 {
    padding-left: 3rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.25rem !important;
  }
  .gap-lg-2 {
    gap: 0.5rem !important;
  }
  .gap-lg-3 {
    gap: 1rem !important;
  }
  .gap-lg-4 {
    gap: 1.5rem !important;
  }
  .gap-lg-5 {
    gap: 3rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-3 {
    margin-top: 1rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-5 {
    margin-top: 3rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.25rem !important;
  }
  .me-lg-2 {
    margin-right: 0.5rem !important;
  }
  .me-lg-3 {
    margin-right: 1rem !important;
  }
  .me-lg-4 {
    margin-right: 1.5rem !important;
  }
  .me-lg-5 {
    margin-right: 3rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }
  .ms-lg-3 {
    margin-left: 1rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }
  .ms-lg-5 {
    margin-left: 3rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-3 {
    padding-top: 1rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-5 {
    padding-top: 3rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pe-lg-3 {
    padding-right: 1rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pe-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }
  .ps-lg-3 {
    padding-left: 1rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }
  .ps-lg-5 {
    padding-left: 3rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1170px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.25rem !important;
  }
  .gap-xl-2 {
    gap: 0.5rem !important;
  }
  .gap-xl-3 {
    gap: 1rem !important;
  }
  .gap-xl-4 {
    gap: 1.5rem !important;
  }
  .gap-xl-5 {
    gap: 3rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-3 {
    margin-top: 1rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-5 {
    margin-top: 3rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xl-3 {
    margin-right: 1rem !important;
  }
  .me-xl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xl-5 {
    margin-right: 3rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xl-3 {
    margin-left: 1rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xl-5 {
    margin-left: 3rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-3 {
    padding-top: 1rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-5 {
    padding-top: 3rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xl-3 {
    padding-right: 1rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xl-3 {
    padding-left: 1rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xl-5 {
    padding-left: 3rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.25rem !important;
  }
  .gap-xxl-2 {
    gap: 0.5rem !important;
  }
  .gap-xxl-3 {
    gap: 1rem !important;
  }
  .gap-xxl-4 {
    gap: 1.5rem !important;
  }
  .gap-xxl-5 {
    gap: 3rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.25rem !important;
  }
  .m-xxl-2 {
    margin: 0.5rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 3rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }
  .me-xxl-3 {
    margin-right: 1rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .me-xxl-5 {
    margin-right: 3rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }
  .ms-xxl-3 {
    margin-left: 1rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .ms-xxl-5 {
    margin-left: 3rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.25rem !important;
  }
  .p-xxl-2 {
    padding: 0.5rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 3rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-5 {
    padding-top: 3rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }
  .pe-xxl-3 {
    padding-right: 1rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pe-xxl-5 {
    padding-right: 3rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }
  .ps-xxl-3 {
    padding-left: 1rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .ps-xxl-5 {
    padding-left: 3rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1401px) {
  .fs-1 {
    font-size: 2.625rem !important;
  }
  .fs-2 {
    font-size: 2.25rem !important;
  }
  .fs-3 {
    font-size: 1.875rem !important;
  }
  .fs-4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*=== Base  ===*/
* {
  outline: none;
  padding: 0;
}
*::after {
  margin: 0;
  padding: 0;
}
*::before {
  margin: 0;
  padding: 0;
}

.page-wraper {
  background: #fff;
}

body {
  line-height: 1.6;
  color: #666666;
  font-size: 1rem;
  font-family: "Oswald", sans-serif;
  font-family: var(--font-family-base);
  background-color: #efefef;
}
@media only screen and (max-width: 575px) {
  body {
    font-size: 15px;
  }
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
.comments-title a,
.blog-title a,
.comment-reply-title a,
.wp-block-search .wp-block-search__label a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: var(--title);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
.comments-title,
.blog-title,
.comment-reply-title,
.wp-block-search .wp-block-search__label,
h5,
.h5,
h6,
.h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-family-title);
  color: var(--title);
}

h1,
.h1,
.h1 {
  line-height: 1.2;
  font-size: 2.625rem;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  h1,
  .h1,
  .h1 {
    font-size: 2.25rem;
  }
}

h2,
.h2,
.h2 {
  font-size: 35px;
  line-height: 1.2;
  font-weight: 700;
}
@media only screen and (max-width: 767px) {
  h2,
  .h2,
  .h2 {
    font-size: 1.875rem;
  }
}

h3,
.h3,
.h3 {
  line-height: 1.2;
  font-size: 1.875rem;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  h3,
  .h3,
  .h3 {
    font-size: 1.625rem;
  }
}
@media only screen and (max-width: 767px) {
  h3,
  .h3,
  .h3 {
    font-size: 1.375rem;
  }
}

h4,
.h4,
.comments-title,
.blog-title,
.comment-reply-title,
.wp-block-search .wp-block-search__label,
.h4 {
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  h4,
  .h4,
  .comments-title,
  .blog-title,
  .comment-reply-title,
  .wp-block-search .wp-block-search__label,
  .h4 {
    font-size: 1.375rem;
  }
}
@media only screen and (max-width: 575px) {
  h4,
  .h4,
  .comments-title,
  .blog-title,
  .comment-reply-title,
  .wp-block-search .wp-block-search__label,
  .h4 {
    font-size: 1.25rem;
  }
}

h5,
.h5,
.h5 {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 600;
}

h6,
.h6,
.h6 {
  font-size: 1rem;
  line-height: 1.2;
  font-weight: 600;
}

ul {
  padding: 0;
  margin: 0;
}

img {
  border-style: none;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

li {
  list-style: none;
}

.custom-list li {
  list-style: disc !important;
  /* padding-left: 20px; */
}

.custom-sublist li {
  list-style: circle !important;
  /* padding-left: 20px; */
}
.text-center {
  position: relative;
  z-index: 2; /* Ensure this is higher than the svg */
}

.svg-shape-2 {
  position: absolute; /* or relative if you want it within the flow */
  z-index: 1; /* Lower z-index so it stays behind the text */
}

.footer-bottom {
  position: relative; /* Ensure the container has positioning to contain the absolutely positioned SVG */
}

a {
  text-decoration: none;
  outline: none;
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
a:hover,
a:focus,
a.active {
  text-decoration: none;
  outline: none;
  color: var(--primary-hover);
}

.text-primary {
  color: var(--primary) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
}

.bg-secondary-2 {
  background-color: var(--secondary-2) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

.text-secondary-2 {
  color: var(--secondary-2) !important;
}

b,
strong,
.strong {
  font-weight: 500;
}

.h-0 {
  height: 0;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin-top: 60px;
}
#main-wrapper.show {
  opacity: 1;
}

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px;
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (max-width: 1280px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}

::selection {
  color: white !important;
  background: #ffd8c2;
}

.modal-backdrop {
  z-index: 99999;
}

.modal {
  z-index: 100000;
}

/* Flaticon */
[class*="flaticon-"] {
  font-family: "Flaticon";
}

.content-inner {
  padding-top: 118px;
  padding-bottom: 15px;
}
@media only screen and (max-width: 1480px) {
  .content-inner {
    padding-top: 100px;
    padding-bottom: 70px;
  }
}
@media only screen and (max-width: 991px) {
  .content-inner {
    padding-top: 70px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
.contact {
  padding-top: 120px;
  padding-bottom: 120px;
}
.content-inner-1 {
  padding-top: 50px;
  padding-bottom: 180px;
}
@media only screen and (max-width: 1480px) {
  .content-inner-1 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 991px) {
  .content-inner-1 {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner-1 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.content-inner-2 {
  padding-top: 120px;
  padding-bottom: 0;
}
@media only screen and (max-width: 1480px) {
  .content-inner-2 {
    padding-top: 100px;
  }
}
@media only screen and (max-width: 991px) {
  .content-inner-2 {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner-2 {
    padding-top: 50px;
  }
}

.content-inner-3 {
  padding-top: 60px;
  padding-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .content-inner-3 {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner-3 {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}

.content-inner-4 {
  padding-top: 0;
  padding-bottom: 200px;
}
@media only screen and (max-width: 991px) {
  .content-inner-4 {
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .content-inner-4 {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}

.mfp-bg {
  z-index: 99999;
}

.mfp-wrap {
  z-index: 100000;
}

.rounded {
  border-radius: var(--border-radius-base) !important;
}

@media only screen and (max-width: 991px) {
  .dz-order-1 {
    order: 1;
    margin-top: 20px;
  }
}

.resize-wrapper {
  margin-left: calc((100% - 1170px) / 2);
}

.theme-text-color {
  color: var(--theme-text-color);
}

#pointer-dot {
  left: -6px;
  top: -6px;
  width: 12px;
  height: 12px;
  position: fixed;
  border-radius: 10px;
  z-index: 999998;
  pointer-events: none;
  transition: border-color 0.5s;
  background: var(--primary);
}

#pointer-ring {
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  display: block;
  background-color: var(--rgba-primary-1);
  border: 0;
  position: fixed;
  border-radius: 100px;
  z-index: 999999;
  pointer-events: none;
  transition: width 0.3s, height 0.3s;
}
#pointer-ring.active {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}
#pointer-ring.active + #pointer-dot {
  opacity: 0;
}

#loading-area {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999999999;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loading-area.loading-page-1 .loading-inner .inner {
  text-align: center;
}
#loading-area.loading-page-1 .loading-inner .item-circle {
  width: 400px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 575px) {
  #loading-area.loading-page-1 .loading-inner .item-circle {
    width: 300px;
  }
}
#loading-area.loading-page-1 .loading-inner .load-text {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load {
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
  position: relative;
  font-weight: 700;
  font-size: 24px;
  font-family: var(--font-family-title);
  line-height: 30px;
  text-align: center;
  user-select: none;
}
@media only screen and (max-width: 575px) {
  #loading-area.loading-page-1 .loading-inner .load-text .text-load {
    font-size: 20px;
  }
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load:before {
  animation: letters-loading 4s infinite;
  color: var(--title);
  content: attr(data-text);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 30px;
  position: absolute;
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load:nth-child(2):before {
  animation-delay: 0.2s;
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load:nth-child(3):before {
  animation-delay: 0.4s;
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load:nth-child(4):before {
  animation-delay: 0.6s;
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load:nth-child(5):before {
  animation-delay: 0.8s;
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load:nth-child(6):before {
  animation-delay: 1s;
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load:nth-child(7):before {
  animation-delay: 1.2s;
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load:nth-child(8):before {
  animation-delay: 1.4s;
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load:nth-child(9):before {
  animation-delay: 1.6s;
}
#loading-area.loading-page-1 .loading-inner .load-text .text-load:nth-child(10):before {
  animation-delay: 1.8s;
}
#loading-area.loading-page-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
#loading-area.loading-page-2:before,
#loading-area.loading-page-2:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  top: 0;
  background: #222222;
  transition: all 1.5s;
  transform-origin: left top;
  z-index: -1;
}
#loading-area.loading-page-2:before {
  top: auto;
  bottom: 0;
}
#loading-area.loading-page-2.active:before,
#loading-area.loading-page-2.active:after {
  height: 0px;
}
#loading-area.loading-page-2 .text {
  color: #fff;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 36px;
  transform: translateY(-30px);
  opacity: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
@media only screen and (max-width: 767px) {
  #loading-area.loading-page-2 .text {
    font-size: 24px;
  }
}
#loading-area.loading-page-2.show .text {
  opacity: 1;
  transform: translateY(0);
}
#loading-area.loading-page-2.active .text {
  opacity: 0;
  transform: translateY(30px);
}
#loading-area.loading-page-3 {
  background-color: #fff;
}
#loading-area.loading-page-3 .loading-inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
#loading-area.loading-page-3 .loading-inner .load-text .text-load {
  font-weight: 700;
  display: inline-block;
  color: rgba(0, 0, 0, 0.15);
  position: relative;
  font-size: 70px;
  line-height: 70px;
  font-size: 60px;
  letter-spacing: -1px;
  text-align: center;
  user-select: none;
  text-transform: uppercase;
  font-family: var(--font-family-title);
}
#loading-area.loading-page-3 .loading-inner .load-text .text-load:before {
  animation: letters-loading 4s infinite;
  color: var(--title);
  content: attr(data-text);
  left: 0;
  opacity: 0;
  top: 0;
  line-height: 70px;
  position: absolute;
  text-transform: uppercase;
}
#loading-area.loading-page-3 .loading-inner .load-text .text-load:nth-child(2):before {
  animation-delay: 0.2s;
}
#loading-area.loading-page-3 .loading-inner .load-text .text-load:nth-child(3):before {
  animation-delay: 0.4s;
}
#loading-area.loading-page-3 .loading-inner .load-text .text-load:nth-child(4):before {
  animation-delay: 0.6s;
}
#loading-area.loading-page-3 .loading-inner .load-text .text-load:nth-child(5):before {
  animation-delay: 0.8s;
}
#loading-area.loading-page-3 .loading-inner .load-text .text-load:nth-child(6):before {
  animation-delay: 1s;
}
#loading-area.loading-page-3 .loading-inner .load-text .text-load:nth-child(7):before {
  animation-delay: 1.2s;
}
#loading-area.loading-page-3 .loading-inner .load-text .text-load:nth-child(8):before {
  animation-delay: 1.4s;
}
@media only screen and (max-width: 575px) {
  #loading-area.loading-page-3 .loading-inner .load-text .text-load {
    font-size: 30px;
  }
  #loading-area.loading-page-3 .loading-inner .wrapper {
    height: 80px;
    width: 80px;
  }
}

@keyframes dzTextAnimate {
  10% {
    opacity: 0.5;
  }
  20% {
    opacity: 1;
    top: 0;
    transform: rotateX(-360deg);
  }
  80% {
    opacity: 1;
    transform: rotateX(-360deg);
    top: 0;
  }
  90% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    top: 100px;
  }
}

@keyframes dzCircleBounceLeft {
  0% {
    left: 20%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 20%;
  }
}

@keyframes dzCircleBounceRight {
  0% {
    left: 80%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 80%;
  }
}

@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@keyframes dzBlinkEffect {
  0%,
  100% {
    transform: translate(0, 0);
  }
  8% {
    transform: translate(-5%, -10%);
  }
  18% {
    transform: translate(-15%, 5%);
  }
  27% {
    transform: translate(7%, -25%);
  }
  42% {
    transform: translate(-5%, 25%);
  }
  48% {
    transform: translate(-15%, 10%);
  }
  55% {
    transform: translate(15%, 0);
  }
  68% {
    transform: translate(0, 15%);
  }
  82% {
    transform: translate(3%, 25%);
  }
  92% {
    transform: translate(-10%, 10%);
  }
}

@keyframes letters-loading {
  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

.font-10 {
  font-size: 10px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-22 {
  font-size: 22px;
}

.font-24 {
  font-size: 24px;
}

.font-26 {
  font-size: 26px;
}

.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}

.font-40 {
  font-size: 40px;
}

.font-45 {
  font-size: 45px;
}

.font-50 {
  font-size: 50px;
}

.font-55 {
  font-size: 55px;
}

.font-60 {
  font-size: 60px;
}

.font-70 {
  font-size: 70px;
}

.font-75 {
  font-size: 75px;
}

.font-80 {
  font-size: 80px;
}

.font-90 {
  font-size: 90px;
}

.font-100 {
  font-size: 100px;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.p-a0 {
  padding: 0px;
}

.p-t0 {
  padding-top: 0px;
}

.p-b0 {
  padding-bottom: 0px;
}

.p-l0 {
  padding-left: 0px;
}

.p-r0 {
  padding-right: 0px;
}

.p-lr0 {
  padding-left: 0px;
  padding-right: 0px;
}

.p-tb0 {
  padding-bottom: 0px;
  padding-top: 0px;
}

.m-a0 {
  margin: 0px;
}

.m-t0 {
  margin-top: 0px;
}

.m-b0 {
  margin-bottom: 0px;
}

.m-l0 {
  margin-left: 0px;
}

.m-r0 {
  margin-right: 0px;
}

.m-lr0 {
  margin-left: 0px;
  margin-right: 0px;
}

.m-tb0 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.p-a5 {
  padding: 5px;
}

.p-t5 {
  padding-top: 5px;
}

.p-b5 {
  padding-bottom: 5px;
}

.p-l5 {
  padding-left: 5px;
}

.p-r5 {
  padding-right: 5px;
}

.p-lr5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-tb5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.m-a5 {
  margin: 5px;
}

.m-t5 {
  margin-top: 5px;
}

.m-b5 {
  margin-bottom: 5px;
}

.m-l5 {
  margin-left: 5px;
}

.m-r5 {
  margin-right: 5px;
}

.m-lr5 {
  margin-left: 5px;
  margin-right: 5px;
}

.m-tb5 {
  margin-bottom: 5px;
  margin-top: 5px;
}

.p-a10 {
  padding: 10px;
}

.p-t10 {
  padding-top: 10px;
}

.p-b10 {
  padding-bottom: 10px;
}

.p-l10 {
  padding-left: 10px;
}

.p-r10 {
  padding-right: 10px;
}

.p-lr10 {
  padding-left: 10px;
  padding-right: 10px;
}

.p-tb10 {
  padding-bottom: 10px;
  padding-top: 10px;
}

.m-a10 {
  margin: 10px;
}

.m-t10 {
  margin-top: 10px;
}

.m-b10 {
  margin-bottom: 10px;
}

.m-l10 {
  margin-left: 10px;
}

.m-r10 {
  margin-right: 10px;
}

.m-lr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.m-tb10 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.p-a15 {
  padding: 15px;
}

.p-t15 {
  padding-top: 15px;
}

.p-b15 {
  padding-bottom: 15px;
}

.p-l15 {
  padding-left: 15px;
}

.p-r15 {
  padding-right: 15px;
}

.p-lr15 {
  padding-left: 15px;
  padding-right: 15px;
}

.p-tb15 {
  padding-bottom: 15px;
  padding-top: 15px;
}

.m-a15 {
  margin: 15px;
}

.m-t15 {
  margin-top: 15px;
}

.m-b15 {
  margin-bottom: 15px;
}

.m-l15 {
  margin-left: 15px;
}

.m-r15 {
  margin-right: 15px;
}

.m-lr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.m-tb15 {
  margin-bottom: 15px;
  margin-top: 15px;
}

.p-a20 {
  padding: 20px;
}

.p-t20 {
  padding-top: 20px;
}

.p-b20 {
  padding-bottom: 20px;
}

.p-l20 {
  padding-left: 20px;
}

.p-r20 {
  padding-right: 20px;
}

.p-lr20 {
  padding-left: 20px;
  padding-right: 20px;
}

.p-tb20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.m-a20 {
  margin: 20px;
}

.m-t20 {
  margin-top: 20px;
}

.m-b20 {
  margin-bottom: 20px;
}

.m-l20 {
  margin-left: 20px;
}

.m-r20 {
  margin-right: 20px;
}

.m-lr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.m-tb20 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.p-a25 {
  padding: 25px;
}

.p-t25 {
  padding-top: 25px;
}

.p-b25 {
  padding-bottom: 25px;
}

.p-l25 {
  padding-left: 25px;
}

.p-r25 {
  padding-right: 25px;
}

.p-lr25 {
  padding-left: 25px;
  padding-right: 25px;
}

.p-tb25 {
  padding-bottom: 25px;
  padding-top: 25px;
}

.m-a25 {
  margin: 25px;
}

.m-t25 {
  margin-top: 25px;
}

.m-b25 {
  text-align: left;
  margin-bottom: 25px;
  margin-left: 0rem;
  margin-right: 1rem;
  font-size: 12px;
}

.m-l25 {
  margin-left: 25px;
}

.m-r25 {
  margin-right: 25px;
}

.m-lr25 {
  margin-left: 25px;
  margin-right: 25px;
}

.m-tb25 {
  margin-bottom: 25px;
  margin-top: 25px;
}

.p-a30 {
  padding: 30px;
}

.p-t30 {
  padding-top: 30px;
}

.p-b30 {
  padding-bottom: 30px;
}

.p-l30 {
  padding-left: 30px;
}

.p-r30 {
  padding-right: 30px;
}

.p-lr30 {
  padding-left: 30px;
  padding-right: 30px;
}

.p-tb30 {
  padding-bottom: 30px;
  padding-top: 30px;
}

.m-a30 {
  margin: 30px;
}

.m-t30 {
  margin-top: 30px;
}

.m-b30 {
  margin-bottom: 30px;
}

.m-l30 {
  margin-left: 30px;
}

.m-r30 {
  margin-right: 30px;
}

.m-lr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.m-tb30 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.p-a35 {
  padding: 35px;
}

.p-t35 {
  padding-top: 35px;
}

.p-b35 {
  padding-bottom: 35px;
}

.p-l35 {
  padding-left: 35px;
}

.p-r35 {
  padding-right: 35px;
}

.p-lr35 {
  padding-left: 35px;
  padding-right: 35px;
}

.p-tb35 {
  padding-bottom: 35px;
  padding-top: 35px;
}

.m-a35 {
  margin: 35px;
}

.m-t35 {
  margin-top: 35px;
}

.m-b35 {
  margin-bottom: 35px;
}

.m-l35 {
  margin-left: 35px;
}

.m-r35 {
  margin-right: 35px;
}

.m-lr35 {
  margin-left: 35px;
  margin-right: 35px;
}

.m-tb35 {
  margin-bottom: 35px;
  margin-top: 35px;
}

.p-a40 {
  padding: 40px;
}

.p-t40 {
  padding-top: 40px;
}

.p-b40 {
  padding-bottom: 40px;
}

.p-l40 {
  padding-left: 40px;
}

.p-r40 {
  padding-right: 40px;
}

.p-lr40 {
  padding-left: 40px;
  padding-right: 40px;
}

.p-tb40 {
  padding-bottom: 40px;
  padding-top: 40px;
}

.m-a40 {
  margin: 40px;
}

.m-t40 {
  margin-top: 40px;
}

.m-b40 {
  margin-bottom: 40px;
}

.m-l40 {
  margin-left: 40px;
}

.m-r40 {
  margin-right: 40px;
}

.m-lr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.m-tb40 {
  margin-bottom: 40px;
  margin-top: 40px;
}

.p-a45 {
  padding: 45px;
}

.p-t45 {
  padding-top: 45px;
}

.p-b45 {
  padding-bottom: 45px;
}

.p-l45 {
  padding-left: 45px;
}

.p-r45 {
  padding-right: 45px;
}

.p-lr45 {
  padding-left: 45px;
  padding-right: 45px;
}

.p-tb45 {
  padding-bottom: 45px;
  padding-top: 45px;
}

.m-a45 {
  margin: 45px;
}

.m-t45 {
  margin-top: 45px;
}

.m-b45 {
  margin-bottom: 45px;
}

.m-l45 {
  margin-left: 45px;
}

.m-r45 {
  margin-right: 45px;
}

.m-lr45 {
  margin-left: 45px;
  margin-right: 45px;
}

.m-tb45 {
  margin-bottom: 45px;
  margin-top: 45px;
}

.p-a50 {
  padding: 50px;
}

.p-t50 {
  padding-top: 50px;
}

.p-b50 {
  padding-bottom: 50px;
}

.p-l50 {
  padding-left: 50px;
}

.p-r50 {
  padding-right: 50px;
}

.p-lr50 {
  padding-left: 50px;
  padding-right: 50px;
}

.p-tb50 {
  padding-bottom: 50px;
  padding-top: 50px;
}

.m-a50 {
  margin: 50px;
}

.m-t50 {
  margin-top: 50px;
}

.m-b50 {
  margin-bottom: 50px;
}

.m-l50 {
  margin-left: 50px;
}

.m-r50 {
  margin-right: 50px;
}

.m-lr50 {
  margin-left: 50px;
  margin-right: 50px;
}

.m-tb50 {
  margin-bottom: 50px;
  margin-top: 50px;
}

.p-a55 {
  padding: 55px;
}

.p-t55 {
  padding-top: 55px;
}

.p-b55 {
  padding-bottom: 55px;
}

.p-l55 {
  padding-left: 55px;
}

.p-r55 {
  padding-right: 55px;
}

.p-lr55 {
  padding-left: 55px;
  padding-right: 55px;
}

.p-tb55 {
  padding-bottom: 55px;
  padding-top: 55px;
}

.m-a55 {
  margin: 55px;
}

.m-t55 {
  margin-top: 55px;
}

.m-b55 {
  margin-bottom: 55px;
}

.m-l55 {
  margin-left: 55px;
}

.m-r55 {
  margin-right: 55px;
}

.m-lr55 {
  margin-left: 55px;
  margin-right: 55px;
}

.m-tb55 {
  margin-bottom: 55px;
  margin-top: 55px;
}

.p-a60 {
  padding: 60px;
}

.p-t60 {
  padding-top: 60px;
}

.p-b60 {
  padding-bottom: 60px;
}

.p-l60 {
  padding-left: 60px;
}

.p-r60 {
  padding-right: 60px;
}

.p-lr60 {
  padding-left: 60px;
  padding-right: 60px;
}

.p-tb60 {
  padding-bottom: 60px;
  padding-top: 60px;
}

.m-a60 {
  margin: 60px;
}

.m-t60 {
  margin-top: 60px;
}

.m-b60 {
  margin-bottom: 60px;
}

.m-l60 {
  margin-left: 60px;
}

.m-r60 {
  margin-right: 60px;
}

.m-lr60 {
  margin-left: 60px;
  margin-right: 60px;
}

.m-tb60 {
  margin-bottom: 60px;
  margin-top: 60px;
}

.p-a65 {
  padding: 65px;
}

.p-t65 {
  padding-top: 65px;
}

.p-b65 {
  padding-bottom: 65px;
}

.p-l65 {
  padding-left: 65px;
}

.p-r65 {
  padding-right: 65px;
}

.p-lr65 {
  padding-left: 65px;
  padding-right: 65px;
}

.p-tb65 {
  padding-bottom: 65px;
  padding-top: 65px;
}

.m-a65 {
  margin: 65px;
}

.m-t65 {
  margin-top: 65px;
}

.m-b65 {
  margin-bottom: 65px;
}

.m-l65 {
  margin-left: 65px;
}

.m-r65 {
  margin-right: 65px;
}

.m-lr65 {
  margin-left: 65px;
  margin-right: 65px;
}

.m-tb65 {
  margin-bottom: 65px;
  margin-top: 65px;
}

.p-a70 {
  padding: 70px;
}

.p-t70 {
  padding-top: 70px;
}

.p-b70 {
  padding-bottom: 70px;
}

.p-l70 {
  padding-left: 70px;
}

.p-r70 {
  padding-right: 70px;
}

.p-lr70 {
  padding-left: 70px;
  padding-right: 70px;
}

.p-tb70 {
  padding-bottom: 70px;
  padding-top: 70px;
}

.m-a70 {
  margin: 70px;
}

.m-t70 {
  margin-top: 70px;
}

.m-b70 {
  margin-bottom: 70px;
}

.m-l70 {
  margin-left: 70px;
}

.m-r70 {
  margin-right: 70px;
}

.m-lr70 {
  margin-left: 70px;
  margin-right: 70px;
}

.m-tb70 {
  margin-bottom: 70px;
  margin-top: 70px;
}

.p-a75 {
  padding: 75px;
}

.p-t75 {
  padding-top: 75px;
}

.p-b75 {
  padding-bottom: 75px;
}

.p-l75 {
  padding-left: 75px;
}

.p-r75 {
  padding-right: 75px;
}

.p-lr75 {
  padding-left: 75px;
  padding-right: 75px;
}

.p-tb75 {
  padding-bottom: 75px;
  padding-top: 75px;
}

.m-a75 {
  margin: 75px;
}

.m-t75 {
  margin-top: 75px;
}

.m-b75 {
  margin-bottom: 75px;
}

.m-l75 {
  margin-left: 75px;
}

.m-r75 {
  margin-right: 75px;
}

.m-lr75 {
  margin-left: 75px;
  margin-right: 75px;
}

.m-tb75 {
  margin-bottom: 75px;
  margin-top: 75px;
}

.p-a80 {
  padding: 80px;
}

.p-t80 {
  padding-top: 80px;
}

.p-b80 {
  padding-bottom: 80px;
}

.p-l80 {
  padding-left: 80px;
}

.p-r80 {
  padding-right: 80px;
}

.p-lr80 {
  padding-left: 80px;
  padding-right: 80px;
}

.p-tb80 {
  padding-bottom: 80px;
  padding-top: 80px;
}

.m-a80 {
  margin: 80px;
}

.m-t80 {
  margin-top: 80px;
}

.m-b80 {
  margin-bottom: 80px;
}

.m-l80 {
  margin-left: 80px;
}

.m-r80 {
  margin-right: 80px;
}

.m-lr80 {
  margin-left: 80px;
  margin-right: 80px;
}

.m-tb80 {
  margin-bottom: 80px;
  margin-top: 80px;
}

.p-a85 {
  padding: 85px;
}

.p-t85 {
  padding-top: 85px;
}

.p-b85 {
  padding-bottom: 85px;
}

.p-l85 {
  padding-left: 85px;
}

.p-r85 {
  padding-right: 85px;
}

.p-lr85 {
  padding-left: 85px;
  padding-right: 85px;
}

.p-tb85 {
  padding-bottom: 85px;
  padding-top: 85px;
}

.m-a85 {
  margin: 85px;
}

.m-t85 {
  margin-top: 85px;
}

.m-b85 {
  margin-bottom: 85px;
}

.m-l85 {
  margin-left: 85px;
}

.m-r85 {
  margin-right: 85px;
}

.m-lr85 {
  margin-left: 85px;
  margin-right: 85px;
}

.m-tb85 {
  margin-bottom: 85px;
  margin-top: 85px;
}

.p-a90 {
  padding: 90px;
}

.p-t90 {
  padding-top: 90px;
}

.p-b90 {
  padding-bottom: 90px;
}

.p-l90 {
  padding-left: 90px;
}

.p-r90 {
  padding-right: 90px;
}

.p-lr90 {
  padding-left: 90px;
  padding-right: 90px;
}

.p-tb90 {
  padding-bottom: 90px;
  padding-top: 90px;
}

.m-a90 {
  margin: 90px;
}

.m-t90 {
  margin-top: 90px;
}

.m-b90 {
  margin-bottom: 90px;
}

.m-l90 {
  margin-left: 90px;
}

.m-r90 {
  margin-right: 90px;
}

.m-lr90 {
  margin-left: 90px;
  margin-right: 90px;
}

.m-tb90 {
  margin-bottom: 90px;
  margin-top: 90px;
}

.p-a95 {
  padding: 95px;
}

.p-t95 {
  padding-top: 95px;
}

.p-b95 {
  padding-bottom: 95px;
}

.p-l95 {
  padding-left: 95px;
}

.p-r95 {
  padding-right: 95px;
}

.p-lr95 {
  padding-left: 95px;
  padding-right: 95px;
}

.p-tb95 {
  padding-bottom: 95px;
  padding-top: 95px;
}

.m-a95 {
  margin: 95px;
}

.m-t95 {
  margin-top: 95px;
}

.m-b95 {
  margin-bottom: 95px;
}

.m-l95 {
  margin-left: 95px;
}

.m-r95 {
  margin-right: 95px;
}

.m-lr95 {
  margin-left: 95px;
  margin-right: 95px;
}

.m-tb95 {
  margin-bottom: 95px;
  margin-top: 95px;
}

.p-a100 {
  padding: 100px;
}

.p-t100 {
  padding-top: 100px;
}

.p-b100 {
  padding-bottom: 100px;
}

.p-l100 {
  padding-left: 100px;
}

.p-r100 {
  padding-right: 100px;
}

.p-lr100 {
  padding-left: 100px;
  padding-right: 100px;
}

.p-tb100 {
  padding-bottom: 100px;
  padding-top: 100px;
}

.m-a100 {
  margin: 100px;
}

.m-t100 {
  margin-top: 100px;
}

.m-b100 {
  margin-bottom: 100px;
}

.m-l100 {
  margin-left: 100px;
}

.m-r100 {
  margin-right: 100px;
}

.m-lr100 {
  margin-left: 100px;
  margin-right: 100px;
}

.m-tb100 {
  margin-bottom: 100px;
  margin-top: 100px;
}

@media only screen and (max-width: 1401px) {
  .m-lg-t0 {
    margin-top: 0px;
  }
  .m-lg-b0 {
    margin-bottom: 0px;
  }
  .m-lg-t5 {
    margin-top: 5px;
  }
  .m-lg-b5 {
    margin-bottom: 5px;
  }
  .m-lg-t10 {
    margin-top: 10px;
  }
  .m-lg-b10 {
    margin-bottom: 10px;
  }
  .m-lg-t15 {
    margin-top: 15px;
  }
  .m-lg-b15 {
    margin-bottom: 15px;
  }
  .m-lg-t20 {
    margin-top: 20px;
  }
  .m-lg-b20 {
    /* margin-bottom: 20px; */
  }
  .m-lg-t25 {
    margin-top: 25px;
  }
  .m-lg-b25 {
    margin-bottom: 25px;
  }
  .m-lg-t30 {
    margin-top: 30px;
  }
  .m-lg-b30 {
    margin-bottom: 30px;
  }
  .m-lg-t35 {
    margin-top: 35px;
  }
  .m-lg-b35 {
    margin-bottom: 35px;
  }
  .m-lg-t40 {
    margin-top: 40px;
  }
  .m-lg-b40 {
    margin-bottom: 40px;
  }
  .m-lg-t45 {
    margin-top: 45px;
  }
  .m-lg-b45 {
    margin-bottom: 45px;
  }
  .m-lg-t50 {
    margin-top: 50px;
  }
  .m-lg-b50 {
    margin-bottom: 50px;
  }
  .m-lg-t55 {
    margin-top: 55px;
  }
  .m-lg-b55 {
    margin-bottom: 55px;
  }
  .m-lg-t60 {
    margin-top: 60px;
  }
  .m-lg-b60 {
    margin-bottom: 60px;
  }
  .m-lg-t65 {
    margin-top: 65px;
  }
  .m-lg-b65 {
    margin-bottom: 65px;
  }
  .m-lg-t70 {
    margin-top: 70px;
  }
  .m-lg-b70 {
    margin-bottom: 70px;
  }
  .m-lg-t75 {
    margin-top: 75px;
  }
  .m-lg-b75 {
    margin-bottom: 75px;
  }
  .m-lg-t80 {
    margin-top: 80px;
  }
  .m-lg-b80 {
    margin-bottom: 80px;
  }
  .m-lg-t85 {
    margin-top: 85px;
  }
  .m-lg-b85 {
    margin-bottom: 85px;
  }
  .m-lg-t90 {
    margin-top: 90px;
  }
  .m-lg-b90 {
    margin-bottom: 90px;
  }
  .m-lg-t95 {
    margin-top: 95px;
  }
  .m-lg-b95 {
    margin-bottom: 95px;
  }
  .m-lg-t100 {
    margin-top: 100px;
  }
  .m-lg-b100 {
    margin-bottom: 100px;
  }
  .m-lg-r0 {
    margin-right: 0;
  }
  .m-lg-l0 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 991px) {
  .m-md-t0 {
    margin-top: 0px;
  }
  .m-md-b0 {
    margin-bottom: 0px;
  }
  .m-md-t5 {
    margin-top: 5px;
  }
  .m-md-b5 {
    margin-bottom: 5px;
  }
  .m-md-t10 {
    margin-top: 10px;
  }
  .m-md-b10 {
    margin-bottom: 10px;
  }
  .m-md-t15 {
    margin-top: 15px;
  }
  .m-md-b15 {
    margin-bottom: 15px;
  }
  .m-md-t20 {
    margin-top: 20px;
  }
  .m-md-b20 {
    margin-bottom: 20px;
  }
  .m-md-t25 {
    margin-top: 25px;
  }
  .m-md-b25 {
    margin-bottom: 25px;
  }
  .m-md-t30 {
    margin-top: 30px;
  }
  .m-md-b30 {
    margin-bottom: 30px;
  }
  .m-md-t35 {
    margin-top: 35px;
  }
  .m-md-b35 {
    margin-bottom: 35px;
  }
  .m-md-t40 {
    margin-top: 40px;
  }
  .m-md-b40 {
    margin-bottom: 40px;
  }
  .m-md-t45 {
    margin-top: 45px;
  }
  .m-md-b45 {
    margin-bottom: 45px;
  }
  .m-md-t50 {
    margin-top: 50px;
  }
  .m-md-b50 {
    margin-bottom: 50px;
  }
  .m-md-t55 {
    margin-top: 55px;
  }
  .m-md-b55 {
    margin-bottom: 55px;
  }
  .m-md-t60 {
    margin-top: 60px;
  }
  .m-md-b60 {
    margin-bottom: 60px;
  }
  .m-md-t65 {
    margin-top: 65px;
  }
  .m-md-b65 {
    margin-bottom: 65px;
  }
  .m-md-t70 {
    margin-top: 70px;
  }
  .m-md-b70 {
    margin-bottom: 70px;
  }
  .m-md-t75 {
    margin-top: 75px;
  }
  .m-md-b75 {
    margin-bottom: 75px;
  }
  .m-md-t80 {
    margin-top: 80px;
  }
  .m-md-b80 {
    margin-bottom: 80px;
  }
  .m-md-t85 {
    margin-top: 85px;
  }
  .m-md-b85 {
    margin-bottom: 85px;
  }
  .m-md-t90 {
    margin-top: 90px;
  }
  .m-md-b90 {
    margin-bottom: 90px;
  }
  .m-md-t95 {
    margin-top: 95px;
  }
  .m-md-b95 {
    margin-bottom: 95px;
  }
  .m-md-t100 {
    margin-top: 100px;
  }
  .m-md-b100 {
    margin-bottom: 100px;
  }
  .m-md-r0 {
    margin-right: 0;
  }
  .m-md-l0 {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .m-sm-t0 {
    margin-top: 0px;
  }
  .m-sm-b0 {
    margin-bottom: 0px;
  }
  .m-sm-t5 {
    margin-top: 5px;
  }
  .m-sm-b5 {
    margin-bottom: 5px;
  }
  .m-sm-t10 {
    margin-top: 10px;
  }
  .m-sm-b10 {
    margin-bottom: 10px;
  }
  .m-sm-t15 {
    margin-top: 15px;
  }
  .m-sm-b15 {
    margin-bottom: 15px;
  }
  .m-sm-t20 {
    margin-top: 20px;
  }
  .m-sm-b20 {
    margin-bottom: 20px;
  }
  .m-sm-t25 {
    margin-top: 25px;
  }
  .m-sm-b25 {
    margin-bottom: 25px;
  }
  .m-sm-t30 {
    margin-top: 30px;
  }
  .m-sm-b30 {
    margin-bottom: 30px;
  }
  .m-sm-t35 {
    margin-top: 35px;
  }
  .m-sm-b35 {
    margin-bottom: 35px;
  }
  .m-sm-t40 {
    margin-top: 40px;
  }
  .m-sm-b40 {
    margin-bottom: 40px;
  }
  .m-sm-t45 {
    margin-top: 45px;
  }
  .m-sm-b45 {
    margin-bottom: 45px;
  }
  .m-sm-t50 {
    margin-top: 50px;
  }
  .m-sm-b50 {
    margin-bottom: 50px;
  }
  .m-sm-t55 {
    margin-top: 55px;
  }
  .m-sm-b55 {
    margin-bottom: 55px;
  }
  .m-sm-t60 {
    margin-top: 60px;
  }
  .m-sm-b60 {
    margin-bottom: 60px;
  }
  .m-sm-t65 {
    margin-top: 65px;
  }
  .m-sm-b65 {
    margin-bottom: 65px;
  }
  .m-sm-t70 {
    margin-top: 70px;
  }
  .m-sm-b70 {
    margin-bottom: 70px;
  }
  .m-sm-t75 {
    margin-top: 75px;
  }
  .m-sm-b75 {
    margin-bottom: 75px;
  }
  .m-sm-t80 {
    margin-top: 80px;
  }
  .m-sm-b80 {
    margin-bottom: 80px;
  }
  .m-sm-t85 {
    margin-top: 85px;
  }
  .m-sm-b85 {
    margin-bottom: 85px;
  }
  .m-sm-t90 {
    margin-top: 90px;
  }
  .m-sm-b90 {
    margin-bottom: 90px;
  }
  .m-sm-t95 {
    margin-top: 95px;
  }
  .m-sm-b95 {
    margin-bottom: 95px;
  }
  .m-sm-t100 {
    margin-top: 100px;
  }
  .m-sm-b100 {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 576px) {
  .m-xs-t0 {
    margin-top: 0px;
  }
  .m-xs-b0 {
    margin-bottom: 0px;
  }
  .m-xs-t5 {
    margin-top: 5px;
  }
  .m-xs-b5 {
    margin-bottom: 5px;
  }
  .m-xs-t10 {
    margin-top: 10px;
  }
  .m-xs-b10 {
    margin-bottom: 10px;
  }
  .m-xs-t15 {
    margin-top: 15px;
  }
  .m-xs-b15 {
    margin-bottom: 15px;
  }
  .m-xs-t20 {
    margin-top: 20px;
  }
  .m-xs-b20 {
    margin-bottom: 20px;
  }
  .m-xs-t25 {
    margin-top: 25px;
  }
  .m-xs-b25 {
    margin-bottom: 25px;
  }
  .m-xs-t30 {
    margin-top: 30px;
  }
  .m-xs-b30 {
    margin-bottom: 30px;
  }
  .m-xs-t35 {
    margin-top: 35px;
  }
  .m-xs-b35 {
    margin-bottom: 35px;
  }
  .m-xs-t40 {
    margin-top: 40px;
  }
  .m-xs-b40 {
    margin-bottom: 40px;
  }
  .m-xs-t45 {
    margin-top: 45px;
  }
  .m-xs-b45 {
    margin-bottom: 45px;
  }
  .m-xs-t50 {
    margin-top: 50px;
  }
  .m-xs-b50 {
    margin-bottom: 50px;
  }
  .m-xs-t55 {
    margin-top: 55px;
  }
  .m-xs-b55 {
    margin-bottom: 55px;
  }
  .m-xs-t60 {
    margin-top: 60px;
  }
  .m-xs-b60 {
    margin-bottom: 60px;
  }
  .m-xs-t65 {
    margin-top: 65px;
  }
  .m-xs-b65 {
    margin-bottom: 65px;
  }
  .m-xs-t70 {
    margin-top: 70px;
  }
  .m-xs-b70 {
    margin-bottom: 70px;
  }
  .m-xs-t75 {
    margin-top: 75px;
  }
  .m-xs-b75 {
    margin-bottom: 75px;
  }
  .m-xs-t80 {
    margin-top: 80px;
  }
  .m-xs-b80 {
    margin-bottom: 80px;
  }
  .m-xs-t85 {
    margin-top: 85px;
  }
  .m-xs-b85 {
    margin-bottom: 85px;
  }
  .m-xs-t90 {
    margin-top: 90px;
  }
  .m-xs-b90 {
    margin-bottom: 90px;
  }
  .m-xs-t95 {
    margin-top: 95px;
  }
  .m-xs-b95 {
    margin-bottom: 95px;
  }
  .m-xs-t100 {
    margin-top: 100px;
  }
  .m-xs-b100 {
    margin-bottom: 100px;
  }
}

.max-w50 {
  max-width: 50px;
}

.max-w60 {
  max-width: 60px;
}

.max-w80 {
  max-width: 80px;
}

.max-w100 {
  max-width: 100px;
}

.max-w200 {
  max-width: 200px;
}

.max-w300 {
  max-width: 300px;
}

.max-w400 {
  max-width: 400px;
}

.max-w500 {
  max-width: 500px;
}

.max-w600 {
  max-width: 600px;
}

.max-w700 {
  max-width: 700px;
}

.max-w800 {
  max-width: 800px;
}

.max-w900 {
  max-width: 900px;
}

.max-w1000 {
  max-width: 1000px;
}

.bg-img-fix {
  background-attachment: fixed;
  background-size: cover;
}

.full-img {
  width: 100%;
}

.sticky-top {
  top: 120px;
}

.page-title {
  padding: 65px 0px 45px;
  position: relative;
  background: #faf8f2;
  background-size: cover;
  background-position: center;
}
.page-title p {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #7272a8;
  text-transform: capitalize;
}
@media only screen and (max-width: 575px) {
  .page-title p {
    font-size: 16px;
  }
}
.page-title h3,
.page-title .h3 {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  text-transform: capitalize;
}
@media only screen and (max-width: 575px) {
  .page-title h3,
  .page-title .h3 {
    font-size: 28px;
  }
}

.img-cover {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.img-cover-1 {
  height: 100%;
  width: 100%;
}

.img-cover-2 {
  height: 306px;
  width: 100%;
  object-fit: cover;
}

.img-cover-3 {
  height: 100% !important;
  width: 100%;
  object-fit: cover;
}

section {
  position: relative;
}

.section-head {
  margin-bottom: 40px;
  position: relative;
}
.section-head1 {
  margin-top: 4rem;
  margin-bottom: 40px;
  position: relative;
}
.section-head .sub-title {
  text-transform: uppercase;
  color: #666666;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 5px;
}
@media only screen and (max-width: 767px) {
  .section-head .sub-title {
    font-size: 16px;
  }
}
.section-head .p-big {
  font-size: 18px;
}
@media only screen and (max-width: 1480px) {
  .section-head .p-big {
    font-size: 16px;
  }
}
.section-head p {
  color: #666666;
  max-width: 600px;
}
.section-head.text-center p {
  margin-left: auto;
  margin-right: auto;
}
.section-head .title {
  margin-bottom: 15px;
}
.section-head .title span {
  background: var(--gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section-head h2.title,
.section-head .title.h2 {
  font-size: 35px;
}
@media only screen and (max-width: 1480px) {
  .section-head h2.title,
  .section-head .title.h2 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 1280px) {
  .section-head h2.title,
  .section-head .title.h2 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .section-head h2.title,
  .section-head .title.h2 {
    font-size: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .section-head h2.title,
  .section-head .title.h2 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 1280px) {
  .section-head {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .section-head {
    margin-bottom: 20px;
  }
}
.section-head.side-line {
  padding-left: 60px;
}
.section-head.side-line:after,
.section-head.side-line:before {
  content: "";
  position: absolute;
  height: 90%;
  top: 5px;
  transform: skewX(-8deg);
  box-shadow: 0px 10px 15px 0px rgba(255, 129, 57, 0.15);
}
.section-head.side-line:after {
  left: 0;
  width: 15px;
  background: var(--gradient);
}
.section-head.side-line:before {
  left: 23px;
  width: 5px;
  background-color: var(--primary);
}
@media only screen and (max-width: 767px) {
  .section-head.side-line {
    padding-left: 30px;
  }
  .section-head.side-line:after {
    width: 10px;
  }
  .section-head.side-line:before {
    left: 12px;
  }
}
.section-head.style-1 .sub-title {
  font-size: 18px;
  font-weight: 400;
  color: #666;
}
.section-head.style-1 .title {
  font-size: 60px;
  font-weight: 700;
  color: #222222;
}
@media only screen and (max-width: 1280px) {
  .section-head.style-1 .title {
    font-size: 36px;
  }
}
@media only screen and (max-width: 575px) {
  .section-head.style-1 .title {
    font-size: 32px;
  }
}
.section-head.style-1 .title span {
  background: none;
  -webkit-text-fill-color: var(--primary);
  text-shadow: none;
}
.section-head.style-1 .description {
  font-size: 20px;
  color: var(--secondary);
  font-weight: 500;
  font-style: italic;
  letter-spacing: 1px;
  text-transform: capitalize;
}
@media only screen and (max-width: 575px) {
  .section-head.style-1 .description {
    font-size: 16px;
  }
}

.res-pl {
  padding-left: 50px;
}
@media only screen and (max-width: 1280px) {
  .res-pl {
    padding-left: 30px;
  }
}

.row.spno,
.spno {
  margin-left: 0;
  margin-right: 0;
}
.row.spno [class*="col"],
.row.spno [class*="col"],
.spno [class*="col"],
.spno [class*="col"] {
  padding-left: 0;
  padding-right: 0;
}

.row.sp4,
.sp4 {
  margin-left: -4px;
  margin-right: -4px;
}
.row.sp4 [class*="col"],
.row.sp4 [class*="col"],
.sp4 [class*="col"],
.sp4 [class*="col"] {
  padding-left: 4px;
  padding-right: 4px;
}

.row.sp15,
.sp15 {
  margin-left: -7px;
  margin-right: -7px;
}
.row.sp15 [class*="col"],
.row.sp15 [class*="col"],
.sp15 [class*="col"],
.sp15 [class*="col"] {
  padding-left: 7px;
  padding-right: 7px;
}

.row.sp10,
.sp10 {
  margin-left: -5px;
  margin-right: -5px;
}
.row.sp10 [class*="col-"],
.sp10 [class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row.sp60,
.sp60 {
  margin-left: -30px;
  margin-right: -30px;
}
.row.sp60 [class*="col-"],
.sp60 [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

.row.sp40,
.sp40 {
  margin-left: -20px;
  margin-right: -20px;
}
.row.sp40 [class*="col-"],
.sp40 [class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

.row.sp20,
.sp20 {
  margin-left: -10px;
  margin-right: -10px;
}
.row.sp20 [class*="col-"],
.sp20 [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row.sp16,
.sp16 {
  margin-left: -8px;
  margin-right: -8px;
}
.row.sp16 [class*="col-"],
.sp16 [class*="col-"] {
  padding-left: 8px;
  padding-right: 8px;
}

.rounded-xl {
  border-radius: 50% !important;
}

.rounded-lg {
  border-radius: 20px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-sm {
  border-radius: 5px !important;
}

.text-orange {
  color: #ff8853;
}

.text-yellow {
  color: #ffe713;
}

.text-skyblue {
  color: #029e76;
}

.text-red {
  color: #ff0003;
}

.text-green {
  color: #029e76;
}

.text-blue {
  color: #281e8b;
}

.bg-gray {
  background-color: #f3f4f8;
}

.bg-orange {
  background-color: #ff8853;
}

.bg-yellow {
  background-color: #ffe713 !important;
}

.bg-skyblue {
  background-color: #00aeff;
}

.bg-red {
  background-color: #ff0003;
}

.bg-green {
  background-color: #029e76;
}

.bg-blue {
  background-color: #281e8b;
}

.bg-pink {
  background-color: #ff5e78;
}

.text-black {
  color: #071c35;
}

.bg-purple {
  background-color: #9a69f9;
}

.scale8 {
  transform: scale(1.85);
  -moz-transform: scale(1.85);
  -webkit-transform: scale(1.85);
  -ms-transform: scale(1.85);
  -o-transform: scale(1.85);
}

.scale7 {
  transform: scale(1.7);
  -moz-transform: scale(1.7);
  -webkit-transform: scale(1.7);
  -ms-transform: scale(1.7);
  -o-transform: scale(1.7);
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}

.scale08 {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}

.scale05 {
  transform: scale(0.5);
  -moz-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  -o-transform: scale(0.5);
}

.tl {
  transform-origin: left;
  -moz-transform-origin: left;
  -webkit-transform-origin: left;
  -ms-transform-origin: left;
  -o-transform-origin: left;
}

.shadow-none {
  box-shadow: unset;
}

.separator {
  height: 1px;
  width: 100%;
  background: lightgrey;
}

@media only screen and (min-width: 1401px) and (max-width: 1440px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@font-face {
  font-family: "Kenyan Coffee";
  src: url("../fonts/kenyan_coffee/kenyan_coffee_rg.ttf") format("truetype"), url("../fonts/kenyan_coffee/kenyan_coffee_rg_it.ttf") format("truetype");
}

@font-face {
  font-family: "Kenyan Coffee";
  src: url("../fonts/kenyan_coffee/kenyan_coffee_bd.ttf") format("truetype"), url("../fonts/kenyan_coffee/kenyan_coffee_bd_it.ttf") format("truetype");
  font-weight: bold;
}

/*=== Layout ===*/
.logo-header {
  display: table;
  float: left;
  vertical-align: middle;
  padding: 0;
  color: #efbb20;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  width: 180px;
  height: 100px;
  position: relative;
  z-index: 9;
}
@media only screen and (max-width: 991px) {
  .logo-header {
    width: 180px;
    height: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .logo-header {
    width: 180px;
    height: 70px;
  }
}
.logo-header a {
  display: table-cell;
  vertical-align: middle;
}
.logo-header img {
  max-width: 180px;
  object-fit: contain;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .logo-header img {
    width: 165px;
  }
}
@media only screen and (max-width: 575px) {
  .logo-header img {
    max-width: 150px;
  }
}
.logo-header span {
  font-size: 20px;
  letter-spacing: 20px;
}

.top-bar {
  background-color: #fff;
  color: #212529;
  padding: 12px 0;
  position: relative;
  border-bottom: 1px solid #d7d7d7;
}
.top-bar .dz-topbar-left {
  float: left;
}
.top-bar .dz-topbar-left li {
  padding-right: 20px;
}
.top-bar .dz-topbar-right {
  float: right;
}
.top-bar .dz-topbar-right li {
  padding-left: 30px;
}
.top-bar .dz-social li a {
  color: inherit;
}
.top-bar .dz-topbar-inner {
  margin-left: -15px;
  margin-right: -15px;
}
.top-bar .dz-topbar-center,
.top-bar .dz-topbar-left,
.top-bar .dz-topbar-right {
  padding-left: 15px;
  padding-right: 15px;
}
.top-bar .dz-topbar-center ul,
.top-bar .dz-topbar-left ul,
.top-bar .dz-topbar-right ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.top-bar .dz-topbar-center ul li,
.top-bar .dz-topbar-left ul li,
.top-bar .dz-topbar-right ul li {
  display: inline-flex;
  font-size: 15px;
  color: #000;
  align-items: center;
}
.top-bar .dz-topbar-center ul i,
.top-bar .dz-topbar-left ul i,
.top-bar .dz-topbar-right ul i {
  margin-right: 8px;
  font-size: 17px;
  color: var(--primary);
}
.top-bar .dz-topbar-center .dz-social-icon li,
.top-bar .dz-topbar-left .dz-social-icon li,
.top-bar .dz-topbar-right .dz-social-icon li {
  padding-left: 0;
}
@media only screen and (max-width: 991px) {
  .top-bar {
    display: none;
  }
}

.dz-quik-search {
  background: rgba(33, 37, 41, 0.95);
  position: fixed;
  height: 100%;
  width: 100%;
  top: -100%;
  left: 0;
  padding: 0px 30px;
  z-index: 999;
  overflow: hidden;
  display: none;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-quik-search form {
  width: 100%;
  max-width: 1401px;
  margin: auto;
  position: relative;
  top: 50%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
  transform: translateY(-50%);
}
.dz-quik-search .form-control,
.dz-quik-search .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dz-quik-search select,
.dz-quik-search .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dz-quik-search select {
  padding: 15px 60px 15px 15px;
  width: 100%;
  height: 90px;
  border: none;
  background: 0 0;
  color: #fff;
  font-size: 20px;
  border-bottom: 2px solid #fff;
  border-radius: 0;
}
.dz-quik-search .form-control::-webkit-input-placeholder,
.dz-quik-search .wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-categories-dropdown .dz-quik-search select::-webkit-input-placeholder,
.dz-quik-search .wp-block-archives-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown .dz-quik-search select::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dz-quik-search .form-control:-ms-input-placeholder,
.dz-quik-search .wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-categories-dropdown .dz-quik-search select:-ms-input-placeholder,
.dz-quik-search .wp-block-archives-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown .dz-quik-search select:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dz-quik-search .form-control::placeholder,
.dz-quik-search .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .dz-quik-search select::placeholder,
.dz-quik-search .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .dz-quik-search select::placeholder {
  color: rgba(255, 255, 255, 0.8);
}
.dz-quik-search span {
  position: absolute;
  right: 15px;
  top: 50%;
  margin: -15px 0;
  height: 25px;
  font-size: 20px;
  cursor: pointer;
}
.dz-quik-search.On {
  top: 0;
}

.site-header {
  position: relative;
  z-index: 9999;
}
.site-header .main-bar {
  background: #fff;
  width: 100% !important;
  position: relative;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
}
.site-header .container {
  position: relative;
}
.site-header .extra-nav {
  float: right;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;
  height: 100px;
  padding-left: 30px;
}
.site-header .extra-nav .extra-cell {
  display: flex;
  align-items: center;
}
.site-header .extra-nav .extra-cell > * {
  display: inline-block;
}
.site-header .extra-nav #quik-search-btn {
  color: #212529;
  cursor: pointer;
  margin-right: 0px;
  border: 0;
  width: 45px;
  height: 45px;
  background: #ebebeb;
  line-height: 45px;
  text-align: center;
  font-size: 18px;
  border-radius: 45px;
}
@media only screen and (max-width: 575px) {
  .site-header .extra-nav .btn,
  .site-header .extra-nav .wp-block-button__link {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.site-header .extra-nav ul li {
  display: inline-block;
}
.site-header .extra-nav ul li .icon {
  padding: 0;
  background: transparent;
  min-width: 40px;
  text-align: center;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
}
@media only screen and (max-width: 1199px) {
  .site-header .extra-nav {
    height: 100px;
  }
}
@media only screen and (max-width: 991px) {
  .site-header .extra-nav {
    height: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .site-header .extra-nav {
    display: none;
  }
}

.navbar-toggler {
  border: 0 solid #efbb20;
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0 18px 15px;
  padding: 0;
  float: right;
  outline: none !important;
}
@media only screen and (max-width: 575px) {
  .navbar-toggler {
    margin: 14px 0 14px 15px;
  }
}

.navbar-toggler-skew {
  position: relative;
}
.navbar-toggler-skew span {
  left: 9px;
}
.navbar-toggler-skew:before {
  content: "";
  position: absolute;
  background-color: var(--primary);
  height: 100%;
  width: calc(100% + 10px);
  top: 0;
  z-index: -1;
  left: -5px;
  transform: skewX(-12deg);
}
@media only screen and (max-width: 991px) {
  .navbar-toggler-skew:before {
    display: none;
  }
}

.navicon {
  width: 42px;
  height: 42px;
  background: var(--primary);
  box-shadow: 0 5px 15px -10px var(--primary);
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  border-radius: 0;
}
.navicon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  opacity: 1;
  background: var(--theme-text-color);
  left: 8px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
.navicon span:nth-child(1) {
  top: 11px;
  width: 26px;
}
.navicon span:nth-child(2) {
  top: 20px;
  width: 22px;
}
.navicon span:nth-child(3) {
  top: 30px;
  width: 17px;
}
.navicon.open span:nth-child(1) {
  top: 25px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}
@media only screen and (max-width: 1280px) {
  .navicon.open span:nth-child(1) {
    top: 20px;
    width: 25px;
  }
}
.navicon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}
.navicon.open span:nth-child(3) {
  top: 25px;
  width: 26px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
@media only screen and (max-width: 1280px) {
  .navicon.open span:nth-child(3) {
    top: 20px;
    width: 25px;
  }
}

.header-tb .navicon span {
  background: #fff;
}

.header-nav {
  padding: 0;
}
@media only screen and (max-width: 991px) {
  .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav.show {
    left: -1px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    margin: 0;
    width: 280px;
  }
}
.header-nav .logo-header {
  display: none;
}
@media only screen and (max-width: 991px) {
  .header-nav .logo-header {
    padding: 30px 20px;
    float: none;
    height: auto;
    width: 240px;
  }
}
.header-nav .nav {
  float: right;
  font-size: 0;
  padding: 0;
  position: unset;
}
.header-nav .nav > li {
  margin: 0px;
  font-weight: 400;
  position: relative;
}
.header-nav .nav > li.has-mega-menu {
  position: inherit;
}
@media only screen and (min-width: 991px) {
  .header-nav .nav > li.has-mega-menu.homedemo .mega-menu li {
    width: 25%;
    padding: 0;
  }
  .header-nav .nav > li.has-mega-menu.homedemo .mega-menu li a {
    padding: 20px;
  }
  .header-nav .nav > li.has-mega-menu.homedemo .mega-menu li a:after {
    content: none;
  }
  .header-nav .nav > li.has-mega-menu.homedemo .mega-menu li a .image-new {
    position: relative;
  }
  .header-nav .nav > li.has-mega-menu.homedemo .mega-menu li a img {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    margin-bottom: 25px;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
  }
  .header-nav .nav > li.has-mega-menu.homedemo .mega-menu li a span.badge {
    position: absolute;
    top: 8px;
    right: -25px;
    background: #fff700;
    color: #000;
    border-radius: 0;
    transform: rotate(45deg);
    font-size: 14px;
    padding: 6px 30px;
  }
  .header-nav .nav > li.has-mega-menu.homedemo .mega-menu li:hover a {
    padding: 20px;
  }
  .header-nav .nav > li.has-mega-menu.homedemo .mega-menu li:hover a img {
    transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.has-mega-menu.homedemo .mega-menu li a .image-new {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li {
    border-bottom: 1px solid #eee;
    width: 100%;
  }
}
.header-nav .nav > li.sub-menu-down > a:after {
  content: "\f078";
  font-size: 10px;
  margin-left: 7px;
  margin-top: 0;
  vertical-align: middle;
  font-weight: 900;
  font-family: "Font Awesome 5 Free";
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.sub-menu-down > a:after {
    content: "\f054";
    color: var(--primary);
    background-color: var(--rgba-primary-1);
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    float: right;
    font-size: 12px;
    margin: 0;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.sub-menu-down > a:after:before {
    content: "\f054";
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li.sub-menu-down.open > a:after {
    content: "\f078";
    background: var(--primary);
    color: #fff;
  }
}
.header-nav .nav > li > a {
  color: #071c35;
  font-size: 16px;
  padding: 40px 15px;
  font-family: var(--font-family-base);
  cursor: pointer;
  font-weight: 500;
  display: inline-block;
  position: relative;
  border-width: 0 0 0 1px;
  text-transform: capitalize;
  line-height: 1.1;
}
@media only screen and (max-width: 1401px) {
  .header-nav .nav > li > a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li > a {
    padding: 8px 0;
    display: block;
    line-height: 30px;
    font-size: 16px;
  }
}
.header-nav .nav > li > a span {
  display: inline-block;
}
.header-nav .nav > li .mega-menu,
.header-nav .nav > li .sub-menu {
  background-color: #fff;
  display: block;
  left: 0;
  list-style: none;
  opacity: 0;
  padding: 12px 0;
  position: absolute;
  visibility: hidden;
  width: 220px;
  z-index: 10;
  border-radius: 0;
  margin-top: 20px;
  box-shadow: 0px 1px 40px 0px rgba(0, 0, 0, 0.1);
  text-align: left;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu,
  .header-nav .nav > li .sub-menu {
    display: none;
    position: static;
    visibility: visible;
    width: auto;
    background: transparent;
    box-shadow: none;
    margin: 0;
    border-top: 1px solid #eee;
    padding: 10px 0;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
  .header-nav .nav > li .mega-menu > li > a i,
  .header-nav .nav > li .sub-menu > li > a i {
    color: var(--primary) !important;
    height: 30px;
    line-height: 30px;
    right: 0;
    text-align: center;
    width: 30px;
    z-index: 3;
    font-size: 14px !important;
    margin: 0 !important;
    background: var(--rgba-primary-2);
  }
}
.header-nav .nav > li .mega-menu li,
.header-nav .nav > li .sub-menu li {
  position: relative;
}
.header-nav .nav > li .mega-menu li a,
.header-nav .nav > li .sub-menu li a {
  color: #212529;
  display: block;
  font-size: 15px;
  padding: 8px 25px;
  line-height: 1.3;
  text-transform: capitalize;
  position: relative;
  transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -moz-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  font-weight: 500;
}
.header-nav .nav > li .mega-menu li a:after,
.header-nav .nav > li .sub-menu li a:after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  background: var(--primary);
  left: 20px;
  top: 16px;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li a,
  .header-nav .nav > li .sub-menu li a {
    padding: 2px 0 1px 16px;
    display: block;
    line-height: 30px;
    position: relative;
  }
  .header-nav .nav > li .mega-menu li a:after,
  .header-nav .nav > li .sub-menu li a:after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    width: auto;
    height: auto;
    background: 0 0 !important;
    top: 3px;
    color: #262f5a;
    font-size: 12px;
    position: absolute;
    left: 0;
    font-weight: 700;
  }
}
.header-nav .nav > li .mega-menu li:hover > a,
.header-nav .nav > li .sub-menu li:hover > a {
  color: var(--primary);
  padding: 8px 20px 8px 37px;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li:hover > a,
  .header-nav .nav > li .sub-menu li:hover > a {
    padding: 2px 0 1px 16px;
    background: transparent;
  }
}
.header-nav .nav > li .mega-menu li:hover > a:after,
.header-nav .nav > li .sub-menu li:hover > a:after {
  width: 10px;
}
.header-nav .nav > li .mega-menu li > .sub-menu,
.header-nav .nav > li .sub-menu li > .sub-menu {
  left: 220px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu li > .sub-menu,
  .header-nav .nav > li .sub-menu li > .sub-menu {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu .mega-menu,
  .header-nav .nav > li .mega-menu .sub-menu,
  .header-nav .nav > li .sub-menu .mega-menu,
  .header-nav .nav > li .sub-menu .sub-menu {
    border-top: 0;
    padding-left: 20px;
  }
  .header-nav .nav > li .mega-menu .mega-menu li a:after,
  .header-nav .nav > li .mega-menu .sub-menu li a:after,
  .header-nav .nav > li .sub-menu .mega-menu li a:after,
  .header-nav .nav > li .sub-menu .sub-menu li a:after {
    width: 5px;
    content: "";
    height: 5px;
    background: #262f5a !important;
    border-radius: 4px;
    top: 13px;
  }
}
.header-nav .nav > li .sub-menu li > a > i {
  color: inherit;
  display: block;
  float: right;
  font-size: 10px;
  opacity: 1;
  margin-top: 7px;
}
.header-nav .nav > li .sub-menu.open > a > i:before {
  content: "\f078";
}
.header-nav .nav > li .sub-menu > li.open > a i:before {
  content: "\f107";
}
.header-nav .nav > li .mega-menu {
  display: flex;
  right: 0px;
  width: 100%;
  z-index: 9;
  padding: 0;
}
.header-nav .nav > li .mega-menu > li {
  display: block;
  padding: 30px 0 25px;
  position: relative;
  vertical-align: top;
  width: 25%;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li {
    width: 100%;
    padding: 0;
  }
}
@media only screen and (min-width: 991px) {
  .header-nav .nav > li .mega-menu > li > a {
    color: #222222;
    display: block;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
  }
  .header-nav .nav > li .mega-menu > li > a > i {
    font-size: 14px;
    margin-right: 5px;
    text-align: center;
    width: 15px;
  }
}
.header-nav .nav > li .mega-menu > li:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 0px;
  top: 0px;
  display: block;
  width: 1px;
  height: 100%;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu > li:after {
    content: none;
  }
}
.header-nav .nav > li .mega-menu > li:last-child:after {
  display: none;
}
.header-nav .nav > li .mega-menu > li:hover > a {
  background-color: transparent;
}
.header-nav .nav > li .mega-menu > li li i {
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  width: 15px;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li .mega-menu {
    display: none;
    padding: 10px 0;
  }
}
.header-nav .nav > li.active > a {
  color: var(--primary);
}
.header-nav .nav > li:hover > .mega-menu,
.header-nav .nav > li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
@media only screen and (max-width: 991px) {
  .header-nav .nav > li:hover > .mega-menu,
  .header-nav .nav > li:hover > .sub-menu {
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
  }
}
.header-nav .nav > li:hover > .mega-menu li:hover > .sub-menu,
.header-nav .nav > li:hover > .sub-menu li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  top: -1px;
}
.header-nav .nav > li.open .mega-menu,
.header-nav .nav > li.open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}
.header-nav .nav > li.open .open.menu-item-has-children > a:before {
  transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}
.header-nav .nav > li.open .open .mega-menu,
.header-nav .nav > li.open .open .sub-menu {
  display: block;
  opacity: 1;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}
@media only screen and (max-width: 1480px) {
  .header-nav .nav > li:nth-last-child(3) .sub-menu .sub-menu,
  .header-nav .nav > li:nth-last-child(2) .sub-menu .sub-menu,
  .header-nav .nav > li:last-child .sub-menu .sub-menu {
    left: -220px;
  }
}
@media only screen and (max-width: 1199px) {
  .header-nav .nav {
    padding: 0;
  }
  .header-nav .nav > li:last-child .sub-menu {
    left: auto;
    right: 0;
  }
}
@media only screen and (max-width: 991px) {
  .header-nav .nav {
    float: none;
    padding: 0 20px;
  }
}
.header-nav .dz-social-icon {
  display: none;
  text-align: center;
}
.header-nav .dz-social-icon ul {
  margin: 0 -2px;
}
.header-nav .dz-social-icon li {
  display: inline-block;
  padding: 0 2px;
}
.header-nav .dz-social-icon a {
  display: block;
  width: 35px;
  height: 35px;
  padding: 0;
  color: var(--primary);
  border: 1px solid var(--primary);
  line-height: 33px;
  text-align: center;
  border-radius: 4px;
  font-size: 15px;
}
.header-nav .dz-social-icon a:hover {
  background-color: var(--primary);
  color: white;
  box-shadow: 0px 5px 15px var(--rgba-primary-4);
}
@media only screen and (max-width: 991px) {
  .header-nav .dz-social-icon {
    display: block;
    padding: 30px 10px 20px;
  }
}

.header-transparent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.header-transparent .top-bar,
.header-transparent .main-bar {
  background: transparent;
}
.header-transparent .container-fluid,
.header-transparent .container-sm,
.header-transparent .container-md,
.header-transparent .container-lg,
.header-transparent .container-xl {
  padding-left: 80px;
  padding-right: 80px;
}
@media only screen and (max-width: 1280px) {
  .header-transparent .container-fluid,
  .header-transparent .container-sm,
  .header-transparent .container-md,
  .header-transparent .container-lg,
  .header-transparent .container-xl {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.header-transparent .main-bar {
  box-shadow: none;
}
.header-transparent .main-bar .container:not(.box-header) > .logo-header.logo-dark,
.header-transparent .main-bar .container-fluid:not(.box-header) > .logo-header.logo-dark,
.header-transparent .main-bar .container-sm:not(.box-header) > .logo-header.logo-dark,
.header-transparent .main-bar .container-md:not(.box-header) > .logo-header.logo-dark,
.header-transparent .main-bar .container-lg:not(.box-header) > .logo-header.logo-dark,
.header-transparent .main-bar .container-xl:not(.box-header) > .logo-header.logo-dark {
  display: none;
}
.header-transparent .is-fixed .main-bar .container > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-fluid > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-sm > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-md > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-lg > .logo-header.logo-light,
.header-transparent .is-fixed .main-bar .container-xl > .logo-header.logo-light {
  display: none;
}
.header-transparent .is-fixed .main-bar .container > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-fluid > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-sm > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-md > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-lg > .logo-header.logo-dark,
.header-transparent .is-fixed .main-bar .container-xl > .logo-header.logo-dark {
  display: table;
}

.is-fixed .main-bar {
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: all 0.5s;
}

@media only screen and (max-width: 991px) {
  .mo-left .header-nav {
    overflow-y: scroll;
    position: fixed;
    width: 60px;
    left: -280px;
    height: 100vh !important;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    top: 0;
    background-color: #fff;
    margin: 0;
    z-index: 99;
  }
  .mo-left .header-nav.nav-dark {
    background-color: #202020;
  }
  .mo-left .header-nav.show {
    left: -1px;
    transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -moz-transition: all 0.8s;
    -o-transition: all 0.8s;
    margin: 0;
    width: 280px;
  }
  .mo-left .header-nav .logo-header {
    display: block;
    float: none;
    height: auto !important;
    max-width: 100%;
    padding: 25px 20px;
    width: 100% !important;
  }
  .mo-left .header-nav .logo-header img {
    max-width: unset;
    width: 150px;
    vertical-align: middle;
  }
  .mo-left .header-nav li.open a {
    position: relative;
  }
  .mo-left .navbar-toggler.open {
    z-index: 99;
  }
  .mo-left .navbar-toggler.open:after {
    background-color: rgba(0, 0, 0, 0.6);
    content: "";
    left: 0;
    position: fixed;
    right: -20px;
    top: -63px;
    transform: scale(100);
    -o-transform: scale(100);
    -moz-transform: scale(100);
    -webkit-transform: scale(100);
    width: 100%;
    z-index: -1;
    transition: all 0.5s;
    transform-origin: top right;
    margin: 0 0px 0px 20px;
    -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
  }
  .mo-left .navbar-toggler.open span {
    background: #fff;
  }
}

.text-black .nav > li > a {
  color: #222222;
}

.text-black .navbar-toggler span {
  background: #222222;
}

.main-bar {
  -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
  transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}

.is-fixed .main-bar {
  -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
}

@-moz-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@-ms-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}


@-moz-keyframes headerSlideup {
  0% {
    transform: translateY(150px); /* Start position - off screen */
  }
  100% {
    transform: translateY(0); /* End position - on screen */
  }
}

@-ms-keyframes headerSlideup {
  0% {
    transform: translateY(150px);
  }
  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes headerSlideup {
  0% {
    transform: translateY(150px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes headerSlideup {
  0% {
    transform: translateY(150px);
  }
  100% {
    transform: translateY(0);
  }
}


.site-header.style-1 .main-bar {
  box-shadow: none;
  background-color: transparent;
}
.site-header.style-1 .main-bar .box-header {
  position: relative;
}
@media only screen and (min-width: 1180px) and (max-width: 1280px) {
  .site-header.style-1 .main-bar .box-header {
    padding: 0 20px;
  }
}
.site-header.style-1 .main-bar.is-fixed .box-header:after {
  content: "";
  box-shadow: 0px 14px 30px 0px rgba(225, 50, 0, 0.1);
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 0;
  height: calc(100% - 1px);
  z-index: -1;
  width: calc(100% + 25px);
  transform: translateX(-50%) skewX(-12deg);
}
@media only screen and (max-width: 1280px) {
  .site-header.style-1 .main-bar .box-header:after {
    width: calc(100% + 20px);
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1240px) {
  .site-header.style-1 .main-bar .box-header:after {
    width: calc(100% + -20px);
  }
}
@media only screen and (max-width: 991px) {
  .site-header.style-1 .main-bar .box-header:after {
    transform: translateX(-50%) skewX(0);
  }
}

.site-header.style-1 .container {
  display: grid;
}

.site-header.style-1 .extra-nav {
  height: 80px;
  padding-left: 60px;
}
.site-header.style-1 .extra-nav #quik-search-btn {
  font-size: 16px;
  transform: skewX(-12deg);
  border-radius: 0;
  margin-right: 5px;
}
.site-header.style-1 .extra-nav #quik-search-btn i {
  transform: skewX(12deg);
}
@media only screen and (max-width: 991px) {
  .site-header.style-1 .extra-nav #quik-search-btn {
    transform: skew(0);
    margin-right: 0;
  }
  .site-header.style-1 .extra-nav #quik-search-btn i {
    transform: skew(0);
  }
}
@media only screen and (max-width: 1280px) {
  .site-header.style-1 .extra-nav .appointment-btn {
    display: none;
  }
}
@media only screen and (max-width: 1280px) {
  .site-header.style-1 .extra-nav {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .site-header.style-1 .extra-nav {
    height: 70px;
  }
}

.site-header.style-1 .logo-header {
  height: 80px;
}
.site-header.style-1 .logo-header img {
  max-width: 290px;
}
@media only screen and (max-width: 1280px) {
  .site-header.style-1 .logo-header img {
    max-width: 180px;
  }
}
@media only screen and (max-width: 991px) {
  .site-header.style-1 .logo-header {
    height: 70px;
  }
}

@media only screen and (min-width: 991px) {
  .site-header.style-1 .header-nav .nav > li > a {
    padding: 31px 15px;
  }
  .site-header.style-1 .header-nav .nav > li > a:before {
    content: "";
    background-color: var(--primary);
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translateY(4px);
    height: 4px;
    z-index: 1;
    width: 100%;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0;
  }
}

@media only screen and (min-width: 991px) {
  .site-header.style-1 .header-nav .nav > li.active > a:before,
  .site-header.style-1 .header-nav .nav > li:hover > a:before {
    transform: translateY(0);
    opacity: 1;
  }
}

.site-header.style-1 .top-bar {
  background-color: transparent;
  border-bottom: 0;
}

.site-header.style-1 .is-fixed .main-bar {
  box-shadow: 0px 14px 30px 0px rgba(225, 50, 0, 0.1);
  background-color: white;
}
.site-header.style-1 .is-fixed .main-bar .box-header:after {
  box-shadow: none;
}

@media only screen and (max-width: 991px) {
  .site-header.style-1 .navbar-toggler {
    margin: 14px 0 14px 15px;
  }
  .site-header.style-1 .main-bar {
    margin-top: 10px;
  }
}

.site-header.style-2.header-transparent {
  padding-top: 10px;
}
.site-header.style-2.header-transparent .sticky-header:not(.is-fixed) .header-nav .nav > li > a,
.site-header.style-2.header-transparent .sticky-no:not(.is-fixed) .header-nav .nav > li > a {
  color: #fff;
}
.site-header.style-2.header-transparent .sticky-header:not(.is-fixed) .appointment-btn,
.site-header.style-2.header-transparent .sticky-no:not(.is-fixed) .appointment-btn {
  color: #222222;
  background: #fff;
}
@media only screen and (max-width: 1280px) {
  .site-header.style-2.header-transparent {
    padding-top: 0;
  }
}

.site-header.style-2 .appointment-btn {
  color: #fff;
  background: #222222;
}

.site-header.style-2 .header-search-btn::after {
  display: none;
}

.site-header.style-2 #quik-search-btn {
  font-size: 16px;
  transform: skewX(-12deg);
  border-radius: 0;
  margin-right: 5px;
}
.site-header.style-2 #quik-search-btn i {
  transform: skewX(12deg);
}
@media only screen and (max-width: 991px) {
  .site-header.style-2 #quik-search-btn {
    transform: skew(0);
    margin-right: 0;
  }
  .site-header.style-2 #quik-search-btn i {
    transform: skew(0);
  }
}

@media only screen and (max-width: 991px) {
  .site-header.style-2 .header-nav .nav > li > a {
    color: #000 !important;
  }
}

.new-badge span {
  background: var(--rgba-primary-1);
  color: var(--primary);
  display: inline-block !important;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  margin-left: 5px;
  border: 0;
}

@keyframes shake2 {
  0% {
    transform: skewX(-15deg);
  }
  5% {
    transform: skewX(15deg);
  }
  10% {
    transform: skewX(-15deg);
  }
  15% {
    transform: skewX(15deg);
  }
  20% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}

.dz-bnr-inr {
  height: 300px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr {
    height: 280px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr {
    height: 280px;
  }
}
.dz-bnr-inr .container {
  display: table;
  height: 100%;
}
.dz-bnr-inr .dz-bnr-inr-entry {
  vertical-align: middle;
  display: table-cell;
}
@media only screen and (max-width: 767px) {
  .dz-bnr-inr .dz-bnr-inr-entry {
    text-align: center;
  }
  .dz-bnr-inr .dz-bnr-inr-entry .breadcrumb-row {
    display: inline-block;
  }
}
.dz-bnr-inr h1,
.dz-bnr-inr .h1 {
  margin-bottom: 0px;
  color: #fff;
  text-transform: capitalize;
}
.dz-bnr-inr.dz-bnr-inr-sm {
  height: 300px;
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.dz-bnr-inr-sm {
    height: 250px;
  }
}
.dz-bnr-inr.dz-bnr-inr-md {
  height: 450px;
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.dz-bnr-inr-md {
    height: 350px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.dz-bnr-inr-md {
    height: 300px;
  }
}
.dz-bnr-inr.dz-bnr-inr-lg {
  height: 100vh;
}
.dz-bnr-inr.bnr-no-img:before {
  content: none;
}

.breadcrumb-row ul {
  background: var(--primary);
  margin: 0;
  border-radius: 0;
  border: 0;
  margin-right: 12px;
  transform: skewX(-12deg);
  display: inline-block;
  padding: 4px 33px;
}
@media only screen and (max-width: 991px) {
  .breadcrumb-row ul {
    padding: 6px 25px;
  }
}
.breadcrumb-row ul:after {
  content: "";
  position: absolute;
  background-color: inherit;
  height: 100%;
  width: 7px;
  right: -12px;
  z-index: 1;
  top: 0;
}
.breadcrumb-row ul li {
  padding: 0;
  transform: skewX(12deg);
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
}
.breadcrumb-row ul li.active {
  color: #fff;
}
.breadcrumb-row ul li a {
  color: #fff;
}
.breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
  content: "\f105";
  color: #fff;
  font-weight: 700;
  font-family: "Font Awesome 6 Free";
  font-size: 10px;
  padding-right: 10px;
  top: 2px;
  position: relative;
}
@media only screen and (max-width: 767px) {
  .breadcrumb-row ul {
    padding: 5px 20px;
  }
  .breadcrumb-row ul li {
    font-size: 14px;
  }
}

.dz-bnr-inr.style-1 {
  background-position: bottom left;
}
.dz-bnr-inr.style-1 h1,
.dz-bnr-inr.style-1 .h1 {
  color: var(--title);
  font-size: 35px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 35px;
}
@media only screen and (max-width: 1480px) {
  .dz-bnr-inr.style-1 h1,
  .dz-bnr-inr.style-1 .h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.style-1 h1,
  .dz-bnr-inr.style-1 .h1 {
    font-size: 35px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.style-1 h1,
  .dz-bnr-inr.style-1 .h1 {
    font-size: 32px;
  }
}
.dz-bnr-inr.style-1:after {
  content: attr(data-text);
  position: absolute;
  font-weight: 700;
  font-size: 235px;
  line-height: 1;
  font-family: var(--font-family-title);
  z-index: -2;
  left: -20px;
  color: #fff;
  opacity: 0.3;
  bottom: -10%;
}
@media only screen and (max-width: 1480px) {
  .dz-bnr-inr.style-1:after {
    font-size: 200px;
    bottom: -15%;
  }
}
@media only screen and (max-width: 991px) {
  .dz-bnr-inr.style-1:after {
    font-size: 150px;
    bottom: -10%;
  }
}
@media only screen and (max-width: 575px) {
  .dz-bnr-inr.style-1:after {
    font-size: 60px;
    bottom: -10px;
  }
}
.dz-bnr-inr.style-1 .breadcrumb-row {
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .dz-bnr-inr.style-1 .breadcrumb-row {
    width: 100%;
  }
}

.style-1.header-transparent + .page-content .dz-bnr-inr {
  padding-top: 130px;
}
@media only screen and (max-width: 991px) {
  .style-1.header-transparent + .page-content .dz-bnr-inr {
    padding-top: 140px;
  }
}

.page-title {
  padding: 30px 0;
}
.page-title.dashboard {
  background: transparent;
  margin-left: 75px;
  padding: 20px 0 0px;
}
.page-title .page-title-content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.page-title .page-title-content p span {
  font-weight: 500;
  color: var(--primary);
}

.breadcrumbs {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 575px) {
  .breadcrumbs {
    justify-content: flex-start;
  }
}
.breadcrumbs li a {
  color: #aeaed5;
  display: inline-block;
  margin-left: 15px;
  font-size: 14px;
}
.breadcrumbs li:first-child a {
  margin-left: 0px;
}
.breadcrumbs li.active a {
  color: var(--primary);
}

.bottom {
  background: #1b2a4e;
}
@media only screen and (max-width: 575px) {
  .bottom {
    padding-bottom: 0;
  }
}
.bottom .bottom-logo p {
  line-height: 30px;
  font-weight: 400;
  color: #999999;
}
@media only screen and (max-width: 991px) {
  .bottom .bottom-widget {
    margin-bottom: 30px;
  }
}
.bottom .bottom-widget .widget-title {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.bottom .bottom-widget ul li a {
  color: #999999;
  font-weight: 400;
  margin-bottom: 10px;
  display: inline-block;
}

footer {
  position: relative;
}
footer .widget {
  margin-bottom: 50px;
}
footer strong {
  color: #fff;
}
footer .footer-logo {
  margin-bottom: 25px;
}
footer .footer-logo img {
  max-width: 100%;
}
footer .widget-logo ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
footer .widget-logo ul li {
  display: inline-block;
  flex: 50%;
  padding: 0 15px 15px 0px;
}
footer .widget_about p {
  margin-bottom: 30px;
}
@media only screen and (max-width: 1401px) {
  footer .widget_about {
    padding: 0;
  }
}
@media only screen and (max-width: 1191px) {
  footer .widget_about {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}
footer .footer-title {
  margin: 0;
  margin-bottom: 30px;
  position: relative;
}
footer .footer-title a {
  color: inherit;
}
footer .footer-title .title {
  color: var(--title);
}
footer .footer-title .dz-separator {
  position: absolute;
  bottom: 0;
  left: 0;
}
footer .footer-top {
  padding-top: 100px;
  padding-bottom: 40px;
  position: relative;
  z-index: 2;
}
.play-img {
  margin-top: -1px;
}
@media only screen and (min-width: 992px) and (max-width: 1280px) {
  footer .footer-top {
    padding-top: 80px;
    padding-bottom: 20px;
  }
  .play-img {
    margin-top: -1px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  footer .footer-top {
    padding-top: 60px;
    padding-bottom: 0;
  }
  .play-img {
    margin-top: 3px;
  }
}
@media only screen and (max-width: 767px) {
  footer .footer-top {
    padding-top: 60px;
    padding-bottom: 0;
  }
  .play-img {
    margin-top: -5px;
  }
}
@media only screen and (min-width: 340px) and (max-width: 767px) {
  footer .footer-top {
    padding-top: 60px;
    padding-bottom: 0;
  }
  .play-img {
    margin-top: -4px;
    width: 192px;
  }
}
footer .footer-bottom {
  padding: 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 991px) {
  footer .footer-bottom {
    text-align: center !important;
  }
  footer .footer-bottom .text-left,
  footer .footer-bottom .text-right {
    text-align: center !important;
  }
  footer .footer-bottom .text-right {
    margin-top: 10px;
  }
}
footer .widget-link li {
  display: inline-block;
  text-transform: uppercase;
  margin-left: 20px;
}
@media only screen and (max-width: 767px) {
  footer .widget-link li {
    margin-left: 8px;
    margin-right: 7px;
  }
}
footer .widget-link a {
  color: #fff;
}
footer .widget_services ul li a {
  color: inherit;
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
footer .wp-block-latest-posts li,
footer .wp-block-categories-list li,
footer .wp-block-archives-list li,
footer .widget_categories ul li,
footer .widget_archive ul li,
footer .widget_meta ul li,
footer .widget_pages ul li,
footer .widget_recent_comments ul li,
footer .widget_nav_menu li,
footer .widget_recent_entries ul li,
footer .widget_services ul li {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
footer .widget_rss ul li {
  --title: #fff;
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}
footer .wp-block-calendar table caption,
footer .wp-block-calendar table tbody {
  color: #fff;
}
footer .wp-block-tag-cloud a,
footer .widget_tag_cloud .tagcloud a {
  border-color: rgba(255, 255, 255, 0.1);
}
footer .wp-block-search,
footer .wp-block-archives,
footer .wp-block-latest-posts,
footer .wp-block-latest-comments,
footer .wp-block-categories,
footer .wp-block-calendar {
  margin-bottom: 0;
}
footer .dz-meta ul li span,
footer .dz-meta ul li a {
  color: var(--primary);
}
footer .dz-social-icon ul li {
  padding: 0 10px 0 0px;
}

.contact-ft {
  text-align: center;
  margin-bottom: 40px;
}
.contact-ft i {
  color: var(--primary);
  font-size: 50px;
  line-height: 1;
  margin-bottom: 15px;
  display: inline-block;
}
.contact-ft h4,
.contact-ft .h4,
.contact-ft .comments-title,
.contact-ft .blog-title,
.contact-ft .comment-reply-title,
.contact-ft .wp-block-search .wp-block-search__label,
.wp-block-search .contact-ft .wp-block-search__label {
  color: #fff;
  text-decoration: underline;
}
.contact-ft h5,
.contact-ft .h5 {
  color: #fff;
  font-weight: 400;
}

.list-column ul {
  display: flex;
  flex-wrap: wrap;
}
.list-column ul li {
  flex: 0 0 50%;
  max-width: 50%;
}

.fb-link {
  list-style: none;
  margin: 0;
  padding: 0;
}
.fb-link li {
  display: inline-block;
}
.fb-link li a {
  color: #fff;
  position: relative;
}
.fb-link li a:after {
  content: "";
  background: var(--primary);
  width: 5px;
  height: 5px;
}

.footer-link li {
  display: inline-block;
  position: relative;
  padding: 0 25px 0 20px;
}
.footer-link li a {
  color: inherit;
}
.footer-link li:before {
  content: "";
  height: 8px;
  width: 8px;
  background-color: var(--primary);
  position: absolute;
  top: 50%;
  left: 0;
  border-radius: 8px;
  transform: translateY(-50%);
}

.svg-shape-1 {
  position: absolute;
  top: -67%;
  left: -15%;
}
@media only screen and (max-width: 1480px) {
  .svg-shape-1 {
    top: -45%;
    height: 80%;
    left: -20%;
  }
}
@media only screen and (max-width: 1199px) {
  .svg-shape-1 {
    top: -45%;
    height: 73%;
    left: -30%;
  }
}
@media only screen and (max-width: 767px) {
  .svg-shape-1 {
    top: -5%;
    height: auto;
    width: 60%;
    left: -20%;
  }
}
@media only screen and (max-width: 575px) {
  .svg-shape-1 {
    top: -5%;
    height: auto;
    width: 92%;
    left: -40%;
  }
}

.svg-shape-2 {
  position: absolute;
  bottom: -70%;
  right: -20%;
}
@media only screen and (max-width: 1480px) {
  .svg-shape-2 {
    bottom: -50%;
    right: -22%;
    height: 80%;
  }
}
@media only screen and (max-width: 1199px) {
  .svg-shape-2 {
    bottom: -30%;
    right: -20%;
    height: 50%;
  }
}
@media only screen and (max-width: 991px) {
  .svg-shape-2 {
    bottom: -30%;
    right: -30%;
    height: 40%;
  }
}
@media only screen and (max-width: 767px) {
  .svg-shape-2 {
    bottom: -20%;
    right: -55%;
    height: 40%;
    width: 100%;
  }
}

.site-footer.style-1 {
  color: #444444;
  background-position: center;
  overflow: hidden;
  background-color: #fff;
  background-size: cover;
}
.site-footer.style-1 .footer-title {
  display: inline-block;
  padding-bottom: 15px;
}
.site-footer.style-1 .footer-title:after,
.site-footer.style-1 .footer-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 5px;
  transform: skewX(-20deg);
  background: var(--primary);
}
.site-footer.style-1 .footer-title:after {
  left: 0;
  width: 55px;
}
.site-footer.style-1 .footer-title:before {
  left: 60px;
  width: 7px;
}
.site-footer.style-1 .footer-logo img {
  max-width: 240px;
}
.site-footer.style-1 .girl-img {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
@media only screen and (max-width: 1480px) {
  .site-footer.style-1 .girl-img {
    width: 250px;
  }
}
@media only screen and (max-width: 1280px) {
  .site-footer.style-1 .girl-img {
    width: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .site-footer.style-1 .girl-img {
    display: none;
  }
}
.site-footer.style-1.footer-action .footer-top {
  padding-top: 200px;
}
@media only screen and (max-width: 1280px) {
  .site-footer.style-1.footer-action .footer-top {
    padding-top: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .site-footer.style-1.footer-action .footer-top {
    padding-top: 160px;
  }
}
@media only screen and (min-width: 991px) {
  .site-footer.style-1 .widget-post-bx {
    padding-right: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .site-footer.style-1 .footer-bottom .copyright-text {
    font-size: 14px;
  }
}

.site-footer.bg-dark {
  color: rgba(255, 255, 255, 0.6);
}
.site-footer.bg-dark p {
  color: rgba(255, 255, 255, 0.6);
}
.site-footer.bg-dark h1,
.site-footer.bg-dark .h1,
.site-footer.bg-dark h2,
.site-footer.bg-dark .h2,
.site-footer.bg-dark h3,
.site-footer.bg-dark .h3,
.site-footer.bg-dark h4,
.site-footer.bg-dark .h4,
.site-footer.bg-dark .comments-title,
.site-footer.bg-dark .blog-title,
.site-footer.bg-dark .comment-reply-title,
.site-footer.bg-dark .wp-block-search .wp-block-search__label,
.wp-block-search .site-footer.bg-dark .wp-block-search__label,
.site-footer.bg-dark h5,
.site-footer.bg-dark .h5,
.site-footer.bg-dark h6,
.site-footer.bg-dark .h6,
.site-footer.bg-dark h1 a,
.site-footer.bg-dark .h1 a,
.site-footer.bg-dark h2 a,
.site-footer.bg-dark .h2 a,
.site-footer.bg-dark h3 a,
.site-footer.bg-dark .h3 a,
.site-footer.bg-dark h4 a,
.site-footer.bg-dark .h4 a,
.site-footer.bg-dark .comments-title a,
.site-footer.bg-dark .blog-title a,
.site-footer.bg-dark .comment-reply-title a,
.site-footer.bg-dark .wp-block-search .wp-block-search__label a,
.wp-block-search .site-footer.bg-dark .wp-block-search__label a,
.site-footer.bg-dark h5 a,
.site-footer.bg-dark .h5 a,
.site-footer.bg-dark h6 a,
.site-footer.bg-dark .h6 a {
  color: #fff;
}
.site-footer.bg-dark .post-date {
  color: var(--primary);
}
.site-footer.bg-dark .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.site-footer.bg-dark .recent-posts-entry .widget-post-bx .widget-post:not(:last-child) {
  border-image-source: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
}

#bg {
  background-attachment: fixed;
  background-size: cover;
}

.main-bar-wraper.sticky-no .main-bar {
  position: unset;
}

@media only screen and (min-width: 1280px) {
  .boxed .page-wraper {
    max-width: 1280px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  }
  .boxed .site-footer.style-1 .girl-img {
    width: 200px;
  }
  .boxed .about-bx3 .dz-media .image-box {
    height: 280px;
    width: 280px;
    top: -90px;
    left: -30px;
  }
  .boxed .main-bnr-two .banner-inner {
    min-height: 750px;
    padding-top: 0;
  }
  .boxed .main-bnr-two .banner-inner .data-text {
    top: 20%;
    font-size: 250px;
  }
  .boxed .main-bnr-two .banner-media img {
    max-width: 90%;
  }
  .boxed .main-bnr-three .banner-inner .banner-media img {
    width: 56%;
  }
  .boxed .main-bnr-three .banner-inner .banner-content .title {
    font-size: 120px;
  }
  .boxed .main-bnr-three .banner-inner .banner-content .title .left {
    left: 10%;
  }
  .boxed .main-bnr-three .banner-inner .banner-content .title .right {
    right: 0;
    bottom: 30%;
  }
  .boxed .main-bnr-three .banner-inner .data-text {
    font-size: 250px;
  }
  .boxed .site-header.style-1 .main-bar {
    width: 1280px;
    left: 50%;
    transform: translateX(-50%);
  }
  .boxed .section-wrapper-1 {
    margin: 0;
  }
  .boxed .testimonial-swiper-wrapper-3 .dz-swiper-next3 {
    right: 0;
  }
  .boxed .testimonial-swiper-wrapper-3 .dz-swiper-prev3 {
    left: 0;
  }
  .frame {
    padding: 30px;
  }
  .frame .page-wraper {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
    height: auto !important;
  }
  .frame .is-fixed .main-bar {
    left: 0;
    width: 100%;
    margin: 0;
    position: unset;
  }
  .frame button.scroltop {
    right: 50px;
    bottom: 50px;
  }
}

@media only screen and (max-width: 1280px) {
  .frame {
    padding: 0 !important;
  }
  .frame button.scroltop {
    right: 15px;
    bottom: 15px;
  }
}

.theme-sharped {
  --border-radius-base: 0;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.theme-rounded {
  --border-radius-base: 10px;
  -webkit-transition: none;
  -ms-transition: none;
  transition: none;
}

.theme-bg-color {
  background-color: var(--theme-bg-color);
}

[data-theme-color="color_1"] {
  /* Primary */
  --primary: #ff4848;
  --primary-hover: #ff4848;
  --primary-dark: #ed5600;
  /* Primary RGBA */
  --rgba-secondary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-2: rgba(255, 72, 72, 0.2);
  --rgba-primary-3: rgba(255, 72, 72, 0.3);
  --rgba-primary-4: rgba(255, 72, 72, 0.4);
  --rgba-primary-5: rgba(255, 72, 72, 0.5);
  --rgba-primary-6: rgba(255, 72, 72, 0.6);
  --rgba-primary-7: rgba(255, 72, 72, 0.7);
  --rgba-primary-8: rgba(255, 72, 72, 0.8);
  --rgba-primary-9: rgba(255, 72, 72, 0.9);
  /* Other */
  --secondary: #232323;
  --theme-text-color: #fff;
  --title: #222222;
  /* Gradient */
  --gradient: linear-gradient(89deg, #ff4848 1.12%, #ff4848 44%);
  --gradient-1: linear-gradient(90deg, #ff4848 47.55%, #ff4848 100.15%);
  --gradient-2: linear-gradient(90deg, #ff8039 0%, #ff4848 100%);
  --gradient-3: linear-gradient(180deg, var(--primary) 0%, #ff0000 100%);
  /* Box Shadow */
  --primary-shadow: linear-gradient(90deg, #ff4848 47.55%, #ebc600 100.15%);
  /* extra variable */
  /* --theme2-bg-pattern1: url(../images/pattern/theme/bg-pattern1.svg); */
  --theme3-bg-color: #201916;
  --theme3-secondary: #301f18;
  --theme3-secondary2: #ffdd39;
}

[data-theme-color="color_2"] {
  /* Primary */
  --primary: #f7244f;
  --primary-hover: #ff4848;
  --primary-dark: #dd002d;
  /* Primary RGBA */
  --rgba-secondary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-2: rgba(255, 72, 72, 0.2);
  --rgba-primary-3: rgba(255, 72, 72, 0.3);
  --rgba-primary-4: rgba(255, 72, 72, 0.4);
  --rgba-primary-5: rgba(255, 72, 72, 0.5);
  --rgba-primary-6: rgba(255, 72, 72, 0.6);
  --rgba-primary-7: rgba(255, 72, 72, 0.7);
  --rgba-primary-8: rgba(255, 72, 72, 0.8);
  --rgba-primary-9: rgba(255, 72, 72, 0.9);
  /* Other */
  --secondary: #232323;
  --theme-text-color: #fff;
  --title: #000000;
  /* Gradient */
  --gradient: linear-gradient(89deg, #ff0034 1.12%, #f7244f 44%);
  --gradient-1: linear-gradient(90deg, #ff0034 47.55%, #f7244f 100.15%);
  --gradient-2: linear-gradient(90deg, #f7244f 0%, #eb0030 100%);
  --gradient-3: linear-gradient(180deg, var(--primary) 0%, #bd0026 100%);
  /* Box Shadow */
  --primary-shadow: linear-gradient(90deg, #ff4848 47.55%, #ebc600 100.15%);
  /* extra variable */
  --theme3-bg-color: #420b17;
  --theme3-secondary: #160105;
  --theme3-secondary2: #f7c024;
}

[data-theme-color="color_3"] {
  /* Primary */
  --primary: #06c6d1;
  --primary-hover: #ff4848;
  --primary-dark: #009ea7;
  /* Primary RGBA */
  --rgba-primary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-2: rgba(255, 72, 72, 0.2);
  --rgba-primary-3: rgba(255, 72, 72, 0.3);
  --rgba-primary-4: rgba(255, 72, 72, 0.4);
  --rgba-primary-5: rgba(255, 72, 72, 0.5);
  --rgba-primary-6: rgba(255, 72, 72, 0.6);
  --rgba-primary-7: rgba(255, 72, 72, 0.7);
  --rgba-primary-8: rgba(255, 72, 72, 0.8);
  --rgba-primary-9: rgba(255, 72, 72, 0.9);
  /* Secondary RGBA */
  --rgba-secondary-1: rgba(255, 72, 72, 0.1);
  --rgba-secondary-2: rgba(255, 72, 72, 0.2);
  --rgba-secondary-3: rgba(255, 72, 72, 0.3);
  --rgba-secondary-4: rgba(255, 72, 72, 0.4);
  --rgba-secondary-5: rgba(255, 72, 72, 0.5);
  --rgba-secondary-6: rgba(255, 72, 72, 0.6);
  --rgba-secondary-7: rgba(255, 72, 72, 0.7);
  --rgba-secondary-8: rgba(255, 72, 72, 0.8);
  --rgba-secondary-9: rgba(255, 72, 72, 0.9);
  /* Other */
  --purple: #9a69f9;
  --blue: #281e8b;
  --dark: #281e8b;
  --title: #000000;
  --secondary: #232323;
  --theme-text-color: #fff;
  /* Gradient */
  --gradient: linear-gradient(89deg, #13b0b9 1.12%, #06c6d1 44%);
  --gradient-1: linear-gradient(90deg, #00b1bb 47.55%, #06c6d1 100.15%);
  --gradient-2: linear-gradient(90deg, #00b1bb 0%, #06c6d1 100%);
  --gradient-3: linear-gradient(180deg, var(--primary) 0%, #20a5ad 100%);
  /* Box Shadow */
  --primary-shadow: linear-gradient(90deg, #ff4848 47.55%, #ebc600 100.15%);
  --theme3-bg-color: #281e8b;
  --theme3-secondary: #362aab;
  --theme3-secondary2: #9a69f9;
}

[data-theme-color="color_4"] {
  /* Primary */
  --primary: #b8c724;
  --primary-hover: #ff4848;
  --primary-dark: #a7b70e;
  /* Primary RGBA */
  --rgba-primary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-2: rgba(255, 72, 72, 0.2);
  --rgba-primary-3: rgba(255, 72, 72, 0.3);
  --rgba-primary-4: rgba(255, 72, 72, 0.4);
  --rgba-primary-5: rgba(255, 72, 72, 0.5);
  --rgba-primary-6: rgba(255, 72, 72, 0.6);
  --rgba-primary-7: rgba(255, 72, 72, 0.7);
  --rgba-primary-8: rgba(255, 72, 72, 0.8);
  --rgba-primary-9: rgba(255, 72, 72, 0.9);
  /* Other */
  --secondary: #232323;
  --theme-text-color: #fff;
  --title: #000000;
  /* Gradient */
  --gradient: linear-gradient(89deg, #a8b900 1.12%, #b8c724 44%);
  --gradient-1: linear-gradient(90deg, #b4c509 47.55%, #b8c724 100.15%);
  --gradient-2: linear-gradient(90deg, #b8c724 0%, #abbb14 100%);
  --gradient-3: linear-gradient(180deg, var(--primary) 0%, #839100 100%);
  /* Box Shadow */
  --primary-shadow: linear-gradient(90deg, #ff4848 47.55%, #ebc600 100.15%);
  /* extra variable */
  --theme3-bg-color: #171809;
  --theme3-secondary: #0f1006;
  --theme3-secondary2: #df7056;
}

[data-theme-color="color_5"] {
  /* Primary */
  --primary: #ff4848;
  --primary-hover: #ff4848;
  --primary-dark: #ebc600;
  /* Primary RGBA */
  --rgba-secondary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-2: rgba(255, 72, 72, 0.2);
  --rgba-primary-3: rgba(255, 72, 72, 0.3);
  --rgba-primary-4: rgba(255, 72, 72, 0.4);
  --rgba-primary-05: rgba(255, 72, 72, 0.5);
  --rgba-primary-6: rgba(255, 72, 72, 0.6);
  --rgba-primary-7: rgba(255, 72, 72, 0.7);
  --rgba-primary-8: rgba(255, 72, 72, 0.8);
  --rgba-primary-9: rgba(255, 72, 72, 0.9);
  /* Other */
  --secondary: #232323;
  --theme-text-color: #232323;
  --title: #000000;
  /* Gradient */
  --gradient: linear-gradient(89deg, #f5d002 1.12%, #ff4848 44%);
  --gradient-1: linear-gradient(90deg, #ebc600 47.55%, #ff4848 100.15%);
  --gradient-2: linear-gradient(90deg, #ebc600 0%, #ff4848 100%);
  --gradient-3: linear-gradient(180deg, var(--primary) 0%, #ab9000 100%);
  /* Box Shadow */
  --primary-shadow: linear-gradient(90deg, #ff4848 47.55%, #ebc600 100.15%);
  /* extra variable */
  --theme3-bg-color: #1e1c11;
  --theme3-secondary: #34311d;
  --theme3-secondary2: #ff9780;
}

[data-theme-color="color_6"] {
  /* Primary */
  --primary: #b65fbd;
  --primary-hover: #ff4848;
  --primary-dark: #ab4ab3;
  /* Primary RGBA */
  --rgba-secondary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-2: rgba(255, 72, 72, 0.2);
  --rgba-primary-3: rgba(255, 72, 72, 0.3);
  --rgba-primary-4: rgba(255, 72, 72, 0.4);
  --rgba-primary-05: rgba(255, 72, 72, 0.5);
  --rgba-primary-6: rgba(255, 72, 72, 0.6);
  --rgba-primary-7: rgba(255, 72, 72, 0.7);
  --rgba-primary-8: rgba(255, 72, 72, 0.8);
  --rgba-primary-9: rgba(255, 72, 72, 0.9);
  /* Other */
  --secondary: #232323;
  --theme-text-color: #fff;
  --title: #000000;
  /* Gradient */
  --gradient: linear-gradient(89deg, #b751bf 1.12%, #b65fbd 44%);
  --gradient-1: linear-gradient(90deg, #98489f 47.55%, #b65fbd 100.15%);
  --gradient-2: linear-gradient(90deg, #b65fbd 0%, #a44bab 100%);
  --gradient-3: linear-gradient(180deg, var(--primary) 0%, #812789 100%);
  /* Box Shadow */
  --primary-shadow: linear-gradient(90deg, #ff4848 47.55%, #ebc600 100.15%);
  /* extra variable */
  --theme3-bg-color: #350838;
  --theme3-secondary: #19031a;
  --theme3-secondary2: #5fbd92;
}

[data-theme-color="color_7"] {
  /* Primary */
  --primary: #977fff;
  --primary-hover: #ff4848;
  --primary-dark: #826aed;
  /* Primary RGBA */
  --rgba-secondary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-1: rgba(255, 72, 72, 0.1);
  --rgba-primary-2: rgba(255, 72, 72, 0.2);
  --rgba-primary-3: rgba(255, 72, 72, 0.3);
  --rgba-primary-4: rgba(255, 72, 72, 0.4);
  --rgba-primary-5: rgba(255, 72, 72, 0.5);
  --rgba-primary-6: rgba(255, 72, 72, 0.6);
  --rgba-primary-7: rgba(255, 72, 72, 0.7);
  --rgba-primary-8: rgba(255, 72, 72, 0.8);
  --rgba-primary-9: rgba(255, 72, 72, 0.9);
  /* Other */
  --secondary: #232323;
  --theme-text-color: #fff;
  --title: #232323;
  /* Gradient */
  --gradient: linear-gradient(89deg, #7961e5 1.12%, #977fff 44%);
  --gradient-1: linear-gradient(90deg, #806bd9 47.55%, #977fff 100.15%);
  --gradient-2: linear-gradient(90deg, #977fff 0%, #745ed7 100%);
  --gradient-3: linear-gradient(180deg, var(--primary) 0%, #644dc9 100%);
  /* Box Shadow */
  --primary-shadow: linear-gradient(90deg, #ff4848 47.55%, #ebc600 100.15%);
  /* extra variable */
  --theme3-bg-color: #241954;
  --theme3-secondary: #0f0636;
  --theme3-secondary2: #7ff3ff;
}

/*=== Components ===*/
.card {
  border: 0px solid #e1e1f0;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0px 36px 48px rgba(31, 66, 135, 0.04);
  background: #fff;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1f0;
  background: #fff;
  padding: 20px 30px;
}
.card-title {
  font-size: 24px;
  margin-bottom: 0px;
}
.card .card-body {
  padding: 20px 30px;
  background: #fff;
  border-radius: 15px;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  margin-bottom: 10px;
  background: transparent;
  border-color: #e1e1f0;
}

.form-control,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select {
  height: 50px;
  border: 1px solid gray;
  padding: 10px 20px;
  font-size: 15px;
  font-weight: 400;
  color: #666666;
  transition: all 0.3s ease-in-out;
  background: #fff;
  border-radius: var(--border-radius-base);
}
.form-control .select,
.wp-block-categories-dropdown select .select,
.wp-block-archives-dropdown select .select {
  padding: 12px 0;
}
.form-control span,
.wp-block-categories-dropdown select span,
.wp-block-archives-dropdown select span {
  margin-top: 0;
}
.form-control::-webkit-input-placeholder,
.wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown select::-webkit-input-placeholder {
  color: #666666;
}
.form-control:-ms-input-placeholder,
.wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown select:-ms-input-placeholder {
  color: #666666;
}
.form-control::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder {
  color: #666666;
}
.form-control:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus,
.form-control:active,
.wp-block-categories-dropdown select:active,
.wp-block-archives-dropdown select:active,
.form-control.active,
.wp-block-categories-dropdown select.active,
.wp-block-archives-dropdown select.active {
  border-color: var(--primary);
}
@media only screen and (max-width: 991px) {
  .form-control,
  .wp-block-categories-dropdown select,
  .wp-block-archives-dropdown select {
    height: 50px;
  }
}

.form-group {
  margin-bottom: 20px;
}

.input-skew {
  display: block;
  transform: skewX(-12deg);
  flex: 1 1 auto;
  width: 1%;
  background-color: #fff;
  padding: 0 10px;
  margin: 0 10px;
}
.input-skew input {
  transform: skewX(12deg);
  border: 0;
  border-radius: 0;
  height: 50px;
}

.input-area {
  position: relative;
}
.input-area label {
  font-size: 18px;
  font-weight: 400;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 767px) {
  .input-area label {
    font-size: 16px;
  }
}

.input-line .form-control,
.input-line .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .input-line select,
.input-line .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .input-line select {
  padding: 0;
  height: 50px;
  line-height: 1.2;
  font-size: 16px;
  border: none;
  border-radius: 0;
  background: transparent;
  border-bottom: 2px solid #d9d9d9;
}
.input-line .form-control::placeholder,
.input-line .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .input-line select::placeholder,
.input-line .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .input-line select::placeholder {
  color: #666666 !important;
}

.input-line .default-select .btn.dropdown-toggle,
.input-line .default-select .dropdown-toggle.wp-block-button__link {
  border-bottom: 2px solid #d9d9d9;
}

textarea.form-control {
  min-height: unset;
  height: auto;
}

textarea {
  height: 120px;
  resize: none;
}

input:-internal-autofill-selected {
  background: white !important;
  background-image: none !important;
  color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
  padding: 5px 15px;
  background: #ffffff;
  margin-bottom: 0px !important;
  color: #666666;
  border-color: transparent;
}

.toggle {
  cursor: pointer;
  display: block;
}

.toggle-switch {
  display: inline-block;
  background: #f7f7f7;
  border-radius: 16px;
  width: 45px;
  height: 20px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}
.toggle-switch:before,
.toggle-switch:after {
  content: "";
}
.toggle-switch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: absolute;
  top: 1px;
  left: 3px;
  transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
}
.toggle-checkbox:checked + .toggle-switch {
  background: var(--primary);
}
.toggle-checkbox:checked + .toggle-switch:before {
  left: 26px;
}

.toggle-checkbox {
  position: absolute;
  visibility: hidden;
}

.toggle-label {
  margin-left: 15px;
  position: relative;
  top: 2px;
  font-size: 16px;
  font-weight: 400;
}

.file-upload-wrapper {
  position: relative;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  border: 1px solid #e1e1f0;
  border-radius: 5px;
  color: #aeaed5;
}
.file-upload-wrapper:before,
.file-upload-wrapper:after {
  position: absolute;
  top: 0;
  font-weight: 400;
  pointer-events: none;
}
.file-upload-wrapper:after {
  content: attr(data-text);
  left: 0;
  background: #ffffff;
  padding: 4px 15px;
  display: block;
  width: calc(100% - 40px);
  z-index: 20;
  height: calc(1.5em + 1rem + 2px);
  line-height: 30px;
  border-radius: 5px 10px 10px 5px;
  overflow: hidden;
}
.file-upload-wrapper:before {
  content: "Upload";
  right: 0;
  display: inline-block;
  height: calc(1.5em + 1rem + 2px);
  background: var(--primary);
  color: #fff;
  z-index: 25;
  font-size: 14px;
  line-height: 40px;
  padding: 0 15px;
  text-transform: capitalize;
  border-radius: 0 5px 5px 0;
}
.file-upload-wrapper:hover:before {
  background: #ff6106;
}
.file-upload-wrapper input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  padding: 0;
  display: block;
  cursor: pointer;
  width: 100%;
}

#ui-datepicker-div {
  display: none;
  background: #fafafa;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-top: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  color: #666666;
}

.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
.ui-datepicker-calendar tbody td a {
  display: block;
  border-radius: 0.25rem;
  line-height: 2rem;
  transition: 0.3s all;
  color: #666666;
  font-size: 0.875rem;
  text-decoration: none;
}
.ui-datepicker-calendar tbody td a:hover {
  background-color: var(--primary);
  color: white;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
  background-color: var(--primary);
  color: white;
}

.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  border-radius: 0.25rem;
  transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
  background-color: #ffe2d2;
  color: #fff;
}

.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 0.5rem;
  background-position: 50%;
  transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 50%;
}

.ui-datepicker-header a > span {
  display: none;
}

.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
  color: #666666;
  font-weight: 400;
  font-size: 0.75rem;
}

label.error {
  color: #ff0003;
  position: absolute;
  bottom: 0;
  margin-bottom: -22px;
  font-size: 12px;
  font-weight: 400;
}

.nice-select {
  display: flex;
  align-items: center;
  border-top-left-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.nice-select ul {
  width: 100% !important;
}

.nice-select .list {
  background: #222222;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #222222;
  color: var(--primary);
}

.default-select {
  width: 100% !important;
}
.default-select .btn.dropdown-toggle,
.default-select .dropdown-toggle.wp-block-button__link {
  border-radius: 0;
  height: 48px;
  line-height: 48px;
  background-color: transparent;
  box-shadow: unset;
  border: none;
  outline: 0 !important;
  padding: 0;
  color: #666666;
  font-weight: 400;
}
.default-select .dropdown-menu {
  border-radius: 0;
  background-color: #fff;
  border: 0;
  padding: 0;
  box-shadow: 0px 14px 30px 0px rgba(225, 50, 0, 0.1);
}
.default-select .dropdown-menu.inner {
  padding: 10px 0;
}
.default-select .dropdown-menu span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.default-select .dropdown-menu .dropdown-item {
  padding: 6px 18px;
}
.default-select .dropdown-menu .dropdown-item.active.selected {
  background-color: var(--primary);
  color: #fff !important;
}
.default-select.style-2 {
  left: auto;
}
.default-select.style-2 select {
  display: none !important;
}
.default-select.style-2 .dropdown-toggle:after {
  content: none;
}
.default-select.style-2 .dropdown-toggle {
  border-radius: 0;
  height: 43px;
  color: #fff !important;
  border: none !important;
  outline: none !important;
  background-color: transparent;
  padding: 0;
  line-height: 2;
  padding-bottom: 0px;
  border-bottom: 2px solid #000 !important;
}
.default-select.style-2 .dropdown-toggle .filter-option {
  text-align: center;
  height: auto;
  color: var(--primary);
}
@media only screen and (max-width: 575px) {
  .default-select.style-2 .dropdown-toggle {
    height: 32px;
  }
}
.default-select.style-2 .dropdown-menu {
  border-radius: 0;
  background-color: #fff;
  border: 0;
  padding: 0;
  box-shadow: 0px 14px 30px 0px rgba(225, 50, 0, 0.1);
}
.default-select.style-2 .dropdown-menu span {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.default-select.style-2 .dropdown-menu .dropdown-item {
  padding: 0;
  padding-left: 15px;
}
.default-select.style-2 .dropdown-menu .dropdown-item.active.selected {
  background-color: var(--primary);
  color: #fff !important;
}

.form-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn),
select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100%;
}

.form-select,
select {
  border: 1px solid transparent;
}
.form-select .dropdown-toggle,
select .dropdown-toggle {
  border: 0 !important;
  padding: 0;
  font-weight: normal;
  text-transform: unset;
  outline: none;
}
.form-select .dropdown-toggle:focus,
select .dropdown-toggle:focus {
  outline: 0 !important;
  outline-offset: 0;
}
.form-select .dropdown-toggle:after,
select .dropdown-toggle:after {
  content: none;
}
.form-select.show,
select.show {
  box-shadow: none !important;
  outline: 0px !important;
}
.form-select.show .dropdown-toggle,
select.show .dropdown-toggle {
  box-shadow: none !important;
  outline: 0px !important;
}
.form-select.show.btn-group .dropdown-menu.inner,
select.show.btn-group .dropdown-menu.inner {
  display: block;
}
.form-select .dropdown-menu,
select .dropdown-menu {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  margin-top: -1px;
  padding: 10px 0;
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
}
.form-select .dropdown-menu ul li,
select .dropdown-menu ul li {
  padding: 0;
}
.form-select .dropdown-menu ul li a,
select .dropdown-menu ul li a {
  display: block;
  padding: 8px 20px;
  clear: both;
  font-weight: 400;
  color: #333;
  white-space: nowrap;
}
.form-select .dropdown-menu ul li a img,
select .dropdown-menu ul li a img {
  border-radius: 100%;
  -webkit-border-radius: 100%;
  margin-right: 5px;
}
.form-select .dropdown-menu ul li a:hover,
select .dropdown-menu ul li a:hover {
  background-color: #f2f2f4;
}
.form-select .dropdown-menu ul li.selected a,
select .dropdown-menu ul li.selected a {
  background-color: var(--primary);
  color: #fff;
}
.form-select:focus,
select:focus,
.form-select:active,
select:active,
.form-select.active,
select.active {
  border-color: var(--primary);
}

.form-select.btn-group,
select.btn-group {
  padding: 10px 20px;
  border-width: 1px;
}
.form-select.btn-group.show,
select.btn-group.show {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1) !important;
  border-color: var(--primary);
}
.form-select.btn-group .form-control .dropdown-toggle.btn-default,
select.btn-group .form-control .dropdown-toggle.btn-default,
.form-select.btn-group .wp-block-categories-dropdown select .dropdown-toggle.btn-default,
.wp-block-categories-dropdown .form-select.btn-group select .dropdown-toggle.btn-default,
select.btn-group .wp-block-categories-dropdown select .dropdown-toggle.btn-default,
.wp-block-categories-dropdown select.btn-group select .dropdown-toggle.btn-default,
.form-select.btn-group .wp-block-archives-dropdown select .dropdown-toggle.btn-default,
.wp-block-archives-dropdown .form-select.btn-group select .dropdown-toggle.btn-default,
select.btn-group .wp-block-archives-dropdown select .dropdown-toggle.btn-default,
.wp-block-archives-dropdown select.btn-group select .dropdown-toggle.btn-default {
  padding: 0;
}
.form-select.btn-group .dropdown-toggle .caret,
select.btn-group .dropdown-toggle .caret {
  font-size: 10px;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.form-select.btn-group .dropdown-toggle .caret:before,
select.btn-group .dropdown-toggle .caret:before {
  content: "\f078";
}

.custom-file-label {
  box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
  height: 45px;
  border-radius: 30px !important;
  padding: 10px 20px;
  font-size: 15px;
  border-color: transparent;
}
.custom-file-label:after {
  content: none !important;
}

.dz-form.radius-no .form-control,
.dz-form.radius-no .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dz-form.radius-no select,
.dz-form.radius-no .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dz-form.radius-no select {
  border-radius: 0;
}

.dz-form.style-1 {
  padding-left: 20px;
}
.dz-form.style-1 .input-line .form-control,
.dz-form.style-1 .input-line .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dz-form.style-1 .input-line select,
.dz-form.style-1 .input-line .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dz-form.style-1 .input-line select {
  border-bottom: 3px solid #d9d9d9;
}
@media only screen and (max-width: 1480px) {
  .dz-form.style-1 .input-line .form-control,
  .dz-form.style-1 .input-line .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .dz-form.style-1 .input-line select,
  .dz-form.style-1 .input-line .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .dz-form.style-1 .input-line select {
    border-bottom: 2px solid #d9d9d9;
  }
}
.dz-form.style-1 .input-area label {
  top: 5px;
}
.dz-form.style-1 .input-area.focused label {
  top: -15px;
  color: var(--primary);
}
.dz-form.style-1 select {
  border: none;
  border-bottom: 3px solid #d9d9d9;
  left: 0;
}
.dz-form.style-1 .form-control,
.dz-form.style-1 .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dz-form.style-1 select,
.dz-form.style-1 .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dz-form.style-1 select {
  font-weight: 500;
  color: #000;
  font-size: 16px;
}
.dz-form.style-1 textarea {
  height: 160px;
  padding-top: 15px;
}
.dz-form.style-1 .form-control::-webkit-input-placeholder,
.dz-form.style-1 .wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-categories-dropdown .dz-form.style-1 select::-webkit-input-placeholder,
.dz-form.style-1 .wp-block-archives-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown .dz-form.style-1 select::-webkit-input-placeholder {
  color: #000;
  opacity: 0.7;
}
.dz-form.style-1 .form-control:-ms-input-placeholder,
.dz-form.style-1 .wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-categories-dropdown .dz-form.style-1 select:-ms-input-placeholder,
.dz-form.style-1 .wp-block-archives-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown .dz-form.style-1 select:-ms-input-placeholder {
  color: #000;
  opacity: 0.7;
}
.dz-form.style-1 .form-control::-placeholder,
.dz-form.style-1 .wp-block-categories-dropdown select::-placeholder,
.wp-block-categories-dropdown .dz-form.style-1 select::-placeholder,
.dz-form.style-1 .wp-block-archives-dropdown select::-placeholder,
.wp-block-archives-dropdown .dz-form.style-1 select::-placeholder {
  color: #000;
  opacity: 0.7;
}
.dz-form.style-1 .form-control:-moz-placeholder,
.dz-form.style-1 .wp-block-categories-dropdown select:-moz-placeholder,
.wp-block-categories-dropdown .dz-form.style-1 select:-moz-placeholder,
.dz-form.style-1 .wp-block-archives-dropdown select:-moz-placeholder,
.wp-block-archives-dropdown .dz-form.style-1 select:-moz-placeholder {
  color: #000;
  opacity: 0.7;
}
.dz-form.style-1 .input-group {
  margin-bottom: 60px;
}
.dz-form.style-1 .input-group.m-b30 {
  margin-bottom: 30px;
}
@media only screen and (max-width: 1480px) {
  .dz-form.style-1 .input-group {
    margin-bottom: 45px;
  }
}
.dz-form.style-1 .input-recaptcha {
  margin-bottom: 30px;
}
@media only screen and (max-width: 1199px) {
  .dz-form.style-1 input {
    font-size: 18px;
  }
  .dz-form.style-1 textarea {
    height: 120px;
  }
  .dz-form.style-1 textarea::placeholder,
  .dz-form.style-1 input::placeholder {
    font-size: 16px;
  }
  .dz-form.style-1 .input-group {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-form.style-1 input {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .dz-form.style-1 textarea {
    height: 100px;
  }
  .dz-form.style-1 .input-group {
    margin-bottom: 30px;
  }
  .dz-form.style-1 .input-recaptcha {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 767px) {
  .dz-form.style-1 {
    padding: 0;
  }
}

.dz-form.style-2 {
  padding: 50px;
  z-index: 9;
  margin-bottom: -80px;
}
.dz-form.style-2 label {
  color: var(--theme-text-color);
}
.dz-form.style-2 .input-area {
  position: relative;
}
.dz-form.style-2 .input-area label {
  font-size: 14px;
  font-weight: 500;
  color: var(--theme-text-color);
  z-index: -1;
}
.dz-form.style-2 textarea {
  height: 100px !important;
  padding: 10px 0;
}
.dz-form.style-2 .form-check {
  align-items: center;
  display: flex;
  height: auto;
  min-height: auto;
}
.dz-form.style-2 .form-check .form-check-input {
  height: 24px;
  width: 24px;
  margin-top: 0;
  border-radius: 0;
  background-color: transparent;
  border: 2px solid var(--theme-text-color);
}
.dz-form.style-2 .form-check .form-check-input:checked {
  background-color: transparent;
}
.dz-form.style-2 .btn-light:hover {
  background-color: #222222;
}
.dz-form.style-2 .form-control,
.dz-form.style-2 .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dz-form.style-2 select,
.dz-form.style-2 .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dz-form.style-2 select {
  color: var(--theme-text-color);
  border-bottom: 2px solid var(--theme-text-color);
  font-size: 16px;
}
.dz-form.style-2 .form-control::-webkit-input-placeholder,
.dz-form.style-2 .wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-categories-dropdown .dz-form.style-2 select::-webkit-input-placeholder,
.dz-form.style-2 .wp-block-archives-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown .dz-form.style-2 select::-webkit-input-placeholder {
  color: var(--theme-text-color);
  opacity: 0.7;
}
.dz-form.style-2 .form-control:-ms-input-placeholder,
.dz-form.style-2 .wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-categories-dropdown .dz-form.style-2 select:-ms-input-placeholder,
.dz-form.style-2 .wp-block-archives-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown .dz-form.style-2 select:-ms-input-placeholder {
  color: var(--theme-text-color);
  opacity: 0.7;
}
.dz-form.style-2 .form-control::-placeholder,
.dz-form.style-2 .wp-block-categories-dropdown select::-placeholder,
.wp-block-categories-dropdown .dz-form.style-2 select::-placeholder,
.dz-form.style-2 .wp-block-archives-dropdown select::-placeholder,
.wp-block-archives-dropdown .dz-form.style-2 select::-placeholder {
  color: var(--theme-text-color);
  opacity: 0.7;
}
.dz-form.style-2 .form-control:-moz-placeholder,
.dz-form.style-2 .wp-block-categories-dropdown select:-moz-placeholder,
.wp-block-categories-dropdown .dz-form.style-2 select:-moz-placeholder,
.dz-form.style-2 .wp-block-archives-dropdown select:-moz-placeholder,
.wp-block-archives-dropdown .dz-form.style-2 select:-moz-placeholder {
  color: var(--theme-text-color);
  opacity: 0.7;
}
.dz-form.style-2 .input-group {
  margin-bottom: 30px;
  z-index: 1;
}
.dz-form.style-2 .form-head .title {
  color: var(--theme-text-color);
}
.dz-form.style-2 .form-head p {
  color: var(--theme-text-color);
  opacity: 0.6;
}
@media only screen and (max-width: 1280px) {
  .dz-form.style-2 {
    padding: 45px 35px;
  }
}
@media only screen and (max-width: 1199px) {
  .dz-form.style-2 input {
    font-size: 18px;
  }
  .dz-form.style-2 textarea {
    height: 120px;
  }
  .dz-form.style-2 .input-group {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-form.style-2 input {
    font-size: 16px;
    padding-bottom: 5px;
  }
  .dz-form.style-2 textarea {
    height: 100px;
  }
  .dz-form.style-2 .input-group {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-form.style-2 {
    padding: 30px 25px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-form.style-2 .input-group textarea,
  .dz-form.style-2 .input-group select,
  .dz-form.style-2 .input-group input {
    line-height: 1;
    padding-bottom: 10px;
    font-size: 14px;
  }
  .dz-form.style-2 .input-group label {
    position: absolute;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary);
    top: 4px;
    left: 0px;
    z-index: -1;
    transition: all 0.5s;
  }
  .dz-form.style-2 .input-group textarea {
    padding-top: 10px;
  }
}

.input-group {
  margin-bottom: 20px;
}

.dz-ajax-overlay {
  position: relative;
}
.dz-ajax-overlay:after {
  content: "Loading...";
  height: 100%;
  width: 100%;
  background-color: #000;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 99;
  border-radius: 0;
  opacity: 0.8;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 14px;
  font-style: italic;
  transform: skewX(-12deg);
}

.calculator-form .input-group {
  margin-bottom: 30px !important;
}

.calculator-form .dzFormInner {
  background-color: var(--secondary);
  padding: 20px 30px;
  border-radius: var(--border-radius-base);
}

button:hover,
button:active,
button:focus {
  outline: 0;
}

.btn,
.wp-block-button__link {
  padding: 10.5px 25px;
  border-radius: var(--border-radius-base);
  font-size: 16px;
  font-weight: 500;
  position: relative;
  line-height: 1.5;
  align-items: center;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
@media only screen and (max-width: 1199px) {
  .btn,
  .wp-block-button__link {
    padding: 8px 20px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .btn,
  .wp-block-button__link {
    font-size: 14px;
    padding: 7px 16px;
  }
}
.btn.btn-sharp,
.btn-sharp.wp-block-button__link {
  border-radius: 0;
}
.btn.btn-lg,
.btn-group-lg > .btn,
.btn-group-lg > .wp-block-button__link,
.btn-lg.wp-block-button__link {
  padding: 13px 29px;
  line-height: 1.6;
  font-size: 18px;
}
@media only screen and (max-width: 1480px) {
  .btn.btn-lg,
  .btn-group-lg > .btn,
  .btn-group-lg > .wp-block-button__link,
  .btn-lg.wp-block-button__link {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .btn.btn-lg,
  .btn-group-lg > .btn,
  .btn-group-lg > .wp-block-button__link,
  .btn-lg.wp-block-button__link {
    padding: 12px 24px;
  }
}
@media only screen and (max-width: 767px) {
  .btn.btn-lg,
  .btn-group-lg > .btn,
  .btn-group-lg > .wp-block-button__link,
  .btn-lg.wp-block-button__link {
    padding: 10px 20px;
    font-size: 14px;
  }
}
.btn.btn-primary,
.wp-block-button__link {
  color: var(--theme-text-color);
}
.btn.btn-primary:hover,
.wp-block-button__link:hover {
  color: var(--theme-text-color);
}
.btn i,
.wp-block-button__link i {
  font-size: 18px;
  line-height: 1;
}
.btn.btn-skew,
.btn-skew.wp-block-button__link {
  border-radius: 0;
  border: 0;
  margin-left: 5px;
  margin-right: 12px;
  transform: skewX(-12deg);
}
.btn.btn-skew span,
.btn-skew.wp-block-button__link span {
  padding: 0;
  transform: skewX(12deg);
  display: inline-block;
}
.btn.btn-skew:after,
.btn-skew.wp-block-button__link:after {
  content: "";
  position: absolute;
  background-color: inherit;
  height: 100%;
  width: 7px;
  right: -12px;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.btn.btn-skew:before,
.btn-skew.wp-block-button__link:before {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 0%;
  top: 0;
  left: 0px;
  transition-timing-function: ease-in-out;
  z-index: -1;
}
.btn.btn-skew:hover:before,
.btn-skew.wp-block-button__link:hover:before {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-right: 7px solid #fff;
  opacity: 0;
  width: 110%;
}
.btn.btn-skew.btn-sm,
.btn-group-sm > .btn.btn-skew,
.btn-group-sm > .btn-skew.wp-block-button__link,
.btn-skew.btn-sm.wp-block-button__link {
  font-size: 14px;
  padding: 8px 20px;
}
.btn.btn-skew.btn-sm:after,
.btn-group-sm > .btn.btn-skew:after,
.btn-group-sm > .btn-skew.wp-block-button__link:after,
.btn-skew.btn-sm.wp-block-button__link:after {
  width: 5px;
  right: -9px;
}
.btn.btn-skew-2,
.btn-skew-2.wp-block-button__link {
  border-radius: 0;
  border: 0;
  z-index: 1;
  overflow: hidden;
  background-color: transparent;
}
.btn.btn-skew-2:after,
.btn-skew-2.wp-block-button__link:after {
  content: "";
  position: absolute;
  background-color: var(--theme3-secondary2);
  height: 100%;
  width: 100%;
  left: -75%;
  z-index: -1;
  top: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: skewX(-30deg);
}
.btn.btn-skew-2:before,
.btn-skew-2.wp-block-button__link:before {
  content: "";
  position: absolute;
  background-color: var(--primary);
  height: 100%;
  width: 100%;
  right: -30%;
  z-index: -1;
  top: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: skewX(-30deg);
}
.btn.btn-skew-2:hover,
.btn-skew-2.wp-block-button__link:hover {
  background-color: transparent;
}
.btn.btn-skew-2:hover:after,
.btn-skew-2.wp-block-button__link:hover:after {
  left: -30%;
}
.btn.btn-skew-2:hover:before,
.btn-skew-2.wp-block-button__link:hover:before {
  right: -75%;
}
.btn.btn-skew-2:active,
.btn-skew-2.wp-block-button__link:active,
.btn.btn-skew-2:focus,
.btn-skew-2.wp-block-button__link:focus {
  background-color: transparent !important;
}

.shadow-primary {
  box-shadow: 0px 5px 30px 0px var(--rgba-primary-2);
}

.btn-info {
  color: #fff;
}
.btn-info:hover {
  color: #fff;
}

.btn-white {
  background-color: #fff;
  color: var(--primary);
}
.btn-white:hover {
  color: var(--primary);
}

.btn-primary,
.wp-block-button__link {
  border-color: var(--primary);
  background-color: var(--primary);
}
.btn-primary.disabled,
.disabled.wp-block-button__link,
.btn-primary:disabled,
.wp-block-button__link:disabled,
.btn-primary:not(:disabled):not(.disabled).active,
.wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-primary:active,
.wp-block-button__link:active,
.btn-primary:focus,
.wp-block-button__link:focus,
.btn-primary:hover,
.wp-block-button__link:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.btn-secondary {
  border-color: var(--secondary);
  background-color: var(--secondary);
}
.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.btn-link {
  color: var(--primary);
  text-decoration: none;
}
.btn-link:hover {
  color: var(--primary-hover);
}

.btn-outline-primary,
.is-style-outline .wp-block-button__link {
  color: var(--primary);
  border-color: var(--primary);
}
.btn-outline-primary.disabled,
.is-style-outline .disabled.wp-block-button__link,
.btn-outline-primary:disabled,
.is-style-outline .wp-block-button__link:disabled,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.is-style-outline .wp-block-button__link:not(:disabled):not(.disabled):active,
.btn-outline-primary:active,
.is-style-outline .wp-block-button__link:active,
.btn-outline-primary:focus,
.is-style-outline .wp-block-button__link:focus,
.btn-outline-primary:hover,
.is-style-outline .wp-block-button__link:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
  color: #fff;
}

.btn-link-lg {
  font-weight: 700;
  font-size: 18px;
}
.btn-link-lg:hover {
  text-decoration: unset;
}

.btn-light {
  background-color: #fff;
}
.btn-light:hover {
  background-color: var(--primary);
  color: #fff;
  border-color: transparent;
}
.btn-light.text-primary:hover {
  color: #fff;
}

.wp-block-button {
  margin-bottom: 0.3125rem;
}

.is-style-squared .wp-block-button__link {
  border-radius: 0;
}

.wp-block-button__link {
  border-radius: 60px;
}
.wp-block-button__link:after {
  content: none;
}

.table-responsive-sm {
  min-width: 48rem;
}

.table {
  margin-bottom: 0px;
  font-weight: 400;
}
.table tr td,
.table tr th {
  padding: 15px;
}

.table tr th {
  color: #222222;
  font-weight: 500;
}

.table-striped thead tr th {
  padding-bottom: 15px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.table-striped tfoot {
  border-top: 2px solid #e1e1f0;
}
.table-striped tfoot tr th {
  padding-top: 10px;
}

.dz-separator {
  display: inline-block;
  margin-bottom: 10px;
  margin-top: 8px;
  position: relative;
}
.dz-separator.m-b0 {
  margin-bottom: 0;
}
.dz-separator.style-liner {
  width: 20px;
}
.dz-separator.style-icon {
  width: 30px;
  height: auto;
  text-align: center;
  font-size: 20px;
}
.dz-separator.style-skew {
  width: 15px;
  height: 10px;
  margin-left: 1px;
  margin-right: 1px;
  -moz-transform: skewX(-10deg);
  -webkit-transform: skewX(-10deg);
  -o-transform: skewX(-10deg);
  -ms-transform: skewX(-10deg);
  transform: skewX(-10deg);
}
.dz-separator.style-skew[class*="style-"]:after,
.dz-separator.style-skew[class*="style-"]:before {
  width: 80px;
  height: 4px;
  left: 20px;
  -moz-transform: translateY(-50%) skewX(-10deg);
  -webkit-transform: translateY(-50%) skewX(-10deg);
  -o-transform: translateY(-50%) skewX(-10deg);
  -ms-transform: translateY(-50%) skewX(-10deg);
  transform: translateY(-50%) skewX(-10deg);
}
.dz-separator.style-skew[class*="style-"]:before {
  right: 20px;
  left: auto;
}
.dz-separator.bnr-title {
  height: 1px;
  width: 155px;
  opacity: 0.5;
}
.dz-separator.bnr-title:before,
.dz-separator.bnr-title:after {
  position: absolute;
  content: "";
  background-color: inherit;
}
.dz-separator.bnr-title:before {
  height: inherit;
  right: -80px;
  width: 25px;
}
.dz-separator.bnr-title:after {
  height: inherit;
  right: -90px;
  top: 0;
  width: 6px;
}
.dz-separator.bnr-title i {
  background-color: inherit;
  display: block;
  height: inherit;
  position: absolute;
  right: -50px;
  width: 45px;
}

.dz-separator-outer {
  overflow: hidden;
}

.dz-separator.style-1 {
  height: 1px;
  width: 60px;
}

.dz-separator.style-2 {
  height: 10px;
  display: block;
  width: 82px;
  position: relative;
  margin: 0;
}
.dz-separator.style-2:before,
.dz-separator.style-2:after {
  content: "";
  height: 3px;
  width: 89px;
  position: absolute;
  background: var(--primary);
  left: 0;
}
.dz-separator.style-2:after {
  width: 80%;
  bottom: 0;
}
.dz-separator.style-2:before {
  width: 100%;
  top: 0;
}

.site-filters {
  margin-bottom: 30px;
}
.site-filters ul {
  margin: 0;
  list-style: none;
}
.site-filters li {
  display: inline-block;
  padding: 0;
  margin-bottom: 3px;
}
.site-filters li.btn,
.site-filters li.wp-block-button__link {
  box-shadow: none;
  -webkit-box-shadow: none;
  outline: none !important;
}
.site-filters li input {
  display: none;
}
.site-filters li a {
  margin: 0 5px 5px 0;
  display: block;
}
.site-filters li.active [class*="btn"] {
  color: #fff;
  background-color: #efbb20;
}
.site-filters.center {
  text-align: center;
}
.site-filters.center ul {
  display: inline-block;
  margin: auto;
}
.site-filters.center [class*="btn"] {
  display: inline-block;
  margin: 0 5px 10px;
  text-transform: uppercase;
}
@media only screen and (max-width: 575px) {
  .site-filters.center [class*="btn"] {
    margin: 0 1px 5px;
    padding: 5px 10px;
    font-size: 12px;
  }
}
.site-filters.style-1 {
  margin-bottom: 45px;
}
.site-filters.style-1 .filters li {
  text-transform: capitalize !important;
  margin: 0 15px -1px;
  border: 0;
  border-radius: 0;
}
.site-filters.style-1 .filters li:after {
  content: none;
}
.site-filters.style-1 .filters li a {
  color: #777777;
  font-size: 18px;
  margin: 0;
  border-bottom: 2px solid;
  border-color: transparent;
  padding: 0 2px 10px;
  font-weight: 500;
  text-transform: capitalize;
}
.site-filters.style-1 .filters li.active a {
  color: var(--primary);
  border-color: var(--primary);
}
.site-filters.style-1 .filters li:first-child {
  margin-left: 0;
}
.site-filters.style-1 .filters li:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 991px) {
  .site-filters.style-1 .filters li {
    margin: 0 5px -1px;
  }
  .site-filters.style-1 .filters li a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .site-filters.style-1 .filters li {
    margin: 0 0px -6px;
  }
  .site-filters.style-1 .filters li a {
    font-size: 14px;
  }
}

.dz-img-overlay1,
.dz-img-overlay2,
.dz-img-overlay3,
.dz-img-overlay4,
.dz-img-overlay5,
.dz-img-overlay6,
.dz-img-overlay7,
.dz-img-overlay8,
.dz-img-overlay9,
.dz-img-overlay11,
.dz-img-overlay12 {
  position: relative;
}
.dz-img-overlay1:before,
.dz-img-overlay1:after,
.dz-img-overlay2:before,
.dz-img-overlay2:after,
.dz-img-overlay3:before,
.dz-img-overlay3:after,
.dz-img-overlay4:before,
.dz-img-overlay4:after,
.dz-img-overlay5:before,
.dz-img-overlay5:after,
.dz-img-overlay6:before,
.dz-img-overlay6:after,
.dz-img-overlay7:before,
.dz-img-overlay7:after,
.dz-img-overlay8:before,
.dz-img-overlay8:after,
.dz-img-overlay9:before,
.dz-img-overlay9:after,
.dz-img-overlay11:before,
.dz-img-overlay11:after,
.dz-img-overlay12:before,
.dz-img-overlay12:after {
  content: "";
  background: #282d32;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -ms-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}
.dz-img-overlay1.overlay-primary,
.dz-img-overlay2.overlay-primary,
.dz-img-overlay3.overlay-primary,
.dz-img-overlay4.overlay-primary,
.dz-img-overlay5.overlay-primary,
.dz-img-overlay6.overlay-primary,
.dz-img-overlay7.overlay-primary,
.dz-img-overlay8.overlay-primary,
.dz-img-overlay9.overlay-primary,
.dz-img-overlay11.overlay-primary,
.dz-img-overlay12.overlay-primary {
  background: var(--primary);
}

.dz-box:hover .dz-img-overlay1:before {
  opacity: 0.7;
}

.dz-box:hover .dz-img-overlay2:before {
  opacity: 0.9;
}

.dz-box:hover .dz-img-overlay3:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-box:hover .dz-img-overlay4:before {
  width: 100%;
  left: 0;
}

.dz-box:hover .dz-img-overlay5:before {
  height: 100%;
  top: 0;
}

.dz-box:hover .dz-img-overlay6:before {
  height: 100%;
}

.dz-box:hover .dz-img-overlay7:before,
.dz-box:hover .dz-img-overlay7:after {
  width: 50%;
}

.dz-box:hover .dz-img-overlay8:before,
.dz-box:hover .dz-img-overlay8:after {
  width: 100%;
  opacity: 0.3;
}

.dz-box:hover .dz-img-overlay9:before,
.dz-box:hover .dz-img-overlay9:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.dz-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dz-img-overlay1:hover:before {
  opacity: 0.5;
}

.dz-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}
.dz-img-overlay2:before:hover:before {
  opacity: 0.9;
}

.dz-img-overlay3:before {
  left: 50%;
  top: 50%;
}

.dz-img-overlay3:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-img-overlay4:before {
  left: 50%;
  top: 0;
  height: 100%;
}

.dz-img-overlay4:hover:before {
  width: 100%;
  left: 0;
}

.dz-img-overlay5:before {
  left: 0;
  top: 50%;
  width: 100%;
}

.dz-img-overlay5:hover:before {
  height: 100%;
  top: 0;
}

.dz-img-overlay6:before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
}

.dz-img-overlay6:hover:before {
  height: 100%;
}

.dz-img-overlay7:before,
.dz-img-overlay7:after {
  height: 100%;
}

.dz-img-overlay7:after {
  left: auto;
  right: 0;
}

.dz-img-overlay7:hover:before,
.dz-img-overlay7:hover:after {
  width: 50%;
}

.dz-img-overlay8:before,
.dz-img-overlay8:after {
  height: 100%;
}

.dz-img-overlay8:after {
  left: auto;
  right: 0;
}

.dz-img-overlay8:hover:before,
.dz-img-overlay8:hover:after {
  width: 100%;
  opacity: 0.3;
}

.dz-img-overlay9:before:before,
.dz-img-overlay9:before:after,
.dz-img-overlay9:after:before,
.dz-img-overlay9:after:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.dz-img-overlay9:before:hover:before,
.dz-img-overlay9:before:hover:after,
.dz-img-overlay9:after:hover:before,
.dz-img-overlay9:after:hover:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

/*  */
.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
  position: relative;
}
.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before,
.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before,
.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before,
.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.overlay-black-light .container,
.overlay-black-light .container-fluid,
.overlay-black-light .container-sm,
.overlay-black-light .container-md,
.overlay-black-light .container-lg,
.overlay-black-light .container-xl,
.overlay-black-middle .container,
.overlay-black-middle .container-fluid,
.overlay-black-middle .container-sm,
.overlay-black-middle .container-md,
.overlay-black-middle .container-lg,
.overlay-black-middle .container-xl,
.overlay-black-dark .container,
.overlay-black-dark .container-fluid,
.overlay-black-dark .container-sm,
.overlay-black-dark .container-md,
.overlay-black-dark .container-lg,
.overlay-black-dark .container-xl,
.overlay-gradient-light .container,
.overlay-gradient-light .container-fluid,
.overlay-gradient-light .container-sm,
.overlay-gradient-light .container-md,
.overlay-gradient-light .container-lg,
.overlay-gradient-light .container-xl,
.overlay-gradient-middle .container,
.overlay-gradient-middle .container-fluid,
.overlay-gradient-middle .container-sm,
.overlay-gradient-middle .container-md,
.overlay-gradient-middle .container-lg,
.overlay-gradient-middle .container-xl,
.overlay-gradient-dark .container,
.overlay-gradient-dark .container-fluid,
.overlay-gradient-dark .container-sm,
.overlay-gradient-dark .container-md,
.overlay-gradient-dark .container-lg,
.overlay-gradient-dark .container-xl,
.overlay-white-light .container,
.overlay-white-light .container-fluid,
.overlay-white-light .container-sm,
.overlay-white-light .container-md,
.overlay-white-light .container-lg,
.overlay-white-light .container-xl,
.overlay-white-middle .container,
.overlay-white-middle .container-fluid,
.overlay-white-middle .container-sm,
.overlay-white-middle .container-md,
.overlay-white-middle .container-lg,
.overlay-white-middle .container-xl,
.overlay-white-dark .container,
.overlay-white-dark .container-fluid,
.overlay-white-dark .container-sm,
.overlay-white-dark .container-md,
.overlay-white-dark .container-lg,
.overlay-white-dark .container-xl,
.overlay-primary-light .container,
.overlay-primary-light .container-fluid,
.overlay-primary-light .container-sm,
.overlay-primary-light .container-md,
.overlay-primary-light .container-lg,
.overlay-primary-light .container-xl,
.overlay-primary-middle .container,
.overlay-primary-middle .container-fluid,
.overlay-primary-middle .container-sm,
.overlay-primary-middle .container-md,
.overlay-primary-middle .container-lg,
.overlay-primary-middle .container-xl,
.overlay-primary-dark .container,
.overlay-primary-dark .container-fluid,
.overlay-primary-dark .container-sm,
.overlay-primary-dark .container-md,
.overlay-primary-dark .container-lg,
.overlay-primary-dark .container-xl,
.overlay-primary .container,
.overlay-primary .container-fluid,
.overlay-primary .container-sm,
.overlay-primary .container-md,
.overlay-primary .container-lg,
.overlay-primary .container-xl {
  position: relative;
  z-index: 1;
}

.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before {
  background: #222222;
}

.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%);
}

.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before {
  background: #fff;
}

.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  background: var(--primary);
}

.overlay-primary-light:before,
.overlay-white-light:before,
.overlay-gradient-light:before,
.overlay-black-light:before {
  opacity: 0.3;
}

.overlay-primary-middle:before,
.overlay-white-middle:before,
.overlay-gradient-middle:before,
.overlay-black-middle:before {
  opacity: 0.7;
}

.overlay-primary-dark:before,
.overlay-white-dark:before,
.overlay-gradient-dark:before,
.overlay-black-dark:before {
  opacity: 0.9;
}

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.overlay-bx .align-b {
  height: auto;
  left: 0;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  transform: translate(0);
  -o-transform: translate(0);
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
}
.overlay-bx .align-m {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}
.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
}
.overlay-icon a i {
  background-color: #fff;
}

.overlay-bx:hover a > i,
.dz-media:hover .overlay-bx a > i,
.dz-box-bx:hover .overlay-bx a > i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-bx:hover,
.dz-media:hover .overlay-bx,
.dz-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

.gallery-box-1 .overlay-bx .overlay-icon span i,
.gallery-box-1 .overlay-bx .overlay-icon a i {
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
}

.gallery-box-1 .overlay-bx .icon-bx-xs {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.img-overlay-gradients {
  position: relative;
}
.img-overlay-gradients:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.img-overlay-gradients.gradients-secondary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, #232323 100%);
  background: -webkit-linear-gradient(bottom, transparent 0%, #232323 100%);
  background: linear-gradient(to bottom, transparent 0%, #232323 100%);
}
.img-overlay-gradients.gradients-primary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
  background: -webkit-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
  background: linear-gradient(to bottom, transparent 0%, var(--primary) 100%);
}

.overlay-shine .dz-media {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-base);
}
.overlay-shine .dz-media:before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
}
.overlay-shine .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.overlay-shine:hover .dz-media:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.pricingtable-features {
  margin: 0;
  padding: 0;
  list-style: none;
}
.pricingtable-features li {
  padding: 12px;
}
.pricingtable-features li i {
  margin: 0 3px;
}

.no-col-gap .pricingtable-wrapper {
  margin-left: -1px;
}

.table-option {
  border: 2px solid #adadad;
  border-radius: 6px;
  padding: 50px 0px 30px 0;
}
.table-option .pricingtable-footer {
  border: 0;
  background-color: transparent;
}
.table-option .pricingtable-features {
  border: 0;
  background-color: transparent;
}
.table-option .pricingtable-features li {
  border: 0;
  background-color: transparent;
  color: #919191;
  font-size: 18px;
}
.table-option .pricingtable-title {
  border: 0;
  background-color: transparent;
  padding: 0;
}
.table-option .pricingtable-title * {
  color: #000;
}
.table-option .pricingtable-title h2,
.table-option .pricingtable-title .h2 {
  font-size: 28px;
  line-height: 36px;
}
.table-option .pricingtable-price {
  padding: 0;
  border: 0;
  background-color: transparent;
}
.table-option .pricingtable-bx {
  color: #000;
  font-size: 63px;
  font-weight: 800;
}
.table-option .pricingtable-bx strong,
.table-option .pricingtable-bx sup {
  font-size: 30px;
  font-weight: 700;
}
.table-option:hover,
.table-option.active {
  background-color: #eeeeee;
  border: 2px solid #eeeeee;
}
.table-option.dark {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.table-option.dark:hover,
.table-option.dark.active {
  background-color: #404040;
  border: 2px solid #404040;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
}
.table-option.dark:hover .pricingtable-features li,
.table-option.dark:hover .pricingtable-title h2,
.table-option.dark:hover .pricingtable-title .h2,
.table-option.dark:hover .pricingtable-price .pricingtable-bx,
.table-option.dark.active .pricingtable-features li,
.table-option.dark.active .pricingtable-title h2,
.table-option.dark.active .pricingtable-title .h2,
.table-option.dark.active .pricingtable-price .pricingtable-bx {
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.table-option.dark:hover .pricingtable-footer .btn.black,
.table-option.dark:hover .pricingtable-footer .black.wp-block-button__link,
.table-option.dark.active .pricingtable-footer .btn.black,
.table-option.dark.active .pricingtable-footer .black.wp-block-button__link {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0);
  color: #000;
}

.pricingtable-toggle {
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 0;
  margin-bottom: 10px;
}
.pricingtable-toggle span {
  font-size: 18px;
  margin-top: 5px;
  font-weight: 500;
}
.pricingtable-toggle .custom-control-label {
  margin: 0 35px 0 50px;
}

.pricingtable-wrapper {
  box-shadow: 1px 0 50px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-base);
  overflow: hidden;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-color: #fff;
}
.pricingtable-wrapper.style-1 {
  background-color: #222222;
  padding: 65px 43px 57px;
  position: relative;
  z-index: 1;
  border-radius: 0;
  height: 100%;
}
.pricingtable-wrapper.style-1:after,
.pricingtable-wrapper.style-1:before {
  content: "";
  position: absolute;
  height: 42%;
  z-index: -1;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-size: cover;
  background-repeat: no-repeat;
}
.pricingtable-wrapper.style-1:after {
  background-image: url(../images/background/bg-pricetable-1.svg);
  width: 75%;
  height: 90%;
}
.pricingtable-wrapper.style-1:before {
  background-image: url(../images/background/bg-pricetable-2.png);
  width: 40%;
  opacity: 0.2;
}
.pricingtable-wrapper.style-1 .pricingtable-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.pricingtable-wrapper.style-1 .pricingtable-footer {
  margin-top: auto;
}
.pricingtable-wrapper.style-1 .pricingtable-price {
  margin-bottom: 10px;
}
.pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx {
  font-weight: 600;
  font-size: 65px;
  margin: 0;
}
.pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx small,
.pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx .small {
  font-size: 16px;
  color: var(--theme-text-color);
  font-weight: 500;
}
@media only screen and (max-width: 1401px) {
  .pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-bx {
    font-size: 45px;
  }
}
.pricingtable-wrapper.style-1 .pricingtable-price p {
  margin: 0;
  margin-bottom: 15px;
  font-size: 15px;
  color: #999999;
  line-height: 1.9;
}
.pricingtable-wrapper.style-1 .pricingtable-price .pricingtable-type {
  color: #071c35;
  font-weight: 700;
  font-size: 26px;
  font-family: var(--font-family-title);
}
.pricingtable-wrapper.style-1 .pricingtable-features {
  border-top: 1px solid;
  border-color: rgba(255, 255, 255, 0.2);
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: left;
}
.pricingtable-wrapper.style-1 .pricingtable-features li {
  padding: 5px 0 5px 40px;
  position: relative;
  margin: 10px 0;
  color: #fff;
}
.pricingtable-wrapper.style-1 .pricingtable-features li:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-size: 12px;
  font-weight: 600;
  width: 24px;
  height: 24px;
  background: rgba(255, 255, 255, 0.2);
  display: block;
  position: absolute;
  left: 0;
  color: #fff;
  border-radius: 35px;
  text-align: center;
  top: 6px;
  line-height: 26px;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}
@media only screen and (max-width: 1401px) {
  .pricingtable-wrapper.style-1 .pricingtable-features li {
    font-size: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .pricingtable-wrapper.style-1 .pricingtable-features li {
    margin: 5px 0;
  }
}
@media only screen and (max-width: 575px) {
  .pricingtable-wrapper.style-1 .pricingtable-features {
    padding: 15px 0;
  }
}
.pricingtable-wrapper.style-1 .pricingtable-title {
  position: absolute;
  top: 0px;
  left: 40px;
  font-size: 18px;
  line-height: 1.2;
  color: #fff;
  padding: 5px 25px;
  background: #92adc6;
}
.pricingtable-wrapper.style-1 .pricingtable-title.premium {
  background-color: #ffe713;
  color: #000;
}
@media only screen and (max-width: 1401px) {
  .pricingtable-wrapper.style-1 .pricingtable-title {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1401px) {
  .pricingtable-wrapper.style-1 {
    padding: 55px 30px 45px;
  }
}
.pricingtable-wrapper.style-1:hover,
.pricingtable-wrapper.style-1.active {
  background: var(--primary);
}
.pricingtable-wrapper.style-1:hover .btn-primary,
.pricingtable-wrapper.style-1:hover .wp-block-button__link,
.pricingtable-wrapper.style-1.active .btn-primary,
.pricingtable-wrapper.style-1.active .wp-block-button__link {
  background: #000;
  color: #fff;
}
.pricingtable-wrapper.style-1:hover .pricingtable-type,
.pricingtable-wrapper.style-1:hover .pricingtable-price,
.pricingtable-wrapper.style-1:hover .pricingtable-bx,
.pricingtable-wrapper.style-1.active .pricingtable-type,
.pricingtable-wrapper.style-1.active .pricingtable-price,
.pricingtable-wrapper.style-1.active .pricingtable-bx {
  color: #000 !important;
}
.pricingtable-wrapper.style-1:hover .pricingtable-type p,
.pricingtable-wrapper.style-1:hover .pricingtable-price p,
.pricingtable-wrapper.style-1:hover .pricingtable-bx p,
.pricingtable-wrapper.style-1.active .pricingtable-type p,
.pricingtable-wrapper.style-1.active .pricingtable-price p,
.pricingtable-wrapper.style-1.active .pricingtable-bx p {
  color: var(--theme-text-color);
}
.pricingtable-wrapper.style-1:hover .pricingtable-title,
.pricingtable-wrapper.style-1.active .pricingtable-title {
  background: #000;
}
.pricingtable-wrapper.style-1:hover .pricingtable-title.premium,
.pricingtable-wrapper.style-1.active .pricingtable-title.premium {
  color: white;
}
.pricingtable-wrapper.style-1:hover .pricingtable-features,
.pricingtable-wrapper.style-1.active .pricingtable-features {
  border-color: rgba(255, 255, 255, 0.3);
}
.pricingtable-wrapper.style-1:hover .pricingtable-features li,
.pricingtable-wrapper.style-1.active .pricingtable-features li {
  color: var(--theme-text-color);
}
.pricingtable-wrapper.style-1:hover .pricingtable-features li:after,
.pricingtable-wrapper.style-1.active .pricingtable-features li:after {
  background: #000;
  color: var(--primary);
}
.pricingtable-wrapper.style-1:hover:before,
.pricingtable-wrapper.style-1.active:before {
  opacity: 1;
}

.membership-box:after {
  content: "";
  z-index: -1;
  top: 0;
  right: 0;
  width: 59%;
  position: absolute;
  height: calc(100% - 150px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1480px) {
  .membership-box:after {
    width: 62%;
  }
}
@media only screen and (max-width: 1199px) {
  .membership-box:after {
    width: 63%;
  }
}
@media only screen and (max-width: 991px) {
  .membership-box:after {
    display: none;
  }
}

.membership-box .pricing-content {
  padding-left: 70px;
  padding-right: 15px;
}
@media only screen and (max-width: 1199px) {
  .membership-box .pricing-content {
    padding-left: 40px;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 991px) {
  .membership-box .pricing-content {
    padding: 0;
  }
}

.membership-box .tab-content .content {
  font-size: 18px;
}
@media only screen and (max-width: 767px) {
  .membership-box .tab-content .content {
    font-size: 15px;
  }
}

.dz-img-effect {
  position: relative;
  overflow: hidden;
  display: block;
  vertical-align: middle;
}
.dz-img-effect img {
  display: block;
  margin: 0;
  width: 100%;
  height: auto;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -moz-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dz-img-effect.opacity img:hover {
  opacity: 0.8;
}
.dz-img-effect.zoom-slow img {
  transition: all 10s;
  -moz-transition: all 10s;
  -webkit-transition: all 10s;
  -o-transition: all 10s;
}
.dz-img-effect.zoom-slow:hover img {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dz-img-effect.zoom:hover img {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
}
.dz-img-effect.shrink:hover img {
  transform: scale(0.8);
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  -o-transform: scale(0.8);
}
.dz-img-effect.side-pan:hover img {
  margin-left: -11%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dz-img-effect.vertical-pan:hover img {
  margin-top: -10%;
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
}
.dz-img-effect.fade-in {
  background: #000;
}
.dz-img-effect.fade-in img {
  opacity: 0.65;
}
.dz-img-effect.fade-in:hover img {
  opacity: 1;
}
.dz-img-effect.fade-out {
  background: #000;
}
.dz-img-effect.fade-out:hover img {
  opacity: 0.7;
}
.dz-img-effect.rotate:hover img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}
.dz-img-effect.image-sepia img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
}
.dz-img-effect.blurr img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}
.dz-img-effect.blurr:hover img {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}
.dz-img-effect.blurr-invert img {
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
}
.dz-img-effect.blurr-invert:hover img {
  filter: blur(0px);
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(0px);
}
.dz-img-effect.off-color img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}
.dz-img-effect.off-color:hover img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.dz-img-effect.on-color img {
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(1);
  -o-filter: grayscale(1);
  -ms-filter: grayscale(1);
}
.dz-img-effect.on-color:hover img {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
  -moz-filter: grayscale(0);
  -o-filter: grayscale(0);
  -ms-filter: grayscale(0);
}

.dz-box:hover .dz-img-effect.rotate img {
  -moz-transform: scale(1.5) rotate(-20deg);
  -webkit-transform: scale(1.5) rotate(-20deg);
  -o-transform: scale(1.5) rotate(-20deg);
  -ms-transform: scale(1.5) rotate(-20deg);
  transform: scale(1.5) rotate(-20deg);
}

.dz-accordion .accordion-item {
  background: #fff;
  margin-bottom: 20px;
  border: 0;
}
@media only screen and (max-width: 575px) {
  .dz-accordion .accordion-item {
    margin-bottom: 15px;
  }
}

.dz-accordion .accordion-body {
  padding: 25px 30px;
  border-radius: 0;
}
@media only screen and (max-width: 575px) {
  .dz-accordion .accordion-body {
    padding: 15px;
  }
}

.dz-accordion .accordion-collapse {
  border: 0;
  border: 0;
  border-radius: 0 !important;
}

.dz-accordion.dz-accordion-skew .accordion-item {
  margin-bottom: 20px;
}
@media only screen and (max-width: 575px) {
  .dz-accordion.dz-accordion-skew .accordion-item {
    margin-bottom: 15px;
  }
}

.dz-accordion.dz-accordion-skew .accordion-body {
  padding: 20px 25px;
  border-radius: 0;
}
@media only screen and (max-width: 575px) {
  .dz-accordion.dz-accordion-skew .accordion-body {
    padding: 15px 15px;
  }
}

.dz-accordion.dz-accordion-skew .accordion-header {
  transform: skewX(-12deg);
  background: var(--rgba-primary-1);
  border-left: 3px solid var(--primary);
}
.dz-accordion.dz-accordion-skew .accordion-header .accordion-button {
  transform: skewX(12deg);
  font-size: 16px;
  border: 0;
  font-weight: 600;
  border-radius: 0;
  padding: 18px 60px 18px 25px;
  transition: all 0.5s;
  color: black;
  background-color: transparent;
  box-shadow: unset;
}
.dz-accordion.dz-accordion-skew .accordion-header .accordion-button:after {
  content: none;
}
.dz-accordion.dz-accordion-skew .accordion-header .accordion-button .toggle-close {
  background-color: var(--primary);
  font-family: themify;
  height: 32px;
  width: 32px;
  line-height: 32px;
  transform: skewX(-12deg);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 12px;
  top: 12px;
  text-align: center;
  border-radius: 0;
  font-size: 24px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  overflow: hidden;
}
.dz-accordion.dz-accordion-skew .accordion-header .accordion-button .toggle-close:before {
  content: "\f105";
  color: white;
  font-size: 16px;
  font-family: "Font Awesome 6 Free";
  z-index: 99;
  opacity: 1;
  transform: rotate(90deg) skewX(0deg) skewY(-12deg);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  display: block;
}
.dz-accordion.dz-accordion-skew .accordion-header .accordion-button.collapsed {
  background: transparent;
  color: inherit;
}
.dz-accordion.dz-accordion-skew .accordion-header .accordion-button.collapsed:after {
  opacity: 0;
}
.dz-accordion.dz-accordion-skew .accordion-header .accordion-button.collapsed .toggle-close {
  content: "";
}
.dz-accordion.dz-accordion-skew .accordion-header .accordion-button.collapsed .toggle-close:after {
  opacity: 1;
}
.dz-accordion.dz-accordion-skew .accordion-header .accordion-button.collapsed .toggle-close:before {
  opacity: 1;
  transform: rotate(0) skewX(12deg);
}
@media only screen and (max-width: 575px) {
  .dz-accordion.dz-accordion-skew .accordion-header .accordion-button {
    padding: 14px 52px 14px 18px;
    font-size: 15px;
  }
}

.dz-accordion.dz-accordion-skew.skew-none .accordion-header {
  transform: skewX(0);
}
.dz-accordion.dz-accordion-skew.skew-none .accordion-header .accordion-button {
  transform: skewX(0);
}
.dz-accordion.dz-accordion-skew.skew-none .accordion-header .accordion-button .toggle-close {
  transform: skewX(0);
}
.dz-accordion.dz-accordion-skew.skew-none .accordion-header .accordion-button .toggle-close:before {
  transform: rotate(90deg) skewX(0deg) skewY(0);
}
.dz-accordion.dz-accordion-skew.skew-none .accordion-header .accordion-button.collapsed .toggle-close:before {
  transform: rotate(0) skewX(0);
}

.dz-accordion.dz-accordion-outline .accordion-item {
  background: transparent;
  margin-bottom: 20px;
  border: 0;
}

.dz-accordion.dz-accordion-outline .accordion-body {
  padding: 20px 25px;
  background-color: var(--secondary);
  border-radius: 0;
  color: #fff;
}
@media only screen and (max-width: 575px) {
  .dz-accordion.dz-accordion-outline .accordion-body {
    padding: 15px;
  }
}

.dz-accordion.dz-accordion-outline .accordion-collapse {
  border: 0;
  border: 0;
  border-radius: 0 !important;
}

.dz-accordion.dz-accordion-outline .accordion-header {
  transform: skewX(0);
  background: none;
}
.dz-accordion.dz-accordion-outline .accordion-header .accordion-button {
  transform: skewX(0);
  font-size: 18px;
  font-weight: 400;
  border-radius: 0;
  padding: 18px 65px 18px 25px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: var(--primary);
  border: 1px solid var(--primary);
  background-color: var(--secondary);
}
.dz-accordion.dz-accordion-outline .accordion-header .accordion-button:after {
  content: none;
}
.dz-accordion.dz-accordion-outline .accordion-header .accordion-button .toggle-close {
  height: 58px;
  width: 58px;
  transform: skewX(0);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0px;
  line-height: 58px;
  text-align: center;
  border-radius: 0;
  font-size: 16px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  overflow: hidden;
}
.dz-accordion.dz-accordion-outline .accordion-header .accordion-button .toggle-close:before {
  content: "\f068";
  color: var(--primary);
  font-weight: 700;
  font-size: 16px;
  font-family: "Font Awesome 6 Free";
  z-index: 99;
  transform: none;
  transition: all 0.5s;
  line-height: 55px;
  display: block;
}
@media only screen and (max-width: 1280px) {
  .dz-accordion.dz-accordion-outline .accordion-header .accordion-button .toggle-close {
    height: 55px;
    width: 55px;
    line-height: 55px;
    font-size: 20px;
  }
  .dz-accordion.dz-accordion-outline .accordion-header .accordion-button .toggle-close:before {
    font-size: 24px;
  }
}
.dz-accordion.dz-accordion-outline .accordion-header .accordion-button.collapsed {
  border: 1px solid #ffffff33;
  background: var(--secondary);
  color: #fff;
}
.dz-accordion.dz-accordion-outline .accordion-header .accordion-button.collapsed .toggle-close:before {
  content: "\2b";
}
@media only screen and (max-width: 1280px) {
  .dz-accordion.dz-accordion-outline .accordion-header .accordion-button {
    font-size: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-accordion.dz-accordion-outline .accordion-header .accordion-button {
    padding: 15px 65px 15px 15px;
  }
}

.dz-accordion.dz-accordion-outline.accordion-sm .accordion-body {
  padding: 20px;
}
@media only screen and (max-width: 1280px) {
  .dz-accordion.dz-accordion-outline.accordion-sm .accordion-body {
    padding: 18px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-accordion.dz-accordion-outline.accordion-sm .accordion-body {
    padding: 15px;
  }
}

.dz-accordion.dz-accordion-outline.accordion-sm .accordion-header .accordion-button {
  padding: 18px 60px 18px 25px;
}
.dz-accordion.dz-accordion-outline.accordion-sm .accordion-header .accordion-button .toggle-close {
  height: 100%;
  width: 60px;
}
.dz-accordion.dz-accordion-outline.accordion-sm .accordion-header .accordion-button .toggle-close:before {
  font-size: 25px;
  line-height: 60px;
}
@media only screen and (max-width: 767px) {
  .dz-accordion.dz-accordion-outline.accordion-sm .accordion-header .accordion-button .toggle-close {
    width: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-accordion.dz-accordion-outline.accordion-sm .accordion-header .accordion-button {
    padding: 15px 45px 15px 15px;
  }
}

.pagination li {
  margin: 0 5px;
}
.pagination li .page-numbers,
.pagination li .page-link {
  height: 40px;
  min-width: 40px;
  line-height: 40px;
  transform: skewX(-10deg);
  border-radius: 0;
  color: var(--primary);
  text-align: center;
  border: 0;
  padding: 0;
  transition: all 0.5s;
  font-size: 16px;
  padding: 0 5px;
  display: block;
  background-color: var(--rgba-primary-1);
}
.pagination li .page-numbers span,
.pagination li .page-link span {
  transform: skewX(10deg);
  display: inline-block;
}
.pagination li .page-numbers.current,
.pagination li .page-numbers.active,
.pagination li .page-numbers:hover,
.pagination li .page-link.current,
.pagination li .page-link.active,
.pagination li .page-link:hover {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}
@media only screen and (max-width: 575px) {
  .pagination li {
    margin: 0 5px;
  }
}
.pagination li .prev,
.pagination li .next {
  background-color: transparent;
  border-color: transparent;
  font-size: 18px;
  transform: skewX(0deg);
}
.pagination li .prev:hover,
.pagination li .next:hover {
  background-color: transparent;
  box-shadow: none;
  color: var(--primary);
}

.pagination.text-center {
  justify-content: center;
}

.pagination.rounded-0 .page-item .page-link {
  border-radius: 0;
}

.list-circle li,
.list-angle-double li,
.list-square li,
.list-square-check li {
  position: relative;
  padding: 6px 5px 6px 15px;
}
.list-circle li:before,
.list-angle-double li:before,
.list-square li:before,
.list-square-check li:before {
  position: absolute;
  left: 0;
  top: 4px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.list-circle.primary li:before,
.list-angle-double.primary li:before,
.list-square.primary li:before,
.list-square-check.primary li:before {
  color: var(--primary);
}

.list-angle-double li {
  padding-left: 25px;
}

ul.list-circle li:before {
  content: "\f111";
  font-size: 8px;
  font-weight: 900;
  top: 50%;
  transform: translateY(-50%);
}

ul.list-angle-double li:before {
  content: "\f101";
  font-size: 18px;
}

ul.list-square li:before {
  content: "\f45c";
  top: 50%;
  transform: translateY(-50%);
}

ul.grid-2 {
  display: flex;
  flex-wrap: wrap;
}
ul.grid-2 li {
  width: 50%;
}
@media only screen and (max-width: 575px) {
  ul.grid-2 li {
    width: 100%;
  }
}

.list-square-check li {
  padding: 10px 15px 10px 60px;
  min-height: 45px;
  font-weight: 600;
  color: var(--title);
  margin-bottom: 15px;
}
.list-square-check li:before {
  content: "\f00c";
  top: 50%;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: var(--rgba-primary-1);
  color: #000;
  text-align: center;
  padding: 0 0 0 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  transform: translateY(-50%);
}
.list-square-check li:hover:before {
  background-color: var(--primary);
  color: #fff;
}

.list-check-1 li {
  padding: 12px 0px 12px 40px;
  color: #071c35;
  font-weight: 500;
  position: relative;
}
.list-check-1 li:before {
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 35px;
  transform: translateY(-50%) scale(1);
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 700;
  height: 28px;
  width: 28px;
  line-height: 28px;
  background-color: var(--primary);
  color: #fff;
  text-align: center;
  font-size: 16px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 575px) {
  .list-check-1 li:before {
    height: 25px;
    width: 25px;
    line-height: 25px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 991px) {
  .list-check-1 li {
    padding: 10px 0px 10px 38px;
  }
}

.list-check-1.top li:before {
  top: 40px;
}

.list-check-1.top li p {
  font-family: var(--font-family-title);
  color: #666666;
  margin-bottom: 0;
}

.list-check-1.light li:before {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

.list-check-1.light li:hover:before {
  background-color: var(--primary);
  color: #fff;
}

.list-check-2 li {
  padding: 0 0 0 32px;
  position: relative;
  margin-bottom: 10px;
}
.list-check-2 li:before {
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 35px;
  content: "\f00c";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  color: var(--primary);
  font-size: 18px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: var(--primary);
}

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}

.lightimg {
  cursor: pointer;
}

.lg-progress-bar .lg-progress {
  background-color: var(--primary);
}

.progress-bx.style-1 .progress-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.progress-bx.style-1 .progress-head .title {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .progress-bx.style-1 .progress-head .title {
    font-size: 15px;
  }
}
.progress-bx.style-1 .progress-head span {
  font-weight: 500;
  color: var(--primary);
}
@media only screen and (max-width: 991px) {
  .progress-bx.style-1 .progress-head span {
    font-size: 15px;
  }
}

.progress-bx.style-1 .progress {
  position: relative;
  z-index: 1;
  height: 20px;
  border-radius: 0;
  background-color: transparent;
}
.progress-bx.style-1 .progress .progress-bar {
  transform: skewX(-20deg);
  margin-left: -4px;
}
.progress-bx.style-1 .progress:after {
  content: "";
  position: absolute;
  top: 50%;
  z-index: -1;
  transform: translateY(-50%);
  left: 0;
  height: 1px;
  width: 100%;
  background: rgba(255, 255, 255, 0.2);
}
@media only screen and (max-width: 991px) {
  .progress-bx.style-1 .progress {
    height: 10px;
  }
}

.progress-bar-wrapper1 {
  padding: 65px;
  background-color: var(--secondary);
  height: 100%;
}
.progress-bar-wrapper1 .progress-bx:not(:last-child) {
  margin-bottom: 40px;
}
@media only screen and (max-width: 1480px) {
  .progress-bar-wrapper1 {
    padding: 50px;
  }
}
@media only screen and (max-width: 1199px) {
  .progress-bar-wrapper1 {
    padding: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .progress-bar-wrapper1 {
    padding: 25px;
  }
  .progress-bar-wrapper1 .progress-bx:not(:last-child) {
    margin-bottom: 25px;
  }
}

.scroltop {
  box-shadow: -4px 4px 24px -10px var(--primary) !important;
  background: var(--primary);
  color: var(--theme-text-color);
  border-color: var(--primary);
  border-style: solid;
  border-width: 0;
  bottom: 15px;
  cursor: pointer;
  display: none;
  height: 50px;
  line-height: 50px;
  margin: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 50px;
  z-index: 999;
  box-shadow: -4px 4px 24px -10px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
@media only screen and (max-width: 575px) {
  .scroltop {
    height: 35px;
    width: 35px;
    line-height: 35px;
  }
}

.video-bx1 {
  position: relative;
  min-width: 170px;
  width: 170px;
  height: 170px;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
.video-bx1 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.video-bx1 .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.video-bx1 .video-btn .popup-youtube {
  display: inline-block;
  height: 60px;
  width: 60px;
  min-width: 60px;
  line-height: 60px;
  font-size: 20px;
  border-radius: 60px;
  background-color: #fff;
  color: var(--primary);
}
.video-bx1 .video-btn .popup-youtube:before,
.video-bx1 .video-btn .popup-youtube:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.video-bx1 .video-btn .popup-youtube:before {
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
}
.video-bx1 .video-btn .popup-youtube:after {
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
}
@media only screen and (max-width: 575px) {
  .video-bx1 {
    height: 120px;
    width: 120px;
    min-width: 120px;
  }
  .video-bx1 .video-btn .popup-youtube {
    height: 40px;
    width: 40px;
    min-width: 40px;
    line-height: 40px;
    font-size: 16px;
  }
}

.video-bx2 {
  height: 500px;
}
.video-bx2.large {
  height: 615px;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
.video-bx2.large img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-bx2 .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.video-bx2 .video-btn.sm .popup-youtube {
  height: 72px;
  width: 72px;
  min-width: 72px;
  line-height: 72px;
  font-size: 22px;
}
.video-bx2 .video-btn .popup-youtube {
  display: inline-block;
  height: 100px;
  width: 100px;
  min-width: 100px;
  line-height: 100px;
  font-size: 30px;
  border-radius: 100px;
  background-color: #fff;
  color: var(--primary);
}
.video-bx2 .video-btn .popup-youtube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
.video-bx2 .video-btn .popup-youtube:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}
@media only screen and (max-width: 991px) {
  .video-bx2 {
    height: 330px;
  }
  .video-bx2.large {
    height: 380px;
  }
}
@media only screen and (max-width: 575px) {
  .video-bx2 {
    height: 200px;
  }
  .video-bx2.large {
    height: 250px;
  }
  .video-bx2 .video-btn .popup-youtube,
  .video-bx2 .video-btn.sm .popup-youtube {
    height: 60px;
    width: 60px;
    min-width: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}

.video-bx3 {
  display: inline-block;
}
.video-bx3 .video-btn .popup-youtube {
  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 52px;
  text-align: center;
  font-size: 18px;
  border-radius: 60px;
  min-width: 60px;
  border: 4px solid #ececec;
  color: var(--secondary);
}
.video-bx3 .video-btn .popup-youtube:before,
.video-bx3 .video-btn .popup-youtube:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid var(--secondary);
  z-index: -1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
.video-bx3 .video-btn .popup-youtube:before {
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
}
.video-bx3 .video-btn .popup-youtube:after {
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
}
.video-bx4-slider {
  display: inline-block;
  position: relative;
  background-color: #fff;
  width: 105%;
  /* border-radius: 55px; */
}

/* .video-bx4 {
  display: inline-block;
  position: relative; 
  background-color: #fff;
  width: 50%;
    border-radius: 55px;
} */
.enroll-btn {
  padding-top: 30px;
}
.video-bx4 i {
  display: inline-block;
  height: 55px;
  width: 55px;
  min-width: 55px;
  border-radius: 100px;
  line-height: 55px;
  background: white;
  text-align: center;
  color: var(--primary);
  font-size: 20px;
  margin-right: 10px;
  box-shadow: 0px 5px 30px 0px rgba(253, 114, 74, 0.1);
}
.video-bx4 .text {
  color: #444444;
  font-weight: 500;
  font-size: 18px;
  padding-right: 20px;
}
.video-bx4:before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 55px;
  width: 55px;
  border-radius: 55px;
  background: #fff;
  z-index: -1;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: 1px 1.732px 55px 0px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 991px) {
  .video-bx4 i {
    height: 50px;
    width: 50px;
    min-width: 50px;
    line-height: 50px;
  }
  .video-bx4 .text {
    font-size: 15px;
  }
  .video-bx4:before {
    height: 50px;
    width: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .video-bx4 i,
  .video-bx4 .popup-youtube {
    height: 45px;
    width: 45px;
    min-width: 39px;
    line-height: 45px;
    font-size: 16px;
    margin-right: 0;
    display: flex;
    justify-content: space-around;
  }
  .video-bx4 .text {
    font-size: 14px;
    /* text-wrap: nowrap;  */
    text-wrap: nowrap;
  }
  .video-bx4:before {
    height: 45px;
    width: 45px;
  }
}

.video-bx5 {
  position: absolute;
  bottom: 20px;
  right: 30px;
  z-index: 10;
  width: 170px;
}
@media only screen and (max-width: 991px) {
  .video-bx5 {
    display: none;
  }
}
.video-bx5 img {
  width: 100%;
  border: 5px solid #fff;
  border-radius: 10px;
}
.video-bx5 .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.video-bx5 .video-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 0;
  animation: pulse-border 1500ms ease-out infinite;
}
.video-bx5 .video-btn i {
  position: relative;
  font-size: 1rem;
  z-index: 2;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.video-bx5 .video-btn:hover {
  background: var(--primary);
}
.video-bx5 .video-btn:hover i {
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .video-bx5 {
    width: 120px;
  }
  .video-bx5 .video-btn {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .video-bx5 {
    width: 100px;
    bottom: 10px;
    right: 10px;
  }
  .video-bx5 .video-btn {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
}

.video-bx6 {
  position: relative;
}
.video-bx6 .title {
  font-size: 70px;
  font-weight: 700;
}
@media only screen and (max-width: 991px) {
  .video-bx6 .title {
    font-size: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .video-bx6 .title {
    font-size: 42px;
    text-align: center;
  }
  .video-bx6 .btn,
  .video-bx6 .wp-block-button__link {
    display: none;
  }
}

.video-btn.style-2 {
  position: relative;
  transform: translate(0%, 0%);
  background: white;
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.video-btn.style-2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 0;
  animation: pulse-border 1500ms ease-out infinite;
}
.video-btn.style-2 i {
  position: relative;
  font-size: 2rem;
  z-index: 2;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.video-btn.style-2:hover {
  background: var(--primary);
}
.video-btn.style-2:hover i {
  color: #fff;
}

.video-bx.style-1 .video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--primary);
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
}
.video-bx.style-1 .video-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: 0;
  animation: pulse-border 1500ms ease-out infinite;
}
.video-bx.style-1 .video-btn i {
  font-size: 25px;
}
@media only screen and (max-width: 1280px) {
  .video-bx.style-1 .video-btn {
    width: 60px;
    height: 60px;
  }
  .video-bx.style-1 .video-btn i {
    font-size: 20px;
  }
}

@keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    opacity: 0.8;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes pulse-border {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  100% {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0;
  }
}

@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
  0% {
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
  100% {
    transform: scale(1.9);
    -moz-transform: scale(1.9);
    -webkit-transform: scale(1.9);
    -ms-transform: scale(1.9);
    -o-transform: scale(1.9);
    opacity: 0;
  }
}

.video-wrapper {
  display: flex;
  align-items: center;
}

.btn-next,
.btn-prev {
  position: static;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 0px;
  color: var(--theme-text-color);
  background: var(--primary);
  font-size: 15px;
  z-index: 1;
  text-align: center;
  margin: 0 10px;
  display: inline-block;
  transform: skewX(-12deg);
  box-shadow: 0px 10px 30px 0px var(--rgba-primary-3);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.btn-next:hover,
.btn-prev:hover {
  background: #000;
  color: #fff;
  box-shadow: 0px 10px 30px 0px rgba(34, 34, 34, 0.2);
}
.btn-next:after,
.btn-prev:after {
  content: none;
}
.btn-next.dark:hover,
.btn-prev.dark:hover {
  background: #fff;
  color: #000;
  box-shadow: 0px 10px 30px 0px rgba(34, 34, 34, 0.2);
}
.btn-next i,
.btn-prev i {
  transform: skewX(12deg);
  font-size: 20px;
  line-height: inherit;
}
@media only screen and (max-width: 1199px) {
  .btn-next,
  .btn-prev {
    width: 35px;
    height: 35px;
    line-height: 35px;
  }
  .btn-next i,
  .btn-prev i {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .btn-next,
  .btn-prev {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .btn-next i,
  .btn-prev i {
    font-size: 15px;
  }
}

.num-pagination {
  display: flex;
  align-items: center;
}
.num-pagination .swiper-pagination {
  width: auto;
}

.swiper-pagination {
  position: static;
  width: auto;
  display: flex;
  align-items: center;
}
.swiper-pagination.style-1 .swiper-pagination-bullet {
  height: auto;
  opacity: 1;
  width: auto;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 0%;
  margin: 0 15px;
  background-color: transparent;
}
.swiper-pagination.style-1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--primary);
  transform: scale(1.7);
  font-weight: 600;
}
.swiper-pagination.style-1.dark {
  color: #fff;
}
.swiper-pagination.style-1.dark .swiper-pagination-bullet {
  color: #fff;
}
.swiper-pagination.style-1.dark .swiper-pagination-bullet.swiper-pagination-bullet-active {
  color: var(--primary);
}
@media only screen and (max-width: 1199px) {
  .swiper-pagination.style-1 .swiper-pagination-bullet {
    margin: 0 10px;
  }
  .swiper-pagination.style-1 .swiper-pagination-bullet.swiper-pagination-bullet-active {
    transform: scale(1.5);
  }
}
.swiper-pagination.style-1.m-lr-lg {
  margin: 0 70px;
}
@media only screen and (max-width: 1199px) {
  .swiper-pagination.style-1.m-lr-lg {
    margin: 0 50px;
  }
}
@media only screen and (max-width: 575px) {
  .swiper-pagination.style-1.m-lr-lg {
    margin: 0 20px;
  }
}


.progress-pagination {
  color: #fff;
  display: flex;
  align-items: center;
}
.progress-pagination .swiper-pagination-progressbar {
  height: 3px;
  width: 100px;
  overflow: hidden;
  position: unset;
  margin: 0 10px;
}
.progress-pagination .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #fff;
}

.service-gallery-swiper .btn-prev,
.service-gallery-swiper .btn-next,
.gallery-swiper .btn-prev,
.gallery-swiper .btn-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) skewX(-12deg);
}

.service-gallery-swiper .btn-prev,
.gallery-swiper .btn-prev {
  left: 0;
}

.service-gallery-swiper .btn-next,
.gallery-swiper .btn-next {
  right: 0;
}

.swiper-btn-lr .btn-prev,
.swiper-btn-lr .btn-next {
  position: absolute;
  top: 50%;
  transform: skewX(-12deg) translateY(-50%);
}
@media only screen and (max-width: 575px) {
  .swiper-btn-lr .btn-prev,
  .swiper-btn-lr .btn-next {
    top: 40%;
  }
}

.swiper-btn-lr .btn-next {
  right: -60px;
}
@media only screen and (max-width: 1280px) {
  .swiper-btn-lr .btn-next {
    right: -10px;
  }
}

.swiper-btn-lr .btn-prev {
  left: -80px;
}
@media only screen and (max-width: 1280px) {
  .swiper-btn-lr .btn-prev {
    left: -10px;
  }
}

.swiper-fade {
  overflow: unset;
}
.swiper-fade .swiper-slide {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.swiper-fade .swiper-slide.swiper-slide-active {
  opacity: 1;
  visibility: visible;
}

.widget {
  margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
  .widget {
    margin-bottom: 35px;
  }
}

.widget-title {
  margin-bottom: 30px;
  padding-bottom: 15px;
  position: relative;
}
@media only screen and (max-width: 1199px) {
  .widget-title {
    margin-bottom: 20px;
  }
}
.widget-title:after,
.widget-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 5px;
  transform: skewX(-20deg);
  background: var(--primary);
}
@media only screen and (max-width: 1480px) {
  .widget-title:after,
  .widget-title:before {
    height: 4px;
  }
}
.widget-title:after {
  left: 0;
  width: 55px;
}
.widget-title:before {
  left: 60px;
  width: 7px;
}
.widget-title .title {
  position: relative;
  margin-bottom: 0px;
  font-size: 1.5rem;
}

.search-bx form {
  margin-bottom: 10px;
}

.search-bx .form-control,
.search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .search-bx select,
.search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .search-bx select {
  border-radius: 0;
  background-color: transparent;
  border: 0;
  z-index: 0 !important;
  padding: 6px 50px 6px 20px;
}

.search-bx .input-group-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

.search-bx .input-skew {
  background: var(--rgba-primary-1);
}

.search-bx button {
  height: 100%;
  width: 60px;
  font-size: 24px;
  padding: 0;
  justify-content: center;
  border-radius: 0;
  transform: skewX(-12deg);
}
.search-bx button i {
  transform: skewX(12deg);
  font-size: 16px;
}

.download-file {
  background-image: var(--gradient);
  color: #fff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px;
}
.download-file .title {
  color: #fff;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}
.download-file ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.download-file ul li a {
  background-color: #fff;
  color: #000;
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.download-file ul li a i {
  width: 18px;
  height: 18px;
  position: relative;
}
.download-file ul li a i:after,
.download-file ul li a i:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}
.download-file ul li a i:before {
  -webkit-animation: download1 2s ease infinite;
  animation: download1 2s ease infinite;
}
.download-file ul li a .text {
  display: inline;
  position: relative;
}
.download-file ul li a:hover .text {
  color: var(--primary);
}

@-webkit-keyframes download1 {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes download1 {
  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.widget_contact {
  position: relative;
  padding: 50px 45px 45px;
  background-size: cover;
  text-align: center;
  position: relative;
  z-index: 1;
  background-color: var(--primary);
  overflow: hidden;
}
.widget_contact:before {
  content: "";
  position: absolute;
  width: 45%;
  height: 65%;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-image: url(../images/background/bg-pricetable-2.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.widget_contact .icon-bx i {
  font-size: 100px;
  color: var(--theme-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100px;
  margin-bottom: 15px;
}
.widget_contact img {
  margin-bottom: 20px;
}
.widget_contact h4,
.widget_contact .h4,
.widget_contact .comments-title,
.widget_contact .blog-title,
.widget_contact .comment-reply-title,
.widget_contact .wp-block-search .wp-block-search__label,
.wp-block-search .widget_contact .wp-block-search__label {
  font-size: 20px;
  font-weight: 600;
  color: var(--theme-text-color);
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}
.widget_contact .phone-number {
  position: relative;
  font-size: 28px;
  color: var(--theme-text-color);
  font-weight: 600;
  margin-bottom: 5px;
}
.widget_contact .phone-number a {
  color: var(--theme-text-color);
}
.widget_contact .email {
  position: relative;
  color: var(--theme-text-color);
  font-weight: 600;
  margin-bottom: 30px;
}
.widget_contact .email a {
  color: var(--theme-text-color);
}

.service-carouse .item {
  overflow: hidden;
  border-radius: 6px;
}

.list-2 {
  display: table;
}
.list-2 li {
  width: 50% !important;
  float: left;
}
@media only screen and (max-width: 1401px) {
  .list-2 li {
    width: 100% !important;
  }
}
@media only screen and (max-width: 991px) {
  .list-2 li {
    width: 50% !important;
  }
}
@media only screen and (max-width: 575px) {
  .list-2 li {
    width: 100% !important;
  }
}

/* widget listing*/
.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}
.wp-block-latest-posts li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu ul li,
.widget_recent_entries ul li,
.widget_services ul li {
  padding-bottom: 1.125rem;
  margin-bottom: 0.8125rem;
  position: relative;
  padding: 0.5rem 0rem 0.5rem 0.9375rem;
  margin-bottom: 0;
  line-height: 1.25rem;
}
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu ul li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
  color: inherit;
  text-transform: capitalize;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.wp-block-latest-posts li a:before,
.wp-block-categories-list li a:before,
.wp-block-archives-list li a:before,
.widget_categories ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before,
.widget_pages ul li a:before,
.widget_recent_comments ul li a:before,
.widget_nav_menu ul li a:before,
.widget_recent_entries ul li a:before,
.widget_services ul li a:before {
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 8px;
  font-size: 14px;
  font-weight: 700;
  color: var(--primary);
}
.wp-block-latest-posts li a:hover,
.wp-block-categories-list li a:hover,
.wp-block-archives-list li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_nav_menu ul li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover {
  color: var(--primary);
}
.wp-block-latest-posts li li,
.wp-block-categories-list li li,
.wp-block-archives-list li li,
.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu ul li li,
.widget_recent_entries ul li li,
.widget_services ul li li {
  border-bottom: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.375rem;
}
.wp-block-latest-posts li li:last-child,
.wp-block-categories-list li li:last-child,
.wp-block-archives-list li li:last-child,
.widget_categories ul li li:last-child,
.widget_archive ul li li:last-child,
.widget_meta ul li li:last-child,
.widget_pages ul li li:last-child,
.widget_recent_comments ul li li:last-child,
.widget_nav_menu ul li li:last-child,
.widget_recent_entries ul li li:last-child,
.widget_services ul li li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.wp-block-latest-posts li li:before,
.wp-block-categories-list li li:before,
.wp-block-archives-list li li:before,
.widget_categories ul li li:before,
.widget_archive ul li li:before,
.widget_meta ul li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_nav_menu ul li li:before,
.widget_recent_entries ul li li:before,
.widget_services ul li li:before {
  top: 0.5rem;
  left: 0;
}
.wp-block-latest-posts ul,
.wp-block-categories-list ul,
.wp-block-archives-list ul,
.widget_categories ul ul,
.widget_archive ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_nav_menu ul ul,
.widget_recent_entries ul ul,
.widget_services ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

/*widget categories*/
.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
  margin-bottom: -0.625rem;
}

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1.2rem !important;
  line-height: 1.3 !important;
}

.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before {
  content: none !important;
}

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
  float: left;
  text-transform: capitalize;
  text-align: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
  padding: 0 !important;
}

.widget_recent_comments ul {
  margin-top: -0.625rem;
}
.widget_recent_comments ul li {
  padding-left: 1.875rem;
  line-height: 1.625rem;
}
.widget_recent_comments ul li a:hover {
  color: inherit;
}
.widget_recent_comments ul li a:before {
  content: none;
}
.widget_recent_comments ul li:before {
  content: "\f27a";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 0.625rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none;
}

.widget_calendar th,
.widget_calendar td {
  text-align: center;
  border: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.4em;
}

.widget_calendar th,
.widget_calendar tfoot td {
  border-color: rgba(0, 0, 0, 0.05);
}
.widget_calendar th:last-child,
.widget_calendar tfoot td:last-child {
  border: 0;
}

.widget_calendar tr {
  border-bottom: 0.0625rem solid var(--rgba-primary-1);
}
.widget_calendar tr:nth-child(2n + 2) {
  background-color: var(--rgba-primary-05);
}

.widget_calendar table {
  border-collapse: collapse;
  margin: 0;
  width: 100%;
}

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
  color: #fff;
  background: var(--primary);
}

.widget_calendar .calendar_wrap td a {
  font-weight: 600;
  text-transform: uppercase;
  color: inherit;
}

.widget_calendar caption {
  text-align: center;
  font-weight: 600;
  caption-side: top;
  background: var(--primary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9375rem;
}

.wp-block-calendar table th {
  font-weight: 400;
  background: var(--primary);
  color: #fff;
}

.wp-block-calendar table caption {
  color: #fff;
}

footer .widget_calendar th,
footer .widget_calendar td {
  border-color: rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}
footer .widget_calendar tr:nth-child(2n + 2) {
  background-color: rgba(255, 255, 255, 0.05);
}

.wp-calendar-nav {
  background: var(--primary);
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.wp-calendar-nav span {
  padding: 0.3125rem 1.25rem;
  width: 44%;
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}
.wp-calendar-nav span a {
  color: #fff;
}

.widget_archive select {
  width: 100%;
  padding: 0.3125rem;
  border: 0.0625rem solid #ccc;
}

.list-2-column ul {
  display: table;
}
.list-2-column ul li {
  width: 50%;
  float: left;
}

.widget_categories select,
.widget_archive select {
  width: 100%;
  border: 0.0625rem solid #ccc;
}

.widget_text select {
  width: 100%;
  border: 0.0625rem solid #ccc;
}
.widget_text select option {
  width: 100%;
}

.widget_text p {
  font-size: 0.9375rem;
  line-height: 1.75rem;
}

/*widget rss  */
.rsswidget img {
  display: inherit;
}

.widget-title .rsswidget {
  display: inline-block;
}

ul a.rsswidget,
ul cite {
  font-weight: 600;
  color: var(--title);
}

ul a.rsswidget {
  font-size: 1.125rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
ul a.rsswidget:hover {
  color: var(--primary);
}

.rssSummary {
  margin: 1.25rem 0;
  line-height: 1.7;
  font-size: 15px;
}

.rss-date {
  display: block;
  margin-top: 0.3125rem;
  font-weight: 400;
}

.widget_rss ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0;
}
.widget_rss ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.widget_gallery ul {
  padding-left: 0;
  display: flex;
  margin-left: -0.3rem;
  margin-right: -0.3rem;
}

.widget_gallery.gallery-grid-2 li {
  width: 50%;
}

.widget_gallery.gallery-grid-4 li {
  width: 25%;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding: 5px;
}
.widget_gallery li img {
  display: inline-block;
  width: 100%;
}
.widget_gallery li:nth-child(3n-3) {
  margin-right: 0;
}
.widget_gallery li:hover {
  opacity: 0.7;
}
.widget_gallery li a {
  display: inline-block;
}

.widget_tag_cloud .tagcloud {
  clear: both;
  display: table;
}
.widget_tag_cloud .tagcloud a {
  position: relative;
  padding: 8px 15px;
  display: inline-block;
  margin: 0 10px 10px 0;
  font-size: 14px;
  color: var(--primary);
  line-height: 1.4;
  border: 0;
  background: var(--rgba-primary-1);
  border-radius: 0;
  transform: skewX(-12deg);
}
@media only screen and (max-width: 1480px) {
  .widget_tag_cloud .tagcloud a {
    padding: 6px 18px;
    margin: 0 10px 10px 0;
  }
}
@media only screen and (max-width: 1199px) {
  .widget_tag_cloud .tagcloud a {
    padding: 6px 14px;
    margin: 0 8px 8px 0;
  }
}
.widget_tag_cloud .tagcloud a:hover {
  box-shadow: 0px 5px 12px var(--rgba-primary-4);
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: #fff;
}
.widget_tag_cloud .tagcloud a span {
  display: inline-block;
  transform: skewX(12deg);
}

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  border-radius: 0.25rem;
  margin-bottom: 20px;
  transition: all 0.5s;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-media {
  width: 92px;
  min-width: 92px;
  margin-right: 15px;
}
@media only screen and (max-width: 1199px) {
  .recent-posts-entry .widget-post-bx .widget-post .dz-media {
    width: 75px;
    min-width: 75px;
  }
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta {
  margin-bottom: 0;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul {
  line-height: 1;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li {
  font-size: 15px;
}
.recent-posts-entry .widget-post-bx .widget-post .title {
  line-height: 1.3;
  font-family: "Poppins";
  margin-bottom: 8px;
  display: block;
  font-weight: 600;
  font-size: 16px;
}
.recent-posts-entry .widget-post-bx .widget-post .post-date {
  font-size: 14px;
}
.recent-posts-entry .widget-post-bx .widget-post:not(:last-child) {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid lightgrey;
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
}

.service_menu_nav {
  background: #222222;
  position: relative;
  padding: 40px 45px;
  z-index: 1;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 767px) {
  .service_menu_nav {
    padding: 40px;
  }
}
.service_menu_nav:after,
.service_menu_nav:before {
  content: "";
  position: absolute;
  height: 50%;
  z-index: -1;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-size: cover;
  background-repeat: no-repeat;
}
.service_menu_nav:after {
  background-image: url(../images/background/bg-pricetable-1.png);
  width: 10%;
  height: 20%;
}
.service_menu_nav:before {
  background-image: url(../images/background/bg-pricetable-2.png);
  width: 40%;
  opacity: 0.2;
}
.service_menu_nav ul li {
  padding: 0;
}
.service_menu_nav ul li a {
  display: block;
  position: relative;
  padding: 15px 0;
  margin-bottom: 10px;
  color: #fff;
  font-size: 18px;
  z-index: 1;
  font-weight: 500;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 767px) {
  .service_menu_nav ul li a {
    padding: 12px 0;
    margin-bottom: 5px;
    font-size: 16px;
  }
}
.service_menu_nav ul li a:before,
.service_menu_nav ul li a:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
  background-color: var(--primary);
  opacity: 0;
  transform: translateX(15px) skewX(-10deg);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.service_menu_nav ul li a:after {
  width: 100%;
  width: calc(100% + 70px);
  right: 0px;
}
@media only screen and (max-width: 767px) {
  .service_menu_nav ul li a:after {
    width: calc(100% + 50px);
  }
}
.service_menu_nav ul li a:before {
  width: 7px;
  right: -15px;
}
.service_menu_nav ul li:hover a,
.service_menu_nav ul li.current-menu-item a,
.service_menu_nav ul li.active a {
  color: var(--theme-text-color);
}
.service_menu_nav ul li:hover a:after,
.service_menu_nav ul li:hover a:before,
.service_menu_nav ul li.current-menu-item a:after,
.service_menu_nav ul li.current-menu-item a:before,
.service_menu_nav ul li.active a:after,
.service_menu_nav ul li.active a:before {
  transform: translateX(0px) skewX(-10deg);
  opacity: 1;
}
.service_menu_nav svg {
  position: absolute;
  bottom: -23px;
  left: -20px;
  transform: rotateY(180deg);
  height: 50px;
  width: 175px;
}

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
  margin-bottom: 20px;
}
.wp-block-categories-dropdown select:after,
.wp-block-archives-dropdown select:after {
  content: "\f078";
  font-weight: 900;
  font-family: "Line Awesome Free";
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
}

.post-tags strong {
  font-weight: 700;
  color: #212529;
  margin-right: 10px;
}

.post-tags a {
  margin-right: 10px;
  display: inline-block;
}

.widget_profile .dz-media {
  width: 100%;
  min-width: 100%;
}
.widget_profile .dz-media img {
  border-radius: var(--border-radius-base);
}

.widget_profile p {
  font-size: 16px;
}

.widget-newslatter .form-control,
.widget-newslatter .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .widget-newslatter select,
.widget-newslatter .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .widget-newslatter select {
  background-color: #f3f4f6;
  border-radius: var(--border-radius);
  border: 0;
}

.widget-newslatter p {
  font-size: 16px;
}

.widget-newslatter button {
  width: 60px;
  height: 60px;
  padding: 0;
  justify-content: center;
}

.widget-newslatter .input-group {
  margin-bottom: 0;
}

.shop-widget,
.widget.style-1 {
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1);
  padding: 35px;
  border-bottom: 4px solid;
  border-color: var(--primary);
}
.shop-widget .search-bx,
.widget.style-1 .search-bx {
  margin-bottom: 0;
}
.shop-widget .search-bx .form-control,
.shop-widget .search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .shop-widget .search-bx select,
.shop-widget .search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .shop-widget .search-bx select,
.widget.style-1 .search-bx .form-control,
.widget.style-1 .search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .widget.style-1 .search-bx select,
.widget.style-1 .search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .widget.style-1 .search-bx select {
  background-color: #f3f4f6;
  box-shadow: unset;
}
.shop-widget .search-bx .input-group,
.widget.style-1 .search-bx .input-group {
  margin-bottom: 0;
}
@media only screen and (max-width: 1280px) {
  .shop-widget,
  .widget.style-1 {
    padding: 25px;
  }
}

.widget_working.style-2 {
  padding: 30px;
  padding-bottom: 15px;
  background: var(--secondary);
}
.widget_working.style-2 ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.widget_working.style-2 ul li {
  width: 50%;
  color: #fff;
  display: inline-block;
  vertical-align: top;
}

.side-bar h1,
.side-bar .h1 {
  font-size: 2rem;
}

.side-bar h2,
.side-bar .h2 {
  font-size: 1.625rem;
}

.side-bar h3,
.side-bar .h3 {
  font-size: 1.5rem;
}

.side-bar h4,
.side-bar .h4,
.side-bar .comments-title,
.side-bar .blog-title,
.side-bar .comment-reply-title,
.side-bar .wp-block-search .wp-block-search__label,
.wp-block-search .side-bar .wp-block-search__label {
  font-size: 1.25rem;
}

.side-bar h5,
.side-bar .h5 {
  font-size: 1.125rem;
}

.side-bar h6,
.side-bar .h6 {
  font-size: 1rem;
}

.sidebar-widget .wp-block-woocommerce-attribute-filter > h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .comments-title,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .blog-title,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .comment-reply-title,
.sidebar-widget .wp-block-search .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.wp-block-search .sidebar-widget .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h6,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h6,
.sidebar-widget .wp-block-group__inner-container > h1,
.sidebar-widget .wp-block-group__inner-container > .h1,
.sidebar-widget .wp-block-group__inner-container > h2,
.sidebar-widget .wp-block-group__inner-container > .h2,
.sidebar-widget .wp-block-group__inner-container > h3,
.sidebar-widget .wp-block-group__inner-container > .h3,
.sidebar-widget .wp-block-group__inner-container > h4,
.sidebar-widget .wp-block-group__inner-container > .h4,
.sidebar-widget .wp-block-group__inner-container > .comments-title,
.sidebar-widget .wp-block-group__inner-container > .blog-title,
.sidebar-widget .wp-block-group__inner-container > .comment-reply-title,
.sidebar-widget .wp-block-search .wp-block-group__inner-container > .wp-block-search__label,
.wp-block-search .sidebar-widget .wp-block-group__inner-container > .wp-block-search__label,
.sidebar-widget .wp-block-group__inner-container > h5,
.sidebar-widget .wp-block-group__inner-container > .h5,
.sidebar-widget .wp-block-group__inner-container > h6,
.sidebar-widget .wp-block-group__inner-container > .h6,
.shop-widget .wp-block-woocommerce-attribute-filter > h1,
.shop-widget .wp-block-woocommerce-attribute-filter > .h1,
.shop-widget .wp-block-woocommerce-attribute-filter > h2,
.shop-widget .wp-block-woocommerce-attribute-filter > .h2,
.shop-widget .wp-block-woocommerce-attribute-filter > h3,
.shop-widget .wp-block-woocommerce-attribute-filter > .h3,
.shop-widget .wp-block-woocommerce-attribute-filter > h4,
.shop-widget .wp-block-woocommerce-attribute-filter > .h4,
.shop-widget .wp-block-woocommerce-attribute-filter > .comments-title,
.shop-widget .wp-block-woocommerce-attribute-filter > .blog-title,
.shop-widget .wp-block-woocommerce-attribute-filter > .comment-reply-title,
.shop-widget .wp-block-search .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.wp-block-search .shop-widget .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.shop-widget .wp-block-woocommerce-attribute-filter > h5,
.shop-widget .wp-block-woocommerce-attribute-filter > .h5,
.shop-widget .wp-block-woocommerce-attribute-filter > h6,
.shop-widget .wp-block-woocommerce-attribute-filter > .h6,
.shop-widget .wp-block-group__inner-container > h1,
.shop-widget .wp-block-group__inner-container > .h1,
.shop-widget .wp-block-group__inner-container > h2,
.shop-widget .wp-block-group__inner-container > .h2,
.shop-widget .wp-block-group__inner-container > h3,
.shop-widget .wp-block-group__inner-container > .h3,
.shop-widget .wp-block-group__inner-container > h4,
.shop-widget .wp-block-group__inner-container > .h4,
.shop-widget .wp-block-group__inner-container > .comments-title,
.shop-widget .wp-block-group__inner-container > .blog-title,
.shop-widget .wp-block-group__inner-container > .comment-reply-title,
.shop-widget .wp-block-search .wp-block-group__inner-container > .wp-block-search__label,
.wp-block-search .shop-widget .wp-block-group__inner-container > .wp-block-search__label,
.shop-widget .wp-block-group__inner-container > h5,
.shop-widget .wp-block-group__inner-container > .h5,
.shop-widget .wp-block-group__inner-container > h6,
.shop-widget .wp-block-group__inner-container > .h6 {
  position: relative;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.comments-title,
.blog-title,
.comment-reply-title,
.wp-block-search .wp-block-search__label {
  color: var(--title);
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 30px;
}

.wp-block-group:where(.has-background) {
  padding: 1.25em 2.375em;
}

.widget_working ul li {
  display: block;
  margin-bottom: 15px;
}
.widget_working ul li .time {
  font-weight: 600;
  color: var(--primary);
  display: block;
}

.widget_getintuch ul li {
  position: relative;
  margin-bottom: 30px;
  padding-left: 45px;
  color: #fff;
}
@media only screen and (max-width: 1280px) {
  .widget_getintuch ul li {
    padding-left: 35px;
  }
}
@media only screen and (max-width: 1199px) {
  .widget_getintuch ul li {
    margin-bottom: 25px;
    padding-left: 35px;
  }
}
.widget_getintuch ul li h5,
.widget_getintuch ul li .h5 {
  font-size: 20px;
  line-height: 1.33;
  margin-bottom: 5px;
}
.widget_getintuch ul li i {
  position: absolute;
  font-size: 24px;
  line-height: 1;
  left: 0;
  top: 2px;
  color: var(--primary);
}
@media only screen and (max-width: 575px) {
  .widget_getintuch ul li i {
    font-size: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .widget_getintuch ul li p {
    font-size: 14px;
  }
}

.inquiry-modal {
  padding: 0 15px;
}
.inquiry-modal .modal-dialog {
  max-width: 700px;
  display: flex;
  min-height: auto;
  background-color: #fff;
  align-items: stretch;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
.inquiry-modal .inquiry-adv {
  flex: 0 0 50%;
  max-width: 50%;
}
@media only screen and (max-width: 767px) {
  .inquiry-modal .inquiry-adv {
    display: none;
  }
}
.inquiry-modal .inquiry-adv img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.inquiry-modal .modal-content {
  border: 0;
  border-radius: 0;
  background: #fff;
  padding: 30px;
  flex: 0 0 50%;
  max-width: 50%;
}
@media only screen and (max-width: 767px) {
  .inquiry-modal .modal-content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px;
  }
}
.inquiry-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  display: block;
  text-align: center;
}
.inquiry-modal .modal-content .modal-header .modal-title {
  font-size: 24px;
  margin-bottom: 25px;
  line-height: 1.3;
  font-family: var(--font-family-title);
  text-align: center;
  width: 100%;
}
.inquiry-modal .modal-content .modal-header i {
  color: var(--primary);
  font-size: 54px;
  line-height: 1;
  display: inline-block;
}
.inquiry-modal .modal-content .modal-body {
  padding: 0;
}
.inquiry-modal .btn-close {
  margin: 0;
  position: absolute;
  right: 5px;
  top: 0px;
  color: #000;
  font-weight: 100;
  text-shadow: none;
  opacity: 1;
  font-size: 40px;
  padding: 0;
  height: 40px;
  line-height: 40px;
  width: 40px;
  border: 0;
  background: transparent;
}

.subscribe-modal .modal-content {
  min-width: 536px;
  display: flex;
  background-color: #fff;
  align-items: stretch;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
.subscribe-modal .modal-content .modal-body {
  padding: 50px 40px;
}
.subscribe-modal .modal-content .modal-body .icon-box {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 35px;
}
.subscribe-modal .modal-content .modal-body .icon-box.bg-shape:after {
  content: "";
  position: absolute;
  background-color: var(--primary);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: -1;
}
.subscribe-modal .modal-content .modal-body .dz-title {
  font-size: 26px;
  font-weight: 600;
  color: #242424;
  margin-bottom: 15px;
}
.subscribe-modal .modal-content .modal-body p {
  color: rgba(0, 0, 0, 0.6);
  max-width: 450px;
  margin: 0 auto 25px;
}
.subscribe-modal .modal-content .modal-body .dzSubscribe .dzFormMsg,
.subscribe-modal .modal-content .modal-body .dzSubscribe .dzSubscribeMsg {
  position: static;
  margin: 0 auto;
}
.subscribe-modal .modal-content .modal-body .dzSubscribe.dz-ajax-overlay:after {
  transform: none;
  height: 40%;
  background-color: var(--secondary);
}
.subscribe-modal .modal-content .modal-body .input-group .form-control,
.subscribe-modal .modal-content .modal-body .input-group .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .subscribe-modal .modal-content .modal-body .input-group select,
.subscribe-modal .modal-content .modal-body .input-group .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .subscribe-modal .modal-content .modal-body .input-group select {
  margin-bottom: 30px;
  color: #242424;
  width: 100%;
  border-radius: 0;
  height: 60px;
  border-color: #b3b3b3;
}
.subscribe-modal .modal-content .modal-body .input-group .form-control::placeholder,
.subscribe-modal .modal-content .modal-body .input-group .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .subscribe-modal .modal-content .modal-body .input-group select::placeholder,
.subscribe-modal .modal-content .modal-body .input-group .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .subscribe-modal .modal-content .modal-body .input-group select::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.subscribe-modal .modal-content .modal-body .input-group .form-control:focus,
.subscribe-modal .modal-content .modal-body .input-group .wp-block-categories-dropdown select:focus,
.wp-block-categories-dropdown .subscribe-modal .modal-content .modal-body .input-group select:focus,
.subscribe-modal .modal-content .modal-body .input-group .wp-block-archives-dropdown select:focus,
.wp-block-archives-dropdown .subscribe-modal .modal-content .modal-body .input-group select:focus {
  border-color: var(--primary);
}
.subscribe-modal .modal-content .modal-body .btn,
.subscribe-modal .modal-content .modal-body .wp-block-button__link {
  box-shadow: 0px 4px 44px 0px rgba(6, 198, 209, 0.33);
}
.subscribe-modal .modal-content .modal-body .pattern1 {
  position: absolute;
  top: 15px;
  right: 0;
  width: 100px;
  height: 100px;
  opacity: 0.5;
  animation: move4 5s infinite;
}
.subscribe-modal .modal-content .modal-body .pattern2 {
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100px;
  height: 100px;
  opacity: 0.5;
  animation: move4 3.5s infinite;
}
@media only screen and (max-width: 575px) {
  .subscribe-modal .modal-content {
    min-width: 300px;
  }
  .subscribe-modal .modal-content .modal-body {
    padding: 40px 20px;
  }
  .subscribe-modal .modal-content .modal-body .icon-box img {
    width: 50px;
    height: 50px;
  }
  .subscribe-modal .modal-content .modal-body .icon-box.bg-shape:after {
    width: 85px;
    height: 85px;
  }
  .subscribe-modal .modal-content .modal-body .dz-title {
    font-size: 20px;
  }
  .subscribe-modal .modal-content .modal-body p {
    margin: 0 auto 16px;
    font-size: 14px;
    max-width: 460px;
  }
  .subscribe-modal .modal-content .modal-body .input-group .form-control,
  .subscribe-modal .modal-content .modal-body .input-group .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .subscribe-modal .modal-content .modal-body .input-group select,
  .subscribe-modal .modal-content .modal-body .input-group .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .subscribe-modal .modal-content .modal-body .input-group select {
    margin-bottom: 20px;
    height: 45px;
  }
  .subscribe-modal .modal-content .modal-body .pattern1,
  .subscribe-modal .modal-content .modal-body .pattern2 {
    width: 50px;
    height: 50px;
  }
}

.dzmove1 {
  animation: dzMove1 5s linear infinite;
}

@keyframes dzMove1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  50% {
    -webkit-transform: translate(25px, 25px);
    transform: translate(25px, 25px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.dzmove2 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite;
}

@-webkit-keyframes dzmove2 {
  0% {
    -webkit-transform: rotate(-25deg) translate(-50px, -50px);
    transform: rotate(-25deg) translate(-50px, -50px);
  }
  50% {
    -webkit-transform: rotate(25deg) translate(50px, 50px);
    transform: rotate(25deg) translate(50px, 50px);
  }
  100% {
    -webkit-transform: rotate(-25deg) translate(-50px, -50px);
    transform: rotate(-25deg) translate(-50px, -50px);
  }
}

.dzheart {
  animation: dzHeart 1s linear infinite;
}

@keyframes dzHeart {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}

.nav-tabs.style-1 {
  border: 0;
  background: var(--gradient);
  position: relative;
  padding: 5px 7px;
  margin-left: 20px;
  display: inline-flex;
  transform: skewX(-10deg);
  box-shadow: 0px 10px 15px 0px rgba(255, 129, 57, 0.15);
}
.nav-tabs.style-1:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 8px;
  left: -18px;
  top: 0;
  background: var(--primary-dark);
}
.nav-tabs.style-1 .nav-link {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  border-radius: 0;
  line-height: 25px;
  padding: 10px 25px;
  border: none;
  color: var(--theme-text-color);
  isolation: unset;
}
.nav-tabs.style-1 .nav-link span {
  transform: skewX(10deg);
  display: block;
}
.nav-tabs.style-1 .nav-link.active {
  color: #000;
}
@media only screen and (max-width: 1280px) {
  .nav-tabs.style-1 .nav-link {
    line-height: 1.1;
    padding: 10px 19px;
  }
}
@media only screen and (max-width: 991px) {
  .nav-tabs.style-1 {
    margin-left: 17px;
  }
  .nav-tabs.style-1:after {
    left: -15px;
  }
  .nav-tabs.style-1 .nav-link {
    font-size: 14px;
    padding: 10px 16px;
  }
}
.nav-tabs.style-1.dark {
  background: var(--secondary);
  box-shadow: none;
}
.nav-tabs.style-1.dark .nav-link {
  color: #fff;
}
.nav-tabs.style-1.dark .nav-link.active {
  color: #000;
}
.nav-tabs.style-1.dark:after {
  background: var(--secondary);
}

.checkbox-info .form-check-input:focus {
  border-color: #00aeff;
  outline: 0;
}

.checkbox-info .form-check-input:checked {
  background-color: #00aeff;
  border-color: #00aeff;
}

.checkbox-danger .form-check-input:focus {
  border-color: #ff0003;
  outline: 0;
}

.checkbox-danger .form-check-input:checked {
  background-color: #ff0003;
  border-color: #ff0003;
}

.checkbox-success .form-check-input:focus {
  border-color: #13c24d;
  outline: 0;
}

.checkbox-success .form-check-input:checked {
  background-color: #13c24d;
  border-color: #13c24d;
}

.checkbox-warning .form-check-input:focus {
  border-color: #ffbd13;
  outline: 0;
}

.checkbox-warning .form-check-input:checked {
  background-color: #ffbd13;
  border-color: #ffbd13;
}

.checkbox-secondary .form-check-input:focus {
  border-color: #232323;
  outline: 0;
}

.checkbox-secondary .form-check-input:checked {
  background-color: #232323;
  border-color: #232323;
}

.form-check-input:checked {
  background-color: var(--primary);
}

.dz-social-icon ul li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}
.dz-social-icon ul li a {
  font-size: 18px;
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-social-icon ul li a:hover {
  color: var(--primary);
}
.dz-social-icon ul li:first-child {
  padding-left: 0;
  margin-left: 0;
}

.dz-social-icon.dark ul li a {
  color: #222222;
}

.dz-social-icon.style-1 ul li {
  display: inline-block;
}
.dz-social-icon.style-1 ul li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  font-size: 16px;
  text-align: center;
  background-color: var(--primary);
  color: var(--theme-text-color);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-social-icon.style-1 ul li a:hover {
  background-color: #232323;
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .dz-social-icon.style-1 ul li a {
    height: 32px;
    width: 32px;
    min-width: 32px;
    line-height: 32px;
    font-size: 14px;
  }
}

.dz-social-icon.style-1.dark ul li a:hover {
  background-color: #fff;
  color: #000;
}

.call-action {
  position: relative;
  z-index: unset;
}
.call-action.style-1 .inner-content {
  text-align: center;
  background: var(--rgba-primary-5);
  margin: 0 -65px;
  padding: 60px 60px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: 0px 30px 65px 0px rgba(0, 0, 0, 0.1);
}
.call-action.style-1 .inner-content:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--primary);
  left: 0;
  bottom: 0;
  z-index: -1;
  transform: skewY(-2.5deg);
}
.call-action.style-1 .inner-content .title {
  color: var(--theme-text-color);
}
@media only screen and (max-width: 575px) {
  .call-action.style-1 .inner-content .title {
    font-weight: 600;
    font-size: 24px;
  }
}
.call-action.style-1 .inner-content p {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--theme-text-color);
  text-transform: capitalize;
}
@media only screen and (max-width: 1280px) {
  .call-action.style-1 .inner-content p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .call-action.style-1 .inner-content p {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.call-action.style-1 .inner-content .form-control,
.call-action.style-1 .inner-content .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .call-action.style-1 .inner-content select,
.call-action.style-1 .inner-content .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .call-action.style-1 .inner-content select {
  border: 0;
  border-radius: 0;
  height: 100%;
}
@media only screen and (max-width: 1280px) {
  .call-action.style-1 .inner-content {
    margin: 0;
  }
}
@media only screen and (max-width: 991px) {
  .call-action.style-1 .inner-content {
    padding: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .call-action.style-1 .inner-content {
    padding: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .call-action.style-1 .inner-content {
    padding: 30px;
  }
  .call-action.style-1 .inner-content .input-group-addon {
    width: 100%;
  }
  .call-action.style-1 .inner-content .input-skew {
    width: 100%;
    margin: 0 10px 15px;
  }
}
.call-action.footer-action {
  margin-bottom: -100px;
  position: relative;
  z-index: 9;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 0.9;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #dae2f3;
  width: 4px;
}

.ps__thumb-y {
  background-color: #dae2f3;
  width: 4px;
}

.counter-inner-1 {
  padding: 80px 60px 50px;
  position: relative;
  z-index: 1;
  margin: 0 -75px;
}
.counter-inner-1 .counter-box {
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.counter-inner-1 .counter-box .title {
  margin: 0;
  color: #06c6d1;
  font-size: 80px;
  font-weight: 700;
}
.counter-inner-1 .counter-box p {
  margin: 0;
  color: #9a69f9;
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  .counter-inner-1 .counter-box p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 575px) {
  .counter-inner-1 .counter-box {
    display: block;
    text-align: center;
  }
}
.counter-inner-1 .triangle1 {
  position: absolute;
  top: 0;
  left: 2px;
  transform: translateY(-72%) rotate(8.5deg);
}
.counter-inner-1 .triangle2 {
  position: absolute;
  bottom: 0;
  right: 2px;
  transform: translateY(72%) rotate(8.5deg);
}
@media only screen and (max-width: 1280px) {
  .counter-inner-1 {
    padding: 50px 40px 20px;
    margin: 0;
  }
  .counter-inner-1 .counter-box .title {
    font-size: 60px;
  }
  .counter-inner-1 .counter-box p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 991px) {
  .counter-inner-1 .counter-box .title {
    font-size: 48px;
  }
  .counter-inner-1 .counter-box p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .counter-inner-1 {
    padding: 40px 30px 10px;
  }
  .counter-inner-1 .triangle1 {
    top: 10px;
    left: 1px;
    width: 120px;
  }
  .counter-inner-1 .triangle2 {
    bottom: 9px;
    right: 1px;
    width: 120px;
  }
}
@media only screen and (max-width: 575px) {
  .counter-inner-1 {
    padding: 30px 30px 0px;
  }
  .counter-inner-1 .counter-box .title {
    font-size: 42px;
  }
  .counter-inner-1 .counter-box p {
    font-size: 15px;
  }
}

.counter-inner-2 {
  text-align: center;
}
.counter-inner-2 .title,
.counter-inner-2 span {
  font-size: 80px;
  font-family: "Poppins", sans-serif;
  display: flex;
  font-weight: 700;
  line-height: 1.2;
  justify-content: center;
  color: var(--primary);
  margin-bottom: 0;
}
.counter-inner-2 p {
  font-size: 18px;
  font-weight: 600;
}
@media only screen and (max-width: 1480px) {
  .counter-inner-2 .title,
  .counter-inner-2 span {
    font-size: 60px;
  }
  .counter-inner-2 p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .counter-inner-2 .title,
  .counter-inner-2 span {
    font-size: 45px;
  }
}
@media only screen and (max-width: 767px) {
  .counter-inner-2 .title,
  .counter-inner-2 span {
    font-size: 35px;
  }
}

.counter-inner-3 {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 25px 70px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6px);
  padding: 36px 0;
  margin-top: -80px;
  z-index: 9;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .counter-inner-3 {
    padding: 20px 0;
  }
}
@media only screen and (max-width: 767px) {
  .counter-inner-3 {
    margin-top: -15px;
  }
}
@media only screen and (max-width: 575px) {
  .counter-inner-3 {
    margin-left: 0;
    margin-right: 0;
  }
}
.counter-inner-3 .counter-box {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 575px) {
  .counter-inner-3 .counter-box {
    border-right: 0;
  }
}
.counter-inner-3 .counter-box .title {
  font-weight: 700;
  font-size: 85px;
  color: var(--primary);
  margin: 0;
}
@media only screen and (max-width: 991px) {
  .counter-inner-3 .counter-box .title {
    font-size: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .counter-inner-3 .counter-box .title {
    font-size: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .counter-inner-3 .counter-box .title {
    font-size: 40px;
  }
}
.counter-inner-3 .counter-box p {
  color: #232323;
  font-weight: 500;
}
@media only screen and (max-width: 575px) {
  .counter-inner-3 .counter-box p {
    font-size: 14px;
  }
}

.process-box.style-1 {
  padding: 30px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.process-box.style-1 .dz-info {
  display: none;
}
.Mental-Box{
  background-color: #F2F2F2;
  padding: 1rem;
  border-left: .5rem solid #5C5C5C;
  margin: 1rem 0 1rem 0;
}
.process-box.style-1:hover {
  display: inline-block;
}
.process-box.style-1 .step {
  font-weight: 275;
  font-size: 60px;
  color: #232323;
  line-height: unset;
  position: relative;
  z-index: 10;
}
.process-box.style-1 .step span {
  font-size: 1rem;
  color: var(--primary);
  font-weight: 500;
}
.process-box.style-1 .dz-title {
  font-size: 30px;
  font-weight: 500 !important;
  color: #232323;
  margin: 0;
}
.Share-link{
  margin: 4rem;
}
.Share-link h5{
  font-size: 1.2rem;
} 
.social-icons a {
  margin-right: 15px;
  /* Adds a gap between icons */
}

.social-icons a:last-child {
  margin-right: 0;
  /* Removes the margin for the last icon */
}
.dz-press{
  font-size: 25px;
  font-weight: 400 !important;

}
.process-box.style-1 .descripiton {
  font-size: 1rem;
  font-weight: 300;
  color: #666666;
  margin-bottom: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .process-box.style-1 .step {
    font-weight: 500;
  }
  .process-box.style-1 .dz-title {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0.4rem;
  }
  .process-box.style-1 .descripiton {
    font-size: 14px;
  }
}
@media only screen and (max-width: 575px) {
  .process-box.style-1 {
    padding: 15px 0 30px;
  }
}

.dz-box.style-3 .dz-info {
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  padding: 20px;
}

.image-tooltip.style-2 {
  padding: 0 !important;
}

.main-bnr-one {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}
@media only screen and (max-width: 1480px) {
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
.main-bnr-one .banner-content {
  padding-top: 80px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
  z-index: 2;
}
.main-bnr-one .banner-content .title {
  line-height: 1.2;
  font-size: 60px;
  font-weight: 700;
  position: relative;
  padding-left: 30px;
  margin-bottom: 20px;
}
.main-bnr-one .banner-content .title:after {
  content: "";
  background-color: var(--primary);
  border-radius: 10px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 85%;
  z-index: 1;
  width: 10px;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-one .banner-content .title {
    font-size: 52px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-content .title {
    font-size: 42px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-content .title {
    font-size: 32px;
    padding-left: 25px;
    margin-bottom: 15px;
  }
  .main-bnr-one .banner-content .title:after {
    width: 5px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-content .title {
    font-size: 25px;
    padding-left: 15px;
    margin-bottom: 10px;
    margin-left: 25px;
  }
}
.main-bnr-one .banner-content p {
  font-size: 18px;
  margin-bottom: 45px;
  color: #444444;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-content p {
    font-size: 15px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-content p {
    font-size: 14px;
    margin-bottom: 20px;
  }
}
.main-bnr-one .banner-content .btn,
.main-bnr-one .banner-content .wp-block-button__link {
  margin-right: 30px;
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-content .btn,
  .main-bnr-one .banner-content .wp-block-button__link {
    margin-right: 25px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-content {
    padding-top: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-one .banner-content {
    padding-bottom: 30px;
  }
}
.main-bnr-one .point-list li {
  position: absolute;
}



.point-list li:nth-child(1) span {
  border-radius: 30px 30px 0 30px;
  bottom: 0;
  left: 0;
  transform: translate(-100%, -100%) scale(0);
  transform-origin: right bottom;
}

.point-list li:nth-child(1).show span {
  transform: translate(-103%, -24%) scale(1);
}

.point-list li:nth-child(2) span {
  border-radius: 30px 30px 30px 0px;
  bottom: 0;
  left: 0;
  transform: translate(-100%, -100%) scale(0);
  transform-origin: right bottom;
}

.point-list li:nth-child(2).show span {
  transform: translate(14%, -53%) scale(1)
}

.main-bnr-one .point-list li i {
  height: 50px;
  width: 50px;
  font-size: 20px;
  border-radius: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  z-index: 1;
  position: relative;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: var(--primary);
  color: var(--theme-text-color);
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .point-list li i {
    height: 35px;
    width: 35px;
    font-size: 12px;
    line-height: 35px;
  }
}
.main-bnr-one .point-list li.show i {
  background-color: #000;
  color: #fff;
}
.main-bnr-one .point-list li.show i:before {
  content: "\f068";
}
.main-bnr-one .point-list li:after,
.main-bnr-one .point-list li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 100%;
  border-radius: 100%;
  display: block;
  /* border: 1px solid #fff; */
}

.main-bnr-one .point-list li:after {
  animation: fadeup 2.5s ease infinite;
}
.main-bnr-one .point-list li:before {
  animation: fadeup2 2.5s ease infinite;
}
.main-bnr-one .point-list li span {
  position: absolute;
  padding: 16px 27px;
  background: white;
  white-space: nowrap;
  line-height: 1.2;
  box-shadow: 0px 10px 30px 0px rgba(253, 114, 74, 0.1);
  font-size: 18px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .point-list li span {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .point-list li span {
    font-size: 14px;
    padding: 14px 20px;
  }
}
.slick-prev{
  display: none !important;
}
.slick-prev:before {
  content: none !important;
}
.slick-next{
  display: none !important;
}
.slick-next:before {
  content: none !important;
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .point-list {
    display: none;
  }
  /* .main-bnr-one .point-list li i{
    position:"abosulte";
    right:100%;
  } */
  
}
.main-bnr-one .banner-media {
  text-align: center;
  z-index: 2;
  position: relative;
}
.main-bnr-one .media1 {
  max-width: 1000px;
  margin-left: 40px;
  margin-top: -50px;
  position: absolute;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-one .media1 {
    max-width: 1000px;
    margin-left: 30px;
    margin-top: -20px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .media1 {
    max-width: 1000px;
    margin-top: 0px;
    margin-left: 0;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .media1 {
    max-width: 1000px;
    margin-left: -20px;
    margin-top: 30px;
    position: relative;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-one .media1 {
    margin-top: 20px;
    max-width: 200%;
    min-width: 200%;
    margin-left: -60px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .media1 {
    margin-top: 0px;
    max-width: 100%;
    min-width: 120%;
    z-index: 0;
    margin-left: 0;
  }
}
.main-bnr-one .media1 .main-img {
  height: auto;
  width: auto;
  left: 0;
}
.main-bnr-one .media1 .main-img1 {
  height: 35rem;
  width: 60rem;
  left: 0;
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .media1 .main-img,
  .fourth-slider-img {
    width: 100%;
    margin-left: -60rem;
    object-fit: cover;
  }
}
@media only screen and (max-width: 985px) and (min-width: 570px) {
  .main-bnr-one .media1 .main-img,
  .fourth-slider-img .first-slide-image {
    width: 100%;
    object-fit: cover;
    height: 16rem !important;
    margin-left: 1rem;
    margin-top: 7rem;
  }
}

.main-bnr-one .media1 .point-list li:nth-child(1) {
  top: 72dvh;
  left: 30%;
}
.main-bnr-one .media1 .point-list li:nth-child(1) span {
  top: 0;
  left: 100%;
  transform: translateY(-100%) scale(0);
  border-radius: 30px 30px 30px 0px;
  transform-origin: left bottom;
}
.main-bnr-one .media1 .point-list li:nth-child(1).show span {
  transform: translateY(-100%) scale(1);
}
.main-bnr-one .media1 .point-list li:nth-child(2) {
  top: 37%;
  left: 2%;
}
.main-bnr-one .media1 .point-list li:nth-child(2) span {
  transform: translate(-100%, -100%) scale(0);
  bottom: 0;
  left: 0;
  border-radius: 30px 30px 0px 30px;
  transform-origin: right bottom;
}
.main-bnr-one .media1 .point-list li:nth-child(2).show span {
  transform: translate(-100%, -100%) scale(1);
}
.main-bnr-one .media1 .point-list li:nth-child(3) {
  top: 50%;
  left: 42%;
}
.main-bnr-one .media1 .point-list li:nth-child(3) span {
  top: 0;
  left: 100%;
  transform: translateY(-100%) scale(0);
  border-radius: 30px 30px 30px 0px;
  transform-origin: left bottom;
}
.main-bnr-one .media1 .point-list li:nth-child(3).show span {
  transform: translateY(-100%) scale(1);
}
.main-bnr-one .media2 {
  width: 1000px;
  margin-left: -80px;
}
@media only screen and (max-width: 1680px) {
  .main-bnr-one .media2 {
    width: 880px;
    margin-top: 100px;
  }
}
@media only screen and (max-width: 1480px) {
  .main-bnr-one .media2 {
    width: 820px;
    margin-left: -120px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .media2 {
    width: 700px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .media2 {
    width: 450px;
    margin-top: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-one .media2 {
    width: 320px;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 50px;
  }
}
.main-bnr-one .media2 .point-list li:nth-child(1) {
  top: 12%;
  left: 60%;
}
.main-bnr-one .media2 .point-list li:nth-child(1) span {
  top: 0;
  left: 100%;
  transform: translateY(-100%) scale(0);
  border-radius: 30px 30px 30px 0px;
  transform-origin: left bottom;
}
.main-bnr-one .media2 .point-list li:nth-child(1).show span {
  transform: translateY(-100%) scale(1);
}
.main-bnr-one .media2 .point-list li:nth-child(2) {
  top: 45%;
  left: 25%;
}
.main-bnr-one .media2 .point-list li:nth-child(2) span {
  transform: translate(-100%, -100%) scale(0);
  bottom: 0;
  left: 0;
  border-radius: 30px 30px 0px 30px;
  transform-origin: right bottom;
}
.main-bnr-one .media2 .point-list li:nth-child(2).show span {
  transform: translate(-100%, -100%) scale(1);
}
.main-bnr-one .media2 .point-list li:nth-child(3) {
  top: 62%;
  left: 62%;
}
.main-bnr-one .media2 .point-list li:nth-child(3) span {
  top: 0;
  left: 100%;
  transform: translateY(-100%) scale(0);
  border-radius: 30px 30px 30px 0px;
  transform-origin: left bottom;
}
.main-bnr-one .media2 .point-list li:nth-child(3).show span {
  transform: translateY(-100%) scale(1);
}
.main-bnr-one .media3 {
  max-width: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.main-bnr-one .top-content {
  margin-bottom: auto;
  padding-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .top-content {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .top-content {
    padding-right: 70px;
    margin-top: -120px;
  }
}
.main-bnr-one .bottom-content {
  margin-top: auto;
  margin-bottom: 0;
  width: 100%;
  padding-bottom: 120px;
}
.main-bnr-one .bottom-content .partner-title {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 10px;
}
.main-bnr-one .bottom-content .partner-title:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  height: 3px;
  width: 50px;
  background: var(--primary);
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .bottom-content {
    display: none;
  }
}
.main-bnr-one .main-btn {
  position: absolute;
  top: 50%;
  z-index: 1;
}
.main-bnr-one .main-btn i {
  height: 90px;
  width: 90px;
  text-align: center;
  line-height: 90px;
  background-color: #fff;
  z-index: 1;
  color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.main-bnr-one .main-btn i:before {
  display: block;
  position: absolute;
  font-size: 24px;
}
.main-bnr-one .main-btn:before,
.main-bnr-one .main-btn:after {
  content: "";
  position: absolute;
  z-index: -1;
}
.main-bnr-one .main-btn:before {
  height: 80px;
  width: 80px;
  background-color: var(--rgba-primary-2);
  top: -10px;
}
.main-bnr-one .main-btn:after {
  height: 50px;
  width: 50px;
  top: -20px;
  background-color: var(--rgba-primary-1);
}
.main-bnr-one .main-btn.main-btn-prev {
  left: -48px;
  transform: translateY(-50%) rotate(45deg);
}
.main-bnr-one .main-btn.main-btn-prev:before {
  right: -10px;
}
.main-bnr-one .main-btn.main-btn-prev:after {
  right: -20px;
}
.main-bnr-one .main-btn.main-btn-prev i:before {
  right: 25px;
  top: -10px;
  transform: rotate(-45deg);
}
.main-bnr-one .main-btn.main-btn-next {
  right: -48px;
  transform: translateY(-50%) rotate(-45deg);
}
.main-bnr-one .main-btn.main-btn-next:before {
  left: -10px;
}
.main-bnr-one .main-btn.main-btn-next:after {
  left: -20px;
}
.main-bnr-one .main-btn.main-btn-next i:before {
  left: 25px;
  top: -10px;
  transform: rotate(45deg);
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .main-btn {
    top: 62%;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .main-btn {
    top: 50%;
  }
  .main-bnr-one .main-btn i {
    height: 75px;
    width: 75px;
    line-height: 75px;
  }
  .main-bnr-one .main-btn i:before {
    font-size: 18px;
  }
  .main-bnr-one .main-btn:before {
    height: 65px;
    width: 65px;
  }
  .main-bnr-one .main-btn:after {
    height: 35px;
    width: 35px;
  }
  .main-bnr-one .main-btn.main-btn-prev i:before {
    right: 15px;
    top: -15px;
  }
  .main-bnr-one .main-btn.main-btn-next i:before {
    left: 15px;
    top: -15px;
  }
}
.main-bnr-one .main-btn:hover i {
  background-color: var(--primary);
  color: #fff;
}
.main-bnr-one .main-slider {
  overflow: unset;
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-row {
    flex-direction: column-reverse;
  }
}
.main-bnr-one .banner-inner {
  display: flex;
  min-height: 980px;
  padding-top: 180px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom left;
  /* @include respond('phone-land'){
			padding-top: 120px;
			padding-bottom: 0;
			height: 700px;
		} */
}
.main-bnr-one .banner-inner .row {
  height: 100%;
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-inner .row {
    align-items: center;
  }
}
.main-bnr-one .banner-inner .data-text {
  z-index: 1;
  color: #fff;
  line-height: 1.3;
  font-weight: 700;
  font-size: 245px;
  opacity: 0.4;
  display: flex;
  font-family: var(--font-family-title);
  filter: drop-shadow(0px 30px 50px rgba(255, 129, 57, 0.15));
  position: absolute;
  left: 20px;
  bottom: 100px;
}
.main-bnr-one .banner-inner .data-text span:nth-child(1) {
  animation: animateBGTextBnr 3s infinite 0.2s ease-in-out;
}
.main-bnr-one .banner-inner .data-text span:nth-child(2) {
  animation: animateBGTextBnr 3s infinite 0.4s ease-in-out;
}
.main-bnr-one .banner-inner .data-text span:nth-child(3) {
  animation: animateBGTextBnr 3s infinite 0.6s ease-in-out;
}
.main-bnr-one .banner-inner .data-text span:nth-child(4) {
  animation: animateBGTextBnr 3s infinite 0.8s ease-in-out;
}
.main-bnr-one .banner-inner .data-text span:nth-child(5) {
  animation: animateBGTextBnr 3s infinite 1s ease-in-out;
}
.main-bnr-one .banner-inner .data-text span:nth-child(6) {
  animation: animateBGTextBnr 3s infinite 1.2s ease-in-out;
}
.main-bnr-one .banner-inner .data-text span:nth-child(7) {
  animation: animateBGTextBnr 3s infinite 1.4s ease-in-out;
}
.main-bnr-one .banner-inner .data-text.style-2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 330px;
  line-height: 330px;
  text-transform: capitalize;
  background: linear-gradient(180.27deg, #fff 22.87%, rgba(255, 255, 255, 0) 75.03%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.5);
  background-clip: text !important;
  top: 20%;
  left: 0;
  bottom: auto;
  text-shadow: 0px -15px 50px rgba(152, 54, 0, 0.1);
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 1;
}
.main-bnr-one .banner-inner .data-text.style-2 span {
  animation: none;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-one .banner-inner .data-text.style-2 {
    font-size: 350px;
    top: 25%;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-inner .data-text.style-2 {
    font-size: 250px;
    top: 5%;
  }
}
@media (max-width: 1401px) {
  .main-bnr-one .banner-inner .data-text.style-2 {
    font-size: 220px;
    top: 20%;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-inner .data-text.style-2 {
    font-size: 180px;
    top: 30%;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-one .banner-inner .data-text.style-2 {
    font-size: 150px;
    top: 20%;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-inner .data-text.style-2 {
    font-size: 80px;
    top: 10%;
  }
}
@media only screen and (max-width: 1480px) {
  .main-bnr-one .banner-inner {
    min-height: 950px;
    padding-top: 150px;
  }
  .main-bnr-one .banner-inner .data-text {
    font-size: 150px;
    bottom: 150px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one .banner-inner {
    min-height: 850px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one .banner-inner {
    min-height: 550px;
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .main-bnr-one .banner-inner .data-text {
    font-size: 80px;
    bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-one .banner-inner {
    height: 100%;
    padding-bottom: 40px;
    margin-bottom: -50px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one .banner-inner {
    padding-bottom: 80px;
  }
}
.main-bnr-one.style-2 {
  padding-bottom: 0;
  margin-bottom: 0px;
}
.main-bnr-one.style-2::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ff4848;
  opacity: 0.06;
}
@media only screen and (max-width: 575px) {
  .main-bnr-one.style-2 {
    margin-bottom: -0px;
  }
}
.main-bnr-one.style-2 .banner-inner {
  min-height: 960px;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-one.style-2 .banner-inner {
    min-height: 700px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-one.style-2 .banner-inner {
    padding-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-one.style-2 .banner-inner {
    min-height: 500px;
    height: 500px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-one.style-2 .banner-inner {
    min-height: 350px;
    height: 350px;
  }
}

@keyframes animateBGTextBnr {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(12px);
    opacity: 0.09;
  }
}

@keyframes fadeup {
  0% {
    opacity: 0.8;
    transform: scale(0.9);
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes fadeup2 {
  0% {
    opacity: 0.8;
    transform: scale(0.9);
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.main-bnr-two {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}
.main-bnr-two::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary);
  opacity: 0.2;
  z-index: -1;
}
.main-bnr-two .banner-media {
  text-align: center;
  z-index: 2;
  position: relative;
  max-width: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-two .banner-media img {
    max-width: 90%;
  }
}
.main-bnr-two .banner-inner {
  display: flex;
  min-height: 960px;
  padding-top: 180px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-two .banner-inner {
    min-height: 700px;
    padding-top: 50px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-two .banner-inner {
    min-height: 700px;
    padding-top: 50px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-inner {
    padding-top: 0;
    min-height: 500px;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-two .banner-inner {
    min-height: 500px;
    min-height: 400px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-two .banner-inner {
    min-height: 280px;
  }
}
.main-bnr-two .banner-inner .data-text {
  position: absolute;
  color: #fff;
  font-weight: 700;
  font-size: 330px;
  -webkit-text-shadow: 0px -15px 50px rgba(152, 54, 0, 0.1);
  background: linear-gradient(180deg, #ffffff 25%, rgba(255, 255, 255, 0) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  top: 16%;
  left: 0;
  bottom: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 1;
}
.main-bnr-two .banner-inner .data-text span {
  animation: none;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-two .banner-inner .data-text {
    font-size: 250px;
    top: 10%;
  }
}
@media (max-width: 1401px) {
  .main-bnr-two .banner-inner .data-text {
    font-size: 220px;
    top: 28%;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-two .banner-inner .data-text {
    font-size: 130px;
    top: 30%;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-two .banner-inner .data-text {
    font-size: 110px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-two .banner-inner .data-text {
    font-size: 60px;
    top: 40%;
  }
}

.main-bnr-three {
  position: relative;
  align-items: center;
  z-index: 1;
  overflow: hidden;
}
.main-bnr-three::after {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient-3);
  backdrop-filter: blur(5px);
  opacity: 0.75;
  z-index: -1;
}
.main-bnr-three .banner-inner {
  display: flex;
  padding-top: 180px;
  min-height: 960px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-three .banner-inner {
    min-height: 800px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner {
    min-height: 720px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner {
    min-height: 600px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-three .banner-inner {
    min-height: 500px;
  }
}
.main-bnr-three .banner-inner .move-1 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
}
.main-bnr-three .banner-inner .data-text {
  position: absolute;
  color: #fff;
  font-weight: 700;
  font-size: 330px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  top: 32%;
  left: 0;
  bottom: auto;
  text-shadow: 0px -15px 50px var(--rgba-primary-1);
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: 1;
}
.main-bnr-three .banner-inner .data-text span {
  animation: none;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-three .banner-inner .data-text {
    font-size: 250px;
    top: 28%;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .data-text {
    font-size: 200px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner .data-text {
    font-size: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner .data-text {
    font-size: 80px;
  }
}
.main-bnr-three .banner-inner .banner-media {
  text-align: center;
  z-index: 2;
  position: relative;
  max-width: 100%;
  width: 90%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.main-bnr-three .banner-inner .banner-media img {
  width: 615px;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-three .banner-inner .banner-media img {
    width: 520px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .banner-media img {
    width: 450px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner .banner-media img {
    width: 380px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-three .banner-inner .banner-media img {
    width: 280px;
  }
}
.main-bnr-three .banner-inner .banner-content {
  position: relative;
  z-index: 10;
  height: 80%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.main-bnr-three .banner-inner .banner-content .title {
  font-style: italic;
  font-weight: 700;
  font-size: 153px;
  letter-spacing: 0.005em;
  color: #ffffff;
  text-shadow: 0px 30px 80px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 1480px) {
  .main-bnr-three .banner-inner .banner-content .title {
    font-size: 120px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .banner-content .title {
    font-size: 90px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner .banner-content .title {
    font-size: 70px;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner .banner-content .title {
    font-size: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-three .banner-inner .banner-content .title {
    font-size: 36px;
  }
}
.main-bnr-three .banner-inner .banner-content .title .left {
  position: absolute;
  top: 0;
  left: 80px;
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .banner-content .title .left {
    top: 0px;
    left: 150px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner .banner-content .title .left {
    left: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner .banner-content .title .left {
    left: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-three .banner-inner .banner-content .title .left {
    left: 30px;
  }
}
.main-bnr-three .banner-inner .banner-content .title .right {
  position: absolute;
  bottom: 18%;
  right: -120px;
}
@media only screen and (max-width: 1480px) {
  .main-bnr-three .banner-inner .banner-content .title .right {
    right: -60px;
  }
}
@media only screen and (max-width: 1280px) {
  .main-bnr-three .banner-inner .banner-content .title .right {
    bottom: 20%;
    right: -30px;
  }
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner .banner-content .title .right {
    right: 0px;
    bottom: 10%;
  }
}
@media only screen and (max-width: 767px) {
  .main-bnr-three .banner-inner .banner-content .title .right {
    bottom: 18%;
  }
}
@media only screen and (max-width: 575px) {
  .main-bnr-three .banner-inner .banner-content .title .right {
    right: -10px;
  }
}
.main-bnr-three .banner-inner .banner-content .bottom-content {
  margin-left: -20px;
  position: relative;
  padding-top: 20px;
}
@media only screen and (max-width: 575px) {
  .main-bnr-three .banner-inner .banner-content .bottom-content {
    display: none;
  }
}
.main-bnr-three .banner-inner .banner-content .bottom-content p {
  color: #fff;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.6;
}
@media only screen and (max-width: 991px) {
  .main-bnr-three .banner-inner .banner-content .bottom-content p {
    font-size: 14px;
  }
}
.main-bnr-three .banner-inner .banner-content .bottom-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 80%;
  height: 4px;
  background: white;
  border-radius: 5px;
}

.move-1 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite;
}

.move-2 {
  -webkit-animation: move2 5s infinite;
  animation: move2 5s infinite;
}

.move-3 {
  -webkit-animation: move3 5s infinite;
  animation: move3 5s infinite;
}

.move-4 {
  -webkit-animation: move4 5s infinite;
  animation: move4 5s infinite;
}

.move-5 {
  -webkit-animation: move5 5s infinite;
  animation: move5 5s infinite;
}

.move-6 {
  -webkit-animation: move6 5s infinite;
  animation: move6 5s infinite;
}

.move-7 {
  -webkit-animation: move7 5s infinite;
  animation: move7 5s infinite;
}

.move-lr {
  animation: move-lr 3s infinite linear;
}

.rotate-360 {
  animation: rotate-360 20s infinite linear;
}

@keyframes rotate-360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
}

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
}

@-webkit-keyframes move3 {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(5px, 0);
  }
  40% {
    transform: translate(5px, 5px);
  }
  65% {
    transform: translate(0, 5px);
  }
  65% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move4 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(10px, 10px);
    transform: translate(10px, 10px);
  }
  50% {
    -webkit-transform: translate(5px, 5px);
    transform: translate(5px, 5px);
  }
  75% {
    -webkit-transform: translate(10px, -5px);
    transform: translate(10px, -5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move5 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }
  50% {
    -webkit-transform: translate(-5px, -5px);
    transform: translate(-5px, -5px);
  }
  75% {
    -webkit-transform: translate(-10px, 5px);
    transform: translate(-10px, 5px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move6 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(15px, 15px);
    transform: translate(15px, 15px);
  }
  50% {
    -webkit-transform: translate(8px, 8px);
    transform: translate(8px, 8px);
  }
  75% {
    -webkit-transform: translate(15px, -8px);
    transform: translate(15px, -8px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move7 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  25% {
    -webkit-transform: translate(-15px, -15px);
    transform: translate(-15px, -15px);
  }
  50% {
    -webkit-transform: translate(-8px, -8px);
    transform: translate(-8px, -8px);
  }
  75% {
    -webkit-transform: translate(-15px, 8px);
    transform: translate(-15px, 8px);
  }
  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@keyframes move-lr {
  0% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(-15deg);
  }
  20% {
    transform: rotate(20deg);
  }
  30% {
    transform: rotate(-24deg);
  }
  40% {
    transform: rotate(21deg);
  }
  50% {
    transform: rotate(-18deg);
  }
  60% {
    transform: rotate(14deg);
  }
  70% {
    transform: rotate(-11deg);
  }
  80% {
    transform: rotate(7deg);
  }
  90% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(1deg);
  }
}

.about-bx1 {
  z-index: 1;
}
.about-bx1:after {
  content: "";
  position: absolute;
  height: calc(100% + 100px);
  width: 100%;
  top: 0px;
  left: 0;
  background-image: url(../images/about/about-bg.png);
  background-position: center;
  background-size: cover;
  z-index: -2;
}
.about-bx1 .section-head p {
  color: #666666;
}
.about-bx1 .dz-media {
  margin-right: 25px;
  overflow: visible;
  text-align: center;
  margin-bottom: 100px;
}
.about-bx1 .dz-media ul li {
  position: absolute;
}
.about-bx1 .dz-media ul li:nth-child(1) {
  bottom: 12%;
  right: 0%;
  background: var(--gradient-2);
  padding: 20px 25px;
  box-shadow: 0px 20px 50px 0px rgba(255, 63, 19, 0.3);
  transform: skewY(-18deg);
}
.about-bx1 .dz-media ul li:nth-child(1) span {
  font-size: 110px;
  color: var(--theme-text-color);
  font-weight: 600;
  line-height: 1;
  display: inline-block;
}
.about-bx1 .dz-media ul li:nth-child(2) {
  bottom: 13%;
  right: 7%;
  background: var(--secondary);
  padding: 10px 25px;
  box-shadow: 20px 20px 40px 0px rgba(34, 34, 34, 0.4);
  transform: skewY(-18deg);
}
.about-bx1 .dz-media ul li:nth-child(2) span {
  font-size: 35px;
  color: #fff;
  letter-spacing: 3px;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
}
.about-bx1 .dz-media ul li:nth-child(3) {
  bottom: -4%;
  right: 0%;
  background: var(--gradient-1);
  height: 90px;
  width: 380px;
  transform: skewY(-18deg);
}
.about-bx1 .dz-media img {
  /* width: 75%; */
  margin: auto;
  text-align: center;
  display: inline-block;
}
.about-bx1 .dz-media svg {
  position: absolute;
  right: 0;
  z-index: -1;
  height: 100%;
  width: calc(100% + 50px);
}
.cashback-img {
  max-width: fit-content;
  margin-left: 150px;
  display: flex;
}
@media only screen and (max-width: 1280px) {
  .about-bx1 .dz-media svg {
    width: 100%;
  }
  .about-bx1 .dz-media ul li:nth-child(1) span {
    font-size: 80px;
  }
  .about-bx1 .dz-media ul li:nth-child(2) {
    padding: 8px 20px;
  }
  .about-bx1 .dz-media ul li:nth-child(2) span {
    font-size: 20px;
  }
  .about-bx1 .dz-media ul li:nth-child(3) {
    height: 70px;
    width: 300px;
  }
}
@media only screen and (max-width: 991px) {
  .about-bx1 .dz-media {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 75px;
  }
}
@media only screen and (max-width: 575px) {
  .about-bx1 .dz-media ul li:nth-child(1) {
    bottom: 15%;
  }
  .about-bx1 .dz-media ul li:nth-child(1) span {
    font-size: 60px;
  }
  .about-bx1 .dz-media ul li:nth-child(2) span {
    font-size: 16px;
  }
  .about-bx1 .dz-media ul li:nth-child(3) {
    height: 55px;
    width: 250px;
    bottom: -6%;
  }
}

.about-bx2 .contact-us {
  display: flex;
  align-items: center;
}
.about-bx2 .contact-us .icon {
  width: 70px;
  height: 65px;
  border-left: 5px solid var(--primary);
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  box-shadow: 0px 10px 30px 0px rgba(255, 129, 57, 0.15);
  transform: skewX(-10deg);
}
.about-bx2 .contact-us .icon i {
  transform: skewX(10deg);
  font-size: 24px;
  animation: move-lr 1.2s infinite;
  color: var(--primary);
}
.about-bx2 .contact-us .content .number {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--primary);
  margin-bottom: 0;
  margin-top: 3px;
}
.about-bx2 .contact-us .content span {
  color: #000;
  margin: 0;
  font-weight: 500;
}
@media only screen and (max-width: 1280px) {
  .about-bx2 .contact-us .icon {
    width: 55px;
    height: 50px;
    border-left-width: 3px;
  }
  .about-bx2 .contact-us .icon i {
    font-size: 18px;
  }
  .about-bx2 .contact-us .content .number {
    font-size: 24px;
  }
}

.about-bx2 .dz-media {
  overflow: visible;
  position: relative;
  z-index: 1;
}
.about-bx2 .dz-media img {
  box-shadow: 0px 15px 60px 0px rgba(0, 0, 0, 0.15);
  border: 9px solid #fff;
}
.about-bx2 .dz-media .image-box {
  position: relative;
}
.about-bx2 .dz-media .image-box .info-box {
  position: absolute;
  font-size: 18px;
  font-weight: 500;
  background: transparent;
  padding: 10px 25px 10px 20px;
  bottom: 30%;
  z-index: 1;
}
.about-bx2 .dz-media .image-box .info-box svg {
  margin-right: 10px;
}
.about-bx2 .dz-media .image-box .info-box span {
  display: flex;
  align-items: center;
}
.about-bx2 .dz-media .image-box .info-box span i {
  font-size: 20px;
  margin-right: 8px;
}
.about-bx2 .dz-media .image-box .info-box:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 5px;
  left: 0;
  top: 0;
  transform: skewX(-10deg);
  background: var(--primary);
}
.about-bx2 .dz-media .image-box .info-box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100%;
  width: 100%;
  opacity: 0.9;
  background: #fff;
  transform: skewX(-10deg);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
}
.about-bx2 .dz-media .image-box .info-box:hover:before {
  opacity: 1;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.15);
}
.about-bx2 .dz-media .image-box:first-child {
  padding-right: 200px;
  margin-bottom: 35px;
  animation: move1 5s infinite;
}
.about-bx2 .dz-media .image-box:first-child .info-box {
  animation: move5 6s infinite;
  right: 0;
}
.about-bx2 .dz-media .image-box:last-child {
  animation: move1 6s infinite;
  padding-left: 120px;
}
.about-bx2 .dz-media .image-box:last-child .info-box {
  left: 15px;
  animation: move7 7s infinite;
}
.about-bx2 .dz-media:after {
  content: "";
  position: absolute;
  height: 360px;
  width: 360px;
  right: 65px;
  top: 35px;
  border: 65px solid var(--primary);
  box-shadow: 0px 5px 30px 0px var(--rgba-primary-5);
  z-index: -1;
  border-radius: 100%;
  background: transparent;
}
@media only screen and (max-width: 1280px) {
  .about-bx2 .dz-media img {
    border-width: 5px;
  }
  .about-bx2 .dz-media:after {
    height: 250px;
    width: 250px;
    right: 150px;
    top: 60px;
    border-width: 25px;
  }
}
@media only screen and (max-width: 991px) {
  .about-bx2 .dz-media:after {
    right: 120px;
    top: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .about-bx2 .dz-media:after {
    top: 120px;
  }
}
@media only screen and (max-width: 575px) {
  .about-bx2 .dz-media:after {
    height: 200px;
    width: 200px;
    right: 10px;
    top: 80px;
  }
  .about-bx2 .dz-media .image-box:first-child {
    padding-right: 50px;
  }
  .about-bx2 .dz-media .image-box:last-child {
    padding-left: 50px;
  }
  .about-bx2 .dz-media .image-box .info-box {
    font-size: 15px;
    padding: 10px 20px 10px 15px;
  }
  .about-bx2 .dz-media .image-box .info-box svg {
    margin-right: 5px;
  }
}

.about-bx3 {
  position: relative;
}
.about-bx3::after {
  content: "";
  position: absolute;
  top: 10%;
  right: 25%;
  background: url("../images/background/pole.png");
  width: 40%;
  height: 80%;
  background-repeat: no-repeat;
  background-position: right bottom;
  z-index: -1;
}
.about-bx3 .dz-media {
  overflow: visible;
  position: relative;
  width: 100%;
  z-index: 10;
}
.about-bx3 .dz-media::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 74%;
  height: 80px;
  background: #232323;
  clip-path: polygon(0 57%, 100% 0, 100% 100%, -1% 100%);
  z-index: 1;
}
@media only screen and (max-width: 1280px) {
  .about-bx3 .dz-media::before {
    left: 50%;
    transform: translateX(-50%);
    bottom: -1px;
  }
}
.about-bx3 .dz-media img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  position: relative;
}
.about-bx3 .dz-media .tag {
  text-align: center;
  display: inline-block;
  position: absolute;
  padding: 15px;
  bottom: 15px;
  left: 30px;
  font-size: 20px;
  background: var(--primary);
  text-transform: uppercase;
  z-index: 4;
}
@media only screen and (max-width: 575px) {
  .about-bx3 .dz-media .tag {
    max-width: 150px;
    left: 15px;
  }
}
.about-bx3 .dz-media .tag h2,
.about-bx3 .dz-media .tag .h2 {
  margin: 0;
  letter-spacing: -3px;
  font-weight: 700;
  line-height: 1;
  font-size: 6rem;
  color: var(--theme-text-color);
  font-family: "Oswald", sans-serif;
}
@media only screen and (max-width: 575px) {
  .about-bx3 .dz-media .tag h2,
  .about-bx3 .dz-media .tag .h2 {
    font-size: 4rem;
  }
}
.about-bx3 .dz-media .tag h5,
.about-bx3 .dz-media .tag .h5 {
  font-weight: 500;
  letter-spacing: 1px;
  margin: 0;
  margin-top: 5px;
  font-size: 1rem;
  color: var(--theme-text-color);
  font-family: "Oswald", sans-serif;
}
@media only screen and (max-width: 575px) {
  .about-bx3 .dz-media .tag {
    font-size: 0.8rem;
  }
}
.about-bx3 .dz-content .sub-title {
  font-weight: 500;
  font-size: 18px;
  color: #666666;
}
@media only screen and (max-width: 1280px) {
  .about-bx3 .dz-content .sub-title {
    font-size: 1rem;
  }
}
.about-bx3 .dz-content .title {
  font-size: 58px;
}
@media only screen and (max-width: 1280px) {
  .about-bx3 .dz-content .title {
    font-size: 40px;
  }
}
.about-bx3 .dz-content p {
  font-weight: 400;
  font-size: 16px;
  color: #666666;
}
.about-bx3 .dz-content .description {
  font-style: italic;
  font-weight: 500;
  font-size: 20px;
  color: #232323;
}
@media only screen and (max-width: 1280px) {
  .about-bx3 .dz-content .description {
    font-size: 1rem;
  }
}
.about-bx3 .section-head {
  margin-bottom: 20px;
}

.pr-list {
  display: flow-root;
  background: var(--rgba-primary-05);
  border-left: 5px solid var(--primary);
  padding: 15px 25px;
}
.pr-list li {
  font-size: 20px;
  width: 50%;
  float: left;
  font-weight: 400;
  color: #232323;
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  padding: 5px 0;
  display: flex;
  align-items: center;
  /* or 225% */
  /* Secondary-2 */
  color: #232323;
}
@media only screen and (max-width: 1280px) {
  .pr-list li {
    font-size: 1rem;
    line-height: 2rem;
  }
}
@media only screen and (max-width: 575px) {
  .pr-list li {
    width: 100%;
  }
}
.pr-list li i {
  color: var(--primary);
  margin-right: 8px;
  font-size: 20px;
  display: inline-flex;
}

.about-bx4 {
  position: relative;
}
.about-bx4 .dz-media {
  overflow: visible;
  margin-top: 80px;
}
.about-bx4 .dz-media > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  clip-path: polygon(50% 0%, 100% 0, 100% 80%, 85% 100%, 51% 100%, 0 100%, 0 0);
}
@media only screen and (max-width: 1480px) {
  .about-bx4 .dz-media {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 991px) {
  .about-bx4 .dz-media {
    padding: 0;
    max-width: 60%;
    text-align: center;
    margin: auto;
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .about-bx4 .dz-media {
    max-width: 75%;
  }
}
@media only screen and (max-width: 575px) {
  .about-bx4 .dz-media {
    max-width: 95%;
  }
}
.about-bx4 .dz-media .image-box {
  position: absolute;
  height: 330px;
  width: 330px;
  top: -80px;
  left: -90px;
  z-index: 1;
}
.about-bx4 .dz-media .image-box .tag {
  text-align: center;
  display: inline-block;
  position: absolute;
  padding: 15px;
  bottom: 0;
  right: 0;
  font-size: 20px;
  background: var(--primary);
  text-transform: uppercase;
}
.about-bx4 .dz-media .image-box .tag h5,
.about-bx4 .dz-media .image-box .tag .h5 {
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0;
  font-family: "Kenyan Coffee", sans-serif;
  color: var(--theme-text-color);
}
.about-bx4 .dz-media .image-box .tag h2,
.about-bx4 .dz-media .image-box .tag .h2 {
  margin: 0;
  letter-spacing: -3px;
  font-weight: 400;
  line-height: 1;
  font-size: 100px;
  font-family: "Kenyan Coffee", sans-serif;
  color: var(--theme-text-color);
}
.about-bx4 .dz-media .image-box:after {
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: #fff;
}
@media only screen and (max-width: 575px) {
  .about-bx4 .dz-media .image-box:after {
    top: 10px;
    left: 10px;
  }
}
@media only screen and (max-width: 1480px) {
  .about-bx4 .dz-media .image-box {
    height: 270px;
    width: 270px;
    top: -60px;
    left: -70px;
  }
  .about-bx4 .dz-media .image-box .tag h2,
  .about-bx4 .dz-media .image-box .tag .h2 {
    font-size: 70px;
  }
}
@media only screen and (max-width: 1280px) {
  .about-bx4 .dz-media .image-box {
    height: 200px;
    width: 200px;
    top: -30px;
    left: -10px;
  }
  .about-bx4 .dz-media .image-box .tag {
    padding: 10px;
  }
  .about-bx4 .dz-media .image-box .tag h2,
  .about-bx4 .dz-media .image-box .tag .h2 {
    font-size: 50px;
  }
  .about-bx4 .dz-media .image-box .tag h5,
  .about-bx4 .dz-media .image-box .tag .h5 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1199px) {
  .about-bx4 .dz-media .image-box {
    top: -60px;
    left: -35px;
  }
}
@media only screen and (max-width: 575px) {
  .about-bx4 .dz-media .image-box {
    height: 150px;
    width: 150px;
    top: -50px;
    left: -15px;
  }
  .about-bx4 .dz-media .image-box .tag {
    padding: 7px;
  }
  .about-bx4 .dz-media .image-box .tag h2,
  .about-bx4 .dz-media .image-box .tag .h2 {
    font-size: 40px;
  }
  .about-bx4 .dz-media .image-box .tag h5,
  .about-bx4 .dz-media .image-box .tag .h5 {
    font-size: 15px;
  }
}
.about-bx4 .about-content .section-head {
  margin-bottom: 20px;
}
@media only screen and (max-width: 1480px) {
  .about-bx4 .about-content .pr-list li {
    font-size: 20px;
    padding: 1px 0;
  }
}
@media only screen and (max-width: 1199px) {
  .about-bx4 .about-content p {
    font-size: 15px;
  }
  .about-bx4 .about-content .pr-list li {
    font-size: 18px;
    padding: 1px 0;
  }
}
@media only screen and (max-width: 575px) {
  .about-bx4 .about-content .pr-list li {
    width: 100%;
  }
}

.dz-box {
  position: relative;
}

.portfolio-slider .swiper-slide {
  width: auto;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.portfolio-slider .num-pagination {
  margin-top: 110px;
}
@media only screen and (max-width: 1199px) {
  .portfolio-slider .num-pagination {
    margin-top: 90px;
  }
}
@media only screen and (max-width: 991px) {
  .portfolio-slider .num-pagination {
    margin-top: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .portfolio-slider .num-pagination {
    margin-top: 30px;
  }
}
.Eshop {
  margin-top: 30rem;
}

.bazzar-head {
  position: relative;
}

.bazzar-absolute {
  position: absolute;
}
.portfolio-wrapper {
  position: relative;
  transform: skewY(-4deg);
  background: var(--secondary);
  margin-top: 180px;
  z-index: 2;
}
@media only screen and (max-width: 1480px) {
  .portfolio-wrapper {
    margin-top: 120px;
  }
}
@media only screen and (max-width: 575px) {
  .portfolio-wrapper {
    padding-bottom: 30px;
    margin-top: 75px;
  }
}
.portfolio-wrapper .portfolio-wrapper-inner {
  transform: skewY(4deg);
  margin-top: -200px;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 991px) {
  .portfolio-wrapper .portfolio-wrapper-inner {
    margin-top: -150px;
  }
}
@media only screen and (max-width: 575px) {
  .portfolio-wrapper .portfolio-wrapper-inner {
    margin-top: -100px;
  }
}
.portfolio-wrapper .shape-up {
  position: absolute;
  top: -75px;
  transform: skewY(5.5deg);
  left: 0;
}
@media only screen and (max-width: 1480px) {
  .portfolio-wrapper .shape-up {
    width: 450px;
    top: -69px;
  }
}
@media only screen and (max-width: 1199px) {
  .portfolio-wrapper .shape-up {
    width: 400px;
    top: -67px;
  }
}
@media only screen and (max-width: 991px) {
  .portfolio-wrapper .shape-up {
    width: 340px;
    top: -65px;
  }
}
@media only screen and (max-width: 991px) {
  .portfolio-wrapper .shape-up {
    width: 210px;
    top: -60px;
  }
}
.portfolio-wrapper .shape-down {
  position: absolute;
  bottom: -76px;
  right: 0;
  transform: skewY(4deg);
}
@media only screen and (max-width: 1480px) {
  .portfolio-wrapper .shape-down {
    width: 480px;
    bottom: -70px;
  }
}
@media only screen and (max-width: 1199px) {
  .portfolio-wrapper .shape-down {
    width: 430px;
    bottom: -68px;
  }
}
@media only screen and (max-width: 991px) {
  .portfolio-wrapper .shape-down {
    width: 330px;
    bottom: -65px;
  }
}
@media only screen and (max-width: 575px) {
  .portfolio-wrapper .shape-down {
    width: 170px;
    bottom: -60px;
  }
}

.dz-box.style-1 .dz-media img {
  height: 350px;
  object-fit: cover;
  width: inherit;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;

  transition: all 0.5s;
}
@media only screen and (max-width: 1480px) {
  .dz-box.style-1 .dz-media img {
    height: 300px;
  }
}
@media only screen and (max-width: 1199px) {
  .dz-box.style-1 .dz-media img {
    height: 250px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-box.style-1 .dz-media img {
    height: 220px;
  }
}

.menu-item {
  margin-bottom: 10px;
}
.dz-box.style-1 .dz-info {
  position: relative;
  bottom: 13%;
  left: 0;
  z-index: 1;
  max-width: 377px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-box.style-1 .dz-info .title,
.dz-box.style-1 .dz-info .title a {
  color: #9f7536;
  margin: 0;
  font-weight: 700;
  font-size: 35px;
  -webkit-text-stroke: 1px #fff;
}
.dz-box.style-1 .dz-info .title:after,
.dz-box.style-1 .dz-info .title a:after {
  content: "";
  position: absolute;
  height: 4px;
  width: 70px;
  bottom: -20px;
  left: 0px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: var(--gradient);
}
@media only screen and (max-width: 1480px) {
  .dz-box.style-1 .dz-info .title,
  .dz-box.style-1 .dz-info .title a {
    font-size: 29px;
  }
}
@media only screen and (max-width: 1199px) {
  .dz-box.style-1 .dz-info .title,
  .dz-box.style-1 .dz-info .title a {
    font-size: 25px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-box.style-1 .dz-info .title,
  .dz-box.style-1 .dz-info .title a {
    font-size: 21px;
  }
}
@media only screen and (max-width: 1199px) {
  .dz-box.style-1 .dz-info {
    max-width: 335px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-box.style-1 .dz-info {
    max-width: 250px;
  }
}

.dz-box.style-1:hover .dz-media img {
  transform: scale(1.15) rotate(3deg);
}

.dz-box.style-1:hover .dz-info .title:after,
.dz-box.style-1:hover .dz-info .title a:after {
  width: 110px;
}

.dz-box.style-1.box-1 {
  width: 655px;
  margin-left: 65px;
}
.dz-box.style-1.box-1 .dz-media {
  margin-left: 180px;
}
.dz-box.style-1.box-1 .dz-info {
  position: absolute;
}
@media only screen and (max-width: 1480px) {
  .dz-box.style-1.box-1 {
    width: 525px;
    margin-left: 50px;
  }
  .dz-box.style-1.box-1 .dz-media {
    margin-left: 140px;
  }
}
@media only screen and (max-width: 1199px) {
  .dz-box.style-1.box-1 {
    width: 460px;
  }
  .dz-box.style-1.box-1 .dz-media {
    margin-left: 120px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-box.style-1.box-1 {
    width: 400px;
    margin-left: 40px;
  }
  .dz-box.style-1.box-1 .dz-media {
    margin-left: 90px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-box.style-1.box-1 {
    width: 320px;
    margin-left: 30px;
  }
  .dz-box.style-1.box-1 .dz-media {
    margin-left: 50px;
  }
}

.dz-box.style-1.box-2 {
  width: 400px;
  margin-left: 65px;
  transform: translateY(70px);
}
.dz-box.style-1.box-2 .dz-media {
  margin-left: 130px;
}
.dz-box.style-1.box-2 .dz-info {
  max-width: 355px;
  position: absolute;
}
.dz-box.style-1.box-2 .dz-info .title,
.dz-box.style-1.box-2 .dz-info .title a {
  font-size: 28px;
  font-weight: 800;
}
@media only screen and (max-width: 1480px) {
  .dz-box.style-1.box-2 {
    width: 330px;
    margin-left: 50px;
  }
  .dz-box.style-1.box-2 .dz-media {
    margin-left: 110px;
  }
  .dz-box.style-1.box-2 .dz-info {
    max-width: 300px;
  }
  .dz-box.style-1.box-2 .dz-info .title,
  .dz-box.style-1.box-2 .dz-info .title a {
    font-size: 24px;
  }
}
@media only screen and (max-width: 1199px) {
  .dz-box.style-1.box-2 {
    width: 290px;
    transform: translateY(50px);
  }
  .dz-box.style-1.box-2 .dz-media {
    margin-left: 90px;
  }
  .dz-box.style-1.box-2 .dz-info {
    max-width: 280px;
  }
  .dz-box.style-1.box-2 .dz-info .title,
  .dz-box.style-1.box-2 .dz-info .title a {
    font-size: 22px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-box.style-1.box-2 {
    width: 250px;
    transform: translateY(40px);
    margin-left: 40px;
  }
  .dz-box.style-1.box-2 .dz-media {
    margin-left: 70px;
  }
  .dz-box.style-1.box-2 .dz-info {
    max-width: 230px;
  }
  .dz-box.style-1.box-2 .dz-info .title,
  .dz-box.style-1.box-2 .dz-info .title a {
    font-size: 18px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-box.style-1.box-2 {
    width: 320px;
    transform: translateY(0px);
    margin-left: 30px;
  }
  .dz-box.style-1.box-2 .dz-media {
    margin-left: 50px;
  }
}

.dz-box.style-1.box-3 {
  width: 635px;
  margin-left: 5px;
  transform: translateY(35px);
}
.dz-box.style-1.box-3 .dz-media {
  margin-left: 160px;
}
.dz-box.style-1.box-3 .dz-info {
  position: absolute;
}
@media only screen and (max-width: 1480px) {
  .dz-box.style-1.box-3 {
    width: 500px;
    margin-left: 50px;
  }
  .dz-box.style-1.box-3 .dz-media {
    margin-left: 130px;
  }
}
@media only screen and (max-width: 1199px) {
  .dz-box.style-1.box-3 {
    transform: translateY(25px);
    width: 450px;
  }
  .dz-box.style-1.box-3 .dz-media {
    margin-left: 110px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-box.style-1.box-3 {
    transform: translateY(20px);
    width: 400px;
    margin-left: 40px;
  }
  .dz-box.style-1.box-3 .dz-media {
    margin-left: 90px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-box.style-1.box-3 {
    transform: translateY(0px);
    margin-left: 30px;
    width: 320px;
  }
  .dz-box.style-1.box-3 .dz-media {
    margin-left: 50px;
  }
}

.dz-box.style-2 .dz-media {
  margin-bottom: 20px;
}
.dz-box.style-2 .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-box.style-2 .dz-info {
  position: relative;
  bottom: 13%;
  left: 0;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-box.style-2 .dz-info .title,
.dz-box.style-2 .dz-info .title a {
  margin: 0;
  padding-bottom: 20px;
}
.dz-box.style-2 .dz-info .title:after,
.dz-box.style-2 .dz-info .title a:after {
  content: "";
  position: absolute;
  height: 4px;
  width: 70px;
  bottom: 0px;
  left: 0px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: var(--gradient);
}
@media only screen and (max-width: 1199px) {
  .dz-box.style-2 .dz-info .title,
  .dz-box.style-2 .dz-info .title a {
    padding-bottom: 15px;
  }
}

.dz-box.style-2:hover .dz-media img {
  transform: scale(1.15) rotate(3deg);
}

.dz-box.style-2:hover .dz-info .title:after,
.dz-box.style-2:hover .dz-info .title a:after {
  width: 110px;
}

.dz-box.style-3 {
  overflow: hidden;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}
.dz-box.style-3 .dz-media {
  background-size: cover;
  display: block;
  transition: all 0.8s ease 0s;
  width: 100%;
  overflow: hidden;
}
.dz-box.style-3 .dz-media img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-box.style-3 .dz-media:hover img {
  transform: scale(1.2) rotate(-5deg);
}
.dz-box.style-3 .dz-info {
  position: absolute;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  padding: 20px;
}
.dz-box.style-3 .dz-info .sub-title {
  font-family: var(--font-family-base);
}
.dz-box.style-3 .dz-info .title {
  color: white;
}

.image-tooltip {
  position: absolute;
  background: var(--secondary);
  padding: 20px;
  z-index: 999;
  width: 0px;
}
.image-tooltip .title {
  color: #fff;
  white-space: nowrap;
}
.image-tooltip .sub-title {
  white-space: nowrap;
  color: var(--primary);
  margin-bottom: 0;
}

.testimonial-pic {
  background: #fff;
  width: 100px;
  height: 100px;
  position: relative;
  display: inline-block;
  border: 5px solid #fff;
}
.testimonial-pic.radius {
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-pic.radius img {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  -webkit-border-radius: 100%;
}
.testimonial-pic.shadow {
  -webkit-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
  box-shadow: 2px 3px 6px -3px rgba(0, 0, 0, 0.35);
}

.quote-left,
.quote-right {
  position: relative;
}
.quote-left:before,
.quote-right:before {
  font-family: "Flaticon";
  position: absolute;
  z-index: 10;
  height: 36px;
  width: 36px;
  line-height: 36px;
  background-color: var(--primary);
  color: #fff;
  border-radius: 30px;
  text-align: center;
  font-size: 18px;
}

.quote-left:before {
  top: 15px;
  content: "\f115";
  left: 0;
}

.quote-right:before {
  top: 15px;
  content: "\f11e";
  right: 0;
}

.testimonial-text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
}
.testimonial-text p:last-child {
  margin: 0;
}

.testimonial-detail {
  padding: 5px;
}

.testimonial-position {
  font-size: 15px;
}

.testimonial-name,
.testimonial-position {
  display: block;
}

.star-rating li {
  display: inline-block;
}

.testimonial-1 {
  position: relative;
  text-align: center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-top: 20px;
}
.testimonial-1 .testimonial-text {
  font-size: 22px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  margin-top: 20px;
  text-transform: capitalize;
}
@media only screen and (max-width: 1480px) {
  .testimonial-1 .testimonial-text {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1280px) {
  .testimonial-1 .testimonial-text {
    font-size: 18px;
    max-width: 700px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonial-1 .testimonial-text {
    font-size: 16px;
  }
}
.testimonial-1 .testimonial-name {
  margin-bottom: 3px;
  line-height: 1.2;
}
.testimonial-1 .testimonial-position {
  font-size: 16px;
  font-weight: 500;
}
.testimonial-1 .testimonial-rating {
  display: flex;
  margin-top: 8px;
  justify-content: center;
}
.testimonial-1 .testimonial-rating i {
  color: #ffaf29;
  font-size: 16px;
  margin: 0 4px;
}
.testimonial-1 .testimonial-pic {
  height: 160px;
  width: 240px;
  min-width: 110px;
  /* border-radius: %; */
  overflow: hidden;
  margin-bottom: 5px;
  position: relative;
  border: 5px solid #fff;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.15);
}
@media only screen and (max-width: 991px) {
  .testimonial-1 .testimonial-pic {
    height: 160px;
    width: 240px;
    min-width: 80px;
    border-width: 3px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-1 .testimonial-pic {
    height: 120px;
    width: 200px;
    min-width: 60px;
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 1480px) {
  .testimonial-1 {
    margin-top: 0px;
  }
}
.testimonial-1.testimonial-left {
  display: flex;
  text-align: left;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.testimonial-1.testimonial-left .testimonial-rating {
  margin: 0 -5px;
}
.testimonial-1.testimonial-left .testimonial-info {
  padding-left: 40px;
}
@media only screen and (max-width: 575px) {
  .testimonial-1.testimonial-left .testimonial-info p {
    font-size: 14px;
  }
}
.testimonial-1.testimonial-left .testimonial-pic {
  min-width: 320px;
  height: 320px;
}
.testimonial-1.testimonial-left .testimonial-pic img {
  height: 100%;
  object-fit: cover;
}
.testimonial-1.testimonial-left .testimonial-text {
  margin-bottom: 35px;
  margin-top: 25px;
}
@media only screen and (max-width: 1280px) {
  .testimonial-1.testimonial-left .testimonial-pic {
    min-width: 200px;
    height: 200px;
  }
}
@media only screen and (max-width: 991px) {
  .testimonial-1.testimonial-left {
    flex-direction: column;
  }
  .testimonial-1.testimonial-left .testimonial-pic {
    min-width: 100px;
    height: 100px;
    margin-bottom: 25px;
  }
  .testimonial-1.testimonial-left .testimonial-info {
    padding: 0;
    text-align: center;
  }
  .testimonial-1.testimonial-left .testimonial-rating {
    justify-content: center !important;
  }
  .testimonial-1.testimonial-left .testimonial-text {
    margin-bottom: 25px;
    margin-top: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-1.testimonial-left .testimonial-text {
    margin-bottom: 15px;
  }
}

.testimonial-wrapper1 {
  overflow: hidden;
  background-color: var(--rgba-primary-1);
}
.testimonial-wrapper1 .svg-shape {
  position: absolute;
  top: -30%;
  left: -12%;
  z-index: -3;
}
@media only screen and (max-width: 1480px) {
  .testimonial-wrapper1 .svg-shape {
    left: -15%;
    width: 37%;
    top: -28%;
  }
}
@media only screen and (max-width: 1199px) {
  .testimonial-wrapper1 .svg-shape {
    left: -15%;
    width: 38%;
    top: -25%;
  }
}
@media only screen and (max-width: 991px) {
  .testimonial-wrapper1 .svg-shape {
    left: -25%;
    width: 55%;
    top: -40%;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-wrapper1 .svg-shape {
    width: 65%;
    height: 80%;
    left: -35%;
    top: -40%;
  }
}
.testimonial-wrapper1 .svg-shape-2 {
  position: absolute;
  bottom: -80%;
  right: -30%;
  z-index: -3;
}
@media only screen and (max-width: 1480px) {
  .testimonial-wrapper1 .svg-shape-2 {
    bottom: -40%;
    right: -15%;
    height: 550px;
  }
}
@media only screen and (max-width: 1199px) {
  .testimonial-wrapper1 .svg-shape-2 {
    right: -25%;
    height: 490px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonial-wrapper1 .svg-shape-2 {
    bottom: -30%;
    right: -50%;
    height: 350px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-wrapper1 .svg-shape-2 {
    bottom: -30%;
    right: -40%;
    height: 310px;
  }
}
.testimonial-wrapper1 .num-pagination {
  justify-content: center;
  margin-top: 40px;
}
.testimonial-wrapper1 .num-pagination .swiper-pagination {
  margin: 0 50px;
}
@media only screen and (max-width: 575px) {
  .testimonial-wrapper1 .num-pagination {
    margin-top: 30px;
  }
  .testimonial-wrapper1 .num-pagination .swiper-pagination {
    margin: 0 15px;
  }
}
.testimonial-wrapper1:before {
  content: attr(data-text);
  position: absolute;
  font-weight: 700;
  font-size: 200px;
  line-height: 1.15;
  font-family: var(--font-family-title);
  z-index: 0;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0%;
  color: #fff;
  filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.1));
  opacity: 0.3;
}
@media only screen and (max-width: 1480px) {
  .testimonial-wrapper1:before {
    font-size: 150px;
  }
}
@media only screen and (max-width: 1280px) {
  .testimonial-wrapper1:before {
    font-size: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonial-wrapper1:before {
    font-size: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-wrapper1:before {
    font-size: 60px;
  }
}
.testimonial-wrapper1 .avatar1,
.testimonial-wrapper1 .avatar2,
.testimonial-wrapper1 .avatar3,
.testimonial-wrapper1 .avatar4,
.testimonial-wrapper1 .avatar5,
.testimonial-wrapper1 .avatar6 {
  position: absolute;
}
.testimonial-wrapper1 .avatar1 img,
.testimonial-wrapper1 .avatar2 img,
.testimonial-wrapper1 .avatar3 img,
.testimonial-wrapper1 .avatar4 img,
.testimonial-wrapper1 .avatar5 img,
.testimonial-wrapper1 .avatar6 img {
  overflow: hidden;
  border-radius: 100%;
  border: 4px solid #fff;
  box-shadow: 0px 10px 40px 0px rgba(74, 34, 11, 0.1);
  height: 110px;
  width: 110px;
}
@media only screen and (max-width: 1280px) {
  .testimonial-wrapper1 .avatar1 img,
  .testimonial-wrapper1 .avatar2 img,
  .testimonial-wrapper1 .avatar3 img,
  .testimonial-wrapper1 .avatar4 img,
  .testimonial-wrapper1 .avatar5 img,
  .testimonial-wrapper1 .avatar6 img {
    height: 80px;
    width: 80px;
  }
}
@media only screen and (max-width: 991px) {
  .testimonial-wrapper1 .avatar1 img,
  .testimonial-wrapper1 .avatar2 img,
  .testimonial-wrapper1 .avatar3 img,
  .testimonial-wrapper1 .avatar4 img,
  .testimonial-wrapper1 .avatar5 img,
  .testimonial-wrapper1 .avatar6 img {
    height: 60px;
    width: 60px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-wrapper1 .avatar1,
  .testimonial-wrapper1 .avatar2,
  .testimonial-wrapper1 .avatar3,
  .testimonial-wrapper1 .avatar4,
  .testimonial-wrapper1 .avatar5,
  .testimonial-wrapper1 .avatar6 {
    display: none;
  }
}
.testimonial-wrapper1 .avatar1 {
  top: 21%;
  left: 5%;
  animation: move7 5s infinite linear;
}
.testimonial-wrapper1 .avatar2 {
  bottom: 10%;
  left: -1%;
  animation: move7 6s infinite linear;
}
.testimonial-wrapper1 .avatar2 img {
  transform: scale(0.91);
}
.testimonial-wrapper1 .avatar3 {
  bottom: 30%;
  left: 16%;
  animation: move7 4.5s infinite linear;
}
.testimonial-wrapper1 .avatar3 img {
  transform: scale(0.72);
}
.testimonial-wrapper1 .avatar4 {
  top: 16%;
  right: 17%;
  animation: move7 6s infinite linear;
}
.testimonial-wrapper1 .avatar4 img {
  transform: scale(0.55);
}
.testimonial-wrapper1 .avatar5 {
  top: 45%;
  right: -2%;
  animation: move7 5s infinite linear;
}
.testimonial-wrapper1 .avatar5 img {
  transform: scale(0.91);
}
.testimonial-wrapper1 .avatar6 {
  right: 13%;
  bottom: 17%;
  animation: move7 4.5s infinite linear;
}
.testimonial-wrapper1 .avatar6 img {
  transform: scale(0.82);
}

.testimonial-wrapper2 {
  position: relative;
  overflow: hidden;
}
.testimonial-wrapper2 .swiper-slide {
  max-width: 745px;
  opacity: 0.3;
  margin: 0 15px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 1280px) {
  .testimonial-wrapper2 .swiper-slide {
    max-width: 600px;
  }
}
@media only screen and (max-width: 991px) {
  .testimonial-wrapper2 .swiper-slide {
    max-width: 500px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonial-wrapper2 .swiper-slide {
    max-width: 100%;
    padding: 0 15px;
  }
}
.testimonial-wrapper2 .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.testimonial-wrapper2 .swiper-slide.swiper-slide-active .testimonial-2 {
  background-color: var(--secondary);
}
.testimonial-wrapper2 .swiper-slide.swiper-slide-active .testimonial-2 .testimonial-text {
  color: #fff;
}
.testimonial-wrapper2 .swiper-slide.swiper-slide-active .testimonial-2 .testimonial-name {
  color: var(--primary);
}
.testimonial-wrapper2 .swiper-slide.swiper-slide-active .testimonial-2 .testimonial-position {
  color: rgba(255, 255, 255, 0.7);
}

.testimonial-2 .testimonial-pic {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 0;
}
.testimonial-2 .testimonial-pic img {
  height: 100%;
  object-fit: cover;
}

.testimonial-2 .testimonial-info .quote {
  font-size: 2rem;
  color: var(--primary);
}

.testimonial-2 .testimonial-info .testimonial-text {
  font-size: 19px;
  font-weight: 400;
  color: #676767;
}
@media only screen and (max-width: 1280px) {
  .testimonial-2 .testimonial-info .testimonial-text {
    font-size: 1rem;
  }
}

.testimonial-2 .testimonial-info .testimonial-name {
  font-size: 24px;
  font-weight: 500;
}

.testimonial-2 .testimonial-info .testimonial-position {
  font-size: 1rem;
  font-weight: 500;
}

@media only screen and (max-width: 991px) {
  .testimonial-2 .testimonial-pic {
    height: 100px;
    width: 100%;
    text-align: center;
    margin: 0;
  }
  .testimonial-2 .testimonial-pic img {
    border-radius: 50%;
    border: 5px solid var(--rgba-primary-3);
  }
  .testimonial-2 .testimonial-info {
    text-align: center;
  }
}

.testimonial-swiper-wrapper-3 .dz-swiper-next3,
.testimonial-swiper-wrapper-3 .dz-swiper-prev3 {
  position: absolute;
  top: 60%;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 0px;
  color: var(--theme-text-color);
  background: var(--primary);
  font-size: 15px;
  z-index: 1;
  text-align: center;
  margin: 0 10px;
  display: inline-block;
  transform: skewX(-12deg);
  box-shadow: 0px 10px 30px 0px var(--rgba-primary-3);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.testimonial-swiper-wrapper-3 .dz-swiper-next3:hover,
.testimonial-swiper-wrapper-3 .dz-swiper-prev3:hover {
  background: #000;
}
@media only screen and (max-width: 575px) {
  .testimonial-swiper-wrapper-3 .dz-swiper-next3,
  .testimonial-swiper-wrapper-3 .dz-swiper-prev3 {
    top: 40%;
  }
}

.testimonial-swiper-wrapper-3 .dz-swiper-next3 {
  right: 10%;
}
@media only screen and (max-width: 1280px) {
  .testimonial-swiper-wrapper-3 .dz-swiper-next3 {
    right: 2%;
  }
}

.testimonial-swiper-wrapper-3 .dz-swiper-prev3 {
  left: 10%;
}
@media only screen and (max-width: 1280px) {
  .testimonial-swiper-wrapper-3 .dz-swiper-prev3 {
    left: 2%;
  }
}

.icon-bx-xl {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 150px;
  height: 150px;
  line-height: 150px;
}
.icon-bx-xl.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-xl i {
  font-size: 80px;
  vertical-align: middle;
}
.icon-bx-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-bx-lg {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 120px;
  height: 120px;
  line-height: 120px;
}
.icon-bx-lg.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-lg i {
  font-size: 60px;
  vertical-align: middle;
}
.icon-bx-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 55px;
}

.icon-bx-md {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.icon-bx-md.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-md i {
  font-size: 45px;
  vertical-align: middle;
}
.icon-bx-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 40px;
}

.icon-bx-sm {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 67px;
  height: 67px;
  min-width: 67px;
  line-height: 67px;
}
.icon-bx-sm.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-sm i {
  font-size: 35px;
  vertical-align: middle;
}
.icon-bx-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 35px;
}

.icon-bx-xs {
  display: inline-block;
  text-align: center;
  border-radius: 3px;
  width: 60px;
  height: 60px;
  line-height: 60px;
}
.icon-bx-xs.radius {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}
.icon-bx-xs i {
  font-size: 30px;
  vertical-align: middle;
}
.icon-bx-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-xl {
  display: inline-block;
  text-align: center;
  width: 100px;
  line-height: 1;
}
.icon-xl i {
  vertical-align: middle;
  font-size: 80px;
}
.icon-xl img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 80px;
}

.icon-lg {
  display: inline-block;
  text-align: center;
  line-height: 1;
}
.icon-lg i {
  vertical-align: middle;
  font-size: 60px;
}
@media only screen and (max-width: 767px) {
  .icon-lg i {
    font-size: 50px;
    line-height: 1.2;
  }
}
.icon-lg img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 70px;
}

.icon-md {
  display: inline-block;
  text-align: center;
  line-height: 1;
}
.icon-md i {
  vertical-align: middle;
  font-size: 45px;
}
.icon-md img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 50px;
}

.icon-sm {
  display: inline-block;
  text-align: center;
  width: 40px;
  line-height: 1;
}
.icon-sm i {
  vertical-align: middle;
  font-size: 30px;
}
.icon-sm img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 30px;
}

.icon-xs {
  display: inline-block;
  text-align: center;
  width: 30px;
  line-height: 1;
}
.icon-xs i {
  vertical-align: middle;
  font-size: 20px;
}
.icon-xs img {
  vertical-align: middle;
  max-width: 100%;
  width: auto;
  height: 20px;
}

.icon-bx-wraper {
  position: relative;
}
.icon-bx-wraper .dz-tilte {
  margin-top: 0;
  font-weight: 600;
}
.icon-bx-wraper .after-titile-line {
  margin-bottom: 10px;
}
.icon-bx-wraper p:last-child {
  margin: 0;
}
.icon-bx-wraper.center {
  text-align: center;
}
.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
  float: left;
  margin-right: 15px;
}
.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
  line-height: 1;
  float: left;
  margin-right: 10px;
}
.icon-bx-wraper.right {
  text-align: right;
}
.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
  float: right;
  margin-left: 20px;
}
.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
  line-height: 1;
  float: right;
  margin-left: 10px;
}
.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ebedf2;
}
.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
  margin-left: -75px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
  margin-left: -60px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-md {
  margin-left: -50px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
  margin-left: -40px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
  margin-left: -20px;
}
.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.icon-content {
  overflow: hidden;
}

[class*="icon-bx-"][class*="bg-"] a,
[class*="icon-bx-"][class*="bg-"] span {
  color: #fff;
}

[class*="icon-bx-"].bg-white a {
  color: inherit;
}

[class*="icon-bx-"][class*="border-"] {
  display: table;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
}

[class*="icon-bx-"][class*="border-"] .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

.dz-box,
.dz-info,
.dz-tilte,
.dz-tilte-inner {
  position: relative;
}

.dz-tilte-inner {
  display: inline-block;
}

.dz-box[class*="border-"],
.dz-info[class*="border-"] {
  border-color: #eee;
}

.dz-info.border-1,
.dz-info.border-2,
.dz-info.border-3,
.dz-info.border-4,
.dz-info.border-5 {
  border-top: none;
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.left-border,
.right-border {
  position: relative;
}
.left-border:before,
.right-border:before {
  content: "";
  position: absolute;
  top: 5px;
  width: 1px;
  height: 90%;
  background: #ccc;
}

.right-border:before {
  right: 0;
}

.dz-media,
.dz-post-media {
  position: relative;
  overflow: hidden;
}
.dz-media img,
.dz-post-media img {
  width: 370px;
  height: 260px;
}
.phone-myclnq {
  display: flex;
}
.phone-myclnq-img {
  width: 300px !important;
  height: auto !important;
}
@media only screen and (max-width: 767px) and (orientation: portrait) {

  .phone-myclnq-img {
    width: 250px !important;
    height: auto !important;
  }
}

@media only screen and (max-width: 999px) and (orientation: landscape) {
  .phone-myclnq-img {
    width: 200px !important;
    height: auto !important;
  }
}
.myclnq-qr {
  height: 150px !important;
  width: 150px !important;
}

.dz-box-bg {
  overflow: hidden;
  background-size: cover;
}
.dz-box-bg .btn,
.dz-box-bg .wp-block-button__link {
  overflow: unset;
}
.dz-box-bg .glyph-icon {
  font-size: 50px;
}
.dz-box-bg .icon-bx-wraper {
  background-color: #f8f8f8;
}
.dz-box-bg .text-primary,
.dz-box-bg .icon-content .dz-tilte,
.dz-box-bg .icon-content p,
.dz-box-bg .icon-box-btn .btn,
.dz-box-bg .icon-box-btn .wp-block-button__link {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-box-bg.active .icon-bx-wraper {
  background-color: rgba(0, 0, 0, 0.7);
}
.dz-box-bg.active .text-primary,
.dz-box-bg.active .icon-content .dz-tilte,
.dz-box-bg.active .icon-content p,
.dz-box-bg.active .icon-box-btn .btn,
.dz-box-bg.active .icon-box-btn .wp-block-button__link {
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.dz-box-bg .icon-box-btn .btn,
.dz-box-bg .icon-box-btn .wp-block-button__link {
  background-color: #eeeeee;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 0;
  color: #1abc9c;
  font-weight: 600;
  margin-bottom: 0;
}

.icon-bx-wraper.style-1 {
  padding: 30px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin-top: 40px;
  margin-bottom: 15px;
  z-index: 1;
}
.icon-bx-wraper.style-1:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--rgba-primary-1);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transform: skewY(-5deg);
}
.icon-bx-wraper.style-1 .icon-bx {
  height: 140px;
  width: 250px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px -10px 50px 0px var(--rgba-primary-2);
  margin-top: -70px;
}
.icon-bx-wraper.style-1 .icon-content {
  overflow: visible;
}
.icon-bx-wraper.style-1:hover,
.icon-bx-wraper.style-1.active {
  color: var(--theme-text-color);
}
.icon-bx-wraper.style-1:hover .dz-title,
.icon-bx-wraper.style-1:hover .dz-title a,
.icon-bx-wraper.style-1.active .dz-title,
.icon-bx-wraper.style-1.active .dz-title a {
  color: var(--theme-text-color);
}
.icon-bx-wraper.style-1:hover .icon-cell,
.icon-bx-wraper.style-1.active .icon-cell {
  animation: move-lr 1.2s linear;
}
.icon-bx-wraper.style-1:hover .btn,
.icon-bx-wraper.style-1:hover .wp-block-button__link,
.icon-bx-wraper.style-1.active .btn,
.icon-bx-wraper.style-1.active .wp-block-button__link {
  background-color: #fff;
  color: #000;
}
.icon-bx-wraper.style-1:hover:after,
.icon-bx-wraper.style-1.active:after {
  background-color: var(--primary);
  box-shadow: 0px 10px 30px 0px rgba(255, 127, 57, 0.2);
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-1 .icon-bx {
    height: 105px;
    width: 105px;
    margin-top: -60px;
  }
  .icon-bx-wraper.style-1 .icon-bx img {
    width: 170px;
    height: 120px;
  }
}

@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-1 {
    padding: 25px;
  }
  .icon-bx-wraper.style-1 .icon-bx {
    height: 95px;
    width: 150px;
    margin-top: -50px;
  }
  .icon-bx-wraper.style-1 .icon-bx img {
    width: 150px;
    height: 100px;
  }
}

.icon-bx-wraper.style-2 {
  padding: 15px 15px 15px 25px;
  position: relative;
  background: white;
  height: 100px;
  transform: skewX(-12deg);
  display: flex;
  align-items: center;
  box-shadow: 0px 10px 50px 0px rgba(0, 35, 55, 0.1);
}
.icon-bx-wraper.style-2:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 7px;
  height: 100%;
  background: var(--primary);
  opacity: 0.3;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-2 .icon-bx {
  margin-right: 15px;
  transform: skewX(12deg);
  height: 35px;
  width: 75px;
  min-width: 35px;
}
.icon-bx-wraper.style-2 .icon-content {
  transform: skewX(12deg);
}
.icon-bx-wraper.style-2 .icon-content .dz-title {
  color: #000;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}
.icon-bx-wraper.style-2:hover:after {
  opacity: 1;
}
@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-2 {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.icon-bx-wraper.style-3 {
  padding: 30px;
  background-color: var(--secondary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: #fff;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.icon-bx-wraper.style-3 .icon-bx {
  display: flex;
}
.icon-bx-wraper.style-3 .icon-cell {
  display: inline-block;
}
.icon-bx-wraper.style-3 .icon-cell i {
  font-size: 60px;
  line-height: 1;
  display: flex;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-3 .icon-cell img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-3 .icon-content {
  overflow: visible;
}
.icon-bx-wraper.style-3:after {
  content: "";
  position: absolute;
  display: inline-block;
  background-color: var(--primary);
  width: 0%;
  height: 100%;
  top: 0;
  z-index: -1;
  right: 0%;
  transform-origin: right center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-3 .dz-title,
.icon-bx-wraper.style-3 .dz-title a {
  color: #fff;
  font-size: 28px;
  font-weight: 400;
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-3 .dz-title,
  .icon-bx-wraper.style-3 .dz-title a {
    font-size: 24px;
  }
}
.icon-bx-wraper.style-3 p {
  font-size: 15px;
  color: #e0e0e0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-3 .read-more {
  color: var(--primary);
  letter-spacing: 2px;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--font-family-title);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-3:hover,
.icon-bx-wraper.style-3.active {
  color: var(--theme-text-color);
}
.icon-bx-wraper.style-3:hover p,
.icon-bx-wraper.style-3:hover .dz-title,
.icon-bx-wraper.style-3:hover .dz-title a,
.icon-bx-wraper.style-3:hover .read-more,
.icon-bx-wraper.style-3.active p,
.icon-bx-wraper.style-3.active .dz-title,
.icon-bx-wraper.style-3.active .dz-title a,
.icon-bx-wraper.style-3.active .read-more {
  color: var(--theme-text-color);
}
.icon-bx-wraper.style-3:hover .icon-cell,
.icon-bx-wraper.style-3.active .icon-cell {
  display: inline-block;
  animation: move-lr 1.2s linear;
}
.icon-bx-wraper.style-3:hover .icon-cell i,
.icon-bx-wraper.style-3.active .icon-cell i {
  color: var(--theme-text-color);
}
.icon-bx-wraper.style-3:hover .icon-cell img,
.icon-bx-wraper.style-3.active .icon-cell img {
  filter: brightness(0);
}
.icon-bx-wraper.style-3:hover .btn,
.icon-bx-wraper.style-3:hover .wp-block-button__link,
.icon-bx-wraper.style-3.active .btn,
.icon-bx-wraper.style-3.active .wp-block-button__link {
  background-color: #fff;
  color: #000;
}
.icon-bx-wraper.style-3:hover:after,
.icon-bx-wraper.style-3.active:after {
  left: 0;
  width: 100%;
  transform-origin: left center;
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-3 .icon-bx img {
    width: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-3 {
    padding: 25px;
  }
  .icon-bx-wraper.style-3 .icon-bx img {
    width: 45px;
  }
}

.icon-bx-wraper.style-4 {
  padding: 30px;
  background-color: var(--secondary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: #000;
  z-index: 1;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.1);
}
.icon-bx-wraper.style-4::before {
  content: "";
  position: absolute;
  top: 6px;
  right: 6px;
  border-top: 10px solid var(--primary);
  border-right: 10px solid var(--primary);
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
}
.icon-bx-wraper.style-4 .icon-bx {
  display: flex;
}
.icon-bx-wraper.style-4 .icon-bx.icon-bg-white .dz-title {
  margin: 0;
}
.icon-bx-wraper.style-4 .icon-bx.icon-bg-white .icon-cell {
  padding: 10px;
  background: white;
}
.icon-bx-wraper.style-4 .icon-bx.icon-bg-white .icon-cell i {
  font-size: 30px;
}
.icon-bx-wraper.style-4 .icon-cell {
  display: inline-block;
}
.icon-bx-wraper.style-4 .icon-cell i {
  font-size: 60px;
  line-height: 1;
  display: flex;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-4 .icon-cell img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-4 .icon-content {
  overflow: visible;
}
.icon-bx-wraper.style-4:after {
  content: "";
  position: absolute;
  display: inline-block;
  background-color: var(--secondary);
  width: 0%;
  height: 100%;
  top: 0;
  z-index: -1;
  right: 0%;
  transform-origin: right center;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-4 .dz-title,
.icon-bx-wraper.style-4 .dz-title a {
  color: #000;
  font-size: 24px;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-4 .dz-title,
  .icon-bx-wraper.style-4 .dz-title a {
    font-size: 24px;
  }
}
.icon-bx-wraper.style-4 p {
  font-size: 15px;
  color: #666666;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-4 .read-more {
  color: var(--primary);
  letter-spacing: 0.1em;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--font-family-title);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-4:hover,
.icon-bx-wraper.style-4.active {
  color: var(--theme-text-color);
  transform: translateY(-10px);
}
.icon-bx-wraper.style-4:hover .dz-title a,
.icon-bx-wraper.style-4:hover p,
.icon-bx-wraper.style-4.active .dz-title a,
.icon-bx-wraper.style-4.active p {
  color: rgba(255, 255, 255, 0.7);
}
.icon-bx-wraper.style-4:hover .dz-title a,
.icon-bx-wraper.style-4.active .dz-title a {
  color: #fff;
}
.icon-bx-wraper.style-4:hover .read-more,
.icon-bx-wraper.style-4.active .read-more {
  color: var(--primary);
}
.icon-bx-wraper.style-4:hover .icon-cell,
.icon-bx-wraper.style-4.active .icon-cell {
  display: inline-block;
}
.icon-bx-wraper.style-4:hover .icon-cell i,
.icon-bx-wraper.style-4.active .icon-cell i {
  animation: move-lr 1.2s linear;
  color: var(--primary);
}
.icon-bx-wraper.style-4:hover .icon-cell img,
.icon-bx-wraper.style-4.active .icon-cell img {
  filter: brightness(0);
}
.icon-bx-wraper.style-4:hover .btn,
.icon-bx-wraper.style-4:hover .wp-block-button__link,
.icon-bx-wraper.style-4.active .btn,
.icon-bx-wraper.style-4.active .wp-block-button__link {
  background-color: #fff;
  color: #000;
}
.icon-bx-wraper.style-4:hover:after,
.icon-bx-wraper.style-4.active:after {
  left: 0;
  width: 100%;
  transform-origin: left center;
}

.icon-bx-wraper.style-5 {
  padding: 30px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: #000;
  z-index: 1;
  position: relative;
}
.icon-bx-wraper.style-5 .icon-bx {
  display: flex;
}
.icon-bx-wraper.style-5 .icon-cell {
  display: inline-block;
}
.icon-bx-wraper.style-5 .icon-cell i {
  font-size: 60px;
  line-height: 1;
  display: flex;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-5 .icon-cell img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-5 .icon-content {
  padding-right: 60px;
}
.icon-bx-wraper.style-5 .icon-content .dz-title,
.icon-bx-wraper.style-5 .icon-content .dz-title a {
  color: #000;
  font-size: 24px;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-5 .icon-content .dz-title,
  .icon-bx-wraper.style-5 .icon-content .dz-title a {
    font-size: 24px;
  }
}
.icon-bx-wraper.style-5 .icon-content p {
  font-size: 15px;
  color: #666666;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-5 .icon-content .read-more {
  color: #000;
  letter-spacing: 0.1em;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: var(--font-family-title);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-5 .badge {
  position: absolute;
  top: 0;
  right: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: visible;
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0 48%, 0% 0%);
  z-index: -1;
}
@media (max-width: 1401px) {
  .icon-bx-wraper.style-5 .badge {
    clip-path: polygon(80% 0%, 100% 50%, 80% 100%, 0% 100%, 0 48%, 0% 0%);
  }
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-5 .badge {
    clip-path: polygon(70% 0%, 100% 50%, 70% 100%, 0% 100%, 0 48%, 0% 0%);
  }
}
.icon-bx-wraper.style-5 .badge span {
  font-weight: 600;
  font-size: 240px;
  line-height: 30px;
  text-transform: capitalize;
  color: var(--secondary);
  font-family: "Oswald", sans-serif;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-5 .badge span {
    font-size: 180px;
    right: -10px;
  }
}
@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-5 .badge span {
    font-size: 150px;
  }
}
.icon-bx-wraper.style-5 .badge::before {
  content: "";
  position: absolute;
  top: 0;
  width: 80px;
  height: 100%;
  right: 0%;
  background: #232323;
  opacity: 0.13;
  filter: blur(27px);
  transform: matrix(-1, 0, 0, 1, 0, 0);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-5 svg {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: var(--secondary);
}
.icon-bx-wraper.style-5:hover svg,
.icon-bx-wraper.style-5.active svg {
  color: var(--primary);
}
.icon-bx-wraper.style-5:hover .badge span,
.icon-bx-wraper.style-5.active .badge span {
  color: var(--primary);
}
.icon-bx-wraper.style-5:hover::before,
.icon-bx-wraper.style-5.active::before {
  background: var(--primary);
}
.icon-bx-wraper.style-5:hover .read-more,
.icon-bx-wraper.style-5.active .read-more {
  color: var(--primary);
}
@media only screen and (max-width: 767px) {
  .icon-bx-wraper.style-5 {
    background: #fff;
  }
  .icon-bx-wraper.style-5 svg {
    display: none;
  }
  .icon-bx-wraper.style-5 .badge {
    clip-path: none;
    height: auto;
    top: 30px;
    right: 20px;
  }
  .icon-bx-wraper.style-5 .badge span {
    font-size: 80px;
  }
  .icon-bx-wraper.style-5 .badge::before {
    display: none;
  }
}

.icon-bx-wraper.style-6 {
  padding: 30px;
  background-color: var(--secondary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.icon-bx-wraper.style-6 .icon-bx {
  display: flex;
}
.icon-bx-wraper.style-6 .icon-cell {
  display: inline-block;
}
.icon-bx-wraper.style-6 .icon-cell i {
  font-size: 60px;
  line-height: 1;
  display: flex;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-6 .icon-cell img {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-6 .icon-content {
  overflow: visible;
}
.icon-bx-wraper.style-6:after {
  content: "";
  position: absolute;
  display: inline-block;
  background-color: var(--secondary);
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  right: 0%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 1;
  border: 2px solid var(--secondary);
}
.icon-bx-wraper.style-6 p {
  font-size: 15px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-6 .read-more {
  color: var(--primary);
  text-transform: uppercase;
  font-family: var(--font-family-title);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.icon-bx-wraper.style-6:hover,
.icon-bx-wraper.style-6.active {
  color: var(--theme-text-color);
}
.icon-bx-wraper.style-6:hover p,
.icon-bx-wraper.style-6:hover .dz-title,
.icon-bx-wraper.style-6:hover .dz-title a,
.icon-bx-wraper.style-6:hover .read-more,
.icon-bx-wraper.style-6.active p,
.icon-bx-wraper.style-6.active .dz-title,
.icon-bx-wraper.style-6.active .dz-title a,
.icon-bx-wraper.style-6.active .read-more {
  color: var(--theme-text-color);
}
.icon-bx-wraper.style-6:hover .icon-cell,
.icon-bx-wraper.style-6.active .icon-cell {
  display: inline-block;
  animation: move-lr 1.2s linear;
}
.icon-bx-wraper.style-6:hover .icon-cell i,
.icon-bx-wraper.style-6.active .icon-cell i {
  color: var(--theme-text-color);
}
.icon-bx-wraper.style-6:hover .icon-cell img,
.icon-bx-wraper.style-6.active .icon-cell img {
  filter: brightness(0);
}
.icon-bx-wraper.style-6:hover .btn,
.icon-bx-wraper.style-6:hover .wp-block-button__link,
.icon-bx-wraper.style-6.active .btn,
.icon-bx-wraper.style-6.active .wp-block-button__link {
  background-color: #fff;
  color: #000;
}
.icon-bx-wraper.style-6:hover:after,
.icon-bx-wraper.style-6.active:after {
  opacity: 0.8;
  border-color: var(--primary);
}
@media only screen and (max-width: 991px) {
  .icon-bx-wraper.style-6 .icon-bx img {
    width: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .icon-bx-wraper.style-6 {
    padding: 25px;
  }
  .icon-bx-wraper.style-6 .icon-bx img {
    width: 45px;
  }
}

.icon-bx-wraper.pad-t {
  margin-top: 100px;
}
@media only screen and (max-width: 1280px) {
  .icon-bx-wraper.pad-t {
    margin-top: 0;
  }
}

.pad-m {
  margin-top: -86px;
}
@media only screen and (max-width: 1280px) {
  .pad-m {
    margin-top: 0;
  }
}

@keyframes toTopFromBottom {
  49% {
    transform: translateY(-100%);
  }
  50% {
    opacity: 0;
    transform: translateY(100%);
  }
  51% {
    opacity: 1;
  }
}

@keyframes left-right {
  20% {
    transform: translateX(-5px) rotate(-5deg);
  }
  40% {
    transform: translateX(5px) rotate(5deg);
  }
  60% {
    transform: translateX(-5px) rotate(-5deg);
  }
  80% {
    transform: translateX(5px) rotate(5deg);
  }
  100% {
    transform: translateX(-5px) rotate(-5deg);
  }
}

.dz-team {
  transition: all 0.5s;
}
.dz-team .dz-name a {
  transition: all 0.5s;
}
.dz-team .dz-content {
  transition: all 0.5s;
  position: relative;
}
.dz-team .dz-media img {
  width: 100%;
}
.dz-team .dz-position {
  display: block;
}

.dz-team.style-1 {
  position: relative;
  margin-bottom: 100px;
}
@media only screen and (max-width: 767px) {
  .dz-team.style-1 {
    margin-bottom: 60px;
  }
}
.dz-team.style-1 .dz-media {
  border-right: 5px solid var(--rgba-primary-1);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-team.style-1 .dz-media img {
  padding-bottom: 20px;
  height: 100%;
  object-fit: cover;
}
.dz-team.style-1 .dz-media .overlay-content {
  position: absolute;
}
.dz-team.style-1 .team-social {
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 3;
}
.dz-team.style-1 .team-social li {
  opacity: 0;
  height: 45px;
  width: 45px;
  line-height: 45px;
  margin-bottom: 5px;
  text-align: center;
  position: relative;
  transform: translateX(50px) skewY(-12deg);
  background-color: var(--primary-dark);
}
.dz-team.style-1 .team-social li a {
  font-size: 16px;
  width: 100%;
  height: 100%;
  transform: skewY(12deg);
  display: inline-block;
  color: #fff;
}
.dz-team.style-1 .team-social li:nth-child(1) {
  transition: 0.2s;
}
.dz-team.style-1 .team-social li:nth-child(2) {
  transition: 0.4s;
}
.dz-team.style-1 .team-social li:nth-child(3) {
  transition: 0.6s;
}
.dz-team.style-1 .dz-position {
  margin-bottom: 0;
  font-size: 21px;
  font-weight: 500;
  padding: 12px 0;
  padding-right: 25px;
  text-align: end;
  width: 75%;
  position: absolute;
  bottom: -60px;
  right: 0px;
  text-transform: uppercase;
  z-index: 2;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: white !important;
  background-color: var(--primary);
}
@media only screen and (max-width: 1199px) {
  .dz-team.style-1 .dz-position {
    font-size: 16px;
    padding: 8px 25px;
    bottom: -50px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-team.style-1 .dz-position {
    padding-right: 18px;
    padding-left: 0;
  }
}
@media only screen and (max-width: 575px) {
  .dz-team.style-1 .dz-position {
    width: 60%;
  }
}
.dz-team.style-1 .dz-name {
  text-transform: uppercase;
  background-color: #f5f5f5;
  margin-bottom: 0px;
  padding: 25px 25px 25px 0;
  text-align: right;
  width: 95%;
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  bottom: -15px;
  right: 0px;
  z-index: 1;
  color: var(--primary);
}
.dz-team.style-1 .dz-name a {
  color: var(--primary);
}
.dz-team.style-1 .dz-name:after {
  content: "";
  top: 0;
  left: 0px;
  height: 100%;
  width: 100%;
  z-index: -1;
  opacity: 0;
  position: absolute;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: var(--gradient-2);
}
@media only screen and (max-width: 1199px) {
  .dz-team.style-1 .dz-name {
    font-size: 30px;
    padding: 17px 25px;
    padding-left: 0;
    font-weight: 600;
  }
}
@media only screen and (max-width: 1480px) {
  .dz-team.style-1 .dz-name {
    font-size: 25px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .dz-team.style-1 .dz-name {
    padding-right: 18px;
    padding-left: 0;
  }
}
@media only screen and (max-width: 575px) {
  .dz-team.style-1 .dz-name {
    width: 85%;
  }
}
.dz-team.style-1 .dz-content {
  position: absolute;
  width: 100%;
  transform: skewY(-17deg);
}
.dz-team.style-1:hover .team-social li,
.dz-team.style-1.active .team-social li {
  transform: translateY(0px) skewY(-12deg);
  opacity: 1;
}
.dz-team.style-1:hover .dz-media,
.dz-team.style-1.active .dz-media {
  border-color: var(--primary-dark);
}
.dz-team.style-1:hover .dz-content .dz-name,
.dz-team.style-1.active .dz-content .dz-name {
  color: var(--theme-text-color);
}
.dz-team.style-1:hover .dz-content .dz-name a,
.dz-team.style-1.active .dz-content .dz-name a {
  color: var(--theme-text-color);
}
.dz-team.style-1:hover .dz-content .dz-name:after,
.dz-team.style-1.active .dz-content .dz-name:after {
  opacity: 1;
  box-shadow: 0px 20px 50px 0px rgba(255, 63, 19, 0.3);
}
.dz-team.style-1:hover .dz-content .dz-position,
.dz-team.style-1.active .dz-content .dz-position {
  background-color: var(--secondary);
  color: var(--primary) !important;
}

.dz-team.style-2 {
  position: relative;
  margin-bottom: 100px;
}
.dz-team.style-2 .dz-media {
  border-right: 5px solid var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-team.style-2 .dz-media img {
  padding-bottom: 20px;
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 1199px) {
  .dz-team.style-2 .dz-media img {
    padding-bottom: 10px;
  }
}
@media only screen and (max-width: 991px) {
  .dz-team.style-2 .dz-media img {
    padding-bottom: 0px;
  }
}
.dz-team.style-2 .dz-media .overlay-content {
  position: absolute;
}
.dz-team.style-2 .team-social {
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 3;
}
.dz-team.style-2 .team-social li {
  position: relative;
  opacity: 0;
  margin-bottom: 5px;
  background-color: var(--primary);
  height: 45px;
  line-height: 45px;
  width: 45px;
  text-align: center;
  transform: translateX(50px) skewY(-12deg);
}
.dz-team.style-2 .team-social li:nth-child(1) {
  transition: 0.2s;
}
.dz-team.style-2 .team-social li:nth-child(2) {
  transition: 0.4s;
}
.dz-team.style-2 .team-social li:nth-child(3) {
  transition: 0.6s;
}
.dz-team.style-2 .team-social li a {
  font-size: 16px;
  width: 100%;
  height: 100%;
  transform: skewY(12deg);
  display: inline-block;
  color: var(--theme-text-color);
}
.dz-team.style-2 .dz-position {
  margin-bottom: 0;
  font-size: 21px;
  font-weight: 500;
  padding: 12px 0;
  padding-right: 25px;
  text-align: end;
  width: 75%;
  position: absolute;
  bottom: -60px;
  right: 0px;
  text-transform: uppercase;
  z-index: 2;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  color: white !important;
  background-color: var(--secondary);
}
@media only screen and (max-width: 1199px) {
  .dz-team.style-2 .dz-position {
    font-size: 16px;
    padding: 8px 25px;
    bottom: -50px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-team.style-2 .dz-position {
    padding-right: 18px;
    padding-left: 0;
  }
}
.dz-team.style-2 .dz-name {
  text-transform: uppercase;
  background-color: var(--primary);
  margin-bottom: 0px;
  padding: 25px 25px 25px 0;
  text-align: right;
  width: 100%;
  font-size: 40px;
  font-weight: 700;
  position: absolute;
  letter-spacing: 2px;
  bottom: -15px;
  right: 0px;
  z-index: 1;
  color: var(--theme-text-color);
}
@media only screen and (max-width: 1480px) {
  .dz-team.style-2 .dz-name {
    font-size: 32px;
  }
}
@media only screen and (max-width: 1199px) {
  .dz-team.style-2 .dz-name {
    font-size: 28px;
    padding: 17px 25px;
    padding-left: 0;
    font-weight: 600;
  }
}
@media only screen and (max-width: 767px) {
  .dz-team.style-2 .dz-name {
    padding-right: 18px;
    padding-left: 0;
  }
}
.dz-team.style-2 .dz-content {
  position: absolute;
  width: 100%;
  transform: skewY(-17deg);
}
.dz-team.style-2:hover .team-social li,
.dz-team.style-2.active .team-social li {
  transform: translateY(0px) skewY(-12deg);
  opacity: 1;
}

.team-slider .dz-team {
  margin-bottom: 90px;
}
@media only screen and (max-width: 767px) {
  .team-slider .dz-team {
    margin-bottom: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .team-slider .dz-team {
    margin-bottom: 80px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.team-slider-wrapper .btn-next,
.team-slider-wrapper .btn-prev {
  position: absolute;
  top: 50%;
  left: 8%;
  color: var(--theme-text-color);
}

.team-slider-wrapper .btn-next {
  right: 8%;
  left: auto;
}

.team-slider-wrapper .num-pagination {
  margin-top: 50px;
}
@media only screen and (max-width: 767px) {
  .team-slider-wrapper .num-pagination {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .team-slider-wrapper .num-pagination {
    margin-top: 20px;
  }
}

.team-slider-wrapper:hover .btn-prev {
  left: 12%;
}

.team-slider-wrapper:hover .btn-next {
  right: 12%;
}

@media only screen and (max-width: 1680px) {
  .team-slider-wrapper .btn-prev {
    left: 50px;
  }
  .team-slider-wrapper .btn-next {
    right: 50px;
  }
  .team-slider-wrapper:hover .btn-prev {
    left: 100px;
  }
  .team-slider-wrapper:hover .btn-next {
    right: 100px;
  }
}

@media only screen and (max-width: 1480px) {
  .team-slider-wrapper .btn-prev {
    left: 50px;
  }
  .team-slider-wrapper .btn-next {
    right: 50px;
  }
  .team-slider-wrapper:hover .btn-prev {
    left: 50px;
  }
  .team-slider-wrapper:hover .btn-next {
    right: 50px;
  }
}

.dz-card {
  position: relative;
  background-color: #fff;
}
.dz-card .dz-info {
  padding: 30px;
  position: relative;
}
.dz-card .dz-title {
  margin-bottom: 15px;
  word-break: break-word;
}
@media only screen and (max-width: 575px) {
  .dz-card .dz-title {
    margin-bottom: 15px;
  }
}
.dz-card.blog-half {
  display: flex;
}
@media only screen and (max-width: 575px) {
  .dz-card.blog-half {
    display: block;
  }
}
.dz-card.blog-half .dz-info {
  padding: 30px;
  position: relative;
  flex: 1;
}
.dz-card.blog-half .dz-media {
  margin-bottom: 0;
  max-width: 300px;
  min-width: 300px;
}
.dz-card.blog-half .dz-media a {
  display: block;
  height: 100%;
}
.dz-card.blog-half .dz-media img {
  height: 100%;
  object-fit: cover;
  min-height: 215px;
}
@media only screen and (max-width: 575px) {
  .dz-card.blog-half .dz-media img {
    min-height: auto;
  }
}
.dz-card.blog-half.post-video .post-video-icon {
  width: 60px;
  height: 60px;
  font-size: 20px;
}
.dz-card.blog-half .swiper {
  height: 100%;
}

.dz-media.height-sm {
  height: 220px;
}
.dz-media.height-sm img {
  height: 100%;
  object-fit: cover;
}

.dz-media.height-md {
  height: 265px;
}
.dz-media.height-md img {
  height: 100%;
  object-fit: cover;
}
@media only screen and (max-width: 1199px) {
  .dz-media.height-md {
    height: 220px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-media.height-md {
    height: 200px;
  }
}
.dz-media.height-md .post-swiper {
  height: 100%;
}

.dz-media.height-lg {
  height: 350px;
}
@media only screen and (max-width: 1199px) {
  .dz-media.height-lg {
    height: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-media.height-lg {
    height: 220px;
  }
  .dz-media.height-lg img {
    height: 100%;
    object-fit: cover;
  }
}

.dz-meta {
  margin-bottom: 10px;
}
.dz-meta ul {
  margin: 0;
  padding: 0;
}
.dz-meta ul li {
  display: inline-block;
  position: relative;
  font-size: 15px;
  margin-right: 15px;
  padding-right: 15px;
}
.dz-meta ul li:after {
  content: "";
  height: 6px;
  width: 6px;
  background-color: var(--primary);
  position: absolute;
  top: 50%;
  right: -5px;
  border-radius: 50%;
  transform: translateY(-50%);
}
.dz-meta ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}
.dz-meta ul li:last-child:after {
  content: none;
}
.dz-meta ul li i {
  font-size: 16px;
  margin-right: 2px;
  color: var(--primary);
  transform: scale(1.3);
  position: relative;
  top: 2px;
}
.dz-meta ul li a {
  color: inherit;
}
@media only screen and (max-width: 575px) {
  .dz-meta ul li {
    margin-right: 10px;
    padding-right: 10px;
  }
}

.dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: 3px;
  padding: 6px 15px;
  font-size: 15px;
}
.dz-badge:hover {
  color: white;
}

.post-video .post-video-icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--primary);
  font-size: 30px;
  margin: 0 auto;
  background-color: #fff;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}
@media only screen and (max-width: 575px) {
  .post-video .post-video-icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}
.post-video .post-video-icon:before,
.post-video .post-video-icon:after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
  animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
}
.post-video .post-video-icon:before {
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  -webkit-animation: animationSignal1;
}
.post-video .post-video-icon:after {
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  -webkit-animation: animationSignal2;
}

.dz-card.blog-half.sticky {
  position: relative;
  border: 0.375rem solid var(--primary);
  overflow: hidden;
}
.dz-card.blog-half.sticky .sticky-icon {
  background: var(--primary);
  width: 3.125rem;
  height: 3.125rem;
  position: absolute;
  transform: rotate(45deg);
  top: -1.563rem;
  right: -1.563rem;
  z-index: 1;
  color: #fff;
}
.dz-card.blog-half.sticky .sticky-icon i {
  position: absolute;
  bottom: 0.1875rem;
  right: 1.125rem;
  transform: rotate(5deg);
  color: #fff;
  font-size: 1rem;
}

.dz-card.style-1 {
  box-shadow: 0px 0 50px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  padding: 20px;
  transform: skewY(-5deg);
  margin: 30px 0 20px;
}
.dz-card.style-1 .dz-info {
  transform: skewY(5deg);
  padding: 20px 0 0;
}
.dz-card.style-1 .dz-info p {
  margin-bottom: 25px;
  text-transform: capitalize;
}
@media only screen and (max-width: 991px) {
  .dz-card.style-1 .dz-info p {
    margin-bottom: 20px;
  }
}
.dz-card.style-1 .dz-info .dz-meta {
  margin-bottom: 12px;
}
.dz-card.style-1 .dz-info .dz-title {
  margin-bottom: 12px;
}
.dz-card.style-1 .dz-info .post-author img {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .dz-card.style-1 .dz-info .post-author img {
    height: 27px;
    width: 27px;
    margin-right: 5px;
  }
}
.dz-card.style-1 .dz-media {
  height: 220px;
  transform: skewY(5deg);
  border-radius: unset;
  margin-top: -50px;
}
.dz-card.style-1 .dz-media .dz-badge-list {
  position: absolute;
  top: 20px;
  left: 20px;
}
.dz-card.style-1 .dz-media .dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: var(--border-radius-base);
  padding: 8px 15px;
  font-size: 12px;
  text-transform: uppercase;
}
.dz-card.style-1:hover {
  box-shadow: 0px 0 50px rgba(0, 0, 0, 0.2);
}
.dz-card.style-1.blog-half {
  transform: skewX(0deg);
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .dz-card.style-1.blog-half {
    display: block;
  }
}
.dz-card.style-1.blog-half .dz-info {
  transform: skewX(0deg);
  padding: 0 0 0 30px;
}
@media only screen and (max-width: 1199px) {
  .dz-card.style-1.blog-half .dz-info {
    padding: 0 0 0 20px;
  }
  .dz-card.style-1.blog-half .dz-info .dz-title {
    font-size: 20px;
  }
  .dz-card.style-1.blog-half .dz-info .dz-meta ul li:last-child {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .dz-card.style-1.blog-half .dz-info {
    padding: 0;
  }
  .dz-card.style-1.blog-half .dz-info .dz-title {
    font-size: 18px;
  }
}
.dz-card.style-1.blog-half .dz-media {
  transform: skewX(0deg);
  margin: 0;
}
@media only screen and (max-width: 1199px) {
  .dz-card.style-1.blog-half .dz-media {
    max-width: 220px;
    min-width: 220px;
  }
}
@media only screen and (max-width: 767px) {
  .dz-card.style-1.blog-half .dz-media {
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
  }
}

.dz-card.style-2 {
  box-shadow: 0px 0 50px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
  margin-top: 30px;
}
.dz-card.style-2:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 1;
  z-index: 0;
  border: 2px solid transparent;
  background-color: var(--secondary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-card.style-2 .post-date {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 10px 10px;
  text-align: center;
  background-color: var(--primary);
  margin: -30px 0 0px 25px;
  color: var(--theme-text-color);
}
.dz-card.style-2 .post-date .date {
  font-size: 45px;
  color: inherit;
  margin-bottom: 0;
  display: block;
  line-height: 45px;
  font-weight: 700;
}
@media only screen and (max-width: 1480px) {
  .dz-card.style-2 .post-date .date {
    font-size: 36px;
  }
}
.dz-card.style-2 .post-date .months {
  color: inherit;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0;
  display: block;
}
.dz-card.style-2 .dz-info {
  padding: 20px 25px 25px 25px;
  overflow: hidden;
  z-index: 1;
  position: relative;
}
.dz-card.style-2 .dz-info p {
  margin-bottom: 25px;
  text-transform: capitalize;
  color: #ffffffb3;
}
@media only screen and (max-width: 991px) {
  .dz-card.style-2 .dz-info p {
    margin-bottom: 20px;
  }
}
.dz-card.style-2 .dz-info .dz-meta {
  margin-bottom: 12px;
}
@media only screen and (max-width: 1480px) {
  .dz-card.style-2 .dz-info .dz-meta ul li:not(:last-child) {
    margin-right: 10px;
    padding-right: 10px;
  }
}
.dz-card.style-2 .dz-info .dz-meta .post-author {
  color: #fff;
}
@media only screen and (max-width: 1280px) {
  .dz-card.style-2 .dz-info .dz-meta .post-author {
    font-size: 14px;
  }
}
.dz-card.style-2 .dz-info .dz-meta .post-author img {
  height: 35px;
  width: 35px;
  border: 2px solid #fff;
  border-radius: 100%;
  margin-right: 10px;
}
@media only screen and (max-width: 1480px) {
  .dz-card.style-2 .dz-info .dz-meta .post-author img {
    display: none;
  }
}
@media only screen and (max-width: 1280px) {
  .dz-card.style-2 .dz-info .dz-meta .post-author img {
    height: 30px;
    width: 30px;
    display: inline-block;
    margin-right: 5px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-card.style-2 .dz-info .dz-meta .post-author img {
    display: none;
  }
}
.dz-card.style-2 .dz-info .dz-meta .post-author::after {
  background-color: #fff;
}
.dz-card.style-2 .dz-info .dz-meta .post-author span {
  color: #fff;
}
.dz-card.style-2 .dz-info .dz-meta .post-comments a {
  color: #fff;
}
@media only screen and (max-width: 1280px) {
  .dz-card.style-2 .dz-info .dz-meta .post-comments a {
    font-size: 14px;
  }
}
.dz-card.style-2 .dz-info .dz-title {
  margin-bottom: 12px;
}
.dz-card.style-2 .dz-info .dz-title a {
  color: white;
}
@media only screen and (max-width: 1480px) {
  .dz-card.style-2 .dz-info .dz-title {
    font-size: 20px;
  }
}
.dz-card.style-2 .dz-media {
  transform: skewY(5deg);
  border-radius: unset;
  margin-top: -50px;
}
.dz-card.style-2 .dz-media .dz-badge-list {
  position: absolute;
  top: 20px;
  left: 20px;
}
.dz-card.style-2 .dz-media .dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: var(--border-radius-base);
  padding: 8px 15px;
  font-size: 12px;
  text-transform: uppercase;
}
.dz-card.style-2:hover {
  box-shadow: 0px 0 50px rgba(0, 0, 0, 0.2);
}
.dz-card.style-2:hover:after {
  opacity: 0.7;
  border: 2px solid var(--primary);
}
.dz-card.style-2.blog-half {
  transform: skewX(-10deg);
  margin: 0 25px 0 30px;
}
.dz-card.style-2.blog-half .dz-info {
  transform: skewX(10deg);
  padding: 0 0 0 30px;
}
.dz-card.style-2.blog-half .dz-media {
  transform: skewX(10deg);
  margin-top: 0;
  margin-left: -50px;
}

.dz-card.skew-none {
  transform: skewY(0);
}
.dz-card.skew-none .dz-info,
.dz-card.skew-none .dz-media {
  transform: skewY(0);
}

.swiper.blog-slider-wrapper {
  overflow: unset;
}
.swiper.blog-slider-wrapper .swiper-slide {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden !important;
}
.swiper.blog-slider-wrapper .swiper-slide.swiper-slide-prev,
.swiper.blog-slider-wrapper .swiper-slide.swiper-slide-next,
.swiper.blog-slider-wrapper .swiper-slide.swiper-slide-active {
  opacity: 1;
  visibility: visible !important;
}

/*=== Pages ===*/
.counter-wrapper1 {
  position: relative;
  margin-bottom: -60px;
  z-index: 9;
}
@media only screen and (max-width: 991px) {
  .counter-wrapper1 {
    margin-bottom: -100px;
    padding-top: 20px;
  }
}
.counter-wrapper1 .counter-inner {
  padding: 80px 60px 50px;
  position: relative;
  z-index: 1;
  margin: 0 -75px;
  transform: skew(0deg, -8deg);
}
.counter-wrapper1 .counter-inner .counter-box {
  display: block;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.counter-wrapper1 .counter-inner .counter-box .title {
  margin: 0;
  color: #ffbd13;
  font-size: 80px;
  font-weight: 700;
}
.counter-wrapper1 .counter-inner .counter-box p {
  margin: 0;
  color: white;
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  .counter-wrapper1 .counter-inner .counter-box p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 575px) {
  .counter-wrapper1 .counter-inner .counter-box {
    display: block;
    text-align: center;
  }
}
.counter-wrapper1 .counter-inner .triangle1 {
  position: absolute;
  top: 0;
  left: 2px;
  transform: translateY(-72%) rotate(8.5deg);
}
.counter-wrapper1 .counter-inner .triangle2 {
  position: absolute;
  bottom: 0;
  right: 2px;
  transform: translateY(72%) rotate(8.5deg);
}
@media only screen and (max-width: 1480px) {
  .counter-wrapper1 .counter-inner {
    margin: 0;
  }
}
@media only screen and (max-width: 1280px) {
  .counter-wrapper1 .counter-inner {
    padding: 50px 40px 20px;
  }
  .counter-wrapper1 .counter-inner .counter-box .title {
    font-size: 60px;
  }
  .counter-wrapper1 .counter-inner .counter-box p {
    font-size: 18px;
  }
}
@media only screen and (max-width: 991px) {
  .counter-wrapper1 .counter-inner .counter-box .title {
    font-size: 48px;
  }
  .counter-wrapper1 .counter-inner .counter-box p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .counter-wrapper1 .counter-inner {
    padding: 40px 30px 10px;
  }
  .counter-wrapper1 .counter-inner .triangle1 {
    top: 10px;
    left: 1px;
    width: 120px;
  }
  .counter-wrapper1 .counter-inner .triangle2 {
    bottom: 9px;
    right: 1px;
    width: 120px;
  }
}
@media only screen and (max-width: 575px) {
  .counter-wrapper1 .counter-inner {
    padding: 30px 30px 0px;
  }
  .counter-wrapper1 .counter-inner .counter-box .title {
    font-size: 42px;
  }
  .counter-wrapper1 .counter-inner .counter-box p {
    font-size: 15px;
  }
}
.counter-wrapper1 .man {
  position: absolute;
  right: 0;
  top: -280px;
  width: 26vw;
  z-index: 9;
}
@media only screen and (max-width: 1680px) {
  .counter-wrapper1 .man {
    top: -180px;
    width: 20vw;
  }
}
@media only screen and (max-width: 1280px) {
  .counter-wrapper1 .man {
    top: -125px;
    width: 15vw;
  }
}
@media only screen and (max-width: 991px) {
  .counter-wrapper1 .man {
    display: none;
  }
}

.clients-logo {
  height: 50px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clients-logo img {
  width: 135px;
}
.clients-logo:hover img {
  -webkit-animation: logoUpToDown 0.4s forwards;
  -moz-animation: logoUpToDown 0.4s forwards;
  animation: logoUpToDown 0.4s forwards;
}
@media only screen and (max-width: 575px) {
  .clients-logo {
    margin: 0 25px;
  }
}

@keyframes logoUpToDown {
  49% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primary);
}

.about-wrapper2 {
  padding-top: 220px;
}
@media only screen and (max-width: 575px) {
  .about-wrapper2 {
    padding-top: 170px;
  }
}

.dzFormMsg,
.dzSubscribeMsg {
  position: fixed;
  right: 6%;
  z-index: 999;
  bottom: 17px;
}
.dzFormMsg .alert,
.dzSubscribeMsg .alert {
  padding: 10px 15px;
  background: transparent;
  border: 0;
  margin: 0;
  position: relative;
}
.dzFormMsg .alert:after,
.dzSubscribeMsg .alert:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: skewX(-12deg);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}
.dzFormMsg .alert.alert-danger:after,
.dzSubscribeMsg .alert.alert-danger:after {
  background-color: #ffcccd;
}
.dzFormMsg .alert.alert-success:after,
.dzSubscribeMsg .alert.alert-success:after {
  background-color: #d0f3db;
}
@media only screen and (max-width: 1199px) {
  .dzFormMsg,
  .dzSubscribeMsg {
    right: 9%;
    bottom: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .dzFormMsg,
  .dzSubscribeMsg {
    right: 5%;
    bottom: 18%;
    width: 90%;
  }
}
@media only screen and (max-width: 575px) {
  .dzFormMsg,
  .dzSubscribeMsg {
    bottom: 15%;
  }
}

.calculate-table {
  padding-left: 30px;
}
.calculate-table table {
  margin-bottom: 30px;
  width: 100%;
}
.calculate-table table th,
.calculate-table table td {
  text-align: left;
  padding: 15px 20px;
  border-right: 1px solid lightgray;
  width: 38%;
}
.calculate-table table th:last-child,
.calculate-table table td:last-child {
  border: none;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .calculate-table table th,
  .calculate-table table td {
    width: 50%;
  }
}
@media only screen and (max-width: 575px) {
  .calculate-table table th,
  .calculate-table table td {
    padding: 10px 15px;
  }
}
.calculate-table table tbody tr {
  border-bottom: 1px solid lightgray;
}
.calculate-table table tbody tr:last-child {
  border: none;
}
.calculate-table table thead tr {
  border-bottom: 1px solid lightgray;
}
.calculate-table table thead th {
  color: #000;
}
.calculate-table .weight-info b {
  color: #000;
}
@media only screen and (max-width: 991px) {
  .calculate-table {
    padding-left: 0px;
  }
}

.fitness-classes .dz-media {
  width: 100%;
  height: 100%;
}
.fitness-classes .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.fitness-classes .dz-media:hover img {
  transform: scale(1.1);
}

.fitness-classes .dz-info {
  height: 100%;
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 1480px) {
  .fitness-classes .dz-info {
    padding: 30px;
  }
}
.fitness-classes .dz-info .subtitle {
  font-size: 14px;
}
.fitness-classes .dz-info .title {
  margin-bottom: 10px;
}
.fitness-classes .dz-info p {
  margin-bottom: 25px;
}

.z-index-none {
  z-index: unset;
}

@keyframes animateBGText {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(12px);
    opacity: 0.4;
  }
}

/* Google Recaptcha */
.g-recaptcha,
#rc-imageselect {
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

@media only screen and (max-width: 1280px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

@media only screen and (max-width: 575px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.7);
    -moz-transform: scale(0.7);
    -o-transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}

.portfolio-slider-2 .portfolio-2-button-next,
.portfolio-slider-2 .portfolio-2-button-prev {
  position: absolute;
  top: 50%;
  width: 90px;
  height: 90px;
  background: white;
  transform: skew(0);
  color: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%) rotate(-45deg);
}
.portfolio-slider-2 .portfolio-2-button-next:hover,
.portfolio-slider-2 .portfolio-2-button-prev:hover {
  background: var(--primary);
}
.portfolio-slider-2 .portfolio-2-button-next:hover i,
.portfolio-slider-2 .portfolio-2-button-prev:hover i {
  color: var(--theme-text-color);
}
.portfolio-slider-2 .portfolio-2-button-next i,
.portfolio-slider-2 .portfolio-2-button-prev i {
  font-size: 24px;
  position: absolute;
  transform: rotate(45deg) skew(0);
}
@media only screen and (max-width: 575px) {
  .portfolio-slider-2 .portfolio-2-button-next i,
  .portfolio-slider-2 .portfolio-2-button-prev i {
    font-size: 16px;
  }
}

.portfolio-slider-2 .portfolio-2-button-next {
  right: -60px;
}
.portfolio-slider-2 .portfolio-2-button-next i {
  transform: rotate(45deg) skew(0);
  top: 5px;
  left: 16px;
}

.portfolio-slider-2 .portfolio-2-button-prev {
  left: -60px;
}
.portfolio-slider-2 .portfolio-2-button-prev i {
  transform: rotate(45deg) skew(0);
  right: 20px;
  top: 42px;
}

@media only screen and (max-width: 575px) {
  .portfolio-slider-2 .portfolio-2-button-next {
    right: -70px;
  }
  .portfolio-slider-2 .portfolio-2-button-next i {
    top: 10px;
    left: 20px;
  }
  .portfolio-slider-2 .portfolio-2-button-prev {
    left: -70px;
  }
  .portfolio-slider-2 .portfolio-2-button-prev i {
    right: 20px;
    top: 52px;
  }
}

.section-wrapper-1 {
  margin: 0 140px;
}
@media only screen and (max-width: 1480px) {
  .section-wrapper-1 {
    margin: 0;
  }
}

.twentytwenty-img-area {
  overflow: hidden;
  width: 100%;
  position: relative;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 100px;
}
.twentytwenty-img-area::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 0;
}
.twentytwenty-img-area .twentytwenty-wrapper {
  z-index: 20;
  position: relative;
}
.twentytwenty-img-area .twentytwenty-handle {
  position: relative;
  z-index: 110;
}

.ovarlay-box.style-1 {
  position: relative;
  overflow: hidden;
  height: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.ovarlay-box.style-1:before {
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 1;
  position: absolute;
  background-color: var(--primary);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.ovarlay-box.style-1 > img {
  height: 100%;
  object-fit: cover;
  transform: scale(1.05);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
@media only screen and (max-width: 575px) {
  .ovarlay-box.style-1 > img {
    object-fit: cover;
    object-position: top;
    width: 100%;
    height: 340px;
  }
}
.ovarlay-box.style-1 .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
  z-index: 1;
  transform: scale(1.1);
}
.ovarlay-box.style-1 .content .ovarlay-info {
  top: 50%;
  left: 50%;
  color: #fff;
  position: absolute;
  text-align: center;
  background: var(--secondary);
  padding: 20px;
  font-weight: 600;
  transform: translate(-50%, -50%);
}
.ovarlay-box.style-1 .content .ovarlay-info i {
  color: var(--primary);
  display: block;
  font-size: 35px;
  margin-bottom: 10px;
}
.ovarlay-box.style-1 .content .ovarlay-info span {
  font-size: 24px;
}
.ovarlay-box.style-1 .content .ovarlay-info .title {
  color: #fff;
}
@media only screen and (max-width: 1480px) {
  .ovarlay-box.style-1 .content .ovarlay-info i {
    font-size: 27px;
  }
  .ovarlay-box.style-1 .content .ovarlay-info span {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .ovarlay-box.style-1 .content .ovarlay-info {
    padding: 15px;
  }
  .ovarlay-box.style-1 .content .ovarlay-info i {
    font-size: 20px;
  }
  .ovarlay-box.style-1 .content .ovarlay-info span {
    font-size: 16px;
  }
}
.ovarlay-box.style-1:hover {
  transform: scale(1.05);
}
.ovarlay-box.style-1:hover:before {
  opacity: 0.5;
}
.ovarlay-box.style-1:hover .content {
  bottom: 0;
  opacity: 1;
  transform: scale(1);
}
.ovarlay-box.style-1:hover > img {
  transform: scale(1);
}

.bg-data-text {
  position: absolute;
  font-weight: 600;
  font-size: 175px;
  line-height: 1.1;
  left: 0px;
  letter-spacing: -5px;
  bottom: 0;
  color: transparent;
  opacity: 0.4;
  z-index: 1;
  -webkit-text-stroke: 2px var(--rgba-primary-4);
  display: flex;
}
@media only screen and (max-width: 1680px) {
  .bg-data-text {
    font-size: 145px;
  }
}
@media only screen and (max-width: 1480px) {
  .bg-data-text {
    font-size: 120px;
  }
}
@media only screen and (max-width: 1280px) {
  .bg-data-text {
    font-size: 80px;
  }
}
.bg-data-text span:nth-child(1) {
  animation: animateBGText 3s infinite 0.2s ease-in-out;
}
.bg-data-text span:nth-child(2) {
  animation: animateBGText 3s infinite 0.4s ease-in-out;
}
.bg-data-text span:nth-child(3) {
  animation: animateBGText 3s infinite 0.6s ease-in-out;
}
.bg-data-text span:nth-child(4) {
  animation: animateBGText 3s infinite 0.8s ease-in-out;
}
.bg-data-text span:nth-child(5) {
  animation: animateBGText 3s infinite 1s ease-in-out;
}
.bg-data-text span:nth-child(6) {
  animation: animateBGText 3s infinite 1.2s ease-in-out;
}
.bg-data-text span:nth-child(7) {
  animation: animateBGText 3s infinite 1.4s ease-in-out;
}
.bg-data-text span:nth-child(8) {
  animation: animateBGText 3s infinite 1.6s ease-in-out;
}
.bg-data-text span:nth-child(9) {
  animation: animateBGText 3s infinite 1.8s ease-in-out;
}
.bg-data-text span:nth-child(10) {
  animation: animateBGText 3s infinite 2s ease-in-out;
}
.bg-data-text.style-3 {
  color: transparent;
  opacity: 0.4;
  z-index: 1;
  -webkit-text-stroke: 2px var(--primary);
}

@keyframes animateBGText {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(12px);
    opacity: 0.4;
  }
}

.section-wrapper1 {
  margin-top: -50px;
  z-index: 1;
  position: relative;
}
@media only screen and (max-width: 991px) {
  .section-wrapper1 {
    margin-top: 70px;
  }
}
@media only screen and (max-width: 575px) {
  .section-wrapper1 {
    margin-top: 50px;
  }
}

.blog-single {
  margin-bottom: 3.75rem;
}
@media only screen and (max-width: 575px) {
  .blog-single {
    margin-bottom: 2.5rem;
  }
}
.blog-single .dz-post-meta {
  margin-bottom: 1.25rem;
}
.blog-single .post-author img {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  margin-right: 10px;
}
.blog-single .dz-post-text {
  padding-bottom: 5px;
}
.blog-single .dz-post-text:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}
.blog-single .dz-post-tags {
  margin-top: 1.25rem;
}
.blog-single .dz-social-icon {
  display: flex;
  align-items: center;
}
.blog-single .dz-social-icon .title {
  margin-right: 18px;
  margin-bottom: 0;
  line-height: 1.35;
}
.blog-single .dz-social-icon ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  text-align: center;
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}
.blog-single .dz-social-icon ul li a:hover {
  background-color: var(--primary);
  color: #fff;
}
.blog-single .dz-info {
  padding: 0;
}
.blog-single .dz-post-media {
  border-radius: 0.25rem;
}
.blog-single .dz-media,
.blog-single .dz-post-media {
  text-align: center;
}
.blog-single.dz-card .post-tags {
  margin-left: -3px;
  margin-right: -3px;
}
.blog-single.style-1 .dz-media + .dz-info {
  padding: 25px 0 0;
}
.blog-single.style-1 .dz-info {
  padding: 0 0 30px;
}
.blog-single.style-1 .dz-info .blockquote p {
  font-size: 20px;
  font-weight: 600;
}
@media only screen and (max-width: 575px) {
  .blog-single.style-1 .dz-info .post-comment {
    display: none;
  }
}
.blog-single.style-1 .dz-info .post-author img {
  height: 35px;
  width: 35px;
  border-radius: 100%;
  margin-right: 10px;
}
@media only screen and (max-width: 767px) {
  .blog-single.style-1 .dz-info .post-author img {
    height: 27px;
    width: 27px;
    margin-right: 5px;
  }
}

.wp-block-code > code {
  color: #fff;
}

.dz-post-text .widget_archive li a,
.dz-post-text .wp-block-latest-posts li a,
.dz-post-text .wp-block-categories-list li a,
.dz-post-text .wp-block-archives-list li a,
.dz-post-text .widget_categories li a,
.dz-post-text blockquote a,
.dz-post-text .wp-block-button__link {
  box-shadow: none;
  text-decoration: none;
}

.dz-post-text iframe {
  max-width: 100%;
}

.post-header {
  position: relative;
  overflow: hidden;
}
@media only screen and (max-width: 575px) {
  .post-header {
    margin-bottom: 1.25rem;
  }
}
.post-header .dz-media img {
  min-height: 250px;
  object-fit: cover;
  width: 100%;
}
.post-header .dz-info {
  position: absolute;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 100px 30px 30px 30px !important;
  width: 100%;
}
@media only screen and (max-width: 575px) {
  .post-header .dz-info {
    padding: 40px 15px 15px 15px !important;
  }
}
.post-header .dz-info .dz-title {
  color: #fff;
}
@media only screen and (max-width: 575px) {
  .post-header .dz-info .dz-title {
    font-size: 1.125rem;
  }
}
.post-header .dz-info .dz-meta ul li:after {
  background: #fff;
}
.post-header .dz-info .dz-meta ul li strong,
.post-header .dz-info .dz-meta ul li span,
.post-header .dz-info .dz-meta ul li a {
  color: #fff;
}

.post-link-in {
  padding: 15px 50px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.post-link-in:hover {
  background: var(--primary);
  color: #fff;
}

.side-bar {
  padding-left: 20px;
  padding-bottom: 1px;
}
.side-bar .wp-block-search,
.side-bar .wp-block-archives,
.side-bar .wp-block-latest-posts,
.side-bar .wp-block-latest-comments,
.side-bar .wp-block-categories,
.side-bar .wp-block-calendar {
  margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .side-bar {
    padding-left: 0;
    padding-right: 0;
  }
}
.side-bar.left {
  padding-left: 0;
  padding-right: 20px;
}
@media only screen and (max-width: 991px) {
  .side-bar.left {
    padding-right: 0;
    padding-left: 0;
  }
}

.alignnone {
  margin: 0.3125rem 0 1.563rem 0;
}
.alignnoneimg,
.alignnone.wp-caption,
.alignnone.is-resized {
  margin: 0.3125rem 0 1.563rem 0;
}

.aligncenter {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}
.aligncenterimg,
.aligncenter.wp-caption,
.aligncenter.is-resized {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}

.alignright {
  float: right;
  margin: 0.3125rem 0 1.563rem 1.563rem;
}
.alignrightimg,
.alignright.wp-caption,
.alignright.is-resized {
  margin: 0.3125rem 0 1.563rem 1.563rem;
  float: right;
}

.alignleft {
  float: left;
  margin: 0.3125rem 1.563rem 1.563rem 0;
}
.alignleftimg,
.alignleft.wp-caption,
.alignleft.is-resized {
  margin: 0.3125rem 1.563rem 1.563rem 0;
  float: left;
}

.wp-caption {
  max-width: 100%;
  text-align: center;
}
.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0;
}
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}
.wp-caption p.wp-caption-text {
  font-size: 0.813rem;
  line-height: 1.125rem;
  margin: 0;
  padding: 0.625rem 0;
  text-align: left;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
  padding: 1.5rem 2rem 1.5rem 2.5rem;
  font-size: 0.938rem;
  color: var(--title);
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.875rem;
  position: relative;
  clear: both;
  font-weight: 700;
  z-index: 1;
  border: 0;
  background-color: var(--rgba-primary-1);
}
@media only screen and (max-width: 991px) {
  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large,
  blockquote.wp-block-quote,
  blockquote.wp-block-pullquote,
  blockquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft,
  blockquote {
    padding: 1.25rem 1.25rem 1.25rem 2.25rem;
    font-size: 0.813rem;
  }
}
@media only screen and (max-width: 575px) {
  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large,
  blockquote.wp-block-quote,
  blockquote.wp-block-pullquote,
  blockquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft,
  blockquote {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.wp-block-quote.is-large cite,
.wp-block-quote.is-style-large cite,
blockquote.wp-block-quote cite,
blockquote.wp-block-pullquote cite,
blockquote.wp-block-pullquote.alignright cite,
blockquote.wp-block-pullquote.alignleft cite,
blockquote cite {
  font-style: normal;
  position: relative;
  display: block;
  margin-top: -0.3125rem;
  font-weight: 600;
  color: var(--primary);
  line-height: 1.3;
  font-size: 14px;
}
.wp-block-quote.is-large cite:before,
.wp-block-quote.is-style-large cite:before,
blockquote.wp-block-quote cite:before,
blockquote.wp-block-pullquote cite:before,
blockquote.wp-block-pullquote.alignright cite:before,
blockquote.wp-block-pullquote.alignleft cite:before,
blockquote cite:before {
  content: "";
  margin-right: 0.625rem;
  width: 15px;
  height: 2px;
  background: var(--primary);
  display: inline-block;
  vertical-align: middle;
}
.wp-block-quote.is-large b,
.wp-block-quote.is-large strong,
.wp-block-quote.is-large .strong,
.wp-block-quote.is-style-large b,
.wp-block-quote.is-style-large strong,
.wp-block-quote.is-style-large .strong,
blockquote.wp-block-quote b,
blockquote.wp-block-quote strong,
blockquote.wp-block-quote .strong,
blockquote.wp-block-pullquote b,
blockquote.wp-block-pullquote strong,
blockquote.wp-block-pullquote .strong,
blockquote.wp-block-pullquote.alignright b,
blockquote.wp-block-pullquote.alignright strong,
blockquote.wp-block-pullquote.alignright .strong,
blockquote.wp-block-pullquote.alignleft b,
blockquote.wp-block-pullquote.alignleft strong,
blockquote.wp-block-pullquote.alignleft .strong,
blockquote b,
blockquote strong,
blockquote .strong {
  color: inherit;
}
.wp-block-quote.is-large:after,
.wp-block-quote.is-style-large:after,
blockquote.wp-block-quote:after,
blockquote.wp-block-pullquote:after,
blockquote.wp-block-pullquote.alignright:after,
blockquote.wp-block-pullquote.alignleft:after,
blockquote:after {
  content: "";
  width: 5px;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--primary);
  position: absolute;
  box-shadow: 0 0 30px 0 var(--rgba-primary-2);
}
.wp-block-quote.is-large:before,
.wp-block-quote.is-style-large:before,
blockquote.wp-block-quote:before,
blockquote.wp-block-pullquote:before,
blockquote.wp-block-pullquote.alignright:before,
blockquote.wp-block-pullquote.alignleft:before,
blockquote:before {
  content: "";
  z-index: -1;
  background-size: cover;
  left: 20px;
  background-image: var(--quote);
  background-position: center right;
  width: 90px;
  padding: 0;
  opacity: 0.1;
  background-repeat: no-repeat;
  height: 90px;
  top: 0px;
  position: absolute;
}
.wp-block-quote.is-large.wp-block-pullquote.alignleft,
.wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
blockquote.wp-block-quote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft {
  margin: 0rem 1.563rem 1.563rem 0rem;
}
.wp-block-quote.is-large.wp-block-pullquote.alignright,
.wp-block-quote.is-style-large.wp-block-pullquote.alignright,
blockquote.wp-block-quote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright {
  margin: 0rem 0 1.563rem 1.563rem;
}
.wp-block-quote.is-large.style-1,
.wp-block-quote.is-style-large.style-1,
blockquote.wp-block-quote.style-1,
blockquote.wp-block-pullquote.style-1,
blockquote.wp-block-pullquote.alignright.style-1,
blockquote.wp-block-pullquote.alignleft.style-1,
blockquote.style-1 {
  background: #fff;
  color: var(--primary);
  padding: 25px 40px;
  background-color: var(--rgba-primary-1);
}
.wp-block-quote.is-large.style-1 p,
.wp-block-quote.is-style-large.style-1 p,
blockquote.wp-block-quote.style-1 p,
blockquote.wp-block-pullquote.style-1 p,
blockquote.wp-block-pullquote.alignright.style-1 p,
blockquote.wp-block-pullquote.alignleft.style-1 p,
blockquote.style-1 p {
  font-size: 24px;
  line-height: 1.5;
  color: var(--title);
}
.wp-block-quote.is-large.style-1:before,
.wp-block-quote.is-style-large.style-1:before,
blockquote.wp-block-quote.style-1:before,
blockquote.wp-block-pullquote.style-1:before,
blockquote.wp-block-pullquote.alignright.style-1:before,
blockquote.wp-block-pullquote.alignleft.style-1:before,
blockquote.style-1:before {
  left: 20px;
  background-size: contain;
  width: 80px;
  top: 0;
  transform: rotate(180deg);
  background-image: var(--quote-2);
}
.wp-block-quote.is-large.style-1 cite,
.wp-block-quote.is-style-large.style-1 cite,
blockquote.wp-block-quote.style-1 cite,
blockquote.wp-block-pullquote.style-1 cite,
blockquote.wp-block-pullquote.alignright.style-1 cite,
blockquote.wp-block-pullquote.alignleft.style-1 cite,
blockquote.style-1 cite {
  color: var(--primary);
}
.wp-block-quote.is-large.style-1 cite:before,
.wp-block-quote.is-style-large.style-1 cite:before,
blockquote.wp-block-quote.style-1 cite:before,
blockquote.wp-block-pullquote.style-1 cite:before,
blockquote.wp-block-pullquote.alignright.style-1 cite:before,
blockquote.wp-block-pullquote.alignleft.style-1 cite:before,
blockquote.style-1 cite:before {
  background: var(--primary);
}

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
  background: #5608e0;
}

.wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.3;
}

.dz-card.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
  font-size: 18px;
  line-height: 1.5;
  color: var(--title);
  margin-bottom: 1.5rem;
  font-weight: 600;
  font-style: italic;
}
.dz-card.blog-single .dz-post-text blockquote p cite,
.dz-page-text blockquote p cite,
blockquote p cite {
  margin-top: 20px;
}
@media only screen and (max-width: 991px) {
  .dz-card.blog-single .dz-post-text blockquote p,
  .dz-page-text blockquote p,
  blockquote p {
    font-size: 1.125rem;
  }
}
@media only screen and (max-width: 575px) {
  .dz-card.blog-single .dz-post-text blockquote p,
  .dz-page-text blockquote p,
  blockquote p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

.dz-page-text {
  padding-bottom: 30px !important;
}

.dz-card.blog-single .dz-post-text blockquote.style-1 p,
.dz-page-text blockquote.style-1 p,
blockquote.style-1 p {
  margin-bottom: 1.5rem;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

/* Gutenberg Gallery */
/* Gutenberg Css */
/* .dz-card.blog-single,
.dz-card.blog-single + #comment-list */
.min-container,
.dz-page-text,
.dz-page-text + #comment-list,
.dz-page-text + #comment-list + .paginate-links {
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}

.dz-card.blog-single.sidebar + #comment-list {
  padding: 0;
  max-width: 100%;
}

.max-container {
  max-width: 93.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.4375rem;
  padding-right: 3.4375rem;
}

.dz-page-text + #comment-list + .paginate-links,
.dz-page-text + #comment-list,
.dz-page-text {
  padding: 0;
}

.dz-page-text.sidebar {
  width: 100%;
  padding: 0;
  max-width: 100%;
}

.dz-page-text > ol,
.dz-page-text > ul,
.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul {
  list-style: none;
  margin-bottom: 1.875rem;
}

.dz-page-text > ol,
.dz-page-text > ul,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul,
.dz-card.blog-single .dz-post-text.text > ol,
.dz-card.blog-single .dz-post-text.text > ul {
  padding-left: 1.25rem;
}
.dz-page-text > ol ol,
.dz-page-text > ol ul,
.dz-page-text > ul ol,
.dz-page-text > ul ul,
.dz-card.blog-single .dz-post-text > ol ol,
.dz-card.blog-single .dz-post-text > ol ul,
.dz-card.blog-single .dz-post-text > ul ol,
.dz-card.blog-single .dz-post-text > ul ul,
.dz-card.blog-single .dz-post-text.text > ol ol,
.dz-card.blog-single .dz-post-text.text > ol ul,
.dz-card.blog-single .dz-post-text.text > ul ol,
.dz-card.blog-single .dz-post-text.text > ul ul {
  padding-left: 1.563rem;
}

.dz-page-text > ol li,
.dz-page-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text.text > ol li,
.dz-card.blog-single .dz-post-text.text > ul li {
  padding: 0.5rem 0.5rem;
  position: relative;
  list-style: inherit;
}

.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery {
  padding: 0;
  list-style: none;
}

.dz-page-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li {
  padding: 0;
  list-style: none;
  margin: 0.25rem;
}

.dz-page-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li:before {
  content: none;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  margin: 0 0.5rem 0.5rem 0;
}

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 0.5rem;
}

.content-spacious-full .wp-block-gallery {
  margin-left: calc(-12.5rem - (0.313rem / 2));
  margin-right: calc(-12.5rem - (0.313rem / 2));
  width: calc(100% + 25rem + 0.313rem);
  max-width: initial;
}

.alignfull,
.alignwide {
  margin-left: calc(-6.25rem - (0.313rem / 2));
  margin-right: calc(-6.25rem - (0.313rem / 2));
  width: calc(100% + 12.5rem + 0.313rem);
  max-width: initial;
}

.alignfull,
.alignfullwide {
  margin-left: calc(50% - 50vw - (var(--content-share-ml, 0px) / 2));
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
}

.dz-page-text h1,
.dz-page-text .h1,
.dz-post-text h1,
.dz-post-text .h1,
.thm-unit-test .dz-post-text h1,
.thm-unit-test .dz-post-text .h1 {
  margin-bottom: 1rem;
  font-weight: 700;
}

.dz-page-text h2,
.dz-page-text .h2,
.dz-post-text h2,
.dz-post-text .h2,
.thm-unit-test .dz-post-text h2,
.thm-unit-test .dz-post-text .h2 {
  margin-bottom: 1rem;
  font-weight: 700;
}

.dz-page-text h3,
.dz-page-text .h3,
.dz-post-text h3,
.dz-post-text .h3,
.thm-unit-test .dz-post-text h3,
.thm-unit-test .dz-post-text .h3 {
  margin-bottom: 1rem;
  font-weight: 700;
}

.dz-page-text h4,
.dz-page-text .h4,
.dz-page-text .comments-title,
.dz-page-text .blog-title,
.dz-page-text .comment-reply-title,
.dz-page-text .wp-block-search .wp-block-search__label,
.wp-block-search .dz-page-text .wp-block-search__label,
.dz-post-text h4,
.dz-post-text .h4,
.dz-post-text .comments-title,
.dz-post-text .blog-title,
.dz-post-text .comment-reply-title,
.dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .dz-post-text .wp-block-search__label,
.thm-unit-test .dz-post-text h4,
.thm-unit-test .dz-post-text .h4,
.thm-unit-test .dz-post-text .comments-title,
.thm-unit-test .dz-post-text .blog-title,
.thm-unit-test .dz-post-text .comment-reply-title,
.thm-unit-test .dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .thm-unit-test .dz-post-text .wp-block-search__label {
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.dz-page-text h5,
.dz-page-text .h5,
.dz-post-text h5,
.dz-post-text .h5,
.thm-unit-test .dz-post-text h5,
.thm-unit-test .dz-post-text .h5 {
  margin-bottom: 0.75rem;
}

.dz-page-text h6,
.dz-page-text .h6,
.dz-post-text h6,
.dz-post-text .h6,
.thm-unit-test .dz-post-text h6,
.thm-unit-test .dz-post-text .h6 {
  margin-bottom: 0.625rem;
}

.wp-block-code,
pre.wp-block-code,
pre {
  margin: 0 0 1.563rem;
  padding: 1.25rem;
  color: #fff;
  background-color: #212326;
  white-space: pre;
  font-size: 0.938rem;
  border-radius: 0.1875rem;
}

.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li,
.dz-card.blog-single
  .dz-card.blog-single
  .dz-post-text
  p:not(.has-text-color):not(.has-text-align-center):not(.has-text-align-left):not(.has-text-align-right) {
  font-size: 1rem;
  line-height: 1.7;
}

.wp-block-cover,
.wp-block-cover-image {
  color: #fff;
}

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
  margin-bottom: 0;
}

.wp-block-quote.has-text-align-right {
  border-right: 0;
}

.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li {
  position: relative;
}

.dz-page-text ul > li:before,
.dz-page-text ol > li:before,
.dz-card.blog-single .dz-post-text ul > li:before,
.dz-card.blog-single .dz-post-text ol > li:before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  background: #222222;
  left: -0.938rem;
  position: absolute;
  top: 1.063rem;
  border-radius: 4px;
}

.dz-page-text ul > li li:before,
.dz-page-text ol > li li:before,
.dz-card.blog-single .dz-post-text ul > li li:before,
.dz-card.blog-single .dz-post-text ol > li li:before {
  content: none;
}

.dz-page-text p,
.dz-card.blog-single .dz-post-text p {
  margin-bottom: 1.65rem;
}

.paginate-links {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 4rem 0;
}
.paginate-links > a,
.paginate-links > span {
  margin: 0 0 0 0.625rem;
  position: relative;
  border: 0rem solid #6cc000;
  color: #777777;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 50px;
  min-width: 50px;
  height: 50px;
  text-align: center;
  text-transform: capitalize;
  transition: all 500ms ease 0s;
  background: #f3f4f6;
  border-radius: var(--border-radius-base);
}
.paginate-links .current {
  color: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 5px 12px var(--rgba-primary-4);
}

.wp-block-columns {
  margin-bottom: 0;
}

/* Gutenberg Gallery */
.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  position: relative;
}

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
  height: 100%;
  margin: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image figure,
  .wp-block-gallery .blocks-gallery-item figure {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
  }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery .blocks-gallery-image img,
  .wp-block-gallery .blocks-gallery-item img {
    width: auto;
  }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 60%, transparent);
  bottom: 0;
  color: #fff;
  font-size: 0.813rem;
  max-height: 100%;
  overflow: auto;
  padding: 2.5rem 0.625rem 0.3125rem;
  position: absolute;
  text-align: center;
  width: 100%;
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
  display: inline;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img {
    -o-object-fit: cover;
    flex: 1;
    height: 100%;
    object-fit: cover;
  }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc(50% - 0.5rem);
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0;
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%;
}

@media only screen and (max-width: 64rem) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
    max-width: 100% !important;
  }
  .alignwide .alignleft img {
    width: 100%;
  }
  .alignwide .alignleft {
    margin: 0.3125rem 0rem 1.563rem 0;
    float: none;
  }
}

@media (min-width: 37.5rem) {
  .wp-block-gallery.columns-3 .blocks-gallery-image,
  .wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(33.33333% - 0.666rem);
  }
  .wp-block-gallery.columns-4 .blocks-gallery-image,
  .wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(25% - 0.75rem);
  }
  .wp-block-gallery.columns-5 .blocks-gallery-image,
  .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(20% - 0.8rem);
  }
  .wp-block-gallery.columns-6 .blocks-gallery-image,
  .wp-block-gallery.columns-6 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(16.66667% - 0.833rem);
  }
  .wp-block-gallery.columns-7 .blocks-gallery-image,
  .wp-block-gallery.columns-7 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(14.28571% - 0.857rem);
  }
  .wp-block-gallery.columns-8 .blocks-gallery-image,
  .wp-block-gallery.columns-8 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(12.5% - 0.875rem);
  }
  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0;
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
  width: 100%;
}

.wp-block-image.alignfullwide img {
  border-radius: 0;
}

.wp-block-image img {
  border-radius: 0.625rem;
}

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  max-width: 19.0625rem;
  width: 100%;
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  display: flex;
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
  justify-content: center;
}

/* .dz-page-text.sidebar .alignfull,
.dz-page-text.sidebar .alignfullwide,
.dz-page-text.sidebar .alignwide,
.dz-page-text.sidebar .wp-block-gallery,
.blog-single.dz-card.sidebar .alignfullwide,
.blog-single.dz-card.sidebar .alignwide,
.blog-single.dz-card.sidebar .dz-post-text .wp-block-gallery {
    margin-left:0;
    margin-right:0;
    width: 100%;
    max-width: initial;
} */
.blog-overlap {
  background: #fff;
  margin-top: -8.75rem;
  padding: 1.25rem 1.25rem 0;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
}

.blog-single.dz-card.sidebar {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 1rem;
  margin-bottom: 1.875rem;
}
@media only screen and (max-width: 575px) {
  .dz-post-text table,
  .dz-page-text table,
  .wp-block-table {
    font-size: 0.875rem;
  }
}

.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption {
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  margin-top: 0.625rem;
}

.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td,
.wp-block-table th {
  padding: 0.625rem 0.938rem;
  border: 0.0625rem solid #e4e4e4;
  border-right: 0;
  border-left: 0;
}
@media only screen and (max-width: 575px) {
  .dz-post-text td,
  .dz-post-text th,
  .dz-page-text td,
  .dz-page-text th,
  .wp-block-table td,
  .wp-block-table th {
    padding: 0.5rem 0.5rem;
  }
}

.wp-block-media-text {
  margin-bottom: 30px;
}
.wp-block-media-text .wp-block-media-text__content {
  padding: 0 1.875rem;
}

.wp-block-column ol li:before,
.wp-block-column ul li:before {
  content: none !important;
}

.wp-block-pullquote {
  padding: 1em 0;
  border-top: 0;
  border-bottom: 0;
}
.wp-block-pullquote.alignright,
.wp-block-pullquote.alignleft {
  padding: 0;
  border-top: 0;
  border-bottom: 0;
}
.wp-block-pullquote.alignright blockquote,
.wp-block-pullquote.alignleft blockquote {
  margin: 0;
}

.dz-post-text .wp-block-cover-image-text,
.dz-post-text .wp-block-cover-text,
.dz-post-text section.wp-block-cover-image h2,
.dz-post-text section.wp-block-cover-image .h2,
.dz-page-text .wp-block-cover-image-text,
.dz-page-text .wp-block-cover-text,
.dz-page-text section.wp-block-cover-image h2,
.dz-page-text section.wp-block-cover-image .h2 {
  color: #fff;
}

.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
  box-shadow: none;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
}

.dz-post-text .wp-block-calendar a {
  box-shadow: none !important;
  text-decoration: unset;
}

.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
  margin-bottom: 2.5rem;
}

/* Admin Bar */
.admin-bar .is-fixed .main-bar {
  top: 1.875rem;
}

.wp-block-quote.is-style-large cite:before {
  display: none;
}

/* Theme Unit Test */
.post-password-form label {
  font-size: 1rem;
}

.post-password-form input[type="submit"] {
  padding: 0.625rem 1.563rem;
  background-color: var(--primary);
  border: none;
  height: 2.8125rem;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
}

.post-password-form input[type="submit"]:hover {
  background-color: var(--primary);
}

.post-password-form input[type="password"] {
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}

.wp-block-search .wp-block-search__button {
  background: var(--primary);
  border: 0;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.625rem 1.563rem;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.wp-block-search .wp-block-search__input {
  border: 0.0625rem solid #e1e6eb;
  height: 2.8125rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  outline: none;
}

.wp-block-tag-cloud a {
  position: relative;
  border: 2px solid;
  padding: 10px 20px;
  display: inline-block;
  margin: 0 10px 10px 0;
  font-size: 14px;
  color: inherit;
  line-height: 1.4;
  border-color: rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius-base);
}
.wp-block-tag-cloud a:hover {
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: #fff;
}

.wp-block-latest-comments {
  padding: 0 !important;
}
.wp-block-latest-comments .avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}
.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
  margin-left: 4.375rem;
}
.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  color: inherit;
  font-size: 1.063rem;
  line-height: 1.7;
  margin-bottom: 0;
}
.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta a {
  color: inherit;
  box-shadow: none;
  text-decoration: none;
}
.wp-block-latest-comments img + article .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  margin-left: 4.375rem;
}
.wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
  display: block;
  width: 100%;
  color: #9fa1a4;
  font-size: 0.813rem;
  font-weight: 400;
}
.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  margin-bottom: 0.625rem !important;
}
.wp-block-latest-comments li {
  padding: 0 !important;
  border-bottom: 0.0625rem solid #eee;
  padding-bottom: 0.625rem !important;
  margin-bottom: 0.625rem;
}
.wp-block-latest-comments li:before {
  content: none !important;
}

.wp-block-latest-comments__comment-avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}

.comment-content.dz-page-text {
  max-width: 100%;
}
.comment-content.dz-page-text > :last-child {
  margin-bottom: 0;
}

.wp-block-rss {
  padding: 0 !important;
}
.wp-block-rss .wp-block-rss__item {
  padding: 0.3125rem 0 !important;
  border-bottom: 0.0625rem solid #eee;
}
.wp-block-rss .wp-block-rss__item:before {
  content: none !important;
}
.wp-block-rss .wp-block-rss__item a {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  box-shadow: unset !important;
  font-weight: 600;
  color: var(--title);
  text-decoration: none;
}

@media only screen and (max-width: 75rem) {
  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: calc(-6.25rem - (0.625rem / 2));
    margin-right: calc(-6.25rem - (0.625rem / 2));
    width: calc(100% + 12.5rem + 0.625rem);
  }
}

@media only screen and (max-width: 61.9375rem) {
  .admin-bar .mo-left .header-nav {
    top: 1.875rem;
    height: calc(100vh - 1.875rem) !important;
  }
  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .dz-page-text,
  .dz-page-text ul li,
  .dz-page-text ol li,
  .dz-page-text p,
  .dz-card.blog-single .dz-post-text,
  .dz-card.blog-single .dz-post-text ul li,
  .dz-card.blog-single .dz-post-text ol li,
  .dz-card.blog-single .dz-post-text p {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 61.9375rem) and (max-width: 575px) {
  .dz-page-text,
  .dz-page-text ul li,
  .dz-page-text ol li,
  .dz-page-text p,
  .dz-card.blog-single .dz-post-text,
  .dz-card.blog-single .dz-post-text ul li,
  .dz-card.blog-single .dz-post-text ol li,
  .dz-card.blog-single .dz-post-text p {
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 48.875rem) {
  .admin-bar .is-fixed .main-bar {
    top: 2.8125rem;
  }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }
  .wp-block-media-text {
    display: block;
  }
  .wp-block-media-text .wp-block-media-text__media {
    margin-bottom: 0.938rem;
  }
  .wp-block-media-text .wp-block-media-text__content {
    padding: 0;
  }
}

@media only screen and (max-width: 37.5rem) {
  .admin-bar .is-fixed .main-bar {
    top: 0;
  }
  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }
  .admin-bar .mo-left .is-fixed .header-nav {
    top: 0;
    height: 100vh !important;
  }
}

.post-footer {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.938rem 0 0;
}
.post-footer .dz-meta .tag-list {
  padding-bottom: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: capitalize;
  display: inline;
}
.post-footer .dz-meta ul li {
  margin-right: 0.3125rem;
  padding: 0;
  display: inline-block;
  color: #333333;
  font-weight: 500;
  font-size: 0.938rem;
  font-style: italic;
}
.post-footer .post-tag a {
  text-transform: capitalize;
  font-size: 0.938rem;
  color: #999;
  background: transparent;
  padding: 0;
  border: 0;
  line-height: 0.875rem;
  font-weight: 500;
}

.share-post li {
  display: inline-block;
}

.share-post a {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 2.1875rem;
  border: 0.0625rem solid #eee;
  display: block;
  text-align: center;
  line-height: 2.0625rem;
  color: #222222;
}

.extra-blog {
  margin-bottom: 60px;
}

@media only screen and (max-width: 575px) {
  .comment-respond.style-1 .comment-reply-title {
    margin-bottom: 20px;
  }
}

.default-form.comment-respond .comment-reply-title,
.comments-area .comments-title,
.extra-blog .blog-title {
  font-size: 23px;
  font-weight: 800;
}

@media only screen and (max-width: 991px) {
  .default-form {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .default-form {
    margin-bottom: 30px;
  }
}

.comments-area .comment-list {
  margin-bottom: 60px;
  padding: 0;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list {
    margin-bottom: 40px;
  }
}
.comments-area .comment-list .default-form {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.comments-area .comment-list .default-form small,
.comments-area .comment-list .default-form .small {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}
.comments-area .comment-list .default-form small a,
.comments-area .comment-list .default-form .small a {
  color: #e10000;
}
.comments-area .comment-list .dz-page-text {
  padding-bottom: 0 !important;
}
.comments-area .comment-list > .comment .comment-body {
  position: relative;
  padding: 0 0 20px 130px;
  margin-bottom: 30px;
  min-height: 125px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list > .comment .comment-body {
    padding: 0 0 25px 75px;
  }
}
@media only screen and (max-width: 575px) {
  .comments-area .comment-list > .comment .comment-body {
    padding: 0 0 20px 75px;
  }
}
.comments-area .comment-list > .comment .comment-body .comment-author {
  position: absolute;
  left: 0;
  height: 105px;
  width: 105px;
  border-radius: var(--border-radius-sm);
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list > .comment .comment-body .comment-author {
    height: 60px;
    width: 60px;
  }
}
.comments-area .comment-list > .comment .comment-body .comment-author:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  transform: rotate(8deg);
  left: 0;
  z-index: -1;
  top: 0;
  border: 2px solid var(--primary);
}
.comments-area .comment-list > .comment .comment-body .comment-info .title {
  margin-bottom: 8px;
}
.comments-area .comment-list > .comment .comment-body .comment-info .title span {
  font-size: 15px;
}
.comments-area .comment-list > .comment .comment-body .comment-info .fn {
  font-size: 16px;
  line-height: 1.3;
  color: var(--title);
  font-weight: 600;
  font-style: normal;
  margin-bottom: 3px;
  display: block;
  font-family: var(--font-family-title);
}
.comments-area .comment-list > .comment .comment-body .reply .comment-reply-link {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  background: var(--primary);
  color: #fff;
  padding: 6px 10px 4px 10px;
  display: inline-block;
  line-height: 1.3;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: absolute;
  right: 0;
  top: 0;
  transform: skewX(-12deg);
}
.comments-area .comment-list > .comment .comment-body .reply .comment-reply-link span {
  transform: skewX(12deg);
  display: inline-block;
}
.comments-area .comment-list > .comment .comment-body .reply .comment-reply-link:hover {
  background: var(--primary-hover);
}
.comments-area .comment-list > .comment .comment-body .reply .comment-reply-link i {
  margin-right: 8px;
  font-size: 14px;
}
@media only screen and (max-width: 767px) {
  .comments-area .comment-list > .comment .comment-body .reply .comment-reply-link {
    position: unset;
  }
}
.comments-area .comment-list > .comment:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
.comments-area .comment-list > .comment > .children {
  padding-left: 50px;
}
@media only screen and (max-width: 991px) {
  .comments-area .comment-list > .comment > .children {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .comments-area .comment-list > .comment > .children {
    padding-left: 15px;
  }
}
.comments-area .comment-list .comment-form {
  margin-bottom: 40px;
}

.comment-reply-title a {
  font-size: 14px;
  font-weight: 400;
}

.comment-respond small,
.comment-respond .small {
  font-size: 14px;
  margin-left: 7px;
}
.comment-respond small a,
.comment-respond .small a {
  color: #ef060f;
}

.comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.comment-respond .comment-form p {
  margin-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  width: 50%;
}
@media only screen and (max-width: 575px) {
  .comment-respond .comment-form p {
    margin-bottom: 15px;
  }
}
.comment-respond .comment-form p label {
  display: none;
}
.comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p textarea {
  height: 60px;
  border: 3px solid #d9d9d9;
  border-width: 0 0 2px 0;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 400;
  color: #555;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: transparent;
  width: 100%;
  border-radius: var(--border-radius);
}
.comment-respond .comment-form p.comment-form-author input:focus,
.comment-respond .comment-form p.comment-form-author input:active,
.comment-respond .comment-form p.comment-form-email input:focus,
.comment-respond .comment-form p.comment-form-email input:active,
.comment-respond .comment-form p.comment-form-url input:focus,
.comment-respond .comment-form p.comment-form-url input:active,
.comment-respond .comment-form p textarea:focus,
.comment-respond .comment-form p textarea:active {
  background: #fff;
  border-color: var(--primary);
}
@media only screen and (max-width: 575px) {
  .comment-respond .comment-form p.comment-form-author input,
  .comment-respond .comment-form p.comment-form-email input,
  .comment-respond .comment-form p.comment-form-url input,
  .comment-respond .comment-form p textarea {
    font-size: 16px;
  }
}
.comment-respond .comment-form p textarea {
  height: 120px !important;
}
.comment-respond .comment-form p.comment-form-url {
  width: 100%;
}
.comment-respond .comment-form .comment-form-comment {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 575px) {
  .comment-respond .comment-form .comment-form-author,
  .comment-respond .comment-form .comment-form-email,
  .comment-respond .comment-form .comment-form-url {
    width: 100% !important;
  }
}

.dz-page-text [class*="galleryid-"],
.dz-post-text [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

.gallery .gallery-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0.3125rem;
  position: relative;
  box-sizing: border-box;
}
.gallery .gallery-item img {
  float: left;
  padding: 0 0rem;
  width: 100%;
  border: none !important;
}

.gallery .gallery-caption {
  font-size: 0.813rem;
  color: #707070;
  display: block;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
  padding: 0.5em 0;
  clear: both;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.post-password-form {
  position: relative;
  clear: both;
}
.post-password-form label {
  display: block;
  font-size: 1rem;
}
.post-password-form input[type="password"] {
  width: 100%;
  border: 0.0625rem solid #ebedf2;
  padding: 0.625rem 6.25rem 0.625rem 0.938rem;
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}
.post-password-form input[type="submit"] {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.625rem 1.25rem;
  background: var(--primary);
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
  height: 2.8125rem;
}
.post-password-form input[type="submit"]:hover {
  background: var(--primary-hover);
}

@media only screen and (max-width: 991px) {
  .side-bar.sticky-top {
    position: unset;
  }
}

.blog-post-banner {
  min-height: 300px;
  padding-top: 70px;
  padding-bottom: 70px;
}
.blog-post-banner .dz-title {
  color: #fff;
  font-size: 32px;
  margin-bottom: 20px;
}
.blog-post-banner .dz-meta {
  color: #fff;
}
.blog-post-banner .dz-meta ul {
  display: flex;
  opacity: 0.7;
}
.blog-post-banner .dz-meta ul li {
  margin-right: 20px;
}
.blog-post-banner .dz-meta ul li a {
  color: #fff;
}

.post-outside {
  margin-top: -120px;
}

.single-post .main-bar {
  border-bottom: 1px solid #eee;
}

.dz-load-more i:before,
.loadmore-btn i:before {
  -webkit-animation: unset !important;
  -moz-animation: unset !important;
  animation: unset !important;
}

.dz-share-post {
  border-top: 1px solid #cccccc;
  border-top-style: dashed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.dz-share-post .post-tags a {
  position: relative;
  border: 1px solid;
  padding: 5px 12px;
  display: inline-block;
  margin: 3px;
  font-size: 13px;
  color: inherit;
  line-height: 1.4;
  border-color: rgba(175, 163, 163, 0.4);
  border-radius: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.dz-share-post .post-tags a span {
  display: inline-block;
}
.dz-share-post .post-tags a:hover {
  box-shadow: 0px 5px 12px var(--rgba-primary-4);
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: #fff;
}
@media only screen and (max-width: 767px) {
  .dz-share-post {
    display: block;
  }
  .dz-share-post .post-tags {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .dz-share-post .post-tags a {
    margin-right: 5px;
  }
}

#comment-list:empty + .paginate-links {
  margin-top: -30px;
}

.post-swiper,
.post-swiper-thumb,
.service-swiper {
  position: relative;
}
.post-swiper .btn-prev,
.post-swiper .btn-next,
.post-swiper .prev-post-swiper-btn,
.post-swiper .next-post-swiper-btn,
.post-swiper .prev-service-swiper-btn,
.post-swiper .next-service-swiper-btn,
.post-swiper-thumb .btn-prev,
.post-swiper-thumb .btn-next,
.post-swiper-thumb .prev-post-swiper-btn,
.post-swiper-thumb .next-post-swiper-btn,
.post-swiper-thumb .prev-service-swiper-btn,
.post-swiper-thumb .next-service-swiper-btn,
.service-swiper .btn-prev,
.service-swiper .btn-next,
.service-swiper .prev-post-swiper-btn,
.service-swiper .next-post-swiper-btn,
.service-swiper .prev-service-swiper-btn,
.service-swiper .next-service-swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) skewX(-10deg);
  height: 45px;
  width: 45px;
  line-height: 45px;
  border-radius: 0;
  text-align: center;
  font-size: 20px;
  background-color: #fff;
  color: var(--primary);
  z-index: 1;
  margin: 0 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.post-swiper .btn-prev:hover,
.post-swiper .btn-next:hover,
.post-swiper .prev-post-swiper-btn:hover,
.post-swiper .next-post-swiper-btn:hover,
.post-swiper .prev-service-swiper-btn:hover,
.post-swiper .next-service-swiper-btn:hover,
.post-swiper-thumb .btn-prev:hover,
.post-swiper-thumb .btn-next:hover,
.post-swiper-thumb .prev-post-swiper-btn:hover,
.post-swiper-thumb .next-post-swiper-btn:hover,
.post-swiper-thumb .prev-service-swiper-btn:hover,
.post-swiper-thumb .next-service-swiper-btn:hover,
.service-swiper .btn-prev:hover,
.service-swiper .btn-next:hover,
.service-swiper .prev-post-swiper-btn:hover,
.service-swiper .next-post-swiper-btn:hover,
.service-swiper .prev-service-swiper-btn:hover,
.service-swiper .next-service-swiper-btn:hover {
  background-color: var(--primary);
  color: #fff;
}
.post-swiper .btn-prev i,
.post-swiper .btn-next i,
.post-swiper .prev-post-swiper-btn i,
.post-swiper .next-post-swiper-btn i,
.post-swiper .prev-service-swiper-btn i,
.post-swiper .next-service-swiper-btn i,
.post-swiper-thumb .btn-prev i,
.post-swiper-thumb .btn-next i,
.post-swiper-thumb .prev-post-swiper-btn i,
.post-swiper-thumb .next-post-swiper-btn i,
.post-swiper-thumb .prev-service-swiper-btn i,
.post-swiper-thumb .next-service-swiper-btn i,
.service-swiper .btn-prev i,
.service-swiper .btn-next i,
.service-swiper .prev-post-swiper-btn i,
.service-swiper .next-post-swiper-btn i,
.service-swiper .prev-service-swiper-btn i,
.service-swiper .next-service-swiper-btn i {
  transform: skewX(10deg);
}
.post-swiper .btn-prev,
.post-swiper .prev-post-swiper-btn,
.post-swiper .prev-service-swiper-btn,
.post-swiper-thumb .btn-prev,
.post-swiper-thumb .prev-post-swiper-btn,
.post-swiper-thumb .prev-service-swiper-btn,
.service-swiper .btn-prev,
.service-swiper .prev-post-swiper-btn,
.service-swiper .prev-service-swiper-btn {
  left: 0;
}
.post-swiper .btn-next,
.post-swiper .next-post-swiper-btn,
.post-swiper .next-service-swiper-btn,
.post-swiper-thumb .btn-next,
.post-swiper-thumb .next-post-swiper-btn,
.post-swiper-thumb .next-service-swiper-btn,
.service-swiper .btn-next,
.service-swiper .next-post-swiper-btn,
.service-swiper .next-service-swiper-btn {
  right: 0;
}
.post-swiper .swiper-slide img,
.post-swiper-thumb .swiper-slide img,
.service-swiper .swiper-slide img {
  width: 100%;
}

.service-swiper img {
  border-radius: var(--border-radius-base);
}

.blog-single .dz-media + .dz-info {
  padding: 30px 0 0;
}

.author-box {
  padding: 35px;
  background: #f3f4f6;
  border-radius: var(--border-radius);
  margin-bottom: 60px;
}
.author-box .author-profile-info {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info {
    display: block;
    text-align: center;
  }
}
.author-box .author-profile-info .author-profile-pic {
  width: 150px;
  height: 150px;
  overflow: hidden;
  min-width: 150px;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-pic {
    width: 80px;
    height: 80px;
    min-width: 80px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
.author-box .author-profile-info .author-profile-pic img {
  width: 100%;
}
.author-box .author-profile-info .author-profile-content {
  padding-left: 25px;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content {
    padding-left: 0;
    padding-right: 0;
  }
}
.author-box .author-profile-info .author-profile-content p {
  margin-bottom: 15px;
}
.author-box .author-profile-info .author-profile-content ul {
  padding: 0;
  margin: 0;
  margin-right: 5px;
  display: table;
  float: left;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content ul {
    display: inline-block;
    float: none;
    margin-right: 0;
  }
}
.author-box .author-profile-info .author-profile-content ul li {
  padding: 0;
  margin: 0;
  margin-right: 10px;
  float: left;
}
@media only screen and (max-width: 575px) {
  .author-box .author-profile-info .author-profile-content ul li {
    margin-right: 5px;
    margin-left: 5px;
  }
}
.author-box .author-profile-info .author-profile-content ul li a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  background: var(--primary);
  color: #fff;
  vertical-align: middle;
  display: block;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: var(--border-radius-sm);
}
.author-box .author-profile-info .author-profile-content ul li a:hover {
  background: var(--primary-hover);
}

#comment-list:empty + .paginate-links {
  margin-top: -30px;
}

.bypostauthor {
  font-size: normal;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.wp-block-columns.alignfull,
.alignfull:not(.has-background) .wp-block-columns {
  padding-left: 1rem;
  padding-right: 1rem;
}

.error-page {
  position: relative;
  height: calc(100vh - 68px);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  min-height: 700px;
}
.error-page .inner-content {
  padding-top: 100px;
}
.error-page .dz_error {
  font-size: 220px;
  font-weight: 700;
  line-height: 0.6;
  margin: auto;
  color: #222222;
  font-family: var(--font-family-title);
}
@media only screen and (max-width: 1680px) {
  .error-page .dz_error {
    font-size: 180px;
  }
}
@media only screen and (max-width: 1480px) {
  .error-page .dz_error {
    font-size: 200px;
    line-height: 1;
  }
}
@media only screen and (max-width: 991px) {
  .error-page .dz_error {
    font-size: 150px;
  }
}
@media only screen and (max-width: 767px) {
  .error-page .dz_error {
    font-size: 125px;
  }
}
@media only screen and (max-width: 575px) {
  .error-page .dz_error {
    font-size: 90px;
  }
}
.error-page .error-head {
  font-size: 30px;
  margin: 40px auto 30px;
  max-width: 600px;
  font-weight: 500;
  color: var(--title);
}
.error-page:after {
  content: attr(data-text);
  position: absolute;
  font-weight: 700;
  font-size: 248px;
  line-height: 1;
  font-family: var(--font-family-title);
  z-index: -2;
  left: 0px;
  color: #fff;
  filter: drop-shadow(0px 10px 40px rgba(255, 129, 57, 0.1));
  opacity: 0.4;
  bottom: 10%;
}
@media only screen and (max-width: 1480px) {
  .error-page {
    min-height: 650px;
  }
  .error-page .error-head {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 40px;
    max-width: 540px;
    line-height: 1.4;
  }
}
@media only screen and (max-width: 991px) {
  .error-page {
    min-height: 500px;
  }
  .error-page .inner-content {
    padding-top: 50px;
  }
  .error-page .error-head {
    margin-bottom: 30px;
  }
  .error-page:after {
    font-size: 200px;
  }
}
@media only screen and (max-width: 767px) {
  .error-page .error-head {
    font-size: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    max-width: 440px;
  }
  .error-page .inner-content {
    padding-top: 0px;
  }
  .error-page:after {
    font-size: 150px;
  }
}
@media only screen and (max-width: 575px) {
  .error-page {
    min-height: 450px;
  }
  .error-page .error-head {
    font-size: 18px;
  }
  .error-page:after {
    font-size: 100px;
  }
}

/* Appointment Page */
.appointment-page {
  position: relative;
  z-index: 1;
  height: calc(100vh - 68px);
  min-height: 750px;
  width: 100%;
  background-size: cover;
  background-position: bottom left;
  padding-top: 130px;
  display: flex;
  align-items: center;
}
.appointment-page:after {
  content: attr(data-text);
  position: absolute;
  font-weight: 700;
  font-size: 245px;
  line-height: 1.3;
  font-family: var(--font-family-title);
  z-index: -1;
  left: 20px;
  color: #fff;
  filter: drop-shadow(0px 30px 50px rgba(255, 129, 57, 0.15));
  opacity: 0.4;
  bottom: 100px;
}
@media only screen and (max-width: 1480px) {
  .appointment-page:after {
    line-height: 1;
    bottom: 30px;
  }
}
.appointment-page .sub-title {
  font-size: 24px;
}
.appointment-page .form-check {
  margin-bottom: 50px;
  line-height: 1;
  position: relative;
  padding-left: 45px;
}
.appointment-page .form-check label {
  font-size: 18px;
  line-height: 1.6;
  font-weight: 500;
  color: #000;
}
.appointment-page .form-check .form-check-input {
  border-radius: 0;
  height: 30px;
  width: 30px;
  margin: 0;
  margin-right: 15px;
  border: 3px solid var(--primary);
  position: absolute;
  left: 0;
}
@media only screen and (max-width: 1480px) {
  .appointment-page .form-check .form-check-input {
    border: 2px solid var(--primary);
  }
}
@media only screen and (max-width: 767px) {
  .appointment-page .form-check .form-check-input {
    border: 1px solid var(--primary);
  }
}
@media only screen and (max-width: 1480px) {
  .appointment-page .form-check {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 991px) {
  .appointment-page .form-check {
    padding-left: 35px;
  }
  .appointment-page .form-check label {
    font-size: 16px;
  }
  .appointment-page .form-check .form-check-input {
    height: 20px;
    width: 20px;
    margin-top: 1px;
  }
}
@media only screen and (max-width: 575px) {
  .appointment-page .form-check {
    margin-bottom: 30px;
  }
  .appointment-page .form-check label {
    font-size: 14px;
  }
  .appointment-page .form-check .form-check-input {
    margin-right: 10px;
  }
}
.appointment-page .appointment-form .appointment-text {
  font-size: 28px;
  font-weight: 500;
  line-height: 2.8;
  margin-bottom: 30px;
  color: #000;
}
@media only screen and (max-width: 1480px) {
  .appointment-page .appointment-form .appointment-text {
    font-size: 24px;
  }
}
@media only screen and (max-width: 1199px) {
  .appointment-page .appointment-form .appointment-text {
    font-size: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .appointment-page .appointment-form .appointment-text {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  .appointment-page .appointment-form .appointment-text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .appointment-page .appointment-form .appointment-text {
    line-height: 2.5;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .appointment-page .appointment-form .dropdown-toggle {
    height: 35px;
  }
}
@media only screen and (max-width: 575px) {
  .appointment-page .appointment-form .dropdown-toggle {
    height: 32px;
    border-bottom: 1px solid #000 !important;
  }
}
.appointment-page .appointment-form .filter-option {
  font-size: 24px;
}
@media only screen and (max-width: 1480px) {
  .appointment-page .appointment-form .filter-option {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1280px) {
  .appointment-page .appointment-form .filter-option {
    font-size: 18px;
  }
}
@media only screen and (max-width: 991px) {
  .appointment-page .appointment-form .filter-option {
    font-size: 16px;
  }
}
@media only screen and (max-width: 575px) {
  .appointment-page .appointment-form .filter-option {
    font-size: 15px;
  }
}
.appointment-page .appointment-form .form-control,
.appointment-page .appointment-form .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .appointment-page .appointment-form select,
.appointment-page .appointment-form .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .appointment-page .appointment-form select {
  border: none;
  line-height: 1;
  width: 300px;
  text-align: center !important;
  color: var(--primary);
  background: transparent !important;
  border-bottom: 2px solid #000;
  transform: translateY(-5px);
  margin: 0 10px;
  border-radius: 0;
  font-size: 24px;
  display: inline-block;
}
.appointment-page .appointment-form .form-control::placeholder,
.appointment-page .appointment-form .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .appointment-page .appointment-form select::placeholder,
.appointment-page .appointment-form .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .appointment-page .appointment-form select::placeholder {
  opacity: 0.6;
}
.appointment-page .appointment-form .form-control:last-child,
.appointment-page .appointment-form .wp-block-categories-dropdown select:last-child,
.wp-block-categories-dropdown .appointment-page .appointment-form select:last-child,
.appointment-page .appointment-form .wp-block-archives-dropdown select:last-child,
.wp-block-archives-dropdown .appointment-page .appointment-form select:last-child {
  width: 480px;
}
@media only screen and (max-width: 1480px) {
  .appointment-page .appointment-form .form-control,
  .appointment-page .appointment-form .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .appointment-page .appointment-form select,
  .appointment-page .appointment-form .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .appointment-page .appointment-form select {
    width: 270px;
    font-size: 20px;
  }
  .appointment-page .appointment-form .form-control:last-child,
  .appointment-page .appointment-form .wp-block-categories-dropdown select:last-child,
  .wp-block-categories-dropdown .appointment-page .appointment-form select:last-child,
  .appointment-page .appointment-form .wp-block-archives-dropdown select:last-child,
  .wp-block-archives-dropdown .appointment-page .appointment-form select:last-child {
    width: 400px;
  }
}
@media only screen and (max-width: 1280px) {
  .appointment-page .appointment-form .form-control,
  .appointment-page .appointment-form .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .appointment-page .appointment-form select,
  .appointment-page .appointment-form .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .appointment-page .appointment-form select {
    font-size: 18px;
  }
}
@media only screen and (max-width: 991px) {
  .appointment-page .appointment-form .form-control,
  .appointment-page .appointment-form .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .appointment-page .appointment-form select,
  .appointment-page .appointment-form .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .appointment-page .appointment-form select {
    width: 160px;
    height: 40px;
    padding: 5px 10px;
    font-size: 16px;
  }
  .appointment-page .appointment-form .form-control:last-child,
  .appointment-page .appointment-form .wp-block-categories-dropdown select:last-child,
  .wp-block-categories-dropdown .appointment-page .appointment-form select:last-child,
  .appointment-page .appointment-form .wp-block-archives-dropdown select:last-child,
  .wp-block-archives-dropdown .appointment-page .appointment-form select:last-child {
    width: 220px;
  }
}
@media only screen and (max-width: 767px) {
  .appointment-page .appointment-form .form-control,
  .appointment-page .appointment-form .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .appointment-page .appointment-form select,
  .appointment-page .appointment-form .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .appointment-page .appointment-form select {
    width: 120px;
    height: 35px;
    font-size: 15px;
  }
  .appointment-page .appointment-form .form-control:last-child,
  .appointment-page .appointment-form .wp-block-categories-dropdown select:last-child,
  .wp-block-categories-dropdown .appointment-page .appointment-form select:last-child,
  .appointment-page .appointment-form .wp-block-archives-dropdown select:last-child,
  .wp-block-archives-dropdown .appointment-page .appointment-form select:last-child {
    width: 150px;
  }
}
@media only screen and (max-width: 575px) {
  .appointment-page .appointment-form .form-control,
  .appointment-page .appointment-form .wp-block-categories-dropdown select,
  .wp-block-categories-dropdown .appointment-page .appointment-form select,
  .appointment-page .appointment-form .wp-block-archives-dropdown select,
  .wp-block-archives-dropdown .appointment-page .appointment-form select {
    margin: 0 5px;
    border-bottom: 1px solid #000;
    height: 32px;
  }
}
.appointment-page .appointment-form .dropdown {
  width: 320px !important;
  text-align: center !important;
}
@media only screen and (max-width: 1480px) {
  .appointment-page .appointment-form .dropdown {
    width: 250px !important;
  }
}
@media only screen and (max-width: 991px) {
  .appointment-page .appointment-form .dropdown {
    width: 160px !important;
  }
}
@media only screen and (max-width: 1480px) {
  .appointment-page {
    min-height: 650px;
  }
}
@media only screen and (max-width: 991px) {
  .appointment-page {
    padding-top: 70px;
    min-height: 600px;
  }
  .appointment-page .sub-title {
    font-size: 20px;
  }
  .appointment-page:after {
    font-size: 180px;
    line-height: 1;
  }
}
@media only screen and (max-width: 767px) {
  .appointment-page {
    padding-top: 50px;
    min-height: 500px;
  }
  .appointment-page .sub-title {
    font-size: 16px;
  }
  .appointment-page:after {
    font-size: 130px;
    bottom: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .appointment-page {
    padding-top: 80px;
    min-height: 550px;
  }
  .appointment-page .sub-title {
    font-size: 16px;
  }
  .appointment-page:after {
    display: none;
  }
}

.custom-react-select {
  width: 320px;
  display: inline-block;
  text-align: center;
}
.custom-react-select > div:nth-child(4) > div > div {
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  padding: 2px 16px;
  background-color: #fff;
  color: #000;
}
.custom-react-select > div:nth-child(4) > div > div:hover {
  background-color: var(--primary);
  color: white;
}
.custom-react-select > div:nth-child(3) {
  border-radius: 0;
  color: #fff !important;
  border: none !important;
  outline: none !important;
  background-color: transparent;
  padding: 0;
  line-height: 1.4;
  padding-bottom: 0px;
  border-bottom: 2px solid #000 !important;
}
.custom-react-select > div:nth-child(3) > div:first-child > div {
  color: var(--primary);
  font-weight: 400;
  font-size: 24px;
}
.custom-react-select > div:nth-child(3) > div:last-child {
  display: none;
}
@media only screen and (max-width: 575px) {
  .custom-react-select {
    width: 160px;
  }
  .custom-react-select > div:nth-child(3) {
    border-bottom: 1px solid #000 !important;
    min-height: 30px;
  }
  .custom-react-select > div:nth-child(3) > div:first-child > div {
    font-size: 15px;
  }
}

.schedule-table table {
  width: calc(100% - 5px);
  text-align: center;
  background: var(--rgba-primary-05);
}
.schedule-table table thead tr {
  border: none;
}
.schedule-table table thead th {
  height: 75px;
  font-family: var(--font-family-title);
  background: var(--primary);
  color: var(--theme-text-color);
  font-weight: 400;
}
.schedule-table table td {
  height: 55px;
  width: 12.5%;
  vertical-align: middle;
  padding: 15px;
  position: relative;
  border: 1px solid var(--rgba-primary-2);
}
.schedule-table table tr {
  border: 1px solid var(--rgba-primary-2);
}

.table-responsive {
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.table-responsive .table-responsive-md {
  min-width: 70rem !important;
}
.table-responsive .table-responsive-sm {
  min-width: 50rem !important;
}

.event-time {
  font-size: 14px;
  letter-spacing: 1px;
  background: var(--secondary);
  color: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.7) !important;
  border-top: 1px solid rgba(0, 0, 0, 0.7) !important;
}

.event {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  position: absolute;
}
.event:before {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  height: 0;
  opacity: 0;
  border-left: 0px solid transparent;
  border-right: 150px solid transparent;
  border-top: 10px solid var(--primary);
  clear: both;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.event .event-tag {
  position: absolute;
  opacity: 0;
  top: 0px;
  transform: translateY(-100%);
  left: 0;
  width: 100%;
  background: #000;
  color: #fff;
  font-size: 13px;
  padding: 4px 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.event .subtitle {
  display: inline-block;
  font-size: 14px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
.event .title {
  display: block;
  font-weight: 500;
}
.event:hover {
  background: var(--rgba-primary-2);
}
.event:hover:before,
.event:hover .event-tag {
  opacity: 1;
}

.coming-soon {
  height: 100vh;
  min-height: 700px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: #000;
  text-align: center;
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.coming-soon .logo-header img {
  max-width: 250px;
}
@media only screen and (max-width: 1280px) {
  .coming-soon {
    min-height: 550px;
  }
}
@media only screen and (max-width: 767px) {
  .coming-soon {
    padding-top: 30px;
    min-height: 500px;
  }
}
.coming-soon .inner-content {
  padding-bottom: 100px;
}
@media only screen and (max-width: 991px) {
  .coming-soon .inner-content {
    padding-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .coming-soon .inner-content {
    padding-bottom: 30px;
  }
}
.coming-soon .dz-head {
  font-size: 42px;
  margin-bottom: 10px;
}
@media only screen and (max-width: 1280px) {
  .coming-soon .dz-head {
    font-size: 42px;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 991px) {
  .coming-soon .dz-head {
    font-size: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .coming-soon .dz-head {
    font-size: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .coming-soon .dz-head {
    font-size: 28px;
  }
}
.coming-soon svg {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
}
.coming-soon svg path {
  fill: var(--primary);
}
.coming-soon p {
  font-size: 20px;
  max-width: 530px;
  margin: 0;
  margin: auto;
}
@media only screen and (max-width: 1280px) {
  .coming-soon p {
    font-size: 17px;
  }
}
@media only screen and (max-width: 991px) {
  .coming-soon p {
    font-size: 15px;
  }
}
.coming-soon .countdown {
  margin-bottom: 40px;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
.coming-soon .countdown .date {
  margin: 0 10px;
  display: inline-block;
  border-radius: 0;
}
.coming-soon .countdown .date .skew {
  position: relative;
}
.coming-soon .countdown .date span {
  display: block;
  font-weight: 500;
  color: var(--secondary);
  border-radius: 6px;
  padding: 2px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
@media only screen and (max-width: 991px) {
  .coming-soon .countdown .date span {
    font-size: 14px;
  }
}
.coming-soon .countdown .date .time {
  margin: 0;
  font-weight: 700;
  font-size: 82px;
  line-height: 82px;
}
@media only screen and (max-width: 991px) {
  .coming-soon .countdown .date .time {
    font-size: 75px;
  }
}
@media only screen and (max-width: 575px) {
  .coming-soon .countdown .date {
    margin: 0 5px;
  }
  .coming-soon .countdown .date .time {
    font-size: 35px;
    line-height: 1;
  }
}
@media only screen and (max-width: 1280px) {
  .coming-soon .countdown {
    margin: 30px 0;
  }
}
@media only screen and (max-width: 575px) {
  .coming-soon .countdown {
    max-width: 340px;
    margin-top: 25px;
    margin-bottom: 25px;
  }
}
.coming-soon .logo-header {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
@media only screen and (max-width: 575px) {
  .coming-soon .logo-header {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .coming-soon .logo-header {
    margin-bottom: 15px;
  }
}
.coming-soon:after {
  content: attr(data-text);
  position: absolute;
  font-weight: 700;
  font-size: 248px;
  line-height: 1;
  font-family: var(--font-family-title);
  z-index: -2;
  left: 0px;
  color: #fff;
  filter: drop-shadow(0px 10px 40px rgba(255, 129, 57, 0.3));
  opacity: 0.3;
  bottom: 0%;
}
@media only screen and (max-width: 991px) {
  .coming-soon:after {
    font-size: 200px;
    bottom: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .coming-soon:after {
    font-size: 150px;
  }
}
@media only screen and (max-width: 575px) {
  .coming-soon:after {
    font-size: 90px;
    bottom: 2%;
  }
}
.coming-soon .shape1,
.coming-soon .shape2,
.coming-soon .shape3,
.coming-soon .shape4,
.coming-soon .shape5,
.coming-soon .girl-img {
  position: absolute;
}
.coming-soon .shape1 {
  left: -20%;
  top: -50%;
}
.coming-soon .shape2 {
  right: -20%;
  bottom: -50%;
}
.coming-soon .shape3 {
  right: 5%;
  top: 10%;
}
.coming-soon .shape4 {
  left: 15%;
  top: 30%;
  z-index: -1;
}
.coming-soon .shape5 {
  left: 50%;
  bottom: -20px;
  z-index: -1;
}
.coming-soon .girl-img {
  left: 0;
  bottom: 0;
  z-index: 1;
}
@media only screen and (max-width: 1280px) {
  .coming-soon .girl-img {
    width: 250px;
  }
  .coming-soon .shape1 {
    left: -10%;
    top: -10%;
    width: 300px;
  }
  .coming-soon .shape2 {
    right: -10%;
    bottom: -10%;
    width: 300px;
  }
  .coming-soon .shape3,
  .coming-soon .shape4,
  .coming-soon .shape5 {
    width: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .coming-soon .girl-img {
    width: 200px;
  }
  .coming-soon .shape1 {
    left: -30%;
    top: -20%;
    width: 250px;
  }
  .coming-soon .shape2 {
    right: -30%;
    bottom: -20%;
    width: 250px;
  }
}

.countdown-timer .items-days {
  width: 120px;
  height: 120px;
  position: relative;
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .countdown-timer .items-days {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (max-width: 575px) {
  .countdown-timer .items-days {
    width: 65px;
    height: 65px;
  }
}
.countdown-timer .items-days .val {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  color: #000;
  font-size: 36px;
}
@media only screen and (max-width: 767px) {
  .countdown-timer .items-days .val {
    font-size: 20px;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 575px) {
  .countdown-timer .items-days .val {
    font-size: 18px;
  }
}

.countdown-timer .kineticjs-content:after {
  content: "";
  position: absolute;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  z-index: -1;
}

.inquiry-modal {
  padding: 0 15px;
}
.inquiry-modal .modal-dialog {
  max-width: 700px;
  display: flex;
  min-height: auto;
  background-color: #fff;
  align-items: stretch;
  border-radius: var(--border-radius-base);
  overflow: hidden;
}
.inquiry-modal .inquiry-adv {
  flex: 0 0 50%;
  max-width: 50%;
}
.inquiry-modal .inquiry-adv img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.inquiry-modal .dz-ajax-overlay:after {
  transform: unset;
}
.inquiry-modal .modal-content {
  border: 0;
  border-radius: 0;
  background: #fff;
  padding: 30px;
  flex: 0 0 50%;
  max-width: 50%;
}
.inquiry-modal .modal-content .modal-header {
  border: 0;
  padding: 0;
  display: block;
  text-align: center;
}
.inquiry-modal .modal-content .modal-header i {
  color: var(--primary);
  font-size: 40px;
  line-height: 1;
  display: inline-block;
}
.inquiry-modal .modal-content .modal-title {
  font-size: 24px;
  margin-bottom: 25px;
  line-height: 1.3;
  font-family: var(--font-family-title);
  text-align: center;
  width: 100%;
}
.inquiry-modal .modal-content .modal-body {
  padding: 0;
}
.inquiry-modal .btn-close {
  margin: 0;
  border: 0;
  right: 5px;
  top: 0px;
  opacity: 1;
  padding: 0;
  height: 40px;
  width: 40px;
  font-size: 40px;
  font-weight: 100;
  text-shadow: none;
  line-height: 40px;
  position: absolute;
  background: transparent;
  color: var(--theme-text-color);
}
@media only screen and (max-width: 767px) {
  .inquiry-modal .inquiry-adv {
    display: none;
  }
  .inquiry-modal .modal-content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 20px;
  }
}

.dz_error {
  font-size: 180px;
  font-weight: 700;
  line-height: 150px;
  margin: auto;
  color: var(--primary);
  font-family: var(--font-family-title);
}
@media only screen and (max-width: 991px) {
  .dz_error {
    font-size: 130px;
    line-height: 1;
  }
}
@media only screen and (max-width: 767px) {
  .dz_error {
    font-size: 90px;
  }
}
@media only screen and (max-width: 575px) {
  .dz_error {
    font-size: 75px;
  }
}

.dz-media.post-video iframe {
  height: 420px;
}
@media only screen and (max-width: 767px) {
  .dz-media.post-video iframe {
    height: 250px;
  }
}

.swiper-gallery-wrapper .gallery-thumb-swiper {
  margin-top: 5px;
}

.swiper-gallery-wrapper .thumb-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.swiper-gallery-wrapper .thumb-item:after {
  content: "";
  position: absolute;
  border: 2px solid #fff;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}
@media only screen and (max-width: 1280px) {
  .swiper-gallery-wrapper .thumb-item:after {
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;
  }
}

.swiper-gallery-wrapper .swiper-slide-thumb-active .thumb-item:after {
  opacity: 1;
}

/* .service-section-style-2{
	margin-bottom: -8px;
	.trainer{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	.icon-bx-wraper{
		&.style-4{
			margin-bottom: 30px;
			margin-top: 0;
			max-width: 300px;
			@include respond('tab-port'){
				max-width: 100%;
				width: 100%;
			}
			.icon-bx{
				display: flex;
				align-items: center;
				column-gap: 5px;
				@include respond('phone-land'){
					flex-direction: column;
					align-items: flex-start;
					row-gap: 10px;
					margin-bottom: 5px;	
				}
			}
			@include respond('phone-land'){
				.icon-content{
					p{
						font-size: 14px;
						}
				}
			}
		}
	}
} */
.service-wrapper1 {
  padding-bottom: 50px;
}
.service-wrapper1 .trainer {
  margin-bottom: -90px;
  margin-left: -30px;
  margin-right: -60px;
}
.service-wrapper1 .right-grid {
  padding-left: 50px;
}
.service-wrapper1 .left-grid {
  padding-right: 50px;
}
.service-wrapper1 .section-head {
  min-width: 650px;
}
@media only screen and (max-width: 1191px) {
  .service-wrapper1 .left-grid {
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .service-wrapper1 .section-head {
    min-width: 100%;
  }
  .service-wrapper1 .right-grid {
    padding-left: 15px;
  }
}
@media only screen and (max-width: 575px) {
  .service-wrapper1 .trainer {
    margin-left: 0;
    margin-right: 0;
  }
}

.map-iframe iframe {
  height: 450px;
}
@media only screen and (max-width: 991px) {
  .map-iframe iframe {
    height: 370px;
  }
}
@media only screen and (max-width: 575px) {
  .map-iframe iframe {
    height: 320px;
  }
}

.contact-box {
  z-index: 1;
  padding: 50px 40px;
  position: relative;
  background: var(--secondary);
}
.subscription {
  background: #ff4848;
}
.subscription-detail {
  border: 1px solid black;
  height: 52rem;
}

@media only screen and (max-width: 991px) {
  .contact-box {
    padding: 40px 30px;
  }
}
.contact-box .svg2 {
  position: absolute;
  bottom: -32px;
  right: -22px;
  height: 70px;
  width: 250px;
}
@media only screen and (max-width: 1199px) {
  .contact-box .svg2 {
    bottom: -27px;
    right: -20px;
    height: 60px;
    width: 200px;
  }
}
@media only screen and (max-width: 575px) {
  .contact-box .svg2 {
    bottom: -32px;
    right: -15px;
    height: 68px;
    width: 170px;
  }
}
.contact-box:before,
.contact-box:after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.contact-box:after {
  height: 85%;
  width: 60%;
  background-image: url(../images/background/bg-contact-2.png);
}
.contact-box:before {
  height: 30%;
  width: 25%;
  background-image: url(../images/background/bg-contact-1.png);
}
.contact-box .contact-title {
  font-size: 30px;
  color: #fff;
}
@media only screen and (max-width: 1199px) {
  .contact-box .contact-title {
    font-size: 25px;
  }
}
@media only screen and (max-width: 991px) {
  .contact-box .contact-title {
    font-size: 22px;
  }
}
.contact-box .contact-text {
  color: #ffffff80;
}
@media only screen and (max-width: 991px) {
  .contact-box .contact-text {
    font-size: 14px;
  }
}
.contact-box .widget ul {
  margin-top: 35px;
  margin-bottom: 120px;
}
@media only screen and (max-width: 1199px) {
  .contact-box .widget ul {
    margin-top: 30px;
    margin-bottom: 100px;
  }
}
@media only screen and (max-width: 575px) {
  .contact-box .widget ul {
    margin-bottom: 50px;
  }
}
.contact-box .icon-content p {
  font-size: 16px;
  opacity: 0.8;
  margin-bottom: 0;
}
@media only screen and (max-width: 575px) {
  .contact-box .icon-content p {
    font-size: 14px;
  }
}
.contact-box .icon-xl .icon-lg .icon-sm,
.contact-box .icon-bx-wraper.left .icon-xs {
  line-height: 1;
  float: left;
  margin-right: 15px;
}

.contact-section.style-2 .form-control::placeholder,
.contact-section.style-2 .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .contact-section.style-2 select::placeholder,
.contact-section.style-2 .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .contact-section.style-2 select::placeholder {
  color: #fff !important;
}

.under-construction {
  overflow: hidden;
  padding: 100px;
  height: 100vh;
  display: flex;
  vertical-align: middle;
  position: relative;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.under-construction p {
  font-size: 35px;
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  letter-spacing: 5px;
}
.under-construction .dz-head {
  margin-bottom: 15px;
  line-height: 1.1;
  font-size: 80px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}
.under-construction .warning-img {
  width: 150px;
  margin-bottom: 30px;
}
.under-construction .shape1,
.under-construction .shape2,
.under-construction .shape3,
.under-construction .shape4,
.under-construction .shape5,
.under-construction .girl-img {
  position: absolute;
}
.under-construction .shape1 {
  left: -20%;
  top: -50%;
}
.under-construction .shape2 {
  right: -20%;
  bottom: -50%;
}
.under-construction .shape3 {
  right: 5%;
  top: 10%;
}
.under-construction .shape4 {
  left: 15%;
  top: 30%;
  z-index: -1;
}
.under-construction .shape5 {
  left: 50%;
  bottom: -20px;
  z-index: -1;
}
.under-construction .girl-img {
  left: 0;
  bottom: 0;
  z-index: 1;
}
@media only screen and (max-width: 1280px) {
  .under-construction .girl-img {
    width: 250px;
  }
  .under-construction .shape1 {
    left: -10%;
    top: -10%;
    width: 300px;
  }
  .under-construction .shape2 {
    right: -10%;
    bottom: -10%;
    width: 300px;
  }
  .under-construction .dz-head {
    font-size: 45px;
  }
  .under-construction p {
    font-size: 24px;
  }
  .under-construction .shape3,
  .under-construction .shape4,
  .under-construction .shape5 {
    width: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .under-construction {
    padding: 30px;
  }
  .under-construction .dz-head {
    font-size: 30px;
    margin-bottom: 10px;
  }
  .under-construction .girl-img {
    width: 200px;
  }
  .under-construction .warning-img {
    width: 120px;
    margin-bottom: 20px;
  }
  .under-construction p {
    font-size: 16px;
    letter-spacing: 2px;
  }
  .under-construction .shape1 {
    left: -30%;
    top: -20%;
    width: 250px;
  }
  .under-construction .shape2 {
    right: -30%;
    bottom: -20%;
    width: 250px;
  }
}
.under-construction.style-3 .bg-pattern1 {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}

.port-single-info {
  padding: 45px 45px 10px;
  z-index: 1;
  position: relative;
}
@media only screen and (max-width: 575px) {
  .port-single-info {
    padding: 30px;
  }
}
.port-single-info:after,
.port-single-info:before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background-size: cover;
  background-repeat: no-repeat;
}
.port-single-info:after {
  background-image: url(../images/background/single-box-bg.png);
  width: 37%;
  height: 47%;
}
@media only screen and (max-width: 991px) {
  .port-single-info:after {
    width: 45%;
    height: 26%;
  }
}
@media only screen and (max-width: 767px) {
  .port-single-info:after {
    width: 50%;
    height: 22%;
  }
}
@media only screen and (max-width: 575px) {
  .port-single-info:after {
    width: 250px;
    height: 15%;
  }
}
.port-single-info:before {
  background-image: url(../images/background/bg-pricetable-2.png);
  width: 150px;
  height: 200px;
  opacity: 0.2;
}
.port-single-info svg {
  position: absolute;
  bottom: -32px;
  right: -22px;
  height: 70px;
  width: 250px;
}
@media only screen and (max-width: 991px) {
  .port-single-info svg {
    bottom: -23px;
    right: -20px;
    height: 50px;
    width: 190px;
  }
}
@media only screen and (max-width: 575px) {
  .port-single-info svg {
    bottom: -23px;
    right: -15px;
    height: 50px;
    width: 130px;
  }
}
.port-single-info .portfolio-foot > ul {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
.port-single-info .portfolio-foot > ul > li {
  width: 25%;
  margin-bottom: 30px;
}
.port-single-info .portfolio-foot > ul > li h6,
.port-single-info .portfolio-foot > ul > li .h6 {
  color: var(--primary);
  font-weight: 500;
  font-size: 15px;
}
@media only screen and (max-width: 1199px) {
  .port-single-info .portfolio-foot > ul > li h6,
  .port-single-info .portfolio-foot > ul > li .h6 {
    margin-bottom: 5px;
  }
}
.port-single-info .portfolio-foot > ul > li .text {
  color: #fff;
  font-size: 18px;
}
@media only screen and (max-width: 1280px) {
  .port-single-info .portfolio-foot > ul > li .text {
    font-size: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .port-single-info .portfolio-foot > ul > li {
    width: 50%;
  }
}
@media only screen and (max-width: 575px) {
  .port-single-info .portfolio-foot > ul > li {
    width: 100%;
    margin-bottom: 20px;
  }
}
.port-single-info .widget_getintuch {
  margin-top: 12px;
  margin-bottom: 0;
}
.port-single-info .widget_getintuch ul li {
  margin-bottom: 10px;
  padding-left: 30px;
}
.port-single-info .widget_getintuch ul li span {
  font-size: 15px;
}
.port-single-info .widget_getintuch ul li i {
  font-size: 16px;
  top: 4px;
}

.port-single-content .title {
  font-size: 40px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 991px) {
  .port-single-content .title {
    font-size: 33px;
  }
}
@media only screen and (max-width: 575px) {
  .port-single-content .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
}

.port-single-content .text {
  font-size: 18px;
}
@media only screen and (max-width: 991px) {
  .port-single-content .text {
    font-size: 16px;
  }
}

.dz-box.gallery {
  position: relative;
}
.dz-box.gallery .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transition: all 0.4s;
}
.dz-box.gallery .content:after {
  content: "";
  position: absolute;
  height: 100%;
  opacity: 0;
  z-index: 0;
  width: 100%;
  bottom: 0px;
  left: 0px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  background: #000;
}
.dz-box.gallery .content .view-btn {
  z-index: 1;
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  background-color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: translate(-50%, -50%) skewX(-12deg) translateY(2px);
  cursor: pointer;
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
  color: var(--title);
}
.dz-box.gallery .content .view-btn i {
  transform: skewX(12deg);
}
.dz-box.gallery:hover .content .view-btn {
  opacity: 1;
}
.dz-box.gallery:hover .content:after {
  opacity: 0.5;
}

.gym-time-container {
  height: 73.5vw;
  max-height: 535px;
  max-width: 500 px;
  width: 98vw;
  position: relative;
  overflow: hidden;
}
.gym-time-container figure {
  background: url("/src/assets/images/after-before/before.jpg") no-repeat center center;
  background-size: cover;
  position: absolute;
  margin: 0;
  height: 100%;
  width: 100%;
}
.gym-time-container figure div:first-of-type {
  background-image: url("/src/assets/images/after-before/after.jpg");
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 50%;
  box-shadow: 0 5px 10px -2px rgba(0, 0, 0, 0.3);
  bottom: 0;
}
.gym-time-container figure div:first-of-type::before,
.gym-time-container figure div:first-of-type::after {
  content: "";
  position: absolute;
  right: -2px;
  width: 4px;
  height: calc(50% - 25px);
  background: white;
  z-index: 3;
}
.gym-time-container figure div:first-of-type::before {
  top: 0;
}
.gym-time-container figure div:first-of-type::after {
  bottom: 0;
}
.gym-time-container figure div:last-of-type {
  border-radius: 100%;
  background: white;
  position: absolute;
  height: 50px;
  width: 50px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
}
.gym-time-container figure div:last-of-type::before,
.gym-time-container figure div:last-of-type::after {
  content: "";
  width: 0;
  height: 0;
  border: 6px inset transparent;
  position: absolute;
  top: 50%;
  margin-top: -6px;
}
.gym-time-container figure div:last-of-type::before {
  border-right: 6px solid grey;
  left: 50%;
  margin-left: -17px;
}
.gym-time-container figure div:last-of-type::after {
  border-left: 6px solid grey;
  right: 50%;
  margin-right: -17px;
}
.gym-time-container input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  position: absolute;
  top: 50%;
  left: -25px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  width: calc(100% + 50px);
  z-index: 2;
}
.gym-time-container input[type="range"]:focus,
.gym-time-container input[type="range"]:active {
  border: none;
  outline: none;
}
.gym-time-container input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: transparent;
}
.gym-time-container input[type="range"]::-moz-range-track {
  -moz-appearance: none;
  height: 15px;
  width: 100%;
  background-color: transparent;
  position: relative;
  outline: none;
}

.first-slider-img .main-img {
  height: auto;
  width: auto;
  left: 0;
}
@media only screen and (max-width: 991px) {
  .first-slider-img .first-slide-image {
    width: 100%;
    margin-left: 0;
    object-fit: cover;
    margin-top: 0;
    margin-bottom: "2rem"; /* Adjust to prevent overlap */
  }
}

/* Adjust for small screens (mobile) */
@media only screen and (max-width: 600px) {
  .first-slider-img .first-slide-image {
    width: 100%;
    object-fit: cover;
    margin-top: 0;
    margin-bottom: 2rem;
    /* Adjust to prevent overlap */
  }
}


@media only screen and (max-width: 500px) {
  .fourth-slider-img-one {
    /* width: 100%; */
    height: 25rem !important;
    object-fit: cover;
  }

  .main-img {
    height: 30rem;
  }

  .top-content.backdrop-blur {
    /* -webkit-text-stroke: 3px black; */
  }
}

@media only screen and (max-width: 985px) and (min-width: 570px) {
  .main-bnr-one .media1 .main-img .first-slide-image .first-slide-image {
    margin-bottom: 1rem !important;
    margin-left: 14rem !important;
    /* Adjust as needed for landscape */
  }

  .fourth-slider-img-one {
    height: 16rem !important;
    margin-left: 3rem;
    /* Adjust for landscape if necessary */
  }

  .main-img {
    height: 25rem;
    /* Adjust for landscape if necessary */
  }
}
@media only screen and (max-width: 600px) {
  .main-bnr-one .media1 .main-img .first-slide-image .first-slide-image {
    margin-bottom: 1rem !important;
    margin-left: 12rem !important;
    /* Adjust as needed for landscape */
  }

  .fourth-slider-img-one {
    height: 20rem !important;
    /* Adjust for landscape if necessary */
  }

  .main-img {
    height: 25rem;
    /* Adjust for landscape if necessary */
  }
}

/* for landscape */

@media only screen and (max-width: 480px) and (orientation: landscape) {
  .banner-media {
    /* Adjust the dimensions of the image container */
    width: 100% !important;
    margin-bottom: 8rem !important;
    /* Adjust the bottom margin as needed */
  }

  .banner-media .main-img {
    width: 100% !important;
    height: auto !important;
    /* Maintain aspect ratio */
    object-fit: cover !important;
  }

  .point-list {
    /* Adjust the list styling for mobile landscape if needed */
    font-size: 14px !important;
    /* Example size adjustment */
  }

  .point-list li {
    /* Ensure the list items are displayed properly */
    padding: 10px !important;
    margin: 0 !important;
  }
}
.discount-percent{
  /* border: 1px solid black; */
  border-radius: 2rem;
  width:50%;
  height:2.2rem;
  text-align: center;
  padding-top: 4px;
  background-color:   #C04040;
  color:#ffffff;
  margin-bottom: 1rem;
  margin-right:0,3rem;
}
.off-reason{
  /* border:1px solid black; */
  animation: flash 3s infinite, bounce 2s ;
  color:black;
  font-weight: 600;
  margin-bottom: 0.3rem;
}
@keyframes flash {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.offer-img{
  height:10%;
}
.price-section{
  display: flex;
  height:3.8rem;
}
.free-watch-img2{
  height:7rem;
  position:relative;
  left:3rem;
  /* height:3rem;
  width:30%; */
}
.get-app-btn{
  height:2.7rem;
  width:5.5rem;
  border-radius: 1.4rem;
  background-color: #FF4848;
  color:#ffffff;
  border: none;
  outline: none;
  line-height: 2rem;
  font-weight: 500;
}

.smiling-women-man{
  border-radius: 1rem !important;
  width: 75dvh!important;
  height: 75dvh !important;
  object-fit: cover;
  margin-top: 40px !important;
}

.doc-slider-img{
  border-radius: 1rem !important;
  width: 75dvh !important;
  height: 75dvh !important;
  object-fit: cover;
  margin-top: 40px !important;
}

.custom-header{
  max-width: 1600px;
}

.hbm-title{
  margin-left: 5rem;
}
.article-main-image{
  height: 30rem !important;
  width: auto !important;
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
  .article-main-image{
    height: auto !important;
    width: auto !important;
  }
}
.article-Press-image{
    height: 15rem !important;
      width: auto !important;
}
.container-news-article{
  padding-top: 10px !important;
}

.slider-button.next.arrow-mobile, .slider-button.prev.arrow-mobile{
  display: none;
}

@media (max-width: 767px) {

  .custom-banner-content{
    width: 100vw;
    margin-left: -51dvh;
  }

  .custom-margin-buttons{
    margin-top: -8rem;
  }

  .custom-banner{
    display: flex;
    flex-direction: column-reverse !important;
  }

  .smiling-women-man{
    border-radius: 1rem !important;
    width: 98dvw !important;
    height: 98dvw !important;
    object-fit: cover;
    margin-top: 0 !important;
    margin-left: -18.5dvw !important;
    margin-bottom: 10rem !important;
  }

  .doc-slider-img{
    border-radius: 1rem !important;
    width: 98dvw !important;
    height: 96dvw !important;
    object-fit: cover;
    margin-top: 0 !important;
    margin-left: -18.5dvw !important;
    margin-bottom: 8rem !important;
  }
  .mobile-free-watch{
            margin-top: 16rem;
              margin-left: -22rem;
  }
  .mobile-text{
  margin-top: -8rem;
    margin-left: 12rem;
  }

  .download-image-span{
    padding-left: 15px;
    padding-bottom: 5rem;
  }

  .hbm-title{
    margin-left: 1rem;
  }
  .slider-button {
    font-size: 3rem;
  }

  .arrow-mobile {
    margin-top: -9rem;
}

.fixed-topbar-right ul{
  display: flex;
  gap: 10px;
}
.fixed-topbar-right ul li{
padding-left: 0 !important;
}
/* .fixed-topbar-right ul li:nth-child(1){
  width: 60%;
}
.fixed-topbar-right ul li:nth-child(2){
  width: 40%;
} */
.fixed-topbar-left{
  display: none;
}

.fixed-bot-button-l{
  padding: 8px 15px !important;
}
}

.dz-topbar-right ul{
  display: flex;
  align-items: flex-start;
}
.error-msg-subscription{
margin-top:-2.9rem;
}
.error-msg-pincode{
  margin-top:-3.5rem;
}
@media only screen and (max-width: 600px) {
  .error-msg-subscription {
    margin-top: -1.5rem;
  }
  
  .error-msg-pincode {
    margin-top: -2rem; 
  }
}
@media only screen and (max-width: 812px) and (orientation: landscape) {
  .error-msg-subscription {
    margin-top: -1rem;
  }
  
  .error-msg-pincode {
    margin-top: -1.5rem; 
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .error-msg-subscription {
    margin-top: -2rem; /* Adjust this value as needed for iPad view */
  }
  
  .error-msg-pincode {
    margin-top: -2.5rem; /* Adjust this value as needed for iPad view */
  }
}