#root{
  background-color: white;
  margin: 0;
  padding: 0;
}

html{
  background-color: white;
}


.booking-time-calendly-background {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    min-height: 100vh;
    /* height: 40rem; */
    border-radius: 2rem;
    background-color: #FFF3EC;
    background-image: url(../assets/images/background/bg3.png);
    background-size: cover;
    box-shadow: 0 4px 12px rgba(128, 128, 128, 0.4);
    justify-content: center;
    position: relative;
    left: 10%;
    margin-top: 1.5rem;
    /* overflow: hidden; */
    margin-bottom: 1.8rem;
    padding: 1rem;
}

.inner-div {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 5px;
    flex-direction: column; 
    width: 100%;
    /* width: 95%; */
    /* height: 95%; */
    /* height: auto; */
    border-radius: 2rem;
    margin-top: 7px;
}

.img-ckhk-booking-page{
    position: relative;
    top:2%;
    /* left: 5%; */
    width: 15rem;
    margin-bottom: 35px;
}

.ckhk-image{
    height:4rem;
    width:14rem;
}

.calendlyComponent {
    /* width: 95%; */
    width: 100%;
    /* height: 85%; */
    min-height: 70vh;
    border-radius: 2rem;
    margin-bottom: 4px;
}

.calendly-inline-widget {
    border-radius: 8px;
    height: 100%;
    width: 100%;
    margin: 0%;
}

.home-button{
    font-size: 16px;
    border-radius: 0.6rem;
    color: white;
    background-color: #ff4848;
    border: none;
    width: 9rem;
    height: 3rem;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .img-ckhk-booking-page {
    left: 15% !important;
  }
  .ckhk-image{
    height: 4rem;
    width: 200px;
    margin-bottom: 10px;
  }
}