.responsive-text {
  font-size: 35px;
  font-weight: 800;
  text-align: center;
  padding: 10px 20px;
  position: relative;
  top: 3rem;
}

.black-text {
  color: black;
}

.red-text {
  color: red;
}

@media (max-width: 768px) {
  .responsive-text {
    font-size: 35px;
    margin-top: 80px;
  }
}

@media (max-width: 480px) {
  .responsive-text {
    font-size: 35px;
    margin-top: 60px;
  }
}
