.dz-card{
	position:relative;
	background-color: #fff;
	
	.dz-info{
		padding:30px;
		position:relative;
	}
	.dz-title{
		margin-bottom:15px;
		word-break: break-word;
		@include respond('phone'){
			margin-bottom:15px;
		}
	}
	&.blog-half{
		display:flex;
		
		@include respond('phone'){
			display:block;
		}
		.dz-info{
			padding:30px;
			position:relative;
			flex: 1;
		}
		.dz-media{
			margin-bottom: 0;
			max-width: 300px;
			min-width: 300px;
			a{
				display:block;
				height:100%;
				
				
			}
			img{
				height:100%;
				object-fit:cover;
				min-height: 215px;
				@include respond('phone'){
					min-height: auto;
				}
			}
		}
		&.post-video .post-video-icon{
			width: 60px;
			height: 60px;
			font-size: 20px;
		}
		.swiper{
			height: 100%;
		}
	}
}
.dz-media{
	&.height-sm {
		height:220px;
		img {
			height:100%;
			object-fit:cover;
		}
	}
	&.height-md {
		height:265px;
		img {
			height:100%;
			object-fit:cover;
		}
		@include custommq($max: 1199px) {
			height:220px;
		}
		@include respond ('phone-land'){
			height:200px;
		}
		.post-swiper {
			height:100%;
		}
	}
	&.height-lg {
		height:350px;
		@include custommq($max: 1199px) {
			height:300px;
		}
		@include respond ('phone-land'){
			height:220px;
			img {
				height:100%;
				object-fit:cover;
			}
		}
	}
}
.dz-meta{
	margin-bottom: 10px;
	ul{
		margin:0;
		padding:0;
		li{
			display: inline-block;
			position: relative;
			font-size: 15px;
			margin-right: 15px;
			padding-right: 15px;
			
			&:after{
				content: "";
				height: 6px;
				width: 6px;
				background-color: var(--primary);
				position: absolute;
				top: 50%;
				right: -5px;
				border-radius: 50%;
				transform: translateY(-50%);
			}
			
			&:last-child{
				margin-right:0;
				padding-right: 0;
				&:after{
					content: none;
				}
			}
			i{
				font-size: 16px;
				margin-right: 2px;
				color: var(--primary);
				transform: scale(1.3);
				position: relative;
				top: 2px;
			}
			a{
				color:inherit;
			}
			@include respond('phone'){
				margin-right: 10px;
				padding-right: 10px;
			}
		}	
	}	
}
.dz-badge {
    background-color: var(--primary);
    color: #fff;
    border-radius: 3px;
    padding: 6px 15px;
    font-size: 15px;
	&:hover {
		color:white;
	}
}
.post-video .post-video-icon {
    width: 100px;
    height: 100px;
	line-height: 100px;
	border-radius: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
	color: var(--primary);
	font-size: 30px;
	margin: 0 auto;
	background-color: $white;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
	box-shadow: 0 0 0 8px rgba(#fff,0.5);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
	
	@include respond('phone'){
		height: 50px;
		width: 50px;
		line-height: 50px;
		font-size: 14px;
	}
	&:before,
	&:after{
		content: "";
		top: 0;
		left: 0;
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		border: 1px solid #fff;
		z-index: -1;
		transform:  scale(1);
		-moz-transform:  scale(1);
		-webkit-transform:  scale(1);
		-ms-transform:  scale(1);
		-o-transform:  scale(1);
		-moz-transition:all 0.5;
		-o-transition:all 0.5;
		-webkit-transition:all 0.5;
		-ms-transition:all 0.5;
		transition:all 0.5;
		animation-duration: 3s;
		-webkit-animation-iteration-count: infinite;
		-webkit-animation-duration: 3s;
	}
	&:before{
		-webkit-border-radius: 50%;
		-khtml-border-radius: 50%;
		-moz-border-radius: 50%;
		-ms-border-radius: 50%;
		-o-border-radius: 50%;
		border-radius: 50%;
		animation: animationSignal1;
		animation-iteration-count: infinite;
		-webkit-animation: animationSignal1;
	}
	&:after{
		-webkit-border-radius: 100%;
		-khtml-border-radius: 100%;
		-moz-border-radius: 100%;
		-ms-border-radius: 100%;
		-o-border-radius: 100%;
		border-radius: 100%;
		animation: animationSignal2;
		animation-iteration-count: infinite;
		-webkit-animation: animationSignal2;
	}
}

.dz-card.blog-half.sticky{
    position: relative;
    border: 0.375rem solid var(--primary);
    overflow: hidden;
	.sticky-icon {
		background: var(--primary);
		width: 3.125rem;
		height: 3.125rem;
		position: absolute;
		transform: rotate(45deg);
		top: -1.563rem;
		right: -1.563rem;
		z-index: 1;
		color: #fff;
		
		i{
			position: absolute;
			bottom: 0.1875rem;
			right: 1.125rem;
			transform: rotate(5deg);
			color: #fff;
			font-size: 1rem;
		}
	}
}












