.failure-main{
    border: 1px solid white;
    border-bottom: none;
    text-align: center;
    padding: 0rem;
} 
.header-failure{
    /* border: 1px solid black; */
    display: flex;
    justify-content: center;
    height: 47rem;
    display: flex;
    margin-top: 10rem;  
}
.payment-failure-text   {
    /* border: 1px solid black; */
    height: 13rem;
    margin-top: 2rem;
    width: 60%;
}
.payment-failure-text img{
    height:4rem;
    margin-top:4rem;
    background:white;
}
.title-failure{
 width:80%; 
 margin-left:6rem;
}
.btn-home-failure{
    margin-top: 0.5rem;
    background:#FF4848;
    color: white;
    width: 9rem;
    height: 3rem;
    border-radius: 1.3rem;
    border: none;
}
.qr{
    height:10rem;
    position: relative;
    margin-top: 3rem;
}
.payment-failure-text img{
    margin-left: 2rem;
}
.img-qr-failure{
    /* border: 1px solid black; */
    width: 80%;
    display: flex;
    justify-content: space-around;
    position: relative;
    left: 8%;
}
.letter{
    /* border: 1px solid black; */
    width: 84%;
    position: relative;
    left: 10%;
    box-shadow: 0px 10px 20.47px 0px #0000000D;
    padding: 1rem;
    top:3%;
    margin-top: 1rem;
}
.title-qr-failure{
    /* border: 1px solid black; */
    width: 100%;
    text-align: start;
    font-size: 1.2rem;
    margin-top:1rem;
    margin-left:5rem;
}
.failure-img{
    height:28rem;
}
.free-watch-img{
    border:1px solid black;
  }
@media (max-width: 768px) {
    .failure-main {
        padding: 1rem;
        border-width: 0;
        text-align: center;
    }
    .header-failure {
        flex-direction: column;
        justify-content: center;
        height: auto;
        margin-top: 5rem;
    }
    .payment-failure-text {
        height: auto;
        margin-top: 1rem;
        width: 90%;
    }
    .payment-failure-text  h1{
        margin-left: 2rem;
    }
    .title-failure{
        width:86%; 
        margin-left:3rem;
       }
    .btn-home-failure {
        width: 8rem;
        height: 2.5rem;
        border-radius: 1rem;
        margin-left: 3rem;
    }
    .img-qr-failure {
        flex-direction: row;
        width: 90%;
        left: 0;
        margin: 1rem auto;
    }
    .letter {
        width: 100%;
        left: 0;
        margin-top: 2rem;
        top: 0;
        box-shadow: 0px 5px 10.47px 0px #0000000D;
    }
    .title-qr-failure {
        font-size: 1rem;
        text-align: start;
        margin-left: 1rem;
    }
    .failure-img{
        height:18rem;
    }
}
