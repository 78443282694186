.success-main {
    border: 1px solid white;
    border-bottom: none;
    text-align: center;
    padding: 0;
    box-sizing: border-box; /* Ensure padding and borders are included in element's total width and height */
    padding-bottom: 40px;
}

.header-success {
    display: flex;
    justify-content: center;
    height: auto; /* Change from fixed height to auto */
    margin-top: 10rem;
}

.qr {
    height: 9rem;
    margin-top: 3rem;
    max-width: 100%; /* Prevents the image from overflowing */
}

.payment-success-text {
    height: auto; /* Change from fixed height to auto */
    margin-top: 2rem;
    width: 60%;
    max-width: 100%; /* Prevents the element from overflowing */
    box-sizing: border-box;
}

.btn-home {
    margin-top: 0.5rem;
    background: #FF4848;
    color: white;
    width: auto; /* Allow width to adjust with zoom */
    padding: 0.5rem 1rem; /* Use padding instead of fixed width and height */
    border-radius: 1.3rem;
    border: none;
}

.img-qr {
    display: flex;
    justify-content: space-around;
    position: relative;
    width: 100%;
    left: 2%;
}

.letter {
    width: 84%;
    position: relative;
    left: 10%;
    box-shadow: 0px 10px 20.47px 0px #0000000D;
    padding: 1rem;
    top: 3%;
    margin-top: 1rem;
    overflow: auto;
    box-sizing: border-box;
    
}

.apple-store {
    height: auto; /* Allow height to adjust with zoom */
    max-height: 10rem;
    width: auto; /* Allow width to adjust with zoom */
}

.title-qr {
    text-align: start;
    font-size: 1.2rem;
}

@media (max-width: 768px) {
    .header-success {
        height: auto; /* Change from fixed height to auto */
        margin-top: 5rem;
        flex-direction: column;
        align-items: center;
    }

    .payment-success-text {
        width: 90%;
        height: auto; /* Change from fixed height to auto */
        margin-top: 1rem;
    }

    .btn-home {
        width: auto; /* Allow width to adjust with zoom */
        padding: 0.5rem 1rem; /* Use padding instead of fixed width and height */
        border-radius: 1rem;
    }

    .img-qr {
        width: 90%;
        left: 0;
        justify-content: space-between;
        margin-bottom: 1rem;
    }

    .letter {
        width: 90%;
        left: 6%;
        top: 0;
        box-shadow: 0px 5px 10px 0px #0000000D;
        padding: 0.5rem;
        margin-top: 1.4rem;
    }

    .apple-store {
        height: auto; /* Allow height to adjust with zoom */
        max-height: 8rem;
        width: auto; /* Allow width to adjust with zoom */
        margin-top: 2rem;
    }

    .title-qr {
        font-size: 1rem;
    }
}
