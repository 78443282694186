// Line Awesome
@import url('../icons/font-awesome/css/all.min.css');
@import url('../icons/flaticon/flaticon_bodyshape.css');

@font-face {
	font-family: "Kenyan Coffee";
	src: 
		
		url("../fonts/kenyan_coffee/kenyan_coffee_rg.ttf") format("truetype"),
		url("../fonts/kenyan_coffee/kenyan_coffee_rg_it.ttf") format("truetype");
}
@font-face {
	font-family: "Kenyan Coffee";
	src: 
		
		url("../fonts/kenyan_coffee/kenyan_coffee_bd.ttf") format("truetype"),
		url("../fonts/kenyan_coffee/kenyan_coffee_bd_it.ttf") format("truetype");
		font-weight:bold;
}