.dz_error {
	font-size: 180px;
	font-weight: 700;
	line-height: 150px;
	margin: auto;
	color: var(--primary);
	font-family: var(--font-family-title);
	@include respond ('tab-port'){
		font-size: 130px;
		line-height: 1;
	}
	@include respond ('phone-land'){
		font-size: 90px;
	}
	@include respond ('phone'){
		font-size: 75px;
	}
}


.dz-media{
	&.post-video{
		iframe{
			height: 420px;
			
			@include respond ('phone-land'){
				height: 250px;
			}
		}
	}
}
.swiper-gallery-wrapper{
	.gallery-thumb-swiper{
		margin-top: 5px;
	}
	.thumb-item{
		position: relative;
		overflow: hidden;
		cursor: pointer;
		
		&:after{
			content: "";
			position: absolute;
			border: 2px solid #fff;
			top: 10px;
			left: 10px;
			bottom: 10px;
			right: 10px;
			@include transitionMedium;
			opacity: 0;
		}
		
		@include respond ('tab-land'){
			&:after{
				top: 5px;
				left: 5px;
				bottom: 5px;
				right: 5px;
			}
		}
	}
	.swiper-slide-thumb-active{
		.thumb-item{
			&:after{
				opacity: 1;
			}
		}
	}
}

/* .service-section-style-2{
	margin-bottom: -8px;
	.trainer{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
	}
	.icon-bx-wraper{
		&.style-4{
			margin-bottom: 30px;
			margin-top: 0;
			max-width: 300px;
			@include respond('tab-port'){
				max-width: 100%;
				width: 100%;
			}
			.icon-bx{
				display: flex;
				align-items: center;
				column-gap: 5px;
				@include respond('phone-land'){
					flex-direction: column;
					align-items: flex-start;
					row-gap: 10px;
					margin-bottom: 5px;	
				}
			}
			@include respond('phone-land'){
				.icon-content{
					p{
						font-size: 14px;
						}
				}
			}
		}
	}
} */

.service-wrapper1{
	padding-bottom: 50px;
	
	.trainer{
		margin-bottom: -90px;
		margin-left: -30px;
		margin-right: -60px;
	}
	.right-grid{
		padding-left: 50px;
	}
	.left-grid{
		padding-right: 50px;
	}
	.section-head {
		min-width: 650px;
	}
	@media only screen and (max-width: 1191px){
		.left-grid{
			padding-right: 15px;
		}
	}
	@include respond ('phone-land'){
		.section-head {
			min-width: 100%;
		}
		.right-grid{
			padding-left: 15px;
		}
	}
	@include respond ('phone'){
		.trainer{
			margin-left: 0;
			margin-right: 0;
		}
	}
}