.dz-social-icon{
	ul{
		li{
			display:inline-block;
			padding-left: 5px;
			padding-right: 5px;
			a{
				font-size: 18px;
				color:#fff;
				@include transitionMedium;
				&:hover{
					color:var(--primary);
				}
			}
			&:first-child {
				padding-left: 0;
				margin-left: 0;
			}
		}
	}
	&.dark {
		ul{
			li a {
				color:$dark;
			}
		}
	}
	&.style-1{	
		ul{
			li{
				display:inline-block;
				a{
					
					display:inline-block;
					height: 40px;
					width: 40px;
					min-width: 40px;
					line-height: 40px;
					font-size: 16px;
					text-align: center;
					background-color:var(--primary);
					color: var(--theme-text-color);
					@include transitionMedium;
					
					&:hover{
						background-color: #232323;
						color: #fff;
					}
					@include respond ('phone-land'){
						height: 32px;
						width: 32px;
						min-width: 32px;
						line-height: 32px;
						font-size: 14px;					}
				}
			}
		}
		&.dark {
			ul li a:hover {
				background-color: #fff;
				color: #000;
			}
		}
	}
}