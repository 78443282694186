.nav-tabs {
	&.style-1 {
		border: 0;
		background: var(--gradient);
		position: relative;
		padding: 5px 7px;
		margin-left: 20px;
		display: inline-flex;
	    transform: skewX(-10deg);
	    box-shadow: 0px 10px 15px 0px rgba(255, 129, 57, 0.15);
		&:after {
			content: '';
			position: absolute;
			height: 100%;
			width: 8px;
			left: -18px;
			top: 0;
			background: var(--primary-dark);
		}

		.nav-link {
			margin-bottom: 0;
			font-size: 16px;
			font-weight: 400;
			border-radius: 0;
			line-height: 25px;
			padding: 10px 25px;
			border:none;
			color: var(--theme-text-color);
		    isolation: unset;
		    
			span {
		    	transform: skewX(10deg);
		    	display: block;
		    }
			&.active{
				color: #000;
			}
		}
		
		@include respond('tab-land'){
			.nav-link {
				line-height: 1.1;
				padding: 10px 19px;
			}
		}
		@include respond('tab-port'){
			margin-left: 17px;
			&:after {
				left: -15px;
			}
			.nav-link {
				font-size: 14px;
				padding: 10px 16px;
			}
		}
		&.dark {
			background: var(--secondary);
			box-shadow: none;
			
			.nav-link {
				color: #fff;
				
				&.active{
					color: #000;
				}
			}
			&:after {
				background: var(--secondary);
			}
		}
	}
}