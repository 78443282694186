.dz-team{
	// Style 1
	&.style-1{
		position:relative;
		margin-bottom: 100px;
		
		@include respond('phone-land'){
			margin-bottom: 60px;
		}
		
		.dz-media{
			border-right:5px solid var(--rgba-primary-1);
			@include transitionMedium;
			img{
				padding-bottom: 20px;
				height: 100%;
				object-fit: cover;
			}
			.overlay-content{
				position:absolute;
			}
		}
		.team-social{
			position: absolute;
			right: 0;
			top:5px;
			z-index: 3;
			
			li{
				opacity:0;
				height: 45px;
    			width: 45px;
			    line-height: 45px;
				margin-bottom: 5px;
				text-align:center;
				position: relative;
				transform: translateX(50px) skewY(-12deg);
				background-color: var(--primary-dark);
				
				a{
    				font-size: 16px;
    				width: 100%;
    				height: 100%;
					transform: skewY(12deg);
					display:inline-block;
					color:#fff;
				}
				
				@for $i from 1 to 4{
					&:nth-child(#{$i}){
						transition: $i * 0.2s;
					}
				}
			}
		}
		.dz-position{
			margin-bottom:0;
		    font-size: 21px;
			font-weight: 500;
			padding: 12px 0;
			padding-right: 25px;
		    text-align: end;
    		width: 75%;
			position: absolute;
		    bottom: -60px;
		    right: 0px;
		    text-transform: uppercase;
		    z-index: 2;
		    @include transitionMedium;
		    color: white !important;
		    background-color: var(--primary);
		    @include custommq($max: 1199px) {
				font-size: 16px;
				padding: 8px 25px;
				bottom: -50px;
			}
			@include respond('phone-land'){
    			padding-right: 18px;
    			padding-left: 0;
			}
			@include respond('phone'){
			    width: 60%
			}
		}
		.dz-name{
		    text-transform: uppercase;
		    background-color: #f5f5f5;
			margin-bottom:0px;
			padding: 25px 25px 25px 0;
			text-align: right;
			width: 95%;
		    font-size: 40px;
		    font-weight: 700;
	        position: absolute;
		    bottom: -15px;
		    right: 0px;
		    z-index: 1;
		    color: var(--primary);
		    a {
			    color: var(--primary);
		    }
		    &:after {
				content:'';
			    top: 0;
			    left: 0px;
			    height: 100%;
			    width: 100%;
			    z-index: -1;
			    opacity: 0;
			    position: absolute;
			    @include transitionMedium;
			    background: var(--gradient-2);
			}
			@include custommq($max: 1199px) {
				font-size: 30px;
				padding: 17px 25px;
				padding-left: 0;
				font-weight: 600;
			}	
			@include respond('laptop'){
				font-size: 25px;
				width: 100%;
			}
			@include respond('phone-land'){
    			padding-right: 18px;
    			padding-left: 0;

			}
			@include respond('phone'){
    			width: 85%;
			}
		}
		.dz-content{
			position: absolute;
		    width: 100%;
	        transform: skewY(-17deg);
		}
		&:hover,
		&.active{

			.team-social{
				li{
					transform: translateY(0px) skewY(-12deg);
					opacity:1;
				}
			}
			.dz-media{
				border-color:var(--primary-dark);
			}
			.dz-content{
				.dz-name {
					color: var(--theme-text-color);
					
					a{
						color: var(--theme-text-color);
					}
					&:after{
						opacity:1;
						box-shadow: 0px 20px 50px 0px rgba(255, 63, 19, 0.3);
					}
				}
				.dz-position{
					background-color: var(--secondary);
					color: var(--primary) !important;
				}
			}
		}
	}

	// Style 2
	&.style-2{
		position:relative;
		margin-bottom: 100px;
		.dz-media{
			border-right:5px solid var(--primary);
			@include transitionMedium;
			img{
				padding-bottom: 20px;
				height: 100%;
				object-fit: cover;
				@include custommq ($max:1199px){
	    			padding-bottom: 10px;
				}
				@include respond('tab-port'){
				    padding-bottom: 0px;
				}
			}
			.overlay-content{
				position:absolute;
			}
		}
		.team-social{
			position: absolute;
			right: 0;
			top:5px;
			z-index: 3;
			li{
				position: relative;
				opacity:0;
				margin-bottom: 5px;
				background-color: var(--primary);
				height: 45px;
			    line-height: 45px;
    			width: 45px;
				text-align:center;
				transform: translateX(50px) skewY(-12deg);
				@for $i from 1 to 4 {
				   &:nth-child(#{$i}) { transition: $i * 0.2s; }
				}
				a{
    				font-size: 16px;
    				width: 100%;
    				height: 100%;
					transform: skewY(12deg);
					display:inline-block;
					color:var(--theme-text-color);
				}
			}
		}
		.dz-position{
			margin-bottom:0;
		    font-size: 21px;
			font-weight: 500;
			padding: 12px 0;
			padding-right: 25px;
		    text-align: end;
    		width: 75%;
			position: absolute;
		    bottom: -60px;
		    right: 0px;
		    text-transform: uppercase;
		    z-index: 2;
		    @include transitionMedium;
		    color: white !important;
		    background-color: var(--secondary);
		    @include custommq($max: 1199px) {
				font-size: 16px;
				padding: 8px 25px;
				bottom: -50px;
			}
			@include respond('phone-land'){
    			padding-right: 18px;
    			padding-left: 0;
			}
		}
		.dz-name{
		    text-transform: uppercase;
		    background-color: var(--primary);
			margin-bottom:0px;
			padding: 25px 25px 25px 0;
			text-align: right;
			width: 100%;
		    font-size: 40px;
		    font-weight: 700;
	        position: absolute;
	        letter-spacing: 2px;
		    bottom: -15px;
		    right: 0px;
		    z-index: 1;
		    color: var(--theme-text-color);
			@include respond('laptop'){
				font-size: 32px;
			}
			@include custommq($max: 1199px) {
				font-size: 28px;
				padding: 17px 25px;
				padding-left: 0;
				font-weight: 600;
			}
			@include respond('phone-land'){
    			padding-right: 18px;
    			padding-left: 0;
			}
		}
		.dz-content{
			position: absolute;
		    width: 100%;
	        transform: skewY(-17deg);
		}
		&:hover,
		&.active{
			.team-social{
				li{
					transform: translateY(0px) skewY(-12deg);
					opacity:1;
				}
			}
		}
	}
}
.team-slider {
	.dz-team{
		margin-bottom: 90px;
		@include respond('phone-land'){
			margin-bottom: 70px;
		}
		@include respond('phone'){
			margin-bottom: 80px;
			margin-left: 15px;
			margin-right: 15px;
		}
	}
}

.team-slider-wrapper {
	.btn-next,
	.btn-prev {
		position: absolute;
	    top: 50%;
	    left: 8%;
	    color: var(--theme-text-color);
	}
	.btn-next {
	    right: 8%;
	    left: auto;
	}
	.num-pagination{
		margin-top: 50px;
		@include respond('phone-land'){
			margin-top: 30px;
		}
		@include respond('phone'){
			margin-top: 20px;
		}
	}
	&:hover{
		.btn-prev {
		    left: 12%;
		}
		.btn-next {
		    right: 12%;
		}
	}
	@include respond('wide-desktop'){
		.btn-prev {
		    left: 50px;
		}
		.btn-next {
		    right: 50px;
		}
		&:hover {
			.btn-prev {
			    left: 100px;
			}
			.btn-next {
			    right: 100px;
			}
		}
	}
	@include respond('laptop'){
		.btn-prev {
		    left: 50px;
		}
		.btn-next {
		    right: 50px;
		}
		&:hover {
			.btn-prev {
			    left: 50px;
			}
			.btn-next {
			    right: 50px;
			}
		}
	}
}