@font-face {
    font-family: "flaticon_bodyshape";
    src: url("./flaticon_bodyshape.ttf?0495e7765ec6be94b0de6e9bd01ca11a") format("truetype"),
url("./flaticon_bodyshape.woff?0495e7765ec6be94b0de6e9bd01ca11a") format("woff"),
url("./flaticon_bodyshape.woff2?0495e7765ec6be94b0de6e9bd01ca11a") format("woff2"),
url("./flaticon_bodyshape.eot?0495e7765ec6be94b0de6e9bd01ca11a#iefix") format("embedded-opentype"),
url("./flaticon_bodyshape.svg?0495e7765ec6be94b0de6e9bd01ca11a#flaticon_bodyshape") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_bodyshape !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-play:before {
    content: "\f101";
}
.flaticon-athletics:before {
    content: "\f102";
}
.flaticon-exercise:before {
    content: "\f103";
}
.flaticon-man:before {
    content: "\f104";
}
.flaticon-exercise-1:before {
    content: "\f105";
}
.flaticon-kettlebell:before {
    content: "\f106";
}
.flaticon-lifting:before {
    content: "\f107";
}
.flaticon-gym:before {
    content: "\f108";
}
.flaticon-exercise-2:before {
    content: "\f109";
}
.flaticon-fitness:before {
    content: "\f10a";
}
.flaticon-calendar:before {
    content: "\f10b";
}
.flaticon-medal:before {
    content: "\f10c";
}
.flaticon-user:before {
    content: "\f10d";
}
.flaticon-left-quotes-sign:before {
    content: "\f10e";
}
.flaticon-phone-call:before {
    content: "\f10f";
}
.flaticon-home:before {
    content: "\f110";
}
.flaticon-page:before {
    content: "\f111";
}
.flaticon-text-document:before {
    content: "\f112";
}
.flaticon-heart:before {
    content: "\f113";
}
.flaticon-headphones:before {
    content: "\f114";
}
.flaticon-blogging:before {
    content: "\f115";
}
.flaticon-blog:before {
    content: "\f116";
}
.flaticon-contact-center:before {
    content: "\f117";
}
.flaticon-email:before {
    content: "\f118";
}
.flaticon-email-1:before {
    content: "\f119";
}
.flaticon-envelope:before {
    content: "\f11a";
}
.flaticon-dumbbell:before {
    content: "\f11b";
}
.flaticon-kettlebell-1:before {
    content: "\f11c";
}
.flaticon-gym-1:before {
    content: "\f11d";
}
.flaticon-gym-2:before {
    content: "\f11e";
}
.flaticon-stationary-bike:before {
    content: "\f11f";
}
.flaticon-exercise-3:before {
    content: "\f120";
}
.flaticon-gym-3:before {
    content: "\f121";
}
.flaticon-skipping-rope:before {
    content: "\f122";
}
.flaticon-dumbbells:before {
    content: "\f123";
}
.flaticon-supplement-bottle:before {
    content: "\f124";
}
.flaticon-kettlebell-2:before {
    content: "\f125";
}
.flaticon-gym-machine:before {
    content: "\f126";
}
.flaticon-gym-equipment:before {
    content: "\f127";
}
.flaticon-settings:before {
    content: "\f128";
}
.flaticon-menu:before {
    content: "\f129";
}
.flaticon-menu-1:before {
    content: "\f12a";
}
.flaticon-loupe:before {
    content: "\f12b";
}
.flaticon-notification-bell:before {
    content: "\f12c";
}
.flaticon-settings-1:before {
    content: "\f12d";
}
.flaticon-star:before {
    content: "\f12e";
}
.flaticon-close:before {
    content: "\f12f";
}
.flaticon-share:before {
    content: "\f130";
}
.flaticon-down-arrow:before {
    content: "\f131";
}
.flaticon-verify:before {
    content: "\f132";
}
.flaticon-check-mark:before {
    content: "\f133";
}
