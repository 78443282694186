.sel-dropdown {
  position: relative;
  width: 100%;
}

.sel-dropdown:focus {
  outline: none;
}

.sel-dropdown.big {
  position: relative;
  width: 31rem;
  font-size: 2rem;
  height: 1.6rem;
  letter-spacing: 0.3px;
  font-weight: 400;
  margin-top: 3.4rem;
}

.sel-dropdown.big.no-top-margin {
  margin-top: 0.3rem;
}

.sel-dropdown-header {
  padding: 0rem 1rem;
  cursor: pointer;
  display: flex;
  /* font-weight: 600; */
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
  border-radius: 6px;
  font-size: 16px;
  background-size: "12px";
  line-height: 3.2rem;
  background-position: "right 12px center";
  border-top: "none";
  border-left: "none";
  border-right: "none";
  /* background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 140 140'%3E%3Cpolyline points='10,50 70,110 130,50' fill='none' stroke='%23000' stroke-width='15' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat right center; */
}

.sel-dropdown-placeholder {
  flex-grow: 1;
}

.sel-dropdown-extra-icon {
  padding-right: 0.3rem;
}

.sel-dropdown-arrow {
  transition: transform 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sel-dropdown-arrow img {
  width: 2rem;
  height: 2rem;
}

.sel-dropdown-arrow.open {
  transform: rotate(-180deg);
}

.sel-dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: "white";
  border: none;
  max-height: 7.4rem;
  overflow-y: auto;
  z-index: 5;
  box-shadow: 0px 0px 2px 0px var(--drop-shadow-pop);
  border-radius: 6px;
}

.sel-dropdown-item {
  padding: 10px;
  cursor: pointer;
  background-color: "white" !important;
  /* margin: 0.5rem; */
  /* border-radius: 6px; */
  color: "black" !important;
}

.sel-dropdown-item:hover, .sel-dropdown-item.selected {
  background-color: "gray";
}

.sel-dropdown.disabled .sel-dropdown-header {
  cursor: not-allowed;
}

.sel-dropdown.disabled .sel-dropdown-item {
  cursor: not-allowed;
}

.sel-dropdown-placeholder.big {
  font-size: 2rem;
  line-height: 1.6rem;
  letter-spacing: 0.3px;
  font-weight: 400;
  padding: 1.7rem 1rem 1.7rem 1rem !important;
  height: 5rem;
}

@media only screen and (max-width: 768px) {
  .sel-dropdown-header > span:first-of-type {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 15ch;
    display: inline-block;
    letter-spacing: 1px;
  }
}
