// video-bx
.video-bx1{
	position: relative;
	min-width:170px;
	width:170px;
	height:170px;
	border-radius:var(--border-radius-base);
	overflow:hidden;
	img{
		height:100%;
		width:100%;
		object-fit:cover;
	}
	.video-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		.popup-youtube{
			display:inline-block;
			height:60px;
			width:60px;
			min-width:60px;
			line-height:60px;
			font-size: 20px;
			border-radius:60px;
			background-color:#fff;
			color:var(--primary);
			
			&:before,
			&:after{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				border: 1px solid #fff;
				z-index: -1;
				transform:  scale(1);
				-moz-transform:  scale(1);
				-webkit-transform:  scale(1);
				-ms-transform:  scale(1);
				-o-transform:  scale(1);
				-moz-transition:all 0.5;
				-o-transition:all 0.5;
				-webkit-transition:all 0.5;
				-ms-transition:all 0.5;
				transition:all 0.5;
			}
			&:before{
				-webkit-border-radius: 50%;
				-khtml-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
				border-radius: 100%;
				animation: animationSignal1;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal1;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
			}
			&:after{
				-webkit-border-radius: 100%;
				-khtml-border-radius: 100%;
				-moz-border-radius: 100%;
				-ms-border-radius: 100%;
				-o-border-radius: 100%;
				border-radius: 100%;
				animation: animationSignal2;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal2;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
			}
		}
	}
	@include respond ('phone'){
		height:120px;
		width:120px;
		min-width:120px;
		.video-btn {
			.popup-youtube{
				height:40px;
				width:40px;
				min-width:40px;
				line-height:40px;
				font-size:16px;
			}
		}
	}
}
.video-bx2{
	height:500px;
	&.large{
		height:615px;
		border-radius:var(--border-radius-base);
		overflow:hidden;
		img{
			width:100%;
			height:100%;
			object-fit:cover;
		}
	}
	.video-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		&.sm{
			.popup-youtube{
				height:72px;
				width:72px;
				min-width:72px;
				line-height:72px;
				font-size: 22px;
			}
		}
		.popup-youtube{
			display:inline-block;
			height:100px;
			width:100px;
			min-width:100px;
			line-height:100px;
			font-size: 30px;
			border-radius:100px;
			background-color:#fff;
			color:var(--primary);
			&:before{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				border: 1px solid #fff;
				-webkit-border-radius: 50%;
				-khtml-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
				border-radius: 100%;
				animation: animationSignal1;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal1;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
				z-index: -1;
				transform:  scale(1);
				-moz-transform:  scale(1);
				-webkit-transform:  scale(1);
				-ms-transform:  scale(1);
				-o-transform:  scale(1);
				-moz-transition:all 0.5;
				-o-transition:all 0.5;
				-webkit-transition:all 0.5;
				-ms-transition:all 0.5;
				transition:all 0.5;
			}
			&:after{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				border: 1px solid #fff;
				-webkit-border-radius: 100%;
				-khtml-border-radius: 100%;
				-moz-border-radius: 100%;
				-ms-border-radius: 100%;
				-o-border-radius: 100%;
				border-radius: 100%;
				animation: animationSignal2;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal2;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
				z-index: -1;
				transform:  scale(1);
				-moz-transform:  scale(1);
				-webkit-transform:  scale(1);
				-ms-transform:  scale(1);
				-o-transform:  scale(1);
				-moz-transition:all 0.5;
				-o-transition:all 0.5;
				-webkit-transition:all 0.5;
				-ms-transition:all 0.5;
				transition:all 0.5;
			}
		}
	}
	@include respond ('tab-port'){
		height:330px;
		&.large{
			height:380px;
		}
	}
	@include respond ('phone'){
		height:200px;
		&.large{
			height:250px;
		}
		.video-btn,
		.video-btn.sm{
			.popup-youtube{
				height: 60px;
				width: 60px;
				min-width: 60px;
				line-height: 60px;
				font-size: 20px;
			}
		}
	}
}
.video-bx3{
	display: inline-block;
	.video-btn {
		.popup-youtube{
			display:inline-block;
			height:60px;
			width:60px;
			line-height: 52px;
			text-align: center;
			font-size: 18px;
			border-radius:60px;
			min-width:60px;
			border:4px solid #ececec;
			color:var(--secondary);
			
			&:before,
			&:after{
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				display: block;
				border: 1px solid var(--secondary);
				z-index: -1;
				@include transitionMedium;
				transform:  scale(1);
				-moz-transform:  scale(1);
				-webkit-transform:  scale(1);
				-ms-transform:  scale(1);
				-o-transform:  scale(1);
			}
			&:before{
				-webkit-border-radius: 50%;
				-khtml-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
				border-radius: 100%;
				animation: animationSignal1;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal1;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
			}
			&:after{
				-webkit-border-radius: 100%;
				-khtml-border-radius: 100%;
				-moz-border-radius: 100%;
				-ms-border-radius: 100%;
				-o-border-radius: 100%;
				border-radius: 100%;
				animation: animationSignal2;
				animation-iteration-count: infinite;
				animation-duration: 3s;
				-webkit-animation: animationSignal2;
				-webkit-animation-iteration-count: infinite;
				-webkit-animation-duration: 3s;
			}
		}
	}
}
.video-bx4{
	display: inline-block;
	position: relative;
	i {
	    display: inline-block;
	    height: 55px;
	    width: 55px;
	    min-width: 55px;
	    border-radius: 55px;
	    line-height: 55px;
	    background: white;
	    text-align: center;
	    color: var(--primary);
	    font-size: 20px;
	    margin-right: 10px;
	    box-shadow: 0px 5px 30px 0px rgba(253, 114, 74, 0.1);
	}
	.text {
		color: #444444;
		font-weight: 500;
		font-size: 18px;
		padding-right: 20px;
	}
	&:before {
	    content: "";
	    top: 0;
	    left: 0;
	    position: absolute;
	    height: 55px;
	    width: 55px;
	    border-radius: 55px;
	    background: #fff;
	    z-index: -1;
	    @include transitionFast;
	    box-shadow: 1px 1.732px 55px 0px rgba(0, 0, 0, 0.1 );
	}
	&:hover:before {
	    width: 100%;
	}
	@include respond('tab-port'){
		i {
			height: 50px;
			width: 50px;
			min-width: 50px;
			line-height: 50px;
		}
		.text {
			font-size: 15px;
		}
		&:before {
			height: 50px;
			width: 50px;
		}
	}
	@include respond('phone'){
		i,	
		.popup-youtube {
			height: 45px;
			width: 45px;
			min-width: 45px;
			line-height: 45px;
			font-size: 16px;
			margin-right: 0;
		}
		.text {
			font-size: 16px;
			display: none;
		}
		&:before {
			height: 45px;
			width: 45px;
		}
		&:hover:before {
		    width: 45px;
		}
	}
}
.video-bx5{
	position: absolute;
	bottom: 20px;
	right: 30px;
	z-index: 10;
	width: 170px;

	@include respond('tab-port'){
		display: none;
	}
	img{
		width: 100%;
		border: 5px solid #fff;
		border-radius: 10px;
	}

	.video-btn{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		background: white;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		@include transitionMedium;
		
		&::before{
			content: '' ;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(255,255,255,0.6);
			width: 100%;
			height: 100%;
			border-radius: 50%;
			z-index: 0;
			animation: pulse-border 1500ms ease-out infinite;
		}
		i{
			position: relative;
			font-size: 1rem;
			z-index: 2;
			@include transitionMedium;
		}
		
		&:hover{
			background: var(--primary);
			i{
				color: #fff;
			}
		}
	}
	@include respond('phone-land'){
		width: 120px;
		.video-btn{
			width: 30px;
			height: 30px;
			font-size: 14px;
		}
	}
	@include respond('phone-land'){
		width: 100px;
		bottom: 10px;
		right: 10px;
		.video-btn{
			width: 30px;
			height: 30px;
			font-size: 14px;
		}
	}

}
.video-bx6{
	position: relative;

	.title{
		font-size: 70px;
		font-weight: 700;
		
	}
	@include respond('tab-port'){
		.title{
			font-size: 50px;
		}
	}
	@include respond('phone-land'){
		.title{
			font-size: 42px;
			text-align: center;
		}
		.btn{
			display: none;
		}	
	}
}
.video-btn{
	&.style-2{
		position: relative;
		transform: translate(0%,0%);
		background: white;
		width: 90px;
		height: 90px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		@include transitionMedium;
		
		&::before{
			content: '' ;
			position: absolute;
			top: 0;
			left: 0;
			background: rgba(255,255,255,0.6);
			width: 100%;
			height: 100%;
			border-radius: 50%;
			z-index: 0;
			animation: pulse-border 1500ms ease-out infinite;
		}
		i{
			position: relative;
			font-size: 2rem;
			z-index: 2;
			@include transitionMedium;
		}
		
		&:hover{
			background: var(--primary);
			i{
				color: #fff;
			}
		}
	}
}
.video-bx {
	&.style-1 {
		.video-btn {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			background-color: var(--primary);
			width: 70px;
			height: 70px;
			display: flex;
			justify-content: center;
			border-radius: 50%;
			align-items: center;
			
			&::before{
				content: '' ;
				position: absolute;
				top: 0;
				left: 0;
				background: rgba(255,255,255,0.6);
				width: 100%;
				// background: red;
				height: 100%;
				border-radius: 50%;
				z-index: 0;
				animation: pulse-border 1500ms ease-out infinite;
			}

			i {
				font-size: 25px;
			}
			@include respond('tab-land'){
				width: 60px;
				height: 60px;
				i {
					font-size: 20px;
				}
			}
		}
	}

}


  



@keyframes animationSignal1 {
  /*Video Popup*/
	0% {
		opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.5);
		-moz-transform:  scale(1.5);
		-ms-transform:  scale(1.5);
		-o-transform:  scale(1.5);
		-webkit-transform:  scale(1.5);
		opacity: 0;
	}
}

@keyframes pulse-border {
	0% {
	  transform: scale(1);
	  opacity: 1;
	}
	100% {
	  transform:  scale(1.5);
	  opacity: 0;
	}
  }
@-webkit-keyframes animationSignal1 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
		-webkit-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.5);
		-moz-transform:  scale(1.5);
		-ms-transform:  scale(1.5);
		-o-transform:  scale(1.5);
		-webkit-transform:  scale(1.5);
		opacity: 0;
	}
}
@keyframes animationSignal2 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.9);
		-moz-transform:  scale(1.9);
		-webkit-transform:  scale(1.9);
		-ms-transform:  scale(1.9);
		-o-transform:  scale(1.9);
		opacity: 0;
	}
}
@-webkit-keyframes animationSignal2 {
  /*Video Popup*/
	0% {
		//opacity: 0.8;
		transform: scale(0.9);
		-moz-transform: scale(0.9);
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		-o-transform: scale(0.9);
	}
	100% {
		transform:  scale(1.9);
		-moz-transform:  scale(1.9);
		-webkit-transform:  scale(1.9);
		-ms-transform:  scale(1.9);
		-o-transform:  scale(1.9);
		opacity: 0;
	}
}

.video-wrapper{
	display:flex;
	align-items:center;
}

