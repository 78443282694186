.map-iframe{
	iframe{
		height:450px;
		@include respond('tab-port'){
			height:370px;			
		}
		@include respond('phone'){
			height:320px;
		}
	}
}
.contact-box {
    z-index: 1;
	padding:50px 40px;
	position: relative;
	background: var(--secondary);
	@include respond('tab-port'){
		padding:40px 30px;
	}
	svg {
		position:absolute;
		bottom: -32px;
    	right: -22px;
		height: 70px;
		width: 250px;
		@include custommq ($max:1199px){
			bottom: -27px;
		    right: -20px;
		    height: 60px;
		    width: 200px;
		}
		@include respond('phone'){ 
			bottom: -32px;
			right: -15px;
			height: 68px;
			width: 170px;
		}
	}
	&:before,
	&:after {
		content: '';
	    position: absolute;
	    z-index: -1;
	    bottom: 0;
	    right: 0;
	    background-size: cover;
	    background-repeat: no-repeat;
	}
	&:after {
	    height: 85%;
	    width: 60%;
	    background-image: url(../images/background/bg-contact-2.png);
	}
	&:before {
	    height: 30%;
	    width: 25%;
	    background-image: url(../images/background/bg-contact-1.png);
	}
	.contact-title {
		font-size: 30px;
		color: #fff;
		@include custommq ($max:1199px){
			font-size: 25px;
		}
		@include respond('tab-port'){
			font-size: 22px;
		}
	}
	.contact-text {
		color: #FFFFFF80;
		@include respond('tab-port'){
			font-size: 14px;
		}
	}
	.widget ul {
		margin-top: 35px;
		margin-bottom: 120px;
		
		@include custommq ($max:1199px){
			margin-top: 30px;
			margin-bottom: 100px;
		}
		@include respond('phone'){
			margin-bottom: 50px;
		}
	}
	.icon-content p{
		font-size: 16px;
		opacity: 0.8;
		margin-bottom: 0;
		
		@include respond('phone'){
			font-size: 14px;
		}
	}
	
	.icon-xl
	.icon-lg
	// .icon-md
	.icon-sm, .icon-bx-wraper.left .icon-xs {
		line-height: 1;
		float: left;
		margin-right: 15px;
	}
}
.contact-section{
	&.style-2{
		.form-control{
			&::placeholder{
				color: #fff!important;
			}
		}
	}
}