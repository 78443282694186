.getapp-main {
  border: 1px solid white;
  height: 48rem;
  background-image: url(../assets/images/main-slider//slider1//bg1.jpg);
  background-position: cover;
}
.store-img {
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}
.store-img img {
  width: 160px; /* Adjust the size as needed */
  height: 48px; /* Ensure the same height */
  object-fit: contain; /* Keep the aspect ratio and fit within the box */
  margin: 5px; /* Optional: Add some spacing between images */
}

.app-store-img {
  height: 70px;
}
.second-section-img {
  margin-top: 12rem;
  gap: 70px;
  display: flex;
  justify-content: center;
}
.first-img {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.first-img label {
  font-size: 1.8rem;
  position: relative;
  top: 5%;
}
.mobile-image {
  height: 30rem;
  /* width: 26rem; */
}

.added-shadow {
  width: 230px;
  height: 230px;
  margin-top: 40px;
  box-shadow: 0 1px 3px 0;
  border-radius: 40px;
  background-color: white;
}

.qr-image {
  margin-left: 25.5px;
  width: 11rem;
  height: 11rem;
  margin-top: 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.app-imgs{
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
}
.app-imgs img{
  height: 3rem;
  width: 150px;
  object-fit: cover;
  border-radius: .5rem;
}

@media only screen and (max-width: 768px) {
  .app-imgs{
    width: 100dvw;
    justify-content: center;
    gap: 1.3rem;
    margin-top: 1.5rem;
    margin-bottom: 5rem;
  }
  .getapp-main {
    height: auto; /* Let the height adjust automatically */
    padding: 0 2rem; /* Add some padding for content spacing */
    background-size: cover; /* Ensure the background covers the entire container */
    margin-bottom: -5rem;
  }

  .store-img {
    margin-top: 1rem; /* Reduce the top margin for smaller screens */
    margin-bottom: 2rem; /* Reduce the bottom margin */
    flex-direction: row; /* Stack the images vertically */
  }

  .store-img img {
    width: 135px; /* Smaller image size for mobile */
    height: 100px;
  }

  .second-section-img {
    flex-direction: column; /* Stack the sections vertically */
    align-items: center; /* Center align the items */
    margin-top: 2rem; /* Add margin to space the sections */
  }

  .first-img {
    margin-top: 10rem;
    align-items: center; /* Center align the label and images */
    margin-bottom: 2rem; /* Add margin for spacing */
  }

  .first-img label {
    font-size: 1.5rem; /* Smaller font size for mobile */
    top: 0; /* Reset the top position */
    margin-bottom: 1rem; /* Add some margin below the label */
  }

  .mobile-image {
    display: none;
    height: 25rem; /* Adjust the image size for mobile */
    width: 18rem;
  }

  .qr-image {
    margin-left: 28px;
    margin-top: 2px;
    width: 11rem;
    height: 11rem; /* Adjust the QR image size for mobile */
    margin-top: 1.5rem; /* Reduce the top margin */
    box-shadow: none;
  }

  .play-store{
    position: relative;
    bottom: 24px;
    left: 25px;
  }
  
  .added-shadow {
    margin-top: 15px;
  }
}
