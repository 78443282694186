.faq-container {
    display: flex;
    justify-content: left;
    /* max-width: 800px; */
    /* margin: 0 0 0 0rem; */
    /* padding: 20px; */
}

.faq-list {
    margin-top: 20px;
    margin-left: -25px;
}


.faq-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
}

.faq-question {
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    padding: 10px 0;
    margin-left: 2rem;
}

.faq-question h3 {
    margin: 0 0 0 1rem;
    font-size: 16px;
}

.faq-answer {
    display: flex;
        justify-content: left;
        margin-left: 4rem;
    padding: 10px 0;
    color: #555;
}

.toggle-icon {
    font-size: 1.5rem;
    color: #ff4848;
}