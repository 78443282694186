.custom-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.slides-container {
    width: 100%;
    overflow: hidden;
}

.slides {
    display: flex;
    width: 100%;
}

.slides.transitioning {
    transition: transform 0.5s ease-in-out; 
}

.slides.last-to-first {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out; 
    opacity: 0.5; /* Fade out effect during transition from last to first */
}

.slides>* {
    flex: 0 0 100%;
}

.slider-button {
    position: absolute;
    height: 3rem;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    color: #ff4848;
    border: none;
    padding: 10px;
    font-size: 8rem;
    cursor: pointer;
    z-index: 10;
}

.prev {
    left: 0px;
}

.next {
    right: 0px;
}
@media only screen and (min-width: 340px)and (max-width: 1000px){
.slider-button{
    /* display: none; */
    margin-bottom: 1rem;
}
.arrow-mobile{
    margin-top: -15rem;

}

}
