/* General Styles */
.video-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.video-bx4-slider {
  width: 100%;
  height: auto;
}

.video-thumbnail {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}
/* Play Button Styles */
.play-button {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(133deg, hsla(0, 0%, 100%, .27) 12.14%, hsla(0, 0%, 100%, .09) 86.99%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.play-button::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 20px solid #fff;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  position: relative;
  left: 5px;
}

/* Stop Button Styles */
.stop-button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(133deg, hsla(0, 0%, 100%, .27) 12.14%, hsla(0, 0%, 100%, .09) 86.99%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease;
}

.stop-button::after {
  content: "";
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 5px;
}

/* Video Overlay Styles */
.video-overlay {
  width: 100%;
  bottom: 0;
  background: hsla(0, 0%, 100%, .1);
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  border-radius: 40px;
  padding: 20px;
  margin-bottom: -100%;
  transition: margin .5s, opacity .5s, visibility .5s;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  position: absolute;
  left: 0;
}

/* Card Hover Effects */
.video-container:hover .play-button {
  visibility: visible;
  opacity: 1;
}

.video-container:hover .stop-button {
  visibility: visible;
  opacity: 1;
}

.video-container:hover .video-overlay {
  margin-bottom: 0;
  visibility: visible;
  opacity: 1;
}

/* Title and Price Styles */
.video-overlay .title {
  font-size: 24px;
  margin: 0;
  color: #fff;
}

.video-overlay h5 {
  font-size: 18px;
  margin: 0;
  color: #fff;
}

/* Card Style */
.dz-box {
  position: relative;
}
