.list-circle,
.list-angle-double,
.list-square,
.list-square-check{
	li{
		position: relative;
		padding: 6px 5px 6px 15px;
		&:before{
			position: absolute;
			left: 0;
			top: 4px;
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
		}
	}
	&.primary{
		li{
			&:before{
				color: var(--primary);
			}
		}
	}
}
.list-angle-double{
	li{
		padding-left: 25px;
	}
}
ul{
	&.list-circle{
		li{
			&:before{
				content: "\f111";
				font-size: 8px;
				font-weight: 900;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	&.list-angle-double{				
		li{
			&:before{
				content: "\f101";
				font-size: 18px;
			}
		}
	}
	&.list-square{
		li{
			&:before{
				content: "\f45c";
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
	&.grid-2{
		display: flex;
		flex-wrap: wrap;
		li{
			width: 50%;
		}
		@include respond('phone'){
			li{
				width: 100%;
			}
		}
	}
}
.list-square-check{
	li{
		padding: 10px 15px 10px 60px;
		min-height: 45px;
		font-weight: 600;
		color: var(--title);    
		margin-bottom: 15px;
	
		&:before{
			content: "\f00c";
			top: 50%;
			height: 45px;
			width: 45px;
			line-height: 45px;
			background-color: var(--rgba-primary-1);
			color: #000;
			text-align: center;
			padding: 0 0 0 0;
			@include transitionSlow;
			transform: translateY(-50%);
		}
		&:hover{
			&:before{
				background-color: var(--primary);
				color: #fff;
			}
		}
	}
}
.list-check-1{
	li {
		padding: 12px 0px 12px 40px;
		color:#071c35;
		font-weight:500;
		position:relative;
		&:before{
			position: absolute;
			left: 0;
			top: 50%;
			border-radius:35px;
			transform: translateY(-50%) scale(1);
			content: "\f00c";
			font-family: 'Line Awesome Free';
			font-weight: 700;
			height:28px;
			width:28px;
			line-height:28px;
			background-color:var(--primary);
			color:#fff;
			text-align:center;
			font-size:16px;
			@include transitionMedium;
			@include respond ('phone'){
				height:25px;
				width:25px;
				line-height:25px;
				font-size:14px;
			}
		}
		@include respond ('tab-port'){
			padding: 10px 0px 10px 38px;
		}
	}
	&.top{
		li{
			&:before{
				top:40px;
			}
			p{
				font-family:var(--font-family-title);
				color:$body-color;
				margin-bottom:0;
			}
		}
	}
	&.light{
		li{
			&:before{
				background-color:var(--rgba-primary-1);
				color:var(--primary);
			}
			&:hover{
				&:before{
					background-color:var(--primary);
					color:#fff;
				}
			}
		}
	}
}

.list-check-2{
	li{
		padding: 0 0 0 32px;
		position: relative;
		margin-bottom: 10px;
		
		&:before{
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 35px;
			content: "\f00c";
			font-family: "Font Awesome 6 Free";
			font-weight: 900;
			color: var(--primary);
			font-size: 18px;
			@include transitionMedium;
		}
	}
}