.dz-box.style-2{
	.dz-media{
		margin-bottom: 20px;
		
		img {
	   		@include transitionMedium;
		}
	}
	.dz-info{
		position:relative;
		bottom:13%;
		left:0;
		z-index:1;
		@include transitionMedium;
		.title,
		.title a{
			margin: 0;
			padding-bottom: 20px;
			
			&:after {
				content: '';
			    position: absolute;
			    height: 4px;
			    width: 70px;
			    bottom: 0px;
			    left: 0px;
	    		@include transitionMedium;
			    background: var(--gradient);
			}
			@include custommq($max: 1199px){
				padding-bottom: 15px;
			}
		}
	}
	&:hover {
		.dz-media {				
			img {
				transform: scale(1.15) rotate(3deg);
			}
		}
		.dz-info{
			.title,
			.title a{
				&:after {
				    width: 110px;
				}
			}
		}
	}
}