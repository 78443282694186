.btn-next, 
.btn-prev{
	position: static;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 0px;
    color: var(--theme-text-color);
    background: var(--primary);
    font-size: 15px;
    z-index: 1;
    text-align: center;
    margin: 0 10px;
	display: inline-block;
	transform: skewX(-12deg);
	box-shadow: 0px 10px 30px 0px var(--rgba-primary-3);
	@include transitionMedium;
	
	&:hover{
		background:#000;
		color:#fff;
		box-shadow: 0px 10px 30px 0px rgba(34, 34, 34, 0.2);
	}
	&:after{
		content:none;	
	}
	&.dark {
		&:hover{
			background:#fff;
			color:#000;
			box-shadow: 0px 10px 30px 0px rgba(34, 34, 34, 0.2);
		}
	}
	i{
		transform: skewX(12deg);
		font-size: 20px;
		line-height: inherit;
	}
	@include custommq($max: 1199px) {
		width: 35px;
		height: 35px;
		line-height: 35px;
		i{
			font-size: 16px;
		}
	}
	@include respond('phone'){
		width: 30px;
		height: 30px;
		line-height: 30px;
		i{
			font-size: 15px;
		}
	}
}
.num-pagination{
	display: flex;
    align-items: center;
	
	.swiper-pagination {
		width: auto;
	}
}
.swiper-pagination {
	position: static;
	width: auto;
	display: flex;
	align-items: center;
	
	&.style-1 {
		.swiper-pagination-bullet{
			height:auto;
			opacity: 1;
			width:auto;	
			@include transitionMedium;
			border-radius:0%;
			margin:0 15px;
			background-color:transparent;
			&.swiper-pagination-bullet-active{
				color:var(--primary);
				transform: scale(1.7);
				font-weight: 600;
			}

		}
		&.dark {
			color: #fff;
			.swiper-pagination-bullet{
				color: #fff;
				&.swiper-pagination-bullet-active{
					color:var(--primary);
				}
			}
		}
		@include custommq($max: 1199px) {
			.swiper-pagination-bullet{
				margin:0 10px;
				&.swiper-pagination-bullet-active{
					transform: scale(1.5);
				}
			}
		}
		&.m-lr-lg {
			margin: 0 70px;
			@include custommq($max: 1199px) {
				margin: 0 50px;
			}
			@include respond('phone'){
				margin: 0 20px;
			}
		}
	}
}
.progress-pagination{
	color: #fff;
    display: flex;
    align-items: center;
	
	.swiper-pagination-progressbar{
		height: 3px;
		width: 100px;
		overflow: hidden;
		position: unset;
		margin: 0 10px;
		
		.swiper-pagination-progressbar-fill{
			background-color: #fff;
		}
	}
}


.service-gallery-swiper,
.gallery-swiper{
	.btn-prev,
	.btn-next{
		position: absolute;
		top: 50%;
		transform: translateY(-50%) skewX(-12deg);
	}
	.btn-prev{
		left: 0;		
	}
	.btn-next{
		right: 0;
	}
}
.swiper-btn-lr{
	.btn-prev,
	.btn-next{
		position: absolute;
		top: 50%;
		transform: skewX(-12deg) translateY(-50%);
		
		@include respond('phone'){
			top: 40%;
		}
	}
	.btn-next{
		right: -60px;
		@include respond('tab-land'){
			right: -10px;
		}
	}
	.btn-prev{
		left: -80px;	
		@include respond('tab-land'){
			left: -10px;
		}
	}
}
.swiper-fade{
	overflow: unset;
	
	.swiper-slide{
		opacity: 0;
		visibility: hidden;
		@include transitionSlow;
		
		&.swiper-slide-active{
			opacity: 1;
			visibility: visible;
		}
	}
}