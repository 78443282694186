.diwali-banner-main{
    /* height: 100%; */
}
.diwali-banner-main img{
    /* height: 135%; */
    width: 100%;
    position: relative;
    top: -5%;
    cursor: pointer;
    transition: transform 0.3s ease;
    z-index: 9999;
}
.diwali-banner-main img:hover {
    transform: scale(1.05);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}
@media (max-width: 912px) {
    .diwali-banner-main img {
        width: 100%;  
        height: 18rem;
    }
}
@media (max-width: 480px) {
    .diwali-banner-main img {
        width: 100%; 
       height: 10rem;
    }
}