.main-diet-section{
  height: 100%;
}
.diet-form-container {
    display: flex;
    flex-wrap: wrap;
    width: 73%;
    height: 45rem;
    border-radius: 2rem;
    background-color: #FFF3EC ;
   background-image: url(../assets/images/background/bg3.png);
   background-size: cover;
    box-shadow: 0 4px 12px rgba(128, 128, 128, 0.4);  
    justify-content: center;
    position: relative;
    left: 15%;
   margin-top: 3rem;
    overflow: hidden;
  }

  .diet-invitation-form-container {
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    height: 40rem;
    border-radius: 2rem;
    background-color: #FFF3EC;
    background-image: url(../assets/images/background/bg3.png);
    background-size: cover;
    box-shadow: 0 4px 12px rgba(128, 128, 128, 0.4);
    justify-content: center;
    position: relative;
    left: 20%;
    margin-top: 1.5rem;
    overflow: hidden;
    margin-bottom: 1.8rem;
  }



  .grid-display {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
  }

  .flex-display-invitation-form {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
  }
  

  
  .section1{
    border-radius: 2rem;
    width: 40%;
    height: 100%;
    /* background: linear-gradient(#f06666, hsl(0, 95%, 46%)); */
    background: url(../assets/images/bgImg2.png);
    background-color: #DCF0E5;
    background-size: cover;
    box-shadow: 10px 0 10px rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  .section1 h4{
    border: 1px solid black;
    text-align: center;
    font-size: 2rem;
    color: black;
  }
  .meal-img{
    height: 20rem;
  }
  .animation1 {
    animation: rotateImage 3s linear infinite;
    position: relative;
    left: 38%;
    bottom: -18%;
  }
  @keyframes rotateImage {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .logo{
    /* border:1px solid black; */
    width: 50%;
    height: 8%;
    position: relative;
    top: 3%;
    left: 23%;
  }
.think-img{
  height: 25rem;
  position: relative;
  left: 3%;
  top:25%;
}
.comment-img{
  height:20rem;
  position: relative;
  left: 25%;
  top: 10%;
}

.diet-invitation-img{
  height:15rem;
  position: relative;
  left: 18%;
  top: 15%;
}

  .form-container{
    flex: 1 1 50%;
    padding: 60px;
    padding-top: 6rem;
   position: relative;
   overflow: hidden;
  }


  .steps-main{
    display: flex;
    /* border: 1px solid black; */
    margin-bottom: 0rem;
    justify-content: center;
  }
  .hr-line{
    color: black;
  }
  .steps-main button{
    background-color: #f7f7f7;
    margin-right: 1rem;
    border-radius: 0.5rem;
    width: 3rem;
    height: 3rem;
    border: none;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    /* border: 1px solid black; */
    max-width: 60%;
   height: 46rem;
  }

  .form-group {
    margin-bottom: 20px;
  }
  
  .form-control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
  
  .btn-diet-plan {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 0.6rem;
    color: white;
    background-color: #ff4848;
    border: none;
    width: 6rem;
    cursor: pointer;
    position: relative;
    left: 85%;
    top: 10%;
  }
  .btn-diet-plan-submit {
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 0.6rem;
    color: white;
    background-color: #ff4848;
    border: none;
    width: 6rem;
    cursor: pointer;
    position: absolute;
    left: 82%;
    top: 88%;
  }
  .img-ckhk{
    position: relative;
    top:-14%;
    left: 28%;
    width: 15rem;
  }
  .ckhk-image{
    height:4rem;
    width:14rem;
  }
 
  @media only screen and (max-width: 768px) {
    .main-diet-section {
      height: auto;
    }
  
    .diet-form-container {
      width: 100%;
      height: auto;
      left: 0;
      margin-top: 1.5rem;
      padding: 1rem;
      box-shadow: none;
    } 
    
    .diet-invitation-form-container {
      width: 100%;
      height: auto;
      left: 0;
      margin-top: 1.5rem;
      padding: 1rem;
      box-shadow: none;
    } 
    
    .grid-display {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  
    .section1 {
      width: 100%;
      height: auto;
      background-size: cover;
      margin-bottom: 1rem;
      box-shadow: none;
    }
  
    .section1 h4 {
      font-size: 1.5rem;
    }
  
    .meal-img {
      height: 15rem;
    }
  
    .animation1 {
      left: 50%;
      bottom: -10%;
      transform: translateX(-50%);
    }
  
    .logo {
      width: 60%;
      height: auto;
      top: 2%;
      left: 20%;
    }
  
    .think-img {
      height: 20rem;
      left: 0;
      top: 10%;
    }
  
    .comment-img {
      height: 15rem;
      left:22%;
      top: -3%;
    }

    .diet-invitation-img {
      height: 12rem;
      left:28%;
      top: 2%;
      margin-bottom: 12px;
    }
  
    .form-container {
      max-width: 100%;
      padding: 35px;
      height: auto;
    }
  
    .steps-main {
      flex-direction: row;
      margin-bottom: 1rem;
      margin-top: 2rem;
    }
  
    .steps-main button {
      margin-bottom: 1rem;
      width: 2.5rem;
      height: 2.5rem;
    }
  
    .btn-diet-plan {
      left: 50%;
      top: 5%;
      transform: translateX(-50%);
      width: 8rem;
    }
  
    .btn-diet-plan-submit {
      left: 50%;
      top: 80%;
      transform: translateX(-50%);
      width: 8rem;
    }
  
    .img-ckhk {
      top: 0;
    }
  
    .ckhk-image {
      height: 3rem;
      width: 11rem;
      position: relative;
      left: -15%;
    }
    .btn-diet-plan-submit{
      top:94%;
    }
  }
  
  .heelo{
    outline: none;
    margin-top: 0.3rem;
  }
  .css-tj5bde-Svg{
    color:rgb(128, 126, 126)!important;
  }
 

